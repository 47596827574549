import { IDiscountTypes } from "./../../../../models/Preliquidation/DiscountTypes";
import { useContext, useEffect, useState } from "react";

import { EconomicGroup } from "../../../../models/EconomicGroup";

import { getEconomicGroups } from "../../../../services/group.services";
import { deleteDuplicatedBusinessNames, getTransportCompanies } from "../../../../services/transportCompany.services";
import { TransportCompanyResponse } from "../../../../models/TransportCompany/TransportCompany";
import { getDiscountTypes } from "../../../../services/preliquidation.service";
import PreliquidationContext from "../contexts/PreliquidationContextProvider";

const useGetSelectableData = () => {
  const { economicGroup } = useContext(PreliquidationContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [economicGroups, setEconomicGroups] = useState<EconomicGroup[]>([]);
  const [transportCompanies, setTransportCompanies] = useState<TransportCompanyResponse[]>([]);
  const [economicGroupTransportCompanies, setEconomicGroupTransportCompanies] = useState<TransportCompanyResponse[]>(
    []
  );
  const [discountTypes, setDiscountTypes] = useState<IDiscountTypes[]>([]);

  useEffect(() => {
    setLoading(true);
    Promise.all([getEconomicGroups(), getTransportCompanies(), getDiscountTypes()])
      .then((response) => {
        const [economicGroups, transportCompanies, discountTypes] = response;
        setEconomicGroups(economicGroups);
        setTransportCompanies(deleteDuplicatedBusinessNames(transportCompanies));
        setDiscountTypes(discountTypes);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    Promise.resolve(getTransportCompanies({ economicGroupId: economicGroup })).then((response) =>
      setEconomicGroupTransportCompanies(response)
    );
  }, [economicGroup]);

  return {
    loading,
    economicGroups,
    transportCompanies,
    discountTypes,
    economicGroupTransportCompanies,
  };
};

export default useGetSelectableData;
