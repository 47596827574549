import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import warningAccountModalReducer from './reducers/warningAccountModalSlice';

export const store = configureStore({
  reducer: {
    warningAccountModal: warningAccountModalReducer,
  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
