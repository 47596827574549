import { TransactionReasonDTO } from "../DTO/checkingAccount/TransactionReason.dto"
import { GetCheckingAccountFilters, CheckingAccountPaginated, CheckingAccountServices } from "../models/CheckingAccount/CheckingAccount"
import { CheckingAccountDTO } from "../DTO/checkingAccount/CheckingAccount.dto"
import apiClient from "./api.client"
import { createQueryParams } from "../utils/http.utils"
import moment from "moment"

export const getCheckingAccounts = async ({ from, to, page, size, reason, transportCompanyId, economicGroupId }: GetCheckingAccountFilters): Promise<CheckingAccountPaginated> => {
  const params = createQueryParams({
    From: from,
    To: to,
    Page: page,
    PageSize: size,
    TransactionReasonId: reason,
    TransportCompanyId: transportCompanyId,
    EconomicGroupId: economicGroupId
  })
  const response = await apiClient.get<CheckingAccountDTO>(`/Accounts${params}`)
  const { data } = response
  return {
    page: data.page,
    pageSize: data.pageSize,
    pages: data.pages,
    total: data.totalItems,
    items: data.items.map(item => ({
      date: moment(item.createdOn).format('DD-MM-YYYY'),
      reason: item.transactionReason,
      description: item.description ?? '',
      amount: item.amount,
      balance: item.balance
    })),
    totalBalance: data.totalAmount
  }
}

export const getTransactionReason = async () => {
  const response = await apiClient.get<TransactionReasonDTO[]>('/transactionReasons')
  return response.data
}

const checkingAccountServices: CheckingAccountServices = {
  getTransactionReason,
  getCheckingAccounts 
}

export default checkingAccountServices