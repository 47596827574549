import { useContext, useEffect, useState } from "react"

import { Modal, message } from "antd"

import ManagePaymentsContext from "../../contexts/ManagePaymentsContextProvider"
import { getPaymentsById, updatePayment } from "../../../../../services/payment.services"

import { errorMessages } from "../../../../../models/Errors/Errors"
import PaymentForm from "../../../payments/components/paymentForm/PaymentForm"
import { PaymentsDetails } from "../../../../../models/Payment/Payment"
import { ErrorResponse } from "../../../../../models/Errors/ErrorResponse"

const UpdatePaymentModal = () => {
  const [payment, setPayment] = useState<PaymentsDetails>()
  const [loading, setLoading] = useState<boolean>(true)
  const {
    isUpdatePaymentModalOpen,
    openUpdatePaymentModal,
    closeUpdatePaymentModal,
    handleUpdateTable,
    selectedPayment
  } = useContext(ManagePaymentsContext)

  const handleSubmit = async (values: any) => {
    try {
      const document = values.document
      const file = document ? document[0] : {}
      const data = {
        jsonData: {
          amount: values.amount,
          description: values.description || '',
          economicGroupId: values.group ?? null,
          externalId: values.payId,
          fundsSource: values.sourceFunds || null,
          methodId: values.methodPayment,
          paidAt: values.paymentDate,
          transportCompanyId: values.transporter ?? null
        },
        file: file.originFileObj
      }
      await updatePayment(selectedPayment?.id ?? '', data)
      message.success('Pago actualizado exitosamente')
      handleUpdateTable({})
      closeUpdatePaymentModal()
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data
      const type = errorResponse.Type
      const errorMessage = errorMessages[type] ?? errorMessages.tryAgain
      message.error(errorMessage)
    }

  }

  const getSelectedPayment = async (paymentId: string) => {
    try {
      setLoading(true)
      const response = await getPaymentsById(paymentId)
      setPayment(response)
    } catch {

    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    selectedPayment?.id && getSelectedPayment(selectedPayment?.id)
    return () => {
      setPayment(undefined)
    }
  }, [selectedPayment?.id])

  return (
    <Modal
      open={isUpdatePaymentModalOpen}
      onOk={openUpdatePaymentModal}
      onCancel={closeUpdatePaymentModal}
      footer={null}
      width={1000}
    >
      <PaymentForm
        loadingData={loading}
        hanldeSubmit={handleSubmit}
        amount={payment?.amount}
        payId={payment?.externalId}
        paymentDate={payment?.paidAt}
        paymentMethodId={payment?.methodId}
        sourceFunds={payment?.fundsSource}
        description={payment?.description}
        economicGroupId={payment?.economicGroupId}
        transportCompanyId={payment?.economicGroupId ? null : payment?.transportCompanyId}
        document={payment?.proof}
      />
    </Modal>
  )
}

export default UpdatePaymentModal