import { useContext, useState } from "react";
import { Typography, Button, Table, Tooltip, message } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloudDownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import moment from "moment";

import SkeletonTable from "../../../../shared/skeletonTable/SkeletonTable";

import ManagePaymentsContext from "../../contexts/ManagePaymentsContextProvider";

import { toARSCurrency } from "../../../../../utils/number.utils";
import { getPaymentStateColors } from "../../../../../utils/paymentStateColor";

import { PaymentIds } from "../../../../../constants/payments";
import { COLORS } from "../../../../../constants/colors";

import { generateProofPayment } from "../../../../../services/payment.services";

import { errorMessages } from "../../../../../models/Errors/Errors";

const { Text } = Typography;

const ManagePaymentsTable = () => {
  const [downloadingDocument, setDownloadingDocument] =
    useState<boolean>(false);
  const {
    initialLoading,
    loading,
    payments,
    handleUpdateTable,
    openApprovedPaymentsModal,
    openRejectedPaymentsModal,
    openUpdatePaymentModal,
    handleSelectPayment,
  } = useContext(ManagePaymentsContext);

  if (initialLoading) {
    return (
      <div className="manage-payments-table layer">
        <SkeletonTable numberOfColumns={9} numberOfRows={10} />
      </div>
    );
  }

  const handleGenerateProofPayment = async (proofId: string) => {
    try {
      setDownloadingDocument(true);
      const { data, headers } = await generateProofPayment(proofId);

      const blob = new Blob([data], { type: headers["content-type"] });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      const contentDisposition = headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition
            .split("filename=")[1]
            .split(";")[0]
            .replace(/"/g, "")
        : "";
      const extension = filename ? filename.split(".").pop() : "";

      link.setAttribute("download", `${proofId}.${extension}`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (e: any) {
      message.error(errorMessages.tryAgain);
    } finally {
      setDownloadingDocument(false);
    }
  };

  return (
    <div className="manage-payments-table layer">
      <Table
        loading={loading || downloadingDocument}
        columns={[
          {
            title: "ID",
            dataIndex: "externalId",
            key: "externalId",
            render: (externalId) => externalId,
          },
          {
            title: "Fecha Creación",
            dataIndex: "creationDate",
            key: "creationDate",
            render: (creationDate) => creationDate,
          },
          {
            title: "Fecha Pago",
            dataIndex: "paymentDate",
            key: "paymentDate",
            sorter: (a, b) =>
              moment(a.paymentDate, "YYYY/MM/DD").isBefore(
                moment(b.paymentDate, "YYYY/MM/DD")
              )
                ? -1
                : 1,
            render: (paymentDate) => paymentDate,
          },
          {
            title: "Medio de Pago",
            dataIndex: "paymentMethod",
            key: "paymentMethod",
            render: (paymentMethod) => paymentMethod,
          },
          {
            title: 'Compañia',
            dataIndex: 'company',
            key: 'company',
            width: '250px',
            render: (company) => company.name
          },
          {
            title: "Estado",
            dataIndex: "status",
            key: "status.id",
            sorter: (a, b) =>
              a.status.description.localeCompare(b.status.description),
            width: "180px",
            render: (status) => {
              const color = getPaymentStateColors(status.id as PaymentIds);
              return <Text style={{ color }}>{status.description}</Text>;
            },
          },
          {
            title: "Importe",
            dataIndex: "amount",
            key: "amount",
            render: (amount) => toARSCurrency(amount),
          },
          {
            title: "Acciones",
            dataIndex: "status",
            align: "center",
            fixed: "right",
            render: (status, payment) => {
              return (
                <>
                  <Tooltip placement="top" title={"Aprobar pago"}>
                    <Button
                      disabled={status.id !== PaymentIds.Pending}
                      type="link"
                      icon={
                        <CheckCircleOutlined
                          style={{
                            color:
                              !(status.id === PaymentIds.Pending) ||
                              status.id === PaymentIds.Rejected
                                ? ""
                                : COLORS.GREEN,
                          }}
                        />
                      }
                      onClick={() => {
                        handleSelectPayment(payment);
                        openApprovedPaymentsModal();
                      }}
                    />
                  </Tooltip>
                  <Tooltip placement="top" title={"Rechazar pago"}>
                    <Button
                      disabled={status.id !== PaymentIds.Pending}
                      danger={!(status.id !== PaymentIds.Pending)}
                      type="link"
                      icon={<CloseCircleOutlined />}
                      onClick={() => {
                        handleSelectPayment(payment);
                        openRejectedPaymentsModal();
                      }}
                    />
                  </Tooltip>
                  <Tooltip placement="top" title={"Editar"}>
                    <Button
                      disabled={status.id !== PaymentIds.Pending}
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => {
                        handleSelectPayment(payment);
                        openUpdatePaymentModal();
                      }}
                    />
                  </Tooltip>
                </>
              );
            },
          },
          {
            title: "Comprobantes",
            align: "center",
            fixed: "right",
            render: (_, payment) => {
              const title = payment.proofId
                ? "Descargar comprobante"
                : "Comprobante no disponible";
              return (
                <Tooltip placement="top" title={title}>
                  <Button
                    disabled={!payment.proofId}
                    type="link"
                    icon={<CloudDownloadOutlined />}
                    onClick={() => {
                      handleGenerateProofPayment(payment.proofId);
                    }}
                  />
                </Tooltip>
              );
            },
          },
        ]}
        dataSource={payments?.items.map((p, i) => ({
          ...p,
          key: `${i}-${p.id}`,
        }))}
        scroll={{ x: true }}
        pagination={{
          position: ["bottomCenter"],
          total: payments?.total,
          pageSize: payments?.pageSize,
          locale: { next_page: "Próxima página", prev_page: "Página anterior" },
          current: payments?.page,
          onChange: (page: number, pageSize: number) =>
            handleUpdateTable({ page, pageSize }),
        }}
      />
    </div>
  );
};

export default ManagePaymentsTable;
