import { Alert, Layout, Typography } from "antd"
import { Content } from "antd/es/layout/layout"

import { ProtectionContextProvider } from "./context/ProtectionContextProvider" 
import ProtectionForm from "./components/ProtectionForm/ProtectionForm"
import ProtectionTable from "./components/ProtectionTable/ProtectionTable"

import './protection.scss'

const { Title } = Typography

const Protection = () => {
  return (
    <Layout>
      <Layout>
        <Content className="protection-content">
          <Title level={2}>Protección</Title>
          <div className="protection-content__content-alert">
            <Alert
              message="Seleccione el archivo xlsx que desea importar para actualizar el estado de cobranza “Protección”"
              type="info"
            />
          </div>
          <div className="layer">
            <ProtectionContextProvider>
              <ProtectionForm />
              <ProtectionTable />
            </ProtectionContextProvider>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default Protection