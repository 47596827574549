import { Alert, Layout, Typography } from "antd"
import { Content } from "antd/es/layout/layout"

import { EndorsementAnnulmentContextProvider } from "./context/EndorsementAnnulmentContextProvider"
import AnnulEndorsementsForm from "./components/annulEndorsementsFiltersForm/AnnulEndorsementsForm"
import AnnulmentedendorsementsTable from "./components/annulmentedendorsementsTable/AnnulmentedendorsementsTable"

import './endorsementAnnulment.scss'

const { Title } = Typography

const EndorsomentAnnulment = () => {
  return (
    <Layout>
      <Content className="endorsement-annulment-content">
        <Title level={2}>Anular endosos</Title>
        <div className="endorsement-annulment-content__content-alert">
          <Alert
            message="Seleccione el archivo xlsx que desea importar para anular los endosos."
            type="info"
          />
        </div>
        <div className="endorsement-annulment-content layer">
          <EndorsementAnnulmentContextProvider>
            <AnnulEndorsementsForm />
            <AnnulmentedendorsementsTable />
          </EndorsementAnnulmentContextProvider>
        </div>
      </Content>
    </Layout>
  )
}

export default EndorsomentAnnulment
