import {
  ApplyDiscountParams,
  ApplyDiscountResponse,
  GeneratePreliquidationDraftParams,
  PreliquidationCreatedDTO,
  PreliquidationDTO,
  PreliquidationDetailsDTO,
  PreliquidationTripsDTO,
  PreliquidationUpdatedDTO,
} from "../DTO/preliquidation/preliquidation.dto";
import dayjs from "dayjs";
import {
  PreliquidationDiscount,
  PreliquidationItem,
  PreliquidationServices,
  PreliquidationTripsFilters,
  PreliquidationsFiltersForm,
} from "../models/Preliquidation/Preliquidation";
import { IDiscountTypes } from "../models/Preliquidation/DiscountTypes";
import { PreliquidationState } from "../models/Preliquidation/PreliquidationState";
import apiClient, { ApiDateFormat } from "./api.client";
import { createQueryParams } from "../utils/http.utils";
import moment from "moment";
import { Key } from "react";

export const generatePreliquidationDraft = async (
  params: GeneratePreliquidationDraftParams
): Promise<PreliquidationCreatedDTO> => {
  const requestParams = {
    transportCompanyId: params.transportCompanyId ?? null,
    economicGroupId: params.economicGroupId ?? null,
    excludedTransportCompaniesIds: params.excludedTransportCompaniesIds ?? null,
    deadline: moment(params.deadline).format("YYYY-MM-DD"),
  };
  const response = await apiClient.post(`/Preliquidations`, requestParams);
  return response?.data;
};

export const savePreliquidationPending = async (preliquidationId: string): Promise<PreliquidationUpdatedDTO> => {
  const response = await apiClient.post(`/Preliquidations/${preliquidationId}/Pending`);
  return response?.data;
};

export const getPreliquidationById = async (preliquidationId: string): Promise<PreliquidationItem> => {
  const response = await apiClient.get<PreliquidationItem>(`/Preliquidations/${preliquidationId}`);
  return response.data;
};

export const getTrips = async (
  preliquidationId: string,
  filters: PreliquidationTripsFilters,
  page: number,
  pageSize: number
): Promise<PreliquidationTripsDTO> => {
  const params = {
    Page: page ?? null,
    PageSize: pageSize ?? null,
    TripId: filters?.tripId ?? null,
    Amount: filters?.amount ?? null,
    FromClosedAt:
      filters.closedDate && filters.closedDate[0] ? dayjs(filters.closedDate[0]).format("YYYY-MM-DD") : null,
    ToClosedAt: filters.closedDate && filters.closedDate[1] ? dayjs(filters.closedDate[1]).format("YYYY-MM-DD") : null,
  };

  const queryParams = filters ? createQueryParams(params as any) : "";
  const response = await apiClient.get<PreliquidationTripsDTO>(
    `/Preliquidations/${preliquidationId}/Trips${queryParams}`
  );
  return response.data;
};

export const getPreliquidations = async (
  filters: PreliquidationsFiltersForm,
  page: number,
  pageSize: number
): Promise<PreliquidationDTO> => {
  const { preliquidationId, createdAt, preliquidationStatus, closedDate, transportCompanyId } = filters ?? {};

  const params = {
    PreliquidationId: preliquidationId ?? undefined,
    PreliquidationDate: createdAt ? dayjs(createdAt).format(ApiDateFormat) : undefined,
    PreliquidationStateId: preliquidationStatus ?? undefined,
    DeadLineFrom: closedDate && closedDate[0] ? dayjs(closedDate[0]).format(ApiDateFormat) : undefined,
    DeadLineTo: closedDate && closedDate[1] ? dayjs(closedDate[1]).format(ApiDateFormat) : undefined,
    TransportCompanyId: transportCompanyId ?? undefined,
    EconomicGroupId: filters?.economicGroupId ?? undefined,
    Page: page,
    PageSize: pageSize,
  };

  const queryParams = filters ? createQueryParams(params as any) : "";
  const response = await apiClient.get<PreliquidationDTO>(`/Preliquidations${queryParams}`);
  const data = response.data;

  return {
    page: data.page,
    pageSize: data.pageSize,
    pages: data.pages,
    totalItems: data.totalItems,
    items: data.items.map((preliquidation) => {
      return {
        id: preliquidation.id,
        deadline: preliquidation.deadline ?? "",
        createdAt: preliquidation.createdAt ?? "",
        transportCompany: preliquidation.transportCompany,
        economicGroup: preliquidation.economicGroup,
        totalAmount: preliquidation.totalAmount,
        pendingBalance: preliquidation.pendingBalance,
        preliquidationState: preliquidation.preliquidationState,
        canConfirm: preliquidation.canConfirm,
        canReject: preliquidation.canReject,
        canEdit: preliquidation.canEdit,
      };
    }),
  };
};

export const removeTrip = async (preliquidationId: string, tripId: string): Promise<boolean> => {
  await apiClient.delete(`/Preliquidations/${preliquidationId}/Trip/${tripId}`);
  return true;
};

export const removeMultipleTrips = async (preliquidationId: string, tripIds: Key[]): Promise<boolean> => {
  let apiCall = `/Preliquidations/${preliquidationId}/Trips?`;
  apiCall = apiCall.concat(tripIds.map((tripId) => `tripIds=${tripId}`).join("&"));
  await apiClient.delete(apiCall);
  return true;
};

export const getDiscountTypes = async (): Promise<IDiscountTypes[]> => {
  const response = await apiClient.get<IDiscountTypes[]>(`/DiscountTypes`);
  const { data } = response;
  return data.sort((a, b) => a.description.localeCompare(b.description));
};

export const getDetails = async (preliquidationId: string): Promise<PreliquidationDetailsDTO> => {
  const response = await apiClient.get(`/Preliquidations/${preliquidationId}/Details`);
  return response.data;
};

export const applyDiscount = async (
  preliquidationId: string,
  params: ApplyDiscountParams
): Promise<ApplyDiscountResponse> => {
  const response = await apiClient.patch(`/Preliquidations/${preliquidationId}/Discount`, params);
  return response.data;
};

export const getDiscounts = async (preliquidationId: string): Promise<PreliquidationDiscount[]> => {
  const response = await apiClient.get(`/Preliquidations/${preliquidationId}/Discounts`);
  const { data } = response;
  return data;
};

export const removeDiscount = async (preliquidationId: string, discountId: string): Promise<boolean> => {
  await apiClient.delete(`/Preliquidations/${preliquidationId}/Discount/${discountId}`);
  return true;
};

export const getStates = async (): Promise<PreliquidationState[]> => {
  const response = await apiClient.get<PreliquidationState[]>(`/Preliquidations/States`);
  const { data } = response;
  return data.sort((a, b) => a.description.localeCompare(b.description));
};

export const approvePreliquidation = async (preliquidationId: string): Promise<boolean> => {
  const response = await apiClient.post(`/Preliquidations/${preliquidationId}/confirm`);
  return response.data;
};

export const rejectPreliquidation = async (preliquidationId: string, message: string): Promise<boolean> => {
  const response = await apiClient.post(`/Preliquidations/${preliquidationId}/rejections`, { message });
  return response.data;
};

const preliquidationServices: PreliquidationServices = {
  getTrips,
  removeTrip,
  getDiscountTypes,
  getDetails,
  applyDiscount,
  removeDiscount,
  getDiscounts,
  generatePreliquidationDraft,
  savePreliquidationPending,
  getPreliquidations,
  getStates,
  approvePreliquidation,
  rejectPreliquidation,
  getPreliquidationById,
  removeMultipleTrips,
};

export default preliquidationServices;
