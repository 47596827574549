import { Typography, Skeleton } from "antd"

import { getCollectionStateColors } from "../../../../../utils/collectionStateColors"
import { CollectionStateIds } from "../../../../../constants/collectionState"

import './paymentDetailHeader.scss'

const { Paragraph, Text } = Typography

interface PaymentDetailHeaderProps {
  tripId: string
  collectionStateDescription: string
  arrivalDate: string
  departureDate: string
  loading: boolean
  collectionStateId: CollectionStateIds
}

const PaymentDetailHeader: React.FC<PaymentDetailHeaderProps> = ({
  tripId,
  collectionStateDescription,
  collectionStateId,
  arrivalDate,
  departureDate,
  loading
}) => {
  return (
    <div className="layer">
      {
        loading 
          ? 
            <Skeleton active paragraph={false} className="skeleton" />
          : <Paragraph strong>ID de Viaje: <Text>{tripId}</Text> </Paragraph>
      }

      {
        loading 
          ? 
            <Skeleton active paragraph={false} className="skeleton" />
          : <Paragraph strong>Estado de cobranza: <Text strong style={{ color: getCollectionStateColors(collectionStateId) }}>{collectionStateDescription}</Text> </Paragraph>
      }

      {
        loading 
          ? 
            <Skeleton active paragraph={false} className="skeleton" />
          : <Paragraph strong>Fecha de salida: <Text>{departureDate}</Text> </Paragraph>
      }

      {
        loading 
          ? 
            <Skeleton active paragraph={false} className="skeleton" />
          : <Paragraph strong>Fecha de llegada: <Text>{arrivalDate}</Text> </Paragraph>
      }
    </div>
  )
}
export default PaymentDetailHeader