import { useContext } from "react"

import { Table, Typography } from "antd"

import EndorsementRejectionContext from "../../context/EndorsementRejectionContextProvider"

import { COLORS } from "../../../../../constants/colors"

const { Paragraph, Text } = Typography

const RejectEndorsementsTable = () => {
  const { rejectedEndorsements, loading } = useContext(EndorsementRejectionContext)

  return (
    <>
      <Paragraph strong>Resultados:</Paragraph>
      <Table
      loading={loading}
      columns={[
        { title: 'Fila', dataIndex: 'row' },
        { title: 'ExternalId', dataIndex: 'externalId' },
        { title: 'ServiceTypeId', dataIndex: 'serviceTypeId' },
        {
          title: 'Resultado',
          dataIndex: 'message',
          render: (message, data) => {
            const colorAndStatus = data.success ? {color: COLORS.GREEN, status: 'El viaje ha sido rechazado correctamente.'} : { color: COLORS.RED, status: 'ERROR:'}
            return <Text style={{ color: colorAndStatus.color }} strong>{`${colorAndStatus.status} ${message}`}</Text>
          }
        }
      ]}
      dataSource={rejectedEndorsements.map((rejectedEndorsement, index) => {
        return {
          ...rejectedEndorsement,
          key: `${rejectedEndorsement.externalId}-${index}`
        }
      })}
    />
    </>
  )
}

export default RejectEndorsementsTable