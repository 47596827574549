import { Layout, Typography } from "antd";
import { Content } from "antd/es/layout/layout";

import { ManagePreliquidationContextProvider } from "./contexts/ManagePreliquidationContextProvider";

import "./preliquidations.scss";
import ManagePreliquidationsFilters from "./components/ManagePreliquidationsFilters/ManagePreliquidationsFilters";
import ManagePreliquidationsTable from "./components/ManagePreliquidationsTable/ManagePreliquidationsTable";
import PreliquidationApprovalModal from "./components/PreliquidationApprovalModal/PreliquidationApprovalModal";
import PreliquidationRejectionModal from "./components/PreliquidationRejectionModal/PreliquidationRejectionModal";

const { Title } = Typography;

const ManagePreliquidations = () => {
  return (
    <Layout>
      <Content className="manage-preliquidations-content">
        <Title level={2}>Gestionar preliquidaciones</Title>
        <ManagePreliquidationContextProvider>
          <ManagePreliquidationsFilters />
          <ManagePreliquidationsTable />
          <PreliquidationApprovalModal />
          <PreliquidationRejectionModal />
        </ManagePreliquidationContextProvider>
      </Content>
    </Layout>
  );
};

export default ManagePreliquidations;
