import { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import apiClient from "../../../services/api.client";
import notification from "../notification/Notification";
import { getMessageErrors } from "./errors";
import { useNavigate } from "react-router-dom";
import "./DocumentForm.scss";

type documentType = {
	id: number;
	description: string;
};

const validateMessages = {
	required: "${label} es requerido",
	types: {
		email: "El correo electrónico debe poseer una estructura válida",
	},
	number: {
		range: "El ${label} debe tener ${max}",
	},
};

function DocumentForm({
	setDocumentConfirmed,
	setDocumentSelected,
	setError,
}: any) {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [documentTypes, setDocumentTypes] = useState<documentType[] | null>(
		null,
	);
	const [loading, setLoading] = useState(false);

	const validatedocument = ({
		user,
	}: {
		user: { documentNumber: string; documentTypeId: number };
	}) => {
		setLoading(true);
		apiClient
			.get(
				`/TransportCompanies/Existence?documentNumber=${user.documentNumber}`,
			)
			.then((response) => {
				setLoading(false);
				setDocumentSelected(user);
				switch (response.data) {
					case 1:
						notification(
							"info",
							"No tenemos conocimiento de empresa transportista con el CUIT indicado",
						);
						break;
					case 2:
						setDocumentConfirmed(true);
						break;
					case 3:
						notification(
							"info",
							"Ya existe una compañia transportista para el CUIT ingresado",
						);
						break;
					default:
						break;
				}
			})
			.catch((error) => {
				setLoading(false);

				if (error.response) {
					const { Type } = error.response.data;
					if (Type) {
						setError(getMessageErrors(Type));
					} else {
						notification("error", "Hubo un error procesando su solicitud");
					}
				} else {
					notification("error", "Hubo un error procesando su solicitud");
				}
			});
	};

	useEffect(() => {
		//Obtener CUIT Y CUIL
		apiClient
			.get("/DocumentTypes")
			.then((response: any) => {
				setDocumentTypes(response.data as documentType[]);
				form.setFieldsValue({
					user: { documentTypeId: 1 },
				});
			})
			.catch((error: any) => console.log("error:", error));
	}, []);

	return (
		<Form
			form={form}
			name="basic"
			onFinish={validatedocument}
			validateMessages={validateMessages}
			autoComplete="off"
			className="documentFormModal"
			requiredMark
		>
			<Row justify="center">
				<Col>
					<Form.Item
						name={["user", "documentTypeId"]}
						label="Tipo de Documento"
						required
					>
						<Select>
							{documentTypes?.map((documentType) => (
								<Select.Option
									key={documentType.id}
									value={documentType.id}
									disabled
								>
									{documentType.description}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col md={{ span: 6, offset: 1 }} xs={{ span: 22 }}>
					<Form.Item
						name={["user", "documentNumber"]}
						label="CUIT"
						rules={[
							{
								required: true,
								type: "string",
							},
							{
								pattern: /^(?:\d*)$/,
								message: "El Número de Documento debe ser numérico",
							},
							{
								pattern: /^[\d]{11}$/,
								message: "El Número de Documento debe contener 11 dígitos",
							},
						]}
					>
						<Input style={{ width: "100%" }} maxLength={11} />
					</Form.Item>
				</Col>
			</Row>
			<Row style={{ flexDirection: "column", alignItems: "center" }}>
				<Form.Item
					shouldUpdate
					className="w75"
					style={{ marginBottom: "15px" }}
				>
					{({ isFieldsTouched, getFieldsError }) => {
						const fieldsError = getFieldsError().filter(
							({ errors }) => errors.length,
						);
						return (
							<Button
								type="primary"
								htmlType="submit"
								style={{ marginTop: 24 }}
								disabled={!isFieldsTouched(true) || fieldsError.length > 0}
								loading={loading}
								block
							>
								Continuar
							</Button>
						);
					}}
				</Form.Item>
				<Form.Item className="w75">
					<Button
						ghost
						className="btn-ghost"
						onClick={() => navigate("/")}
						block
					>
						Volver
					</Button>
				</Form.Item>
			</Row>
		</Form>
	);
}

export default DocumentForm;
