import { useContext, useState } from "react"
import { Form, Select, Space, DatePicker, Button, Typography, AutoComplete, Tooltip } from "antd"
import { ClearOutlined, SearchOutlined } from "@ant-design/icons"

import CheckingAccountContext from "../../context/CheckinContextProvider"

import { DefaultDateFormat } from "../../../../../models/Date"
import { toARSCurrency } from "../../../../../utils/number.utils"
import useCheckingAccountFilters from "../../hooks/useCheckingAccountFilters"
import FiltersSkeleton from "../filtersSkeleton/FiltersSkeleton"
import PrivateComponent from "../../../privateComponent/PrivateComponent"
import { BASSA_ADMIN } from "../../../../../constants/roles"
import { useForm } from "antd/es/form/Form"
import { AuthContext } from "../../../../context/AuthContext"

const { RangePicker } = DatePicker;
const { Text } = Typography;

interface FormValues {
  dates?: [moment.Moment, moment.Moment] | undefined
  reason?: string | undefined
  transportCompanyId?: string | undefined
  economicGroupId?: string | undefined
}

const CheckingAccountFilters = () => {
  const { role } = useContext(AuthContext)
  const isAdminUser = role === BASSA_ADMIN
  const { transactionReason, economicGroups, transportCompanies, loading } = useCheckingAccountFilters()
  const [selectedEconomicGroupId, setSelectedEconomicGroupId] = useState<string>()
  const [selectedTransportCompanyId, setSelectedTransportCompanyId] = useState<string>()
  const { handleChangeFilters, checkingAccount, initialLoading } = useContext(CheckingAccountContext)
  const [form] = useForm()

  const handleClickClear = () => {
    setSelectedEconomicGroupId(undefined)
    setSelectedTransportCompanyId(undefined)
  }

  const handleChange = (values: FormValues) => {
    const { dates, reason } = values
    
    const from = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : undefined
    const to = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : undefined
    handleChangeFilters({
      from,
      to,
      reason,
      transportCompanyId: selectedTransportCompanyId,
      economicGroupId: selectedEconomicGroupId
    })
  }

  const handleTransportCompanyChange = (value: string, option: any) => {
    if (option) {
      const transportCompanyId = transportCompanies.find(transportCompany => transportCompany.name === value)?.id
      setSelectedTransportCompanyId(transportCompanyId)
      setSelectedEconomicGroupId(undefined)
      form.setFieldValue('economicGroupId', undefined)
    } else {
      setSelectedTransportCompanyId(undefined)
    }
  }
  
  const handleEconomicGroupChange = (value: string, option: any) => {
    if (option) {
      const economicGroupId = economicGroups.find(economicGroup => economicGroup.name === value)?.id
      setSelectedEconomicGroupId(economicGroupId)
      setSelectedTransportCompanyId(undefined)
      form.setFieldValue('transportCompanyId', undefined)
    } else {
      setSelectedEconomicGroupId(undefined)
    }
  }

  if (loading || initialLoading) {
    return <FiltersSkeleton />
  }

  return (
    <div className="layer">
      <Form name="checkingAccountFiltersForm" layout="vertical" onFinish={handleChange} form={form}>
        <div style={{ textAlign: 'right' }}>
          <Text strong>Saldo:</Text>
          <Text type={checkingAccount && checkingAccount?.totalBalance > 0 ? 'success' : 'danger'} strong>
            {toARSCurrency(checkingAccount?.totalBalance)}
          </Text>
        </div>
        <div>
          <Space wrap>
            <Form.Item label="Fecha" name="dates">
              <RangePicker
                placeholder={["Desde", "Hasta"]}
                format={DefaultDateFormat}
                allowEmpty={[true, true]}
                allowClear
              />
            </Form.Item>

            <PrivateComponent roles={[BASSA_ADMIN]}>
              <Form.Item label="Transportista" name="transportCompanyId">
                <AutoComplete
                  placeholder="Seleccione..."
                  options={transportCompanies.map(transportCompany => ({ value: transportCompany.name, label: transportCompany.name}))}
                  filterOption={(inputValue, option) => {
                    return option!.label.toLowerCase().includes(inputValue.toLowerCase())
                  }}
                  onChange={handleTransportCompanyChange}
                  allowClear
                  disabled={loading}
                  style={{ width: '215px' }}
                />
              </Form.Item>
            </PrivateComponent>

            <PrivateComponent roles={[BASSA_ADMIN]}>
              <Form.Item label="Grupo economico" name="economicGroupId">
                <AutoComplete
                  placeholder="Seleccione..."
                  options={economicGroups.map(economicGroup => ({ value: economicGroup.name, label: economicGroup.name }))}
                  onChange={handleEconomicGroupChange}
                  filterOption={(inputValue, option) => {
                    return option!.label.toLowerCase().includes(inputValue.toLowerCase())
                  }}
                  allowClear
                  disabled={loading}
                  style={{ width: '215px' }}
                />
              </Form.Item>
            </PrivateComponent>

            <Form.Item
              name="reason"
              label="Motivo"
            >
              <Select
                loading={loading}
                disabled={loading}
                placeholder="Seleccione..."
                options={transactionReason.map(({ description, id }) => ({ label: description, value: id }))}
                style={{ width: '215px' }}
              />
            </Form.Item>
          </Space>
        </div>

        <Space wrap>
          <Form.Item>
            <Tooltip
              placement="topRight"
              title={isAdminUser && !selectedEconomicGroupId && !selectedTransportCompanyId ? 'Debe seleccionar una empresa transportista o un grupo economico' : ''}>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
                disabled={isAdminUser && !selectedEconomicGroupId && !selectedTransportCompanyId}
              >
                Buscar
              </Button>
            </Tooltip>
          </Form.Item>

          <Form.Item>
            <Button
              type="default"
              htmlType="reset"
              icon={<ClearOutlined />}
              onClick={handleClickClear}
            >
              Limpiar
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  )
}

export default CheckingAccountFilters