import { useParams } from "react-router-dom";
import moment from "moment";

import { Card, Row, Layout, Typography, Descriptions, Col, Space } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import GoBackTitle from "../../shared/goBackTitle/GoBackTitle";


import { DefaultDateFormat } from "../../../models/Date";

import useTrip from "./hook/useTrip";

import "./TripDetail.scss";

const { Content } = Layout;
const { Title, Text } = Typography;

const TripDetail = () => {
  const { id } = useParams();
  const { trip, loading } = useTrip({ id })

  const DashIfNull = (value: any) => value ?? "-";

  return (
    <Layout>
      <Content className="tripDetailContent">

        <GoBackTitle title="Detalle de viaje" goTo={"/trips"} />

        <Row justify="center" style={{ width: "100%", marginBottom: 30 }}>
          <Col xs={24} md={24} xl={20} xxl={16} className="text-center">
            <Space direction="vertical" size={20}>
              <Card loading={loading} style={{ width: loading ? 320 : "" }}>
                <div className="tripCards">
                  <div className="departureCard">
                    <ArrowRightOutlined className="circle" style={{ color: "#fff" }} />
                    <Title level={4}>Origen</Title>
                    <Text>{trip?.fromCity}</Text>
                    <Text>{DashIfNull(trip?.departureAt)}hs</Text>
                  </div>
                  <div className="arrivalCard">
                    <Title level={4}>Destino </Title>
                    <Text>{trip?.toCity}</Text>
                    <Text>{DashIfNull(trip?.arrivalAt)}hs</Text>
                  </div>
                </div>
                <Descriptions labelStyle={{ color: "grey" }} layout="vertical" size="middle" column={{ xs: 2, sm: 2, md: 3, xxl: 4 }} colon={false}>
                  <Descriptions.Item label="Tipo de Servicio" children={DashIfNull(trip?.serviceType?.description)} />
                  <Descriptions.Item label="Número de Lista" children={DashIfNull(trip?.externalId)} />
                  <Descriptions.Item label="Cantidad de Pasajeros" children={DashIfNull(trip?.passengerCount)} />
                  <Descriptions.Item label="Modalidad" children={DashIfNull(trip?.modality?.description)} />
                </Descriptions>
              </Card>

              <Row gutter={[16, 16]}>
                <Col xs={24} md={24} xl={15} xxl={15}>
                  <Card loading={loading}>
                    <Descriptions title="Transportista" labelStyle={{ color: "grey" }} layout="vertical" size="middle" column={{ xs: 2, sm: 2, md: 3, xxl: 3}} colon={false}>
                      <Descriptions.Item label="Razón Social" children={DashIfNull(trip?.transportCompany?.businessName)} />
                      <Descriptions.Item label="Número de Empresa" children={DashIfNull(trip?.transportCompany?.companyNumber)} />
                      <Descriptions.Item label="CUIT" children={DashIfNull(trip?.transportCompany?.documentNumber)} />
                    </Descriptions>
                  </Card>
                </Col>

                <Col xs={24} md={24} xl={9} xxl={9}>
                  <Card loading={loading}>
                    <Descriptions title="Datos del Vehículo" labelStyle={{ color: "grey" }} layout="vertical" size="middle" column={{ xs: 2, sm: 2 }} colon={false}>
                      <Descriptions.Item label="Dominio" children={DashIfNull(trip?.vehicleInfo?.patentNumber)} />
                      <Descriptions.Item label="Interno" children={DashIfNull(trip?.vehicleInfo?.internNumber)} />
                    </Descriptions>
                  </Card>
                </Col>
              </Row>

              <Card loading={loading}>
                <Descriptions
                  title="Seguro"
                  labelStyle={{ color: "grey" }}
                  layout="vertical"
                  size="middle"
                  column={{ xs: 1, sm: 2, md: 5, xxl: 5 }}
                  colon={false}
                  className="description-footer"
                >
                  <Descriptions.Item label="Aseguradora" children={DashIfNull(trip?.insurancePolicy?.insuranceCompany?.businessName)} />
                  <Descriptions.Item label="Póliza CNRT" children={DashIfNull(trip?.insurancePolicy?.policyNumber)} />
                  <Descriptions.Item label="Póliza compañía" children={DashIfNull(trip?.insurancePolicyByIssuer?.policyNumber)} />
                  <Descriptions.Item label="Fecha de Vencimiento" children={DashIfNull(moment(trip?.insurancePolicy?.expirationDate)?.format(DefaultDateFormat))} />
                  <Descriptions.Item label="Endoso" children={DashIfNull(trip?.endorsement?.number)} />
                </Descriptions> 
              </Card>
            </Space>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default TripDetail;
