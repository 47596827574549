import { BankAccount } from "../BankAccount";
import { Phone } from "../Phone";
import { TransportCompany } from "./TransportCompany";

export class UpdateTransportCompanyRequest {
  constructor(
    public acceptedTermId: string,
    public bankAccounts: BankAccount[],
    public email: string,
    public phone: Phone,
    public recaptchaToken: string
  ) { }

  static create(transportCompany: TransportCompany, recaptchaToken: string) : UpdateTransportCompanyRequest {
    return new UpdateTransportCompanyRequest(
      transportCompany.acceptedTermId,
      transportCompany.bankAccounts,
      transportCompany.email,
      transportCompany.phone,
      recaptchaToken);
  }
}

export class CreateTransportCompanyRequest {
  constructor(
    public acceptedTermId: string,
    public businessName: string,
    public bankAccounts: BankAccount[],
    public companyNumber: string,
    public documentTypeId: number,
    public documentNumber: string,
    public email: string,
    public phone: Phone,
    public recaptchaToken: string
  ) { }

  static create(
    transportCompany: TransportCompany, 
    documentTypeId: number, 
    documentNumber: string,
    recaptchaToken: string) : CreateTransportCompanyRequest {
    return new CreateTransportCompanyRequest(
      transportCompany.acceptedTermId,
      transportCompany.businessName,
      transportCompany.bankAccounts,
      transportCompany.companyNumber,
      documentTypeId,
      documentNumber,
      transportCompany.email,
      transportCompany.phone,
      recaptchaToken);
  }
}