import { Layout, Typography } from 'antd'
import { Content } from 'antd/es/layout/layout'

import UserFilters from './components/userFilters/UserFilters'
import UsersTable from './components/usersTable/UsersTable'

import { UsersContextProvider } from './context/UsersContextProvider'

import './users.scss'

const { Title } = Typography

const Users = () => {
  return (
    <Layout>
      <Content className='users-content'>
        <Title level={2}>Gestionar usuarios</Title>
        <UsersContextProvider>
          <UserFilters />
          <UsersTable />
        </UsersContextProvider>
      </Content>
    </Layout>
  )
}

export default Users