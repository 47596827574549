import axios from "axios";
import appConfig from "../config/app.config";
import { getTokens, isLoggedIn } from "./storage.service";
import { EndorsementStateResponse } from "../models/EndorsementState";
import { RateResponse } from "../models/Rate";
import { PaginatedResponse } from "../models/Pagination";
import { ModalityResponse } from "../models/Modality";
import { ServiceTypeResponse } from "../models/ServiceType";
import { createQueryParams } from "../utils/http.utils";
import { CreateTransportCompanyRequest, UpdateTransportCompanyRequest } from "../models/TransportCompany/TransportCompanyRequest";
import { TransportCompanyResponse } from "../models/TransportCompany/TransportCompanyResponse";

// Configuration
export const ApiDateFormat = "YYYY-MM-DD";
const apiClient = axios.create({
  baseURL: appConfig.ApiBaseUrl,
  timeout: 30000
});

apiClient.interceptors.request.use(config => {
  if (isLoggedIn()) {
      const { token } = getTokens();

      if (config.headers) {
          config.headers.Authorization = `Bearer ${token}`;
      }
  }

  return config;
}, error => Promise.reject(error));

// Endpoints
export const resendEmailConfirmationCode = async (email: string) => 
  await apiClient.post("/Security/ResendEmailConfirmationCode", { email });

export const confirmEmail = async (email: string, code: string, password: string) => 
  await apiClient.post("/Security/ConfirmEmail", { email, code, password });

export const getLastTermId = async () => {
  const response = await apiClient.get<string>("Terms/Last/Id");

  return response.data;
}

export const updateTransportCompany = async (id: string, request: UpdateTransportCompanyRequest): Promise<TransportCompanyResponse> => {
  const response = await apiClient.patch(`/TransportCompanies/${id}`, request);

  return response.data;
}

export const createTransportCompany = async (request: CreateTransportCompanyRequest): Promise<TransportCompanyResponse> => {
  const response = await apiClient.post("/TransportCompanies", request);

  return response.data;
}

export const getEndorsementStates = async () => {
  const response = await apiClient.get<EndorsementStateResponse[]>("/EndorsementStates");

  return response.data;
}

export const getInsuranceCompanyNames = async () => {
  const response = await apiClient.get<string[]>("/InsuranceCompanies/BusinessName");

  return response.data;
}

export const getRates = async (
  page: number, 
  pageSize: number,
  from?: string,
  to?: string,
  modality?: string,
  serviceTypeId?: string,
  toIsDefined?: boolean
) => {
  let params: any = {
    page,
    pageSize,
    from,
    to,
    modality,
    serviceTypeId
  }

  if (toIsDefined !== undefined) {
    params = { ...params, toIsDefined: false }
  }
  const response = await apiClient.get<PaginatedResponse<RateResponse>>(`/Rates${createQueryParams(params)}`);

  return response.data;
};

export const deleteRate = async (id: string) => await apiClient.delete(`/Rates/${id}`);

export const updateRate = async (id: string, to: Date) => await apiClient.patch(`Rates/${id}`, { to: to });

export const getTransportCompanyBankAccounts = async () => {
  const response = await apiClient.get("/Me/TransportCompany/BankAccounts");

  return response.data;
};

export const createBankAccount = async (values: any) => {
  const response = await apiClient.post("/Me/TransportCompany/BankAccounts", values);

  return response.data;
};

export const updateBankAccountActiveness = async (id: string, isActive: boolean) => {
  const response = await apiClient.patch(`/Me/TransportCompany/BankAccounts/${id}`, { isActive });

  return response.data;
};

export const updateTransportCompanyBankAccountsOrder = async (newArray: any[]) => {
  const response = await apiClient.patch("/Me/TransportCompany/BankAccounts", newArray);

  return response.data;
};

export const getModalities = async () => {
  const response = await apiClient.get<ModalityResponse[]>("/Modalities");

  return response.data;
}

export const getModalitiesDescription = async () => {
  const response = await apiClient.get<string[]>("/Modalities/Description");

  return response.data;
}

export const getServiceTypes = async () => {
  const response = await apiClient.get<ServiceTypeResponse[]>("/ServiceTypes");

  return response.data;
}

export const createRate = async (modalityId: string, from: Date, to: Date, amountPerPassenger: number, fee: number) => {
  await apiClient.post("/Rates", {modalityId, from, to, amountPerPassenger, fee});
}

export const createRateBatch = async (serviceTypeId: number, from: Date, to: Date, amountPerPassenger: number, fee: number) => {
  await apiClient.post("/Rates/Batches", {serviceTypeId, from, to, amountPerPassenger, fee});
}

export const exportExcel = async (
  page: number, 
  pageSize: number,
  externalId: number,
  departureFrom: string,
  departureTo: string,
  paidAtFrom: string,
  paidAtTo: string,
  closedAtFrom: string,
  closedAtTo: string,
  estimatedClosedAtFrom: string,
  estimatedClosedAtTo: string,
  endorsementStateId: number,
  collectionStateId: number,
  transportCompany: string,
  insuranceCompany: string,
  economicGroupId: string) => {

  const headers = { 'Content-Type': 'blob', Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" };
  const response = await apiClient.get(`/Trips${createQueryParams({
    page, 
    pageSize,
    externalId,
    departureFrom,
    departureTo,
    paidAtFrom,
    paidAtTo,
    closedAtFrom,
    closedAtTo,
    estimatedClosedAtFrom,
    estimatedClosedAtTo,
    endorsementStateId,
    collectionStateId,
    transportCompany,
    insuranceCompany,
    economicGroupId,
  })}`, { responseType: 'arraybuffer', headers });
  const objectUrl = URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');

  link.href = objectUrl;
  link.setAttribute('download', `${Date.now()}.xlsx`);
  document.body.appendChild(link);
  link.click();
}

export default apiClient;
