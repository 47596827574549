import { Typography } from 'antd';

import '../../tripCertificates.scss'

const { Text, Link } = Typography;

const ContactInfo = () => {
  return (
    <div className='trip-certificate__contact-info'>
      <Text type="secondary" strong>San Luis 3130</Text>
      <Text type="secondary" strong>(C11 86ACJ) Cap. Fed</Text>
      <Text type="secondary" strong>Tel.: (011) 4129-7300</Text>
      <Text type="secondary" strong>Fex: (011) 4129-7321</Text>
      <Link href="https://proteccionmutual.com" target="_blank" strong>
        www.proteccionmutual.com
      </Link>
    </div>
  )
}
export default ContactInfo