import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Role } from '../../../constants/roles';

interface Props {
  children: JSX.Element;
  roles: Role[];
};

const PrivateComponent = ({ children, roles }: Props) => {
  const { role } = useContext(AuthContext);

  if (roles.includes(role)) {
    return children
  }

  return null
}

export default PrivateComponent