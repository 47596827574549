import AsignatureImg from '../../../../../assets/asignature.png'

import { Typography } from 'antd';

import '../../tripCertificates.scss'

const { Text } = Typography;

const Asignature = () => {
  return (
    <div className='trip-certificate__asignature'>
      <img src={AsignatureImg} alt='firma' />
      <Text strong>Marcelo Sciutto</Text>
      <Text strong>Presidente</Text>
    </div>
  )
}
export default Asignature