import { Layout, Typography } from "antd"
import { Content } from "antd/es/layout/layout"

import { CheckingAccountContextProvider } from "./context/CheckinContextProvider"
import CheckingAccountFilters from "./components/checkingAccountFilters/CheckingAccountFilters"
import CheckingAccountTable from "./components/checkingAccountTable/CheckingAccountTable"

import './checkingAccount.scss'

const { Title } = Typography

const CheckingAccount = () => {
  return (
    <Layout>
      <Content className='checking-account-content'>
        <Title level={2}>Cuenta corriente</Title>
        <CheckingAccountContextProvider>
          <CheckingAccountFilters />
          <CheckingAccountTable />
        </CheckingAccountContextProvider>
      </Content>
    </Layout>
  )
}

export default CheckingAccount