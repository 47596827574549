import Asignature from '../asignature/Asignature'
import '../../tripCertificates.scss'
import ContactInfo from '../contactInfo/ContactInfo'

const Footer = () => {
  return (
    <div className='trip-certificate__footer'>
      <div className='trip-certificate__section-space-between'>
        <ContactInfo />
        <Asignature />
      </div>
    </div>
  )
}
export default Footer