export class TripTableFilters {
    constructor(
        public endorsementState: any,
        public collectionState: any,
        public economicGroup: any,
        public paidAtFrom: any,
        public paidAtTo: any,
        public closedAtTo: any,
        public closedAtFrom: any,
        public estimatedClosedAtFrom: any,
        public estimatedClosedAtTo: any,
        public insuranceCompany: any,
        public transportCompany: any,
        public departureFrom: any,
        public departureTo: any,
        public tripExternalId: any,
        public page: any,
        public endorsmentNumber: string | undefined
    ) { }
}