import { useEffect, useState } from "react";
import { Trip } from "../../../../models/Trips/Trip";
import { getTripById } from "../../../../services/trips.services";

interface UseTripProps {
  id?: string
}

const useTrip = ({ id }: UseTripProps) => {
  const [trip, setTrip] = useState<Trip>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getTrip = async () => {
      try {
        setLoading(true);
        const response = await getTripById(id ?? '');
        setTrip(response)
      } catch {

      } finally {
        setLoading(false);
      }
    };

    id && getTrip();
  }, [id]);

  return {
    trip,
    loading
  }
}

export default useTrip