import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import CheckIcon from "../../../assets/CheckIcon";
import MarkIcon from "../../../assets/MarkIcon";
import { useParams } from "react-router-dom";
import "./registerResult.scss";
import { WarningOutlined } from "@ant-design/icons";
import { confirmEmail, register } from "../../../constants/routes";
function RegisterResult() {
  const { obj } = useParams<{ bool: string; obj: string }>();

  var data: any;
  if (obj === "false") {
    data = false;
  } else {
    data = obj && JSON.parse(decodeURIComponent(escape(window.atob(obj))))
  }

  const navigate = useNavigate();
  return (
    <div className="registerResult">
      <div
        style={{
          width: "60%",
          backgroundColor: data.bool ? "#E0F7F7" : "#F0C2C2",
          display: "flex",
          flexDirection:
            data.data && data?.data?.failedBankAccountInsertions.length !== 0
              ? "row-reverse"
              : "column",
          alignItems: "center",
          justifyContent:
            data.data && data?.data?.failedBankAccountInsertions.length !== 0
              ? "flex-end"
              : "center",
          padding: 40,
          borderRadius: 20,
          paddingBottom:
            data.data && data?.data?.failedBankAccountInsertions.length !== 0
              ? 20
              : 60,
          paddingTop:
            data.data && data?.data?.failedBankAccountInsertions.length !== 0
              ? 20
              : 40,
        }}
      >
        <p
          style={{
            fontSize: 26,
            fontWeight: "600",
            color: data.bool ? "#054368" : "#E14942",
            textAlign: "center",
          }}
        >
          {data.bool
            ? "¡Tu registro fue realizado exitosamente!"
            : "No ha sido posible realizar el registro"}
        </p>
        {data.bool ? <CheckIcon /> : <MarkIcon />}
      </div>
      {data.data && data?.data?.failedBankAccountInsertions.length !== 0 && (
        <div className="divError">
          <div className="containerWarning">
            <WarningOutlined style={{ color: "#E99517" }} />
          </div>
          <div>
            <p className="title">
              La siguiente cuenta no pudo adherirse al debin recurrente
            </p>

            <p className="subtitle">
              CUIT: {data?.data?.failedBankAccountInsertions[0].documentNumber}
            </p>
            <p className="subtitle">
              CBU: {data?.data?.failedBankAccountInsertions[0].cbu}
            </p>

            <p className="subtitle">
              Una vez que finalice el registro, podrá reintentar o incorporar
              nuevas cuentas bancarias.
            </p>
          </div>
        </div>
      )}
      <div className="divButton">
        <Button
          type="primary"
          onClick={() =>
            data.bool ? navigate(confirmEmail.path) : navigate(register.path)
          }
          block
        >
          {data.bool ? "Continuar" : "Intentar nuevamente"}
        </Button>
      </div>
    </div>
  );
}
export default RegisterResult;
