import moment from 'moment'

import apiClient from './api.client'

import { DefaultDateFormat, DefaultTimeFormat } from '../models/Date'
import { TripResponse, TripsServices } from '../models/Trips/Trip'

import { TripAnnulmentResponseDTO } from '../DTO/trip/TripAnnulment.dto';
import { OtherPaymentResponseDTO } from '../DTO/trip/other.payment.dto';
import { TripCertificateResponseDTO } from '../DTO/trip/Certificate.dto'
import { TripDTO } from '../DTO/trip/Trip.dto';
import { DebinDTO, DebinStateChangesDTO } from '../DTO/trip/Debin.dto';
import { dateFormat } from '../utils/dateFormatter.util';
import { createQueryParams } from '../utils/http.utils';
import { PaginatedResponse } from '../models/Pagination';
import { ProtectionDTO } from '../DTO/trip/Protection.dto';

const pdfHeaders = { 'Accept': 'application/pdf' };

export const downloadCertificate = async (tripId: string) => {
  const response = await apiClient.post(`/Trips/${tripId}/Certificates`, null, { responseType: 'arraybuffer', headers: pdfHeaders })
  return response.data
}

export const downloadReceipt = async (tripId: string) => {
  const response = await apiClient.get(`/Trips/${tripId}/Receipt`, { responseType: 'arraybuffer', headers: pdfHeaders });
  return response.data
}

export const cancelTrip = async (file: FormData) => {
  const { data } = await apiClient.post<TripAnnulmentResponseDTO[]>(`/Trips/Processes/Annulments`, file, { timeout: 1800000 })
  return data.map(TripAnnulmentDTO => ({
    externalId: TripAnnulmentDTO.externalId,
    serviceTypeId: TripAnnulmentDTO.serviceTypeId,
    message: TripAnnulmentDTO.message,
    success: TripAnnulmentDTO.success,
    row: TripAnnulmentDTO.row
  }))
}

export const getTripCertificate = async ({ tripId, certificateId }: { tripId: string, certificateId: string }) => {
  const response = await apiClient.get<TripCertificateResponseDTO>(`/Trips/${tripId}/Certificates/${certificateId}`);
  const { data } = response

  return {
    externalId: data.tripExternalId.toString(),
    companyName: data.transportCompanyBusinessName ?? 'Sin razón social informada',
    domain: data.vehicle?.patentNumber ?? 'Sin dominio informado',
    arrivalDate: moment(data.arrivalAt).format(`${DefaultDateFormat} ${DefaultTimeFormat}`),
    departureDate: moment(data.departureAt).format(`${DefaultDateFormat} ${DefaultTimeFormat}`),
    tripOrigin: data.fromCity,
    tripDestination: data.toCity,
    numberPassengers: data.passengerCount,
    generatedAt: moment(data.generatedAt).format(`${DefaultDateFormat} ${DefaultTimeFormat}`)
  }
}

export const getTripById = async (tripId: string) => {
  const response = await apiClient.get<TripDTO>(`Trips/${tripId}`)
  const { data } = response
  return {
    id: data.id,
    externalId: data.externalId,
    collectionState: data.collectionState,
    arrivalAt: moment(data.arrivalAt).format(DefaultDateFormat + " " + DefaultTimeFormat),
    departureAt: moment(data.departureAt).format(DefaultDateFormat + " " + DefaultTimeFormat),
    passengerCount: data.passengerCount,
    serviceType: data.serviceType,
    fromCity: data.fromCity,
    toCity: data.toCity,
    transportCompany: data.transportCompany,
    insurancePolicy: data.insurancePolicy,
    insurancePolicyByIssuer: data.insurancePolicyByIssuer,
    vehicleInfo: data.vehicleInfo,
    modality: data.modality,
    endorsement: data.endorsement
  }
}

export const getDebinsByTripId = async (tripId: string) => {
  const response = await apiClient.get<DebinDTO[]>(`Trips/${tripId}/Debins`)
  const { data } = response
  return data.map(debinDto => ({
    id: debinDto.id,
    creationDate: moment(debinDto.createdAt).format(DefaultDateFormat),
    amount: debinDto.amount,
    status: debinDto.state,
    cbu: debinDto.cbu
  }))
}

export const getDebinsStateChanges = async ({ tripId, debinId }: { tripId: string, debinId: number }) => {
  const response = await apiClient.get<DebinStateChangesDTO[]>(`Trips/${tripId}/Debins/${debinId}/StateChanges`)
  const { data: debinsStateChangesDTO } = response
  return debinsStateChangesDTO.map(debinStateChangesDTO => ({
    ocurredAt: dateFormat(debinStateChangesDTO.ocurredAt),
    from: debinStateChangesDTO.from,
    to: debinStateChangesDTO.to
  }))
}

export const processOtherPayments = async (file: FormData) => {
  const { data } = await apiClient.post<OtherPaymentResponseDTO[]>(`/Trips/Processes/OtherPayments`, file, { timeout: 1800000 })
  return data.map(otherPaymentDTO => ({
    externalId: otherPaymentDTO.externalId,
    serviceTypeId: otherPaymentDTO.serviceTypeId,
    message: otherPaymentDTO.message,
    success: otherPaymentDTO.success,
    row: otherPaymentDTO.row
  }))
}

export const getTrips = async (
  page: number, 
  pageSize: number,
  externalId: number,
  departureFrom: string,
  departureTo: string,
  paidAtFrom: string,
  paidAtTo: string,
  closedAtFrom: string,
  closedAtTo: string,
  estimatedClosedAtFrom: string,
  estimatedClosedAtTo: string,
  endorsementStateId: number,
  collectionStateId: number,
  transportCompany: string,
  insuranceCompany: string,
  economicGroupId: string,
  endorsmentNumber?: string,
) => {
    
  const response = await apiClient.get<PaginatedResponse<TripResponse>>(`/Trips${createQueryParams({
    page, 
    pageSize,
    externalId,
    departureFrom,
    departureTo,
    paidAtFrom,
    paidAtTo,
    closedAtFrom,
    closedAtTo,
    estimatedClosedAtFrom,
    estimatedClosedAtTo,
    endorsementStateId,
    collectionStateId,
    transportCompany,
    insuranceCompany,
    economicGroupId,
    endorsmentNumber,
  })}`);

  return response.data;
}

export const getTrip = async (id: string) => {
  const response = await apiClient.get<TripResponse>(`/Trips/${id}`);

  return response.data;
}

export const updatePaymentStateToProtection = async (file: FormData) => {
  const { data } = await apiClient.post<ProtectionDTO[]>(`/Trips/Processes/Protection`, file, { timeout: 1800000 })
  return data
}

const tripsServices: TripsServices = {
  downloadCertificate,
  downloadReceipt,
  getTripById,
  getDebinsByTripId,
  cancelTrip,
  getTripCertificate,
  getDebinsStateChanges,
  getTrips,
  getTrip,
  updatePaymentStateToProtection
}

export default tripsServices