import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, Tooltip, Typography } from 'antd';
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import moment from 'moment';

import { TripResponse, TripTableItem } from '../../../models/Trips/Trip';
import { PaginatedResponse } from '../../../models/Pagination';
import { DefaultDateFormat } from '../../../models/Date';

import { payment, tripDetail } from '../../../constants/routes';
import { BASSA_ADMIN, BASSA_OPERATOR, TRANSPORT_COMPANY } from '../../../constants/roles';
import { CollectionStateIds } from '../../../constants/collectionState';

import { toARSCurrency } from '../../../utils/number.utils';
import { insertIf } from '../../../utils/array.utils';
import { getCollectionStateColors } from '../../../utils/collectionStateColors';

import { AuthContext } from '../../context/AuthContext';

import useTripsDocuments from './hooks/useTripsDocuments';

import './TripsTable.scss';

const { Text } = Typography;

interface TripsTableProps {
  getTripsFromApi: (page: number, pageSize: number) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  trips: PaginatedResponse<TripResponse> | undefined
};

const TripsTable = ({
  getTripsFromApi,
  loading,
  trips,
  setLoading
}: TripsTableProps) => {
  const { role } = useContext(AuthContext);
  const { downloadTripCertificate, downloadTripReceipt } = useTripsDocuments({ setLoading })

  const getActionButtons = (trip: TripTableItem) => {
    const wasPaid = trip.collectionState.id === CollectionStateIds.PaidOut
    const isUnknownDebin = trip.collectionState.id === CollectionStateIds.UnknownDebin
    const IsPaidOutOrUnknownDebinTrip = wasPaid || isUnknownDebin;

    return (
      <>
        <Tooltip title={IsPaidOutOrUnknownDebinTrip ? "Descargar comprobante." : "Este viaje aún no fue abonado."}>
          <Button type="link" disabled={!IsPaidOutOrUnknownDebinTrip} onClick={() => downloadTripReceipt(trip.id)}>
            <DownloadOutlined />
          </Button>
        </Tooltip>
        <Tooltip title={'Descargar constancia de viaje'}>
          <Button type="link" onClick={() => downloadTripCertificate(trip.id)}>
            <FilePdfOutlined />
          </Button>
        </Tooltip>
      </>
    );
  }

  const FormatDateToDisplay = (date: any) => date ? moment(date).format(DefaultDateFormat) : "-";

  return (
    <div className="tripTable">
      <Table
        columns={[
          {
            title: 'Id',
            dataIndex: 'externalId',
            render: (_, trip) => (<Link to={tripDetail.dataRoute(trip.id)} children={trip.externalId} />)
          },
          ...insertIf(role === TRANSPORT_COMPANY,
            {
              title: 'Fecha de Viaje',
              dataIndex: 'departureAt',
              render: (_: any, trip: TripTableItem) => FormatDateToDisplay(trip.departureAt)
            }
          ),
          ...insertIf(role === BASSA_OPERATOR || role === BASSA_ADMIN,
            {
              title: 'Fecha de Cierre',
              dataIndex: 'closedAt',
              render: (_: any, trip: TripTableItem) => FormatDateToDisplay(trip.closedAt)
            }
          ),
          ...insertIf(role === TRANSPORT_COMPANY || role === BASSA_OPERATOR || role === BASSA_ADMIN,
            {
              title: 'Cierre Estimado',
              dataIndex: 'estimatedClosedAt',
              render: (_: any, trip: TripTableItem) => FormatDateToDisplay(trip.estimatedClosedAt)
            }
          ),
          { title: 'Pasajeros', dataIndex: 'passengerCount', key: 'passengerCount', align: 'right' },
          {
            title: 'Importe',
            dataIndex: 'amount',
            align: 'right',
            render: (_, trip) => toARSCurrency(trip.amount) ?? <Tooltip title="El importe será calculado al momento de cierre del viaje" children="-" />
          },
          {
            title: 'Fecha de Cobro',
            dataIndex: 'paidAt',
            render: (_: any, trip: TripTableItem) => FormatDateToDisplay(trip.paidAt)
          },
          {
            title: 'Estado Cobranza',
            dataIndex: 'collectionState.description',
            render: (_, trip) => <Text children={trip.collectionState.description} style={{ color: getCollectionStateColors(trip.collectionState.id) }} />
          },
          ...insertIf(role === BASSA_OPERATOR || role === BASSA_ADMIN,
            {
              title: 'Estado Endoso',
              dataIndex: 'endorsementState',
              key: 'endorsementState'
            }),
          { title: 'Tipo de Servicio', dataIndex: 'serviceType', key: 'serviceType' },
          ...insertIf(role !== TRANSPORT_COMPANY,
            {
              title: 'Transportista',
              dataIndex: 'transportCompany',
              key: 'transportCompany'
            }),
          { title: 'Póliza Compañia', dataIndex: 'policyNumber', key: 'policyNumber' },
          { title: 'Detalle', key: "id", render: (_, trip) => <Link to={payment.dataRoute(trip?.id)} children="Detalle de cobranza" /> },
          { title: 'Comprobantes', key: 'actions', fixed: 'right', width: 50, render: (_, trip) => getActionButtons(trip) }
        ]}
        dataSource={trips?.items?.map((trip: TripResponse): TripTableItem => ({
          key: trip.id,
          id: trip.id,
          amount: trip.amount,
          departureAt: trip.departureAt,
          closedAt: trip.closedAt,
          externalId: trip.externalId,
          passengerCount: trip.passengerCount,
          paidAt: trip.paidAt,
          collectionState: trip.collectionState,
          estimatedClosedAt: trip.estimatedClosedAt,
          serviceType: trip.serviceType?.description,
          endorsementState: trip.endorsementState?.description,
          transportCompany: trip.transportCompany?.businessName,
          policyNumber: trip.insurancePolicyByIssuer?.policyNumber,
        }))}
        loading={loading}
        scroll={{ x: true }}
        pagination={{
          position: ["bottomCenter"],
          total: trips?.totalItems,
          pageSize: trips?.pageSize,
          locale: { next_page: "Próxima página", prev_page: "Página anterior" },
          current: trips?.page,
          onChange: (page: number, pageSize: number) => getTripsFromApi(page, pageSize)
        }}
      />
    </div>
  );
};

export default TripsTable;
