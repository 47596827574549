import { notification as notificationAnt } from 'antd'

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const notification = (type: NotificationType, message: string) => notificationAnt[type]({
  message: message,
  placement: 'bottomRight',
  duration: 5
});

export const successNotification = (message: string) => notification('success', message);
export const errorNotification = (message: string) => notification('error', message);

export default notification;