import { DatePicker, Form, InputNumber, Modal, Select, Space, Switch, Typography } from "antd";
import { useEffect, useState } from "react";
import { ApiDateFormat, createRate, getModalities } from "../../../services/api.client";
import { errorNotification, successNotification } from "../../atoms/notification/Notification";
import { getMessageErrors } from "../../../models/Errors/Errors";
import { ErrorResponse } from "../../../models/Errors/ErrorResponse";
import locale from "antd/es/date-picker/locale/es_ES";
import { DefaultDateFormat } from "../../../models/Date";
import "moment/locale/zh-cn";

const { RangePicker } = DatePicker;
const { Text } = Typography;

interface AddRateModalProps {
  getRatesFromApi: () => void;
  setAddRateModalOpen: (value: any) => void;
  addRateModalOpen: boolean;
}

const commonDatePickerProps = {
  locale,
  format: DefaultDateFormat,
  style: { width: "100%" },
};

const AddRateModal = ({ getRatesFromApi, setAddRateModalOpen, addRateModalOpen }: AddRateModalProps) => {
  const [modalities, setModalities] = useState<any>();
  const [addRateForm] = Form.useForm();
  const [isDateSwitchChecked, setIsDateSwitchChecked] = useState<boolean>(true);

  useEffect(() => {
    const getModalitiesFromApi = async () => addRateModalOpen && setModalities(await getModalities());

    getModalitiesFromApi();

  }, [addRateModalOpen]);

  const addRate = () => {
    addRateForm
      .validateFields()
      .then(async rate => {

        try {

          await createRate(
            rate.modalityId,
            isDateSwitchChecked ? rate.fromTo[0].format(ApiDateFormat) : rate.from.format(ApiDateFormat),
            isDateSwitchChecked ? rate.fromTo[1].format(ApiDateFormat) : null,
            rate.amountPerPassenger,
            rate.fee);
          successNotification("Tarifa creada con éxito");
          setAddRateModalOpen(false);
          addRateForm.resetFields();
          getRatesFromApi();
        } catch (error: any) {
          const errorResponse: ErrorResponse = error.response?.data;
          errorNotification(getMessageErrors(errorResponse?.Type));
        }
      })
      .catch((info) => {
        console.log('Validate failed:', info);
      });
  }

  const RateWithDeadlineSwitch = () => (
    <Space style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
      <Text children="Tarifa con fecha límite" />
      <Switch
        defaultChecked={isDateSwitchChecked}
        size="default"
        checkedChildren="SI"
        unCheckedChildren="NO"
        onChange={() => setIsDateSwitchChecked(!isDateSwitchChecked)}
      />
    </Space >
  );

  return (
    <Modal
      title="Agregar Tarifa"
      okText="Agregar"
      cancelText="Cancelar"
      open={addRateModalOpen}
      onCancel={() => setAddRateModalOpen(false)}
      onOk={() => addRate()}
    >
      <Form
        form={addRateForm}
        layout="vertical"
        name="addRateForm"
      >
        <Form.Item
          name="modalityId"
          label="Modalidad"
          rules={[{ required: true, message: 'Seleccione la modalidad' }]}
        >
          <Select
            placeholder="Seleccionar modalidad"
            onMouseDown={e => { e.preventDefault(); e.stopPropagation(); }}
            options={modalities?.map((modality: any) => ({
              value: modality.id,
              label: `${modality.description} (${modality.serviceType?.description})`,
            }))}
            style={{ width: "100%" }}
          />
        </Form.Item>

        {isDateSwitchChecked &&
          <Form.Item
            name='fromTo'
            label='Fechas'
            rules={[{ required: true, message: 'Seleccione las fechas' }]}
          >
            <RangePicker {...commonDatePickerProps} renderExtraFooter={RateWithDeadlineSwitch} />
          </Form.Item>
        }

        {!isDateSwitchChecked &&
          <Form.Item
            name='from'
            label='Fecha'
            rules={[{ required: true, message: 'Seleccione la fecha' }]}
          >
            <DatePicker {...commonDatePickerProps} renderExtraFooter={RateWithDeadlineSwitch} showToday={false} />
          </Form.Item>
        }

        <Form.Item
          name="amountPerPassenger"
          label="Precio por pasajero"
          rules={[{ required: true, message: 'Seleccione el precio por pasajero' }]}
        >
          <InputNumber
            type="number"
            placeholder="Ingresar precio"
            min={1}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          name="fee"
          label="Fee"
          rules={[{ required: true, message: 'Seleccione el fee' }]}
        >
          <InputNumber
            type="number"
            placeholder="Ingresar porcentaje"
            step="0.01"
            min={0}
            precision={2}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddRateModal;
