import { useEffect, useState } from 'react'
import { message } from 'antd'

import { EconomicGroup } from '../../../../models/EconomicGroup'
import { IPaymentMethods } from '../../../../models/Payment/PaymentMethods'
import { errorMessages } from '../../../../models/Errors/Errors'
import { TransportCompanyResponse } from '../../../../models/TransportCompany/TransportCompany'
import { PaymentState } from '../../../../models/Payment/PaymentState'

import { getEconomicGroups } from '../../../../services/group.services'
import { getTransportCompanies } from '../../../../services/transportCompany.services'
import { getPaymentMethods, getPaymentStates } from "../../../../services/payment.services"

const useDataManagePaymentsFilters = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [economicGroups, setEconomicGroups] = useState<EconomicGroup[]>([])
  const [transportCompanies, setTransportCompanies] = useState<TransportCompanyResponse[]>([])
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethods[]>([])
  const [paymentStates, setPaymentStates] = useState<PaymentState[]>([])

  useEffect(() => {
    Promise.all([
      getEconomicGroups({ HeadPayManually: true }),
      getTransportCompanies({ PayManually: true}),
      getPaymentMethods({ isManual: true }),
      getPaymentStates()
    ]).then((data) => {
      const [economicGroups, transportCompanies, paymentMethods, paymentStates] = data
      setEconomicGroups(economicGroups)
      setTransportCompanies(transportCompanies)
      setPaymentMethods(paymentMethods)
      setPaymentStates(paymentStates)
    }).catch(() => {
      message.error(errorMessages.tryAgain)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  return {
    economicGroups,
    transportCompanies,
    paymentMethods,
    paymentStates,
    loading
  }
}

export default useDataManagePaymentsFilters