import { Alert, Layout, Typography } from "antd"
import { Content } from "antd/es/layout/layout"
import TripAnnulmentForm from "./components/tripAnnulmentForm/TripAnnulmentForm"
import TripAnnulmentTable from "./components/tripAnnulmentTable/TripAnnulmentTable"

import './tripAnnulment.scss'
import { TripAnnulmentContextProvider } from "./context/TripAnnulmentContextProvider"

const { Title } = Typography

const TripAnnulment = () => {
  return (
    <Layout>
      <Content className="trip-annulment-content">
        <Title level={2}>Anular viajes</Title>
        <div className="trip-annulment-content__content-alert">
          <Alert
            message="Por favor, seleccione el archivo de hojas de cálculo con la información de los viajes a anular."
            type="info"
          />
        </div>
        <div className="trip-annulment-content__layer">
          <TripAnnulmentContextProvider>
            <TripAnnulmentForm />
            <TripAnnulmentTable />
          </TripAnnulmentContextProvider>
        </div>
      </Content>
    </Layout>
  )
}

export default TripAnnulment
