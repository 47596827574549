import { Checkbox, Switch, Table } from "antd"
import useUserDataTable from "../../hooks/useUserDataTable"
import SkeletonTable from "../../../../shared/skeletonTable/SkeletonTable"
import './usersTable.scss'

const UsersTable = () => {
  const { handleChangePagination, handleActivateUser, pagination, users, loading, initialLoading } = useUserDataTable()

  if (initialLoading) {
    return (
      <SkeletonTable
        numberOfColumns={5}
        numberOfRows={10}
      />
    )
  }

  return (
    <Table
      className="layer user-table"
      loading={loading}
      columns={[
        { title: 'Mail', dataIndex: 'email' },
        { title: 'Rol', dataIndex: 'role', render: (role) => role.name },
        {
          title: 'Activo',
          dataIndex: 'isActive',
          render: (isActive: boolean) => {
            return <Checkbox checked={isActive} />
          }
        },
        {
          title: 'Bloqueado',
          dataIndex: 'isLockedOut',
          render: (isLockedOut: boolean) => {
            return isLockedOut ? 'Si' : 'No'
          }
        },
        {
          title: 'Activar/desactivar',
          dataIndex: 'isActive',
          render: (isActive: boolean, record: any) => {
            return (
              <Switch
                checked={isActive}
                onChange={() => handleActivateUser(record.id, !isActive)}
              />
            )
          }
        }
      ]} 
      dataSource={users?.data.map(user => ({ ...user, key: user.id }))}
      pagination={{
        position: ["bottomCenter"],
        total: users?.total,
        pageSize: pagination.pageSize,
        locale: { next_page: "Próxima página", prev_page: "Página anterior" },
        current: pagination.page,
        onChange: (page: number, pageSize: number) => handleChangePagination(page, pageSize)
      }}
    />
  )
}

export default UsersTable