import { createRoot } from 'react-dom/client';
import { AuthProvider } from './components/context/AuthContext';
import reportWebVitals from './reportWebVitals';
import App from './App';
import moment from 'moment';
import 'moment/locale/es'; 
import './index.scss';
import { Provider } from 'react-redux'
import { store } from './store/store';

moment.locale('es');

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
