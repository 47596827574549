import { Skeleton, Space } from "antd"

const BankAccountStatusFiltersSkeleton = () => {
  return (
    <div className="layer">
      <div className="container-skeleton">
        <Space wrap>
          <div className="content-input">
            <Skeleton className="input-skeleton" paragraph={false} active={true} />
          </div>
        </Space>
      </div>

      <Space wrap>
        <Skeleton className="button-skeleton" paragraph={false} active={true} style={{ width: '80px' }} />
        <Skeleton className="button-skeleton" paragraph={false} active={true} style={{ width: '120px' }} />
        <Skeleton className="button-skeleton" paragraph={false} active={true} style={{ width: '120px' }} />
      </Space>
    </div>
  )
}

export default BankAccountStatusFiltersSkeleton