interface ErrorMessages {
  [key: string]: string;
}

export const getConfirmEmailMessageErrorByType = (error: string): string => {
  switch (error) {
    case "WrongEmailOrEmailConfirmationCode":
      return "El código o correo electrónico ingresado es inválido.";
    case "InactivatedUser":
      return "El usuario que intenta activar no se encuentra disponible.";
    case "ExpiredEmailConfirmationCode":
      return "El código ingresado se encuentra expirado.";
    case "PasswordTooShort":
      return "La contraseña debe tener al menos 6 caracteres.";
    case "PasswordTooLong":
      return "La contraseña debe tener menos de 50 caracteres.";
    case "PasswordCannotHaveLeadingOrTrailingBlanks":
      return "La contraseña no puede comenzar o terminar con un espacio en blanco.";
    case "PasswordRequiresLower":
      return "La contraseña debe tener al menos una minúscula.";
    case "PasswordRequiresUpper":
      return "La contraseña debe tener al menos una mayúscula.";
    case "PasswordRequiresDigit":
      return "La contraseña debe tener al menos un número.";
    case "PasswordRequiresNonAlphanumeric":
      return "La contraseña debe tener al menos un carácter especial.";
    default:
      return "Error desconocido.";
  }
};

export const getResendEmailConfirmCodeMessageErrorByType = (error: string): string => {
  switch (error) {
    case "UnableToSendEmailConfirmationEmail":
      return "No se pudo enviar el correo electrónico de confirmación.";
    default:
      return "Error desconocido.";
  }
};

export const errorMessages: ErrorMessages = {
  // commons
  tryAgain: "Ocurrió un error inesperado, por favor inténtelo nuevamente.",
  // Existence Errors
  DocumentNumberParameterIsRequired: "El campo CUIT/CUIL es obligatorio.",
  CompanyDocumentNumberMustHaveExactly11Chars: "El CUIT/CUIL debe tener exactamente 11 dígitos.",
  CompanyDocumentNumberMustBeComposedOnlyByDigits: "El CUIT/CUIL debe ser numérico.",
  CompanyDocumentNumberHasAnInvalidCheckDigit: "El número de documento ingresado es inválido.",
  ModalityNotFound: "Modalidad no encontrada.",

  // Required
  CbuInBankAccountIsRequired: "El campo CBU es obligatorio.",
  DocumentNumberInBankAccountIsRequired: "El campo CUIT/CUIL es obligatorio.",
  BusinessNameInTransportCompanyIsRequired: "El campo Nombre de Empresa es obligatorio.",
  CountryCodeInPhoneNumberIsRequired: "El campo Código de Área es obligatorio.",
  NumberInPhoneNumberIsRequired: "El campo Número de Teléfono es obligatorio.",
  PhoneDataIsRequiredInTransportCompany: "Los campos de teléfono son obligatorios.",
  TermNotFound: "Debe aceptar los términos y condiciones.",

  // IsRepeated
  CompanyNumberIsRepeated: "Ya existe una compañía registrada para el número de empresa ingresado.",
  DocumentNumberIsRepeated: "Ya existe una compañía registrada para el documento ingresado.",
  UserWithRepeatedEmail: "Este correo electrónico ya está registrado.",
  TransportCompanyIsAlreadyRegistered: "Esta empresa de transporte ya está registrada.",

  // Transport Company
  TransportCompanyMustHaveOneRelatedActiveBankAccountAtLeast:
    "La compañía de transporte debe tener al menos una cuenta bancaria activa relacionada.",
  TransportCompanyMustHaveOneRelatedBankAccountAtLeast: "Debe ingresar al menos una cuenta bancaria principal.",
  TransportCompanyAlreadyHasABankAccountWithTheSameCbu: "La compañía ya tiene una cuenta bancaria con el mismo CBU.",
  TransportCompanyAlreadyHasABankAccountWithTheSameOrder: "La compañía ya tiene una cuenta bancaria principal.",
  CbuFromBankAccountsMustBeDifferent: "La cuenta principal y secundaria deben ser diferentes.",
  CompanyCuitDoesNotMatchWithBankAccountCuit: "El CUIT de la empresa no coincide con el CUIT de la cuenta bancaria.",

  // Equal or greater
  OrderInBankAccountMustBeEqualOrGreaterThan1: "Debe registrar al menos una cuenta bancaria principal.",

  // Exactly
  CbuMustHaveExactly22Chars: "El CBU debe tener exactamente 22 dígitos.",

  // OnlyDigits
  CountryCodeInPhoneNumberMustBeComposedOnlyByDigits: "El Código de Área debe ser numérico.",
  NumberInPhoneNumberMustBeComposedOnlyByDigits: "El número de teléfono debe ser numérico.",

  // Cannot have more than...
  BusinessNameInTransportCompanyCannotHaveMoreThan200Chars: "Se ha excedido el límite de caracteres.",
  CountryCodeInPhoneNumberCannotHaveMoreThan6Chars: "Se ha excedido el límite de dígitos.",
  NumberInPhoneNumberCannotHaveMoreThan10Chars: "Se ha excedido el límite de dígitos.",

  RecaptchaTokenIsInvalid: "El captcha ingresado es inválido.",

  DocumentTypeNotFound: "Error en el tipo de documento.",
  UnableToSendEmailConfirmationEmail:
    "Error al enviar el correo electrónico de confirmación. Por favor, contacte al administrador.",
  RecaptchaGeneralException: "Error general en Recaptcha.",
  CompanyNumberInTransportCompanyMustBeGreaterThan0: "El número de empresa debe ser mayor a 0.",
  AcceptedTermIdMustBeDefined: "Debe aceptar los términos y condiciones.",
  AcceptedTermIsOld: "Error en los términos y condiciones.",

  // Errors Version 0.2.0
  DebinUnexpectedException: "Error inesperado al realizar DEBIN.",
  InvalidCbu: "El CBU ingresado es inválido.",
  CuitNotBelongEnteredCbu: "El CUIT no está asociado al CBU ingresado.",
  CbuBankAccountInactive: "El CBU ingresado está inactivo.",

  // DEBIN Errors
  BankAccountNotEnabled: "La cuenta bancaria no está habilitada para recibir DEBIN.",
  DebinSubscriptionUnexpectedException: "Error inesperado en la suscripción de DEBIN.",
  DocumentNumberInBankAccountIsInvalid: "El CUIT no está asociado al CBU ingresado.",

  // Errors Version 0.6.0
  BankAccountCurrencyMustBeArgentinePeso: "Debe declarar una cuenta en Pesos Argentinos.",

  // Rate
  RateIsInUse:
    "El registro seleccionado no puede ser eliminado ya que fue utilizado por uno o más viajes para calcular el importe total.",
  ThereIsARateWithSameModalityAndOverlappingDateRange:
    "Existe una tarifa con la misma modalidad y rango de fechas superpuestas.",
  NewEndOfRangeDateIsInvalid: "La nueva fecha de finalización del rango debe ser mayor que el valor actual.",
  EndOfRangeDateMustBeEqualOrGreaterThanRangeStartDate:
    "La fecha de finalización del rango debe ser igual o mayor que la fecha de inicio del rango.",
  AmountPerPassengerInRateCannotBeSetToZeroOrLess: "El monto por pasajero debe ser mayor que cero.",

  FileWithTripsToAnnulNotFound: "No se ha encontrado el archivo con los viajes a anular.",
  FileWithTripsToAnullMustHaveXlsxFormat: "El archivo con los viajes a anular debe tener formato xlsx.",
  ThereIsNoRowsWithTripsToAnnul: "No hay filas con viajes para anular.",
  ThereIsNoRowsWithTripsToChangeStateToOtherPayment:
    "No hay filas con viajes para cambiar el estado de pago a 'Otros pagos'.",

  FileWithTripsToChangeCollectionStateToProtectionNotFound:
    "No se ha encontrado el archivo con los viajes para cambiar el estado de la colección a 'Protección'. Por favor, verifique su solicitud.",
  ThereIsNoRowsWithTripsToChangeStateToProtection:
    "No hay filas con viajes para cambiar el estado de la colección a 'Protección'.",
  FileWithTripsToChangeCollectionStateToProtectionMustHaveXlsxFormat:
    "El archivo con los viajes para cambiar el estado de la colección a 'Protección' debe tener formato xlsx.",

  PaymentProofFileNotFound: "No se ha encontrado el archivo de prueba de pago en el servidor.",
  AccountNotFound: "La compañía de transporte no tiene una cuenta.",

  AmountInPaymentCannotBeSetToLessOrEqualThanZero: "El importe del pago no puede ser igual o inferior a cero.",
  PaymentWithSameExternalIdAlreadyExists: "Ya existe un pago con el mismo identificador de pago.",
  GivenTransportCompanyOrEconomicGroupCannotBeBothNullOrEmpty:
    "Debe ingresar un grupo económico o una empresa transportista.",
  RequestForRegisterPaymentNotFound: "No se ha encontrado la solicitud para registrar un pago.",
  GivenTransportCompanyAndEconomicGroupCannotBeBothDefined:
    "Solo puede ingresar un grupo económico o una empresa transportista.",
  GivenPaymentMethodMustBeDifferentFromDebin: "El método de pago debe ser diferente de Debin.",
  FileProofHasIncorrectFormat: "El comprobante de pago tiene un formato incorrecto. Debería ser: pdf, png o jpg.",
  GivenProofFileSizeExceeded10MB: "El comprobante de pago excede el tamaño máximo permitido de 10MB.",
  RequestForRegisterOrUpdatePaymentNotFound: "No se ha encontrado la solicitud para registrar o actualizar un pago.",
  GivenEconomicGroupMustHavePayManuallyHead: "Un grupo económico determinado debe tener una remuneración manualmente",

  FileWithTripsToPatchEndorsementStateNotFound:
    "No se ha encontrado el archivo con los viajes para cambiar el estado de endoso.",
  FileWithTripsToPatchEndorsementStateMustHaveXlsxFormat:
    "El archivo con los viajes al estado de endoso de parche debe tener formato Xlsx.",
  ThereIsNoRowsWithTripsToPatchEndorsementState: "No hay filas con viajes para parchear el estado de endoso.",
  ThereIsNoRowsWithTripsToPatchEndorsementStateToAnnulled: "No se encontraron registros de endosos para ser anulados.",
  // Preliquidation
  SyMAccountNotFound: "No se ha encontrado la cuenta en SYM.",
  DiscountAlreadyExists: "Ya se aplicó ese tipo de descuento.",
  TotalAmountInPreliquidationCannotBeZeroOrLess: "El importe total de la preliquidación no puede ser cero o menor.",
  PreliquidationNotFound: "No se ha encontrado la preliquidación.",
  PreliquidationDetailNotFound: "No se ha encontrado el detalle de la preliquidación.",
  CalculatedTotalDiscountAmountCannotBeGreaterThanTotalAmount:
    "El importe total calculado del descuento no puede ser mayor que el importe total de la preliquidación.",
  PreliquidationTripWasNotFound: "No se encontró el viaje en la preliquidación.",
  AmountPerPassengerInPassengerCountDiscountCannotBeSetToZeroOrLess:
    "El monto por pasajero en el descuento por cantidad de pasajeros no puede ser cero o menor.",
  CalculatedAmountInDiscountCannotBeZeroOrLess: "El importe calculado en el descuento no puede ser cero o menor.",
  PercentageDiscountValueShouldBeBetweenZeroAndOne: "El valor de descuento porcentual debe estar entre 0 y 1.",
  PercentageDiscountAmountToApplyDiscountShouldBeGreaterThanZero:
    "El importe de descuento porcentual debe ser mayor que cero.",
  UserIsRequiredToCreateAPreliquidationStateChange:
    "Se requiere un usuario para crear un cambio de estado de preliquidación.",
  RejectionDescriptionInPreliquidationStateChangeCannotBeMoreThan500Chars:
    "La descripción de rechazo en el cambio de estado de preliquidación no puede tener más de 500 caracteres.",
  PreliquidationStateCannotBeNullInPreliquidationStateChange:
    "El estado de preliquidación no puede ser nulo en el cambio de estado de preliquidación.",
  PreliquidationStateShouldBeRejectedIdToCrateARejection:
    "El estado de preliquidación debe ser 'Rechazado' para crear un rechazo.",
  AmountInPreliquidationSubDetailCannotBeLessThanZero:
    "El importe en el subdetalle de preliquidación no puede ser menor que cero.",
  AmountInPreliquidationDetailCannotBeLessThanZero:
    "El importe en el detalle de preliquidación no puede ser menor que cero.",
  TotalAmountToPayInPreliquidationCannotBeZeroOrLess:
    "El importe total a pagar en la preliquidación no puede ser cero o menor.",
  TotalAmountInPreliquidationSubDetailTotalAmountCannotBeLessThanZero:
    "El importe total en el subdetalle de preliquidación no puede ser menor que cero.",
  TransportCompanyOrEconomicGroupInPreliquidationCannotBeNull:
    "La compañía de transporte o el grupo económico en la preliquidación no pueden ser nulos.",
  TransportCompanyAndEconomicGroupInPreliquidationCannotBeBothNotNull:
    "La compañía de transporte y el grupo económico en la preliquidación no pueden ser ambos no nulos.",
  InitialPreliquidationStateShouldBeDraft: "El estado inicial de la preliquidación debe ser borrador.",
  TripsAreRequiredToCreateAPreliquidationDetails: "Se requieren viajes para crear detalles de preliquidación.",
  PreliquidationStateDraftNotFound: "No se encontró el estado de preliquidación 'Borrador'.",
  PreliquidationSubDetailNotFound: "No se encontró el subdetalle de preliquidación.",
  AmountPerPassengerNotFound: "Se requiere el monto por pasajero.",
  DiscountValueNotPercentage: "El valor del descuento no es un porcentaje. (Rango permitido 0 - 0.99)",
  DiscountValueIsPercentage: "El valor del descuento es un porcentaje. (debe ser mayor que 1)",
  DiscountTypeNotFound: "No se encontró el tipo de descuento.",
  DiscountCannotBeRemoved:
    "No es posible eliminar el descuento porque la preliquidación está en estado 'Confirmado', 'Rechazado' o 'Liquidado'",
  DiscountCannotBeAdded:
    "No es posible agregar el descuento porque la preliquidación no está en estado 'Borrador' ni 'Pendiente'",
  DiscountGreaterThanTotalPreliquidation: "El descuento es mayor que la preliquidación total",
  DiscountAmountInPreliquidationDetailCannotBeLessThanZero:
    "El importe del descuento en el detalle de preliquidación no puede ser menor que cero",
  DiscountNotFound: "El descuento no ha sido encontrado",
  TripsAreRequiredToCreateAPriliquidationDetails: "Se requieren viajes para crear detalles de preliquidación.",
  PreliquidationStateShouldBeConfirmed:
    "El estado de la preliquidación debe ser 'Confirmado' para confirmar una preliquidación.",
  APreliquidationInRejectedStateCannotBeConfirmed: "No se puede confirmar una preliquidación en estado 'Rechazado'.",
  APreliquidationInDraftStateCannotBeConfirmed: "No se puede confirmar una preliquidación en estado de 'Borrador'.",
  PreliquidationIsAlreadyConfirmed: "La preliquidación ya está confirmada.",
  PreliquidationStateNotFound: "No se encontró el estado de preliquidación.",
  GivenPreliquidationStateShouldBePending: "El estado de preliquidación dado debe ser 'Pendiente'",
  PreliquidationStateShouldBeInDraftToMoveToPendingState:
    "El estado de preliquidación debe estar en Borrador para pasar al estado 'Pendiente'",
  GivenCollectionStateShouldBeInPreliquidation: "El estado de cobro dado debe estar 'En preliquidación'",
  ATripWithoutPendingPaymentCollectionStateCannotBeChangedInPreliquidation:
    "Un viaje sin un estado de cobro 'Pendiente' no se puede cambiar a 'En preliquidación'",
  ATripWithoutPendienteDeEnvioEndorsementStateCannotBeChangedToInPreliquidation:
    "Un viaje sin un estado de endoso 'Pendiente de Envio' no se puede cambiar a 'En Preliquidación'",
  PreliquidationStatePendingNotFound: "No se encontró el estado de preliquidación 'Pendiente'",
  ATripThatExistsInOtherPreliquidationCannotBeTakenForThis: "El viaje existe en otra preliquidación",
  InPreliquidationCollectionStateNotFound: "No se encontró el estado de cobro 'En Preliquidación'",
  PreliquidationIsAlreadyRejected: "La preliquidación ya está rechazada.",
  APreliquidationInConfirmedStateCannotBeRejected: "Una preliquidación en estado 'Confirmado' no puede ser rechazada.",
  APreliquidationInPaidOutStateCannotBeRejected: "Una preliquidación en estado 'Pagado' no puede ser rechazada.",
  GivenCollectionStateShouldBePendingPayment: "El estado de cobro dado debe ser 'Pago pendiente'",
  PendingPaymentCollectionStateNotFound: "No se encontró el estado de cobro 'Pago pendiente'",
  OriginAndDestinationStatesInCollectionStateChangeMustBeDifferent: "El estado de origen y destino deben ser diferentes",
  NotFoundTripsByEconomicGroupForCreatePreliquidation: "No se encontraron viajes para este grupo económico.",
  NotFoundTripsByTransportCompanyForCreatePreliquidation: "No se encontraron viajes para esta compañía transportista",

  // cuenta corriente
  TransportCompanyNotFound: 'No se ha encontrado la empresa transportista.',

  // Endorsement Rejection
  ThereIsNoRowsWithTripsToPatchEndorsementStateToRejected: "No se encontraron registros de endosos para ser rechazados.",
  RejectedEndorsementStateNotFound: "No se encontró el estado 'Rechazado'.",
  ProcessTripMessageWhenExternalIdIsUnexpected: "Sin valor esperable de 'ExternalId' para esta fila.",
  ProcessTripMessageWhenServiceTypeIdIsUnexpected: "Sin valor esperable de 'ServiceTypeId' para esta fila.",
  ProcessTripMessageWhenTripWasNotFound: "No se encontró el viaje.",
  ProcessRejectionEndorsementMessageWhenTheTripIsAlreadyRejected: "El viaje ya se encuentra en estado de endoso 'Rechazado'.",
  ProcessRejectionEndorsementMessageWhenTheTripHasEndorsementStateDifferentFromPendingSending: "El viaje no se encuentra en estado de endoso 'Pendiente de Envio' y no puede rechazarse.",
  ProcessTripMessageWhenAnUnexpectedErrorOcurred: "Ocurrió un error inesperado. Comuníquese con el equipo de desarrollo."
};

export const isTypeAboutBankAccounts = (type: any) => {
  if (
    type === getErrorTypeName(errorMessages.DebinUnexpectedException) ||
    type === getErrorTypeName(errorMessages.InvalidCbu) ||
    type === getErrorTypeName(errorMessages.CuitNotBelongEnteredCbu) ||
    type === getErrorTypeName(errorMessages.CbuBankAccountInactive) ||
    type === getErrorTypeName(errorMessages.BankAccountNotEnabled) ||
    type === getErrorTypeName(errorMessages.DebinSubscriptionUnexpectedException)
  ) {
    return true;
  }

  return false;
};

export const getMessageErrors = (error: string): string =>
  errorMessages[error] || "Error desconocido. Por favor, intente nuevamente más tarde.";

export function getErrorTypeName(type: any): string {
  for (const key in errorMessages) {
    if (errorMessages[key] === type) {
      return key;
    }
  }
  return "";
}
