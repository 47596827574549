import { AutoComplete, Button, DatePicker, Form, Select, Space, Switch } from 'antd';
import Title from "antd/es/typography/Title";
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { ApiDateFormat, getModalitiesDescription, getServiceTypes } from '../../../services/api.client';
import { ServiceTypeResponse } from '../../../models/ServiceType';
import { DefaultDateFormat } from '../../../models/Date';
import dayjs from 'dayjs';
import './RatesFilters.scss';

const { RangePicker } = DatePicker;

interface RateFiltersProps {
  getRatesFromApi: () => void;
  setLoading: (value: any) => void;
  setModality: (value: any) => void;
  setServiceTypeId: (value: any) => void;
  setFromTo: (value: any) => void;
  setIsNotDatedUntil: (value: boolean) => void
  loading: boolean;
  modality?: string;
  serviceTypeId?: string;
  isNotDatedUntil: boolean
  fromTo: [string | undefined, string | undefined];
}

const RateFilters = ({
  getRatesFromApi,
  setLoading,
  setModality,
  setServiceTypeId,
  setFromTo,
  loading,
  modality,
  serviceTypeId,
  fromTo,
  isNotDatedUntil,
  setIsNotDatedUntil
}: RateFiltersProps) => {
  const [form] = Form.useForm();
  const [serviceTypes, setServiceTypes] = useState<ServiceTypeResponse[]>([]);
  const [modalities, setModalities] = useState<string[]>([]);

  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const getModalitiesFromApi = async () => setModalities(await getModalitiesDescription());
    const getServiceTypesFromApi = async () => setServiceTypes(await getServiceTypes());

    getModalitiesFromApi();
    getServiceTypesFromApi();
    getRatesFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRatesIfEnter = (event: React.KeyboardEvent<HTMLElement>) => event.key === 'Enter' && !isSelectOpen && (getRatesFromApi(), setIsSelectOpen(false));

  const clearFilters = () => {
    form.resetFields();
    setModality(undefined);
    setServiceTypeId(undefined);
    setFromTo([undefined, undefined]);
  };

  return (
    <div className="rateTableFilters">
      <div>
        <Title level={4}>Filtros de tarifas</Title>
      </div>

      <Form form={form} layout="vertical">
        <Space size={15} wrap>
          <Form.Item label="Tipo de Servicio" name="serviceType">
            <Select
              placeholder="Tipo de Servicio"
              defaultValue={serviceTypeId}
              options={serviceTypes?.map(p => ({ label: p.description, value: p.id }))}
              onChange={option => setServiceTypeId(option)}
              onKeyUp={getRatesIfEnter}
              onMouseDown={e => { e.preventDefault(); e.stopPropagation(); }}
              onDropdownVisibleChange={(open) => setIsSelectOpen(open)}
              className='ratesFilter'
              loading={loading}
              allowClear
            />
          </Form.Item>
          <Form.Item label="Modalidad" name="modality">
            <AutoComplete
              placeholder="Modalidad"
              defaultValue={modality}
              options={modalities?.map(description => ({ label: description, value: description }))}
              onChange={option => setModality(option)}
              onDropdownVisibleChange={(open) => setIsSelectOpen(open)}
              className='ratesFilter'
              filterOption
              allowClear
            />
          </Form.Item>
          <Form.Item label="Fecha desde - hasta" name="fromTo">
            <RangePicker
              placeholder={["Desde", "Hasta"]}
              format={DefaultDateFormat}
              defaultValue={[fromTo[0] ? dayjs(fromTo[0]) : null, fromTo[1] ? dayjs(fromTo[1]) : null]}
              onChange={fromTo => setFromTo([
                fromTo?.[0]?.format(ApiDateFormat), 
                fromTo?.[1]?.format(ApiDateFormat)
              ])}
              allowEmpty={[true, true]}
              allowClear
            />
          </Form.Item>
          <Form.Item label="Sin fecha hasta" name="undatedUntil ">
            <Switch checked={isNotDatedUntil} onChange={setIsNotDatedUntil} />
          </Form.Item>
        </Space>
      </Form>

      <div>
        <Button
          type="primary"
          style={{ width: 100 }}
          onClick={() => getRatesFromApi()}
          loading={loading}
          disabled={loading}
          icon={<SearchOutlined />}
          className="startButton"
        >
          Buscar
        </Button>
        <Button
          type="default"
          style={{ width: 100 }}
          onClick={() => clearFilters()}
          loading={loading}
          disabled={loading}
          className="startButton"
          icon={<ClearOutlined />}
        >
          Limpiar
        </Button>
      </div>
    </div>
  );
};

export default RateFilters;
