import { BankAccountDTO } from '../DTO/transportCompany/BankAccount'
import { TransportCompanyResponseDTO } from '../DTO/transportCompany/TransportCompany.dto'
import { TransportCompaniesParams, TransportCompanyResponse, TransportCompanyServices } from '../models/TransportCompany/TransportCompany'
import { TransportCompanyBusinessName } from '../models/TransportCompany/TransportCompanyBusinessName'
import { deleteDuplicates } from '../utils/array.utils'
import { createQueryParams } from '../utils/http.utils'
import apiClient from './api.client'

export const getTransportCompanyBusinessNames = async () => {
  const response = await apiClient.get<TransportCompanyBusinessName[]>('/TransportCompanies/BusinessName')
  return deleteDuplicates(response.data)
}

export const getTransportCompanies = async (params?: TransportCompaniesParams): Promise<TransportCompanyResponse[]> => {
  const queryString = createQueryParams(params as any)
  const response = await apiClient.get<TransportCompanyResponseDTO[]>(`TransportCompanies${queryString}`)
  return response.data.map(transport => ({
      id: transport.id,
      name: transport.businessName,
      number: transport.companyNumber
  }))
}

export const getPreliquidationIncludedTransportCompanies = async (preliquidationId: string): Promise<TransportCompanyResponse[]> => {
  const response = await apiClient.get(`/Preliquidations/${preliquidationId}/TransportCompanies`);
  
  return response.data.map((transport: { id: any; businessName: any }) => ({
    id: transport.id,
    name: transport.businessName,
    number: transport.id
  }));
}

export const getBankAccountsByTransportCompanyId = async (transportCompanyId: string) => {
  const response = await apiClient.get<BankAccountDTO[]>(`/TransportCompanies/${transportCompanyId}/BankAccounts`)
  return response.data
}

export const changeBankAccountStatusByTransportCompanyId = async (transportCompanyId: string, bankAccountId: string, isActive: boolean) => {
  const response = await apiClient.patch(`TransportCompanies/${transportCompanyId}/BankAccounts/${bankAccountId}`, {
    isActive
  })
  return response.data
}

export const totalRegisterTransportCompany  = async (params: any) => {
  const response = await apiClient.post('/TransportCompanies', { ...params })
  return response.data
}

export const deleteDuplicatedBusinessNames = (array: TransportCompanyResponse[]): TransportCompanyResponse[] => {
  const uniqueNames = new Set<string>();
  return array.filter((item: TransportCompanyResponse) => {
    if (!uniqueNames.has(item.name)) {
      uniqueNames.add(item.name);
      return true;
    }
    return false;
  });
};

const transportCompanyServices: TransportCompanyServices = {
  getTransportCompanyBusinessNames,
  getTransportCompanies,
  getBankAccountsByTransportCompanyId,
  changeBankAccountStatusByTransportCompanyId,
  totalRegisterTransportCompany,
  getPreliquidationIncludedTransportCompanies,
  deleteDuplicatedBusinessNames
}

export default transportCompanyServices
