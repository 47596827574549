import { useEffect, useState } from "react"
import { Col, Row, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import { useParams } from "react-router-dom"
import apiClient from "../../../services/api.client"

function TermsAndConditions() {
    const { id } = useParams()
    const [content, setContent] = useState<string | null>(null)
    const antIcon = (<LoadingOutlined style={{ fontSize: 64 }} spin />);
   
    useEffect(() => {
        apiClient.get(`/Terms/${id}`).then((response) => {
            const { content }: any = response.data
            setContent(content as string)
        }).catch((error) => {
            console.log('error: ', error)
        })
    }, [id])

    if (content === null) {
        return (<Row><Col span={24} style={{ display: 'flex', justifyContent: 'center' }}><Spin indicator={antIcon} /></Col></Row>)
    } else {
        return (<Row><Col span={24} style={{ display: 'flex', justifyContent: 'center', padding: 32 }}><div dangerouslySetInnerHTML={{ __html: content }} /></Col></Row>)
    }

}

export default TermsAndConditions