import { Table, Typography } from "antd"
import SkeletonTable from "../../../../shared/skeletonTable/SkeletonTable"
import { toARSCurrency } from "../../../../../utils/number.utils"
import { useContext } from "react"
import CheckingAccountContext from "../../context/CheckinContextProvider"

const { Text } = Typography

const CheckingAccountTable = () => {
  const { checkingAccount, loading, initialLoading, handleChangePanigated } = useContext(CheckingAccountContext)

  if (initialLoading) {
    return (
      <SkeletonTable
        numberOfColumns={5}
        numberOfRows={10}
      />
    )
  }

  const handleChangePagination = (page: number, pageSize: number) => {
    handleChangePanigated({ page, size: pageSize })
  }

  return (
    <Table
      className="layer"
      loading={loading}
      columns={[
        { title: 'Fecha', dataIndex: 'date' },
        { title: 'Motivo', dataIndex: 'reason' },
        { title: 'Descripción', dataIndex: 'description' },
        { title: 'Importe', dataIndex: 'amount', render: (amount: number) => {
          const typeAmount = amount < 0 ? { type: 'danger', symbol: '-'} : { type: 'success', symbol: '+'}
          return <Text type={typeAmount.type as any} strong>{typeAmount.symbol} {toARSCurrency(Math.abs(amount))}</Text>
        }},
        { title: 'Saldo', dataIndex: 'balance', render: (balance: number) => <Text>{toARSCurrency(balance)}</Text>}
      ]} 
      dataSource={checkingAccount?.items}
      pagination={{
        position: ["bottomCenter"],
        total: checkingAccount?.total,
        pageSize: checkingAccount?.pageSize,
        locale: { next_page: "Próxima página", prev_page: "Página anterior" },
        current: checkingAccount?.page,
        onChange: (page: number, pageSize: number) => {
          handleChangePagination(page, pageSize)
        }
      }}
    />
  )
}

export default CheckingAccountTable