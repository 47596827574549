import { Skeleton, Space } from "antd"

import './filterSkeleton.scss'
import PrivateComponent from "../../../privateComponent/PrivateComponent"
import { BASSA_ADMIN } from "../../../../../constants/roles"

const FiltersSkeleton = () => {
  return (
    <div className="skeleton-filters layer">
      <Space wrap className="skeleton-filters__content">
        <div className="skeleton-filters__content-inputs">
          <div className="skeleton-filters__field">
            <Skeleton paragraph={false} active={true} className="skeleton-filters__label"/>
            <Skeleton paragraph={false} active={true} className="skeleton-filters__input"/>
          </div>

          <PrivateComponent roles={[BASSA_ADMIN]}>
            <div className="skeleton-filters__field">
              <Skeleton paragraph={false} active={true} className="skeleton-filters__label"/>
              <Skeleton paragraph={false} active={true} className="skeleton-filters__input"/>
            </div>
          </PrivateComponent>

          <PrivateComponent roles={[BASSA_ADMIN]}>
            <div className="skeleton-filters__field">
              <Skeleton paragraph={false} active={true} className="skeleton-filters__label"/>
              <Skeleton paragraph={false} active={true} className="skeleton-filters__input"/>
            </div>
          </PrivateComponent>

          <div className="skeleton-filters__field">
            <Skeleton paragraph={false} active={true} className="skeleton-filters__label"/>
            <Skeleton paragraph={false} active={true} className="skeleton-filters__input"/>
          </div>
        </div>

        <div className="skeleton-filters__field">
          <Skeleton paragraph={false} active={true} className="skeleton-filters__label"/>
        </div>
      </Space>

      <br />

      <Space>
        <Skeleton.Button active />
        <Skeleton.Button active />
      </Space>
    </div>
  )
}

export default FiltersSkeleton