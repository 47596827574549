import { Button, Form, Typography, Row, Col, Space } from "antd";

import { useForm } from "antd/es/form/Form";
import React from "react";

const { Text } = Typography;

interface PreliquidationApprovalFormProps {
  handleClickCancel?: () => void;
  handleOnSubmit: (values: any) => Promise<void>;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const PreliquidationApprovalForm: React.FC<PreliquidationApprovalFormProps> = ({
  handleClickCancel,
  handleOnSubmit,
  isDisabled,
  isLoading,
}) => {
  const [form] = useForm();

  const onFinish = (values: any) => {
    handleOnSubmit && handleOnSubmit({ ...values });
  };

  return (
    <Form form={form} name="PreliquidationApprovalForm" onFinish={onFinish} layout="vertical">
      <Space direction="vertical" size={"large"}>
        <Row>
          <Col span={24}>
            <Text>¿Está seguro de que desea confirmar la preliquidación?</Text>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col>
            {handleClickCancel ? (
              <Form.Item>
                <Button type="default" onClick={handleClickCancel} disabled={isDisabled}>
                  Cancelar
                </Button>
              </Form.Item>
            ) : null}
          </Col>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={isDisabled} loading={isLoading}>
                Confirmar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Space>
    </Form>
  );
};

export default PreliquidationApprovalForm;
