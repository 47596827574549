export const stateColor = (id: number) => {
    switch (id) {
      case 1:
        return "#269C9E";
      case 8:
      case 9:
        return "#616161";
      case 2:
      case 3:
      case 10:
        return "#E1A325";
      case 4:
        return "#2C8800";
      case 5:
      case 6:
      case 7:
        return "#E14942";
      default:
        return "#616161";
    }
  };
