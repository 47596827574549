import { EconomicGroup, EconomicGroupServices, EconomicGroupsParams } from "../models/EconomicGroup";
import { deleteDuplicates } from "../utils/array.utils";
import { createQueryParams } from "../utils/http.utils";
import apiClient from "./api.client";

export const getEconomicGroups = async (params?: EconomicGroupsParams) => {
  const queryParams = params ? createQueryParams(params as any) : ''
  const response = await apiClient.get<EconomicGroup[]>(`/EconomicGroups${queryParams}`);
  return deleteDuplicates(response.data);
}

const economicGroupServices: EconomicGroupServices = {
  getEconomicGroups
}

export default economicGroupServices
