import { useContext } from "react"

import { Rule } from "antd/es/form"

import { Button, Col, Form, Row, Upload } from "antd"
import { UploadOutlined } from "@ant-design/icons"

import ProtectionContext from "../../context/ProtectionContextProvider"

const ProtectionForm = () => {
  const { loading, handleSubmit } = useContext(ProtectionContext)

  const checkFile = (_: Rule, value: any) => {
    if (value && value.length > 0) {
      const file = value[0].originFileObj
      const acceptedExtensions = ['.xlsx']
      const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase()

      const extensionIsValid = acceptedExtensions.includes('.' + fileExtension)
      return extensionIsValid ? Promise.resolve() : Promise.reject(new Error('Debe adjuntar un archivo de tipo xlsx.'));
    }
    return Promise.reject(new Error('Debe adjuntar un archivo de tipo xlsx.'))
  }

  const onFinish = async (values: any) => {
    const [file] = values.document
    await handleSubmit(file.originFileObj)
  }

  return (
    <Form onFinish={onFinish} name="protectionForm">
      <Row gutter={[16, 16]}>
        <Col>
          <Form.Item
            name="document"
            rules={[{ validator: checkFile }]}
            valuePropName="fileList"
            getValueFromEvent={(e) => e && e.fileList}
            label="Archivo Excel"
          >
            <Upload
              maxCount={1}
              name="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              customRequest={({ file, onSuccess, onError }: any) => {
                setTimeout(() => {
                  const acceptExtension = [
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel'
                  ]
                  if (acceptExtension.includes(file.type)) {
                    onSuccess()
                  } else {
                    onError()
                  }                  
                }, 0)
              }}
            >
              <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              loading={loading}
            >
              Procesar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
export default ProtectionForm