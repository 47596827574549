import { useEffect, useState, useContext } from "react"
import BankAccountStatusContext from "../context/bankAccountStatusContextProvider"
import { message } from "antd"
import { errorMessages } from "../../../../models/Errors/Errors"
import { changeBankAccountStatusByTransportCompanyId, getBankAccountsByTransportCompanyId } from "../../../../services/transportCompany.services"
import { IBankAccount } from "../../../../models/BankAccount"

const useBankAccountStatusTable = () => {
  const { filters } = useContext(BankAccountStatusContext)
  const [loading, setLoading] = useState(false)
  const [accounts, setAccounts] = useState<IBankAccount[]>([])

  const getDataAccounts = async () => {
    try {
      setLoading(true)
      const response = await getBankAccountsByTransportCompanyId(filters.transporter!)
      setAccounts(response)
    } catch {
      message.error(errorMessages.tryAgain)
    } finally {
      setLoading(false)
    }
  }

  const handleChangeBankAccountStatus = async (bankAccountId: string, isActive: boolean) => {
    try {
      setLoading(true)
      await changeBankAccountStatusByTransportCompanyId(filters.transporter!, bankAccountId, isActive)
      const updateAccounts = accounts.map(account => {
        return account.id === bankAccountId ? { ...account, isActive } : account
      })
      setAccounts(updateAccounts)
      message.success(isActive ? 'Se activó la cuenta bancaria' : 'Se desactivó la cuenta bancaria')
    } catch {
      message.error(errorMessages.tryAgain)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (filters.transporter) {
      void getDataAccounts()
    }
  }, [filters.transporter])

  return {
    loading,
    accounts,
    handleChangeBankAccountStatus
  }
}

export default useBankAccountStatusTable