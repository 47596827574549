import { Button, Table, Tooltip, Typography, message } from "antd";
import React, { useContext } from "react";
import PreliquidationContext from "../../contexts/PreliquidationContextProvider";
import { toARSCurrency } from "../../../../../utils/number.utils";
import { CloseCircleOutlined } from "@ant-design/icons";
import { PreliquidationDiscount } from "../../../../../models/Preliquidation/Preliquidation";
import { getDiscounts, removeDiscount } from "../../../../../services/preliquidation.service";
import { errorMessages } from "../../../../../models/Errors/Errors";
import useGetStep2Data from "../../hooks/useGetStep2Data";
import SkeletonTable from "../../../../shared/skeletonTable/SkeletonTable";
import { ErrorResponse } from "../../../../../models/Errors/ErrorResponse";

const { Title } = Typography;

const DiscountTable: React.FC = () => {
  const {
    preliquidationId,
    preliquidationDiscounts,
    setPreliquidationDiscounts,
    loadingPreliquidationDiscounts: loading,
    initialLoading,
    discountModified,
    setDiscountModified,
  } = useContext(PreliquidationContext);
  const { handlePreliquidationDiscountsUpdateTable } = useGetStep2Data();

  const handleDeletePreliquidationDiscount = async (preliquidationId: string, id: string) => {
    try {
      setDiscountModified(true);
      const removeResult = await removeDiscount(preliquidationId, id);
      if (removeResult) {
        const response = await getDiscounts(preliquidationId);
        setPreliquidationDiscounts(response);
        handlePreliquidationDiscountsUpdateTable();
        message.success("Descuento eliminado correctamente");
      } else {
        message.error("No se pudo eliminar el descuento");
      }
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      const type = errorResponse.Type;
      const errorMessage = errorMessages[type] ?? errorMessages.tryAgain;
      message.error(errorMessage);
    } finally {
      setDiscountModified(false);
    }
  };

  if (initialLoading) {
    return (
      <div className="generate-preliquidations-discounts-table layer">
        <SkeletonTable numberOfColumns={6} numberOfRows={5} />
      </div>
    );
  }

  return (
    <div>
      <Title level={4}>Descuentos aplicados:</Title>
      <div className="layer">
        <Table<PreliquidationDiscount>
          loading={loading || discountModified}
          columns={[
            {
              title: "Tipo",
              dataIndex: "discountType",
              key: "discountType.id",
              render: (discountType) => discountType.description,
            },
            {
              title: "Compañía",
              dataIndex: "transportCompany",
              key: "transportCompany",
              render: (transportCompany) => transportCompany,
            },
            {
              title: "Valor",
              dataIndex: "value",
              key: "value",
              render: (value) => toARSCurrency(value),
            },
            {
              title: "Monto por pasajero",
              dataIndex: "amountPerPassenger",
              key: "amountPerPassenger",
              render: (amountPerPassenger) => toARSCurrency(amountPerPassenger),
            },
            {
              title: "Descuento",
              dataIndex: "calculatedAmount",
              key: "calculatedAmount",
              render: (calculatedAmount) => toARSCurrency(calculatedAmount),
            },
            {
              title: "Acciones",
              dataIndex: "id",
              align: "center",
              fixed: "right",
              render: (_, record) => {
                return (
                  <>
                    <Tooltip placement="top" title={"Eliminar Descuento"}>
                      <Button
                        type="link"
                        danger={true}
                        icon={<CloseCircleOutlined />}
                        onClick={() => {
                          handleDeletePreliquidationDiscount(preliquidationId, record.id);
                        }}
                      />
                    </Tooltip>
                  </>
                );
              },
            },
          ]}
          dataSource={preliquidationDiscounts?.map((discount, index) => ({
            ...discount,
            key: `${index}-${discount.id}`,
          }))}
          pagination={false}
          rowKey="id"
          virtual
          scroll={{
            y: 400,
            x: 1000,
          }}
        />
      </div>
    </div>
  );
};

export default DiscountTable;
