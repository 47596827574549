import { Layout } from 'antd';
import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { SideBar } from '../../molecules';
import { Role } from '../../../constants/roles';

interface Props {
	component: JSX.Element;
	roles: Role[];
};

const { Content } = Layout;

const PrivateRoute = ({ component, roles }: Props) => {
	const { isAuth, role } = useContext(AuthContext);

	if (isAuth && roles?.includes(role)) {
		return (
			<Layout>
				<SideBar />
				<Content>
					{component}
				</Content>
			</Layout>
		);
	}

	return <Navigate to='/' replace />;
}

export default PrivateRoute;