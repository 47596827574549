import { CollectionStateDTO } from "../DTO/collectionState/CollectionState.dto";
import { CollectionState, CollectionStateServices } from "../models/CollectionState/CollectionState";
import apiClient from "./api.client";

export const getCollectionStates = async (): Promise<CollectionState[]> => {
  const response = await apiClient<CollectionStateDTO[]>('/CollectionStates')
  return response.data
}

const collectionStateServices: CollectionStateServices = {
  getCollectionStates
}

export default collectionStateServices