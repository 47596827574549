import { Layout, Typography } from 'antd'
import { Content } from 'antd/es/layout/layout'

import { BankAccountStatusContextProvider } from './context/bankAccountStatusContextProvider'
import BankAccountStatutsFilters from './components/filters/BankAccountStatutsFilters'
import BankAccountStatusTable from './components/bankAccountStatusTable/BankAccountStatusTable'

import './bankAccountStatus.scss'

const { Title } = Typography

const BankAccountStatus = () => {
  return (
    <Layout>
      <Content className='bank-account-status-content'>
        <Title level={2}>Activar o desactivar Cuentas Bancarias</Title>
        <BankAccountStatusContextProvider>
          <BankAccountStatutsFilters />
          <BankAccountStatusTable />
        </BankAccountStatusContextProvider>
      </Content>
    </Layout>
  )
}

export default BankAccountStatus