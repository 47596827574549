import { useContext, useEffect, useState } from "react"
import { message } from "antd"
import UsersContext from "../context/UsersContextProvider"
import { errorMessages } from "../../../../models/Errors/Errors"
import { activateUser, getUsers } from "../../../../services/user.services"
import { UserPagination } from "../../../../models/User/User"

const useUserDataTable = () => {
  const { filters } = useContext(UsersContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [pagination, setPagination] = useState<{page: number, pageSize: number }>({ page: 1, pageSize: 10 })
  const [users, setUsers] = useState<UserPagination>()

  const getData = async () => {
    try {
      setLoading(true)
      const { page, pageSize } = pagination
      const response = await getUsers({ page, pageSize, filters })
      setUsers(response)
    } catch {
      message.error(errorMessages.tryAgain)
    } finally {
      setInitialLoading(false)
      setLoading(false)
    }
  }

  const handleChangePagination = async (page: number, pageSize: number) => {
    setPagination({ page, pageSize })
  }

  const handleActivateUser = async (userId: string, isActive: boolean) => {
    try {
      setLoading(true)
      const response = await activateUser(userId, isActive)
      const { isActive: userIsActive } = response as any
      message.success(`Usuario ${userIsActive ? 'activado' : 'desactivado'} correctamente.`)
      await getData()
    } catch {
      message.error(errorMessages.tryAgain)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!initialLoading) {
      handleChangePagination(1, pagination.pageSize)
    }
  }, [filters])

  useEffect(() => {
    void getData()
  }, [pagination])

  return {
    users,
    handleChangePagination,
    pagination,
    loading,
    initialLoading,
    handleActivateUser
  }
}

export default useUserDataTable
