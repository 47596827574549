import { Table, Typography } from "antd";
import React, { useContext } from "react";
import { toARSCurrency } from "../../../../../utils/number.utils";
import { PreliquidationSubDetails } from "../../../../../models/Preliquidation/Preliquidation";
import { TypeOfService } from "../../../../../constants/serviceTypes";
import PreliquidationContext from "../../contexts/PreliquidationContextProvider";

const PreliquidationDetailsTable: React.FC = () => {
  const { Title } = Typography;

  const {
    preliquidationDetails,
    loadingPreliquidationDetails: loading,
    discountModified,
    tripModified,
  } = useContext(PreliquidationContext);

  return (
    <div>
      <Title level={4}>Resumen:</Title>
      <div className="layer">
        <Table
          loading={loading || discountModified || tripModified}
          columns={[
            {
              title: "Compañía",
              dataIndex: "transportCompany",
              key: "transportCompany",
              render: (transportCompany) => transportCompany,
            },
            {
              title: "DUT",
              dataIndex: "subDetails",
              key: "amount",
              render: (subDetails) => {
                const dutSubDetails: PreliquidationSubDetails | undefined = subDetails.find(
                  (detail: PreliquidationSubDetails) => detail.serviceType === TypeOfService.DUT
                );
                return dutSubDetails ? toARSCurrency(dutSubDetails.amount) : null;
              },
            },
            {
              title: "Lista Pasajeros",
              dataIndex: "subDetails",
              key: "amount",
              render: (subDetails) => {
                const dutSubDetails: PreliquidationSubDetails | undefined = subDetails.find(
                  (detail: PreliquidationSubDetails) => detail.serviceType === TypeOfService.PassengerList
                );
                return dutSubDetails ? toARSCurrency(dutSubDetails.amount) : null;
              },
            },
            {
              title: "Total liquidación",
              dataIndex: "amountToPay",
              key: "amountToPay",
              render: (amountToPay) => toARSCurrency(amountToPay),
            },
            {
              title: "Descuento",
              dataIndex: "discountAmount",
              key: "discountAmount",
              render: (discountAmount) => toARSCurrency(discountAmount),
            },
            {
              title: "Saldo en Cta. Corriente",
              dataIndex: "currentAccountBalance",
              key: "currentAccountBalance",
              render: (currentAccountBalance) => toARSCurrency(currentAccountBalance),
            },
          ]}
          dataSource={preliquidationDetails?.details.map((p, i) => ({
            ...p,
            key: `${i}-${p.id}`,
          }))}
          scroll={{ x: true }}
          pagination={false}
          summary={() => {
            return (
              <>
                <Table.Summary.Row className="preliquidationDetailsSummary">
                  <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <span>
                      {toARSCurrency(
                        preliquidationDetails?.totalSubDetailAmounts.find(
                          (subDetail) => subDetail.serviceType === TypeOfService.DUT
                        )?.amount
                      )}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <span>
                      {toARSCurrency(
                        preliquidationDetails?.totalSubDetailAmounts.find(
                          (subDetail) => subDetail.serviceType === TypeOfService.PassengerList
                        )?.amount
                      )}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    {toARSCurrency(preliquidationDetails?.totalAmountToPay)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <span>{toARSCurrency(preliquidationDetails?.totalDiscountAmount)}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <span>{toARSCurrency(preliquidationDetails?.totalCurrentAccountBalance)}</span>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </div>
    </div>
  );
};

export default PreliquidationDetailsTable;
