import { Layout } from "antd"
import { Content } from "antd/es/layout/layout"

import GoBackTitle from "../../shared/goBackTitle/GoBackTitle"
import PaymentDetailHeader from "./components/paymentDetailHeader/PaymentDetailHeader"
import PaymentDetailTable from "./components/paymentDetailTable/PaymentDetailTable"

import usePaymentDetail from "./hooks/usePaymentDetail"

import './paymentDetail.scss'

const PaymentDetail = () => {
  const { loadingTrip, trip, debins, loadingDebins } = usePaymentDetail()

  return (
    <Layout>
      <Content className="payment-datail-content">
        <GoBackTitle title="Detalle de cobranza" goTo={"/trips"} />
        <PaymentDetailHeader
          tripId={trip ? trip.externalId.toString() : ''}
          collectionStateDescription={trip ? trip.collectionState.description : ''}
          collectionStateId={trip ? trip.collectionState.id : 0}
          arrivalDate={trip ? trip.arrivalAt : ''}
          departureDate={trip ? trip.departureAt : ''}
          loading={loadingTrip}
        />
        <PaymentDetailTable 
          loading={loadingDebins}
          debins={debins}
        />
      </Content>
    </Layout>
  )
}

export default PaymentDetail