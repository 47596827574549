import { useContext } from "react"

import { Table, Typography } from "antd"

import EndorsementPaymentContext from "../../context/EndorsementPaymentContextProvider"

import { COLORS } from "../../../../../constants/colors"

const { Paragraph, Text } = Typography

const PayEndorsementsTable = () => {
  const { payedEndorsements, loading } = useContext(EndorsementPaymentContext)

  return (
    <>
      <Paragraph strong>Resultados:</Paragraph>
      <Table
      loading={loading}
      columns={[
        { title: 'Fila', dataIndex: 'row' },
        { title: 'ExternalId', dataIndex: 'externalId' },
        { title: 'ServiceTypeId', dataIndex: 'serviceTypeId' },
        {
          title: 'Resultado',
          dataIndex: 'message',
          render: (message, data) => {
            const colorAndStatus = data.success ? {color: COLORS.GREEN, status: 'El viaje ha sido pagado correctamente.'} : { color: COLORS.RED, status: 'ERROR:'}
            return <Text style={{ color: colorAndStatus.color }} strong>{`${colorAndStatus.status} ${message}`}</Text>
          }
        }
      ]}
      dataSource={payedEndorsements.map((payedEndorsement, index) => {
        return {
          ...payedEndorsement,
          key: `${payedEndorsement.externalId}-${index}`
        }
      })}
    />
    </>
  )
}

export default PayEndorsementsTable