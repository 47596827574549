import { useEffect, useState } from "react"

import { Button, Form, Radio, Typography, Row, Col, Table, message } from "antd"

import { useForm } from "antd/es/form/Form"
import { TypeOfPayment } from "../../../../../constants/payments"
import { dateFormatWithoutTime } from "../../../../../utils/dateFormatter.util"
import { getPreliquidations } from "../../../../../services/preliquidation.service"
import { errorMessages } from "../../../../../models/Errors/Errors"
import { ErrorResponse } from "../../../../../models/Errors/ErrorResponse"
import { PreliquidationDTO } from "../../../../../DTO/preliquidation/preliquidation.dto"
import { PreliquidationItem } from "../../../../../models/Preliquidation/Preliquidation"
import { toARSCurrency } from "../../../../../utils/number.utils"
import { PreliquidationStateIds } from "../../../../../constants/preliquidations"

const { Text } = Typography

interface PaymentApprovalFormProps {
  handleClickCancel?: () => void
  handleOnSubmit: (values: any) => Promise<void>
  isDisabled?: boolean
  isLoading?: boolean
  reset?: boolean
  transportCompanyId?: string
  economicGroupId?: string
}

const PaymentApprovalForm: React.FC<PaymentApprovalFormProps> = ({ handleClickCancel, handleOnSubmit, isDisabled, isLoading, reset, transportCompanyId, economicGroupId }) => {
  const [typeOfPayment, setTypeOfPayment] = useState<TypeOfPayment.Preliquidation | TypeOfPayment.account>(TypeOfPayment.account)
  const [form] = useForm()
  const [preliquidations, setPreliquidations] = useState<PreliquidationDTO>();
  const [loadingPreliquidations, setLoadingPreliquidations] = useState<boolean>(false);
  const [selectedPreliquidation, setSelectedPreliquidation] = useState<PreliquidationItem>();

  const resetForm = () => {
    setTypeOfPayment(TypeOfPayment.account)
    form.setFieldsValue({ paymentType: TypeOfPayment.account })
    setSelectedPreliquidation(undefined)
    setPreliquidations(undefined)
  }

  const onFinish = (values: any) => {
    handleOnSubmit && handleOnSubmit({ ...values, preliquidationId: selectedPreliquidation?.id })
  }

  useEffect(() => {
    reset !== undefined && resetForm()
  }, [reset])

  useEffect(() => {
    if (typeOfPayment === TypeOfPayment.Preliquidation) {
      fetchPreliquidationsData(1, 10);
    }
    
  }, [typeOfPayment]);

  const fetchPreliquidationsData = async (page: number, pageSize: number) => {
    try {
      setLoadingPreliquidations(true);
      console.log(transportCompanyId);
      console.log(economicGroupId);
      
      
      if (!economicGroupId) {
        const response = await getPreliquidations({ transportCompanyId, preliquidationStatus: PreliquidationStateIds.Confirmed }, page, pageSize);
        setPreliquidations(response); 
      } else {
        const response = await getPreliquidations({ economicGroupId, preliquidationStatus: PreliquidationStateIds.Confirmed }, page, pageSize);
        setPreliquidations(response);
      }      
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      const erroMessage = errorResponse ? errorMessages[errorResponse.Type] : errorMessages.tryAgain;
      message.error(erroMessage);
    } finally {
      setLoadingPreliquidations(false);
    }
  };

  return (
    <Form
      form={form}
      name="paymentApprovalForm"
      onFinish={onFinish}
      layout="vertical"
    >
      <Form.Item
        label={<Text strong>Seleccione si desea imputar el pago a una preliquidación existente o a la cuenta corriente:</Text>}
        name="paymentType"
        initialValue={typeOfPayment}
      >
        <Radio.Group onChange={e => setTypeOfPayment(e.target.value)} value={typeOfPayment}>
          <Radio value={TypeOfPayment.Preliquidation}>Preliquidación</Radio>
          <Radio value={TypeOfPayment.account}>Cuenta Corriente</Radio>
        </Radio.Group>
      </Form.Item>

      {
        typeOfPayment === TypeOfPayment.Preliquidation && 
        <Row gutter={[16, 16]}>
           <Table
              loading={loadingPreliquidations}
              rowSelection={{
                type: 'radio',
                onChange: (_, selectedRows) => setSelectedPreliquidation(selectedRows[0]),
              }}
              columns={[
                { title: 'Id', dataIndex: 'id', key: 'id' },
                { title: 'Fecha de creacion', dataIndex: 'createdAt', key: "createdAt", render: createdAt => dateFormatWithoutTime(createdAt) },
                { title: 'Monto Preliquidacion', dataIndex: 'totalAmount', key: "totalAmount", render: totalAmount => toARSCurrency(totalAmount) },
                { title: 'Monto Pendiente', dataIndex: 'pendingBalance', key: "pendingBalance", render: pendingBalance => toARSCurrency(pendingBalance) }
              ]}
              dataSource={preliquidations?.items?.map((p, i) => ({ ...p, key: `${i}-${p.id}`}))}
              pagination={{
                position: ["bottomCenter"],
                total: preliquidations?.totalItems,
                pageSize: preliquidations?.pageSize,
                locale: { next_page: "Próxima página", prev_page: "Página anterior" },
                current: preliquidations?.page,
                onChange: (page: number, pageSize: number) => fetchPreliquidationsData(page, pageSize)
              }}
            />
        </Row>
      }

      <Row gutter={[16, 16]}>
        { 
          handleClickCancel ? (
              <Col>
                <Form.Item>
                  <Button type="default" onClick={handleClickCancel} disabled={isDisabled}>
                    Cancelar
                  </Button>
                </Form.Item>
              </Col>
            ) : null
        }
        <Col>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isDisabled || (typeOfPayment === TypeOfPayment.Preliquidation && !selectedPreliquidation)}
              loading={isLoading}
            >
              Guardar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default PaymentApprovalForm