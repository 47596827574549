import { useState } from "react";
import { Alert, Button, Col, Form, Input, Row } from "antd";
import ReactCodeInput from "react-code-input";
import apiClient from "../../../services/api.client";
import { getMessageErrors } from "./errors";
import { useNavigate } from "react-router-dom";
import "./passwordChange.scss";
import { UserOutlined } from "@ant-design/icons";
import notification from "../../atoms/notification/Notification";

function PasswordChange() {
	const navigate = useNavigate();

	const [form] = Form.useForm();

	const [codeSent, setCodeSent] = useState(false);
	const [codeLength, setCodeLength] = useState(0);
	const [error, setError] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	const sendCodeToEmail = (email: any) => {
		setError("");
		apiClient
			.post("/Security/SendPasswordChangeCode", { email })
			.then(() => {
				setLoading(false);
				setCodeSent(true);
				notification(
					"success",
					"Si su correo electrónico se encuentra registrado recibirá un código de 6 dígitos para finalizar el desbloqueo o activación",
				);
			})
			.catch((error) => {
				setLoading(false);
				console.log("Error: ", error);
				notification("error", "Hubo un error procesando su solicitud");
			});
	};

	const updatePassword = (values: any) => {
		delete values["confirmPassword"];
		apiClient
			.post("/Security/UpdatePassword", values)
			.then(() => {
				notification("success", "Contraseña Cambiada");
				navigate("/");
			})
			.catch((error) => {
				console.log("ERROR: ", error);
				setLoading(false);
				setCodeSent(false);
				form.resetFields();

				if (error.response) {
					const { Type } = error.response.data;
					if (Type) {
						setError(getMessageErrors(Type));
					} else {
						notification("error", "Hubo un error procesando su solicitud");
					}
				} else {
					notification("error", "Hubo un error procesando su solicitud");
				}
			});
	};

	const onSuccess = (values: any) => {
		setLoading(true);
		if (codeSent) {
			updatePassword(values);
		} else {
			sendCodeToEmail(values.email);
		}
	};

	return (
		<div className="fPassword">
			<div className="divForm">
				<h4 className="title">Recuperación de Cuenta</h4>
				<p className="parraf">
					Si olvidaste tu contraseña o por algún motivo tu usuario se encuentra
					bloqueado, ingresa tu correo electrónico para ayudarte a recuperarlo.
				</p>
			</div>
			<Form
				name="basic"
				form={form}
				initialValues={{ remember: true }}
				onFinish={onSuccess}
				autoComplete="off"
				layout="vertical"
				requiredMark={false}
			>
				<Row justify="center">
					<Col>
						{error && (
							<div style={{ marginBottom: 10 }}>
								<Alert message={error} type="error" showIcon />
							</div>
						)}
					</Col>
				</Row>
				<Row justify="center">
					<Col xs={{ span: 18 }} md={{ span: 20 }} xl={{ span: 28 }}>
						<Form.Item
							name="email"
							rules={[
								{
									required: true,
									type: "email",
									message:
										"El correo electrónico ingresado posee un formato inválido",
								},
							]}
						>
							<Input
								prefix={<UserOutlined />}
								maxLength={50}
								autoComplete="email"
								placeholder="Correo Electrónico"
							/>
						</Form.Item>
						{codeSent && (
							<>
								<Form.Item
									label="Código"
									name="code"
									rules={[
										{
											required: true,
											message: "El ${label} es requerido",
										},
										{
											pattern: /^(?:\d*)$/,
											message: "Solo se permiten ingresar caracteres numéricos",
										},
									]}
								>
									<ReactCodeInput
										type="text"
										fields={6}
										name="code"
										inputMode="numeric"
										onChange={(value) => {
											setCodeLength(value.length);
										}}
									/>
								</Form.Item>

								<Form.Item
									name="password"
									label="Contraseña"
									hasFeedback
									rules={[
										{
											required: true,
											message: "La contraseña es requerida",
										},
										{
											min: 6,
											message: "La contraseña debe tener al menos 6 caracteres",
										},
										{
											max: 50,
											message:
												"La contraseña debe tener menos de 50 caracteres",
										},
										{
											pattern: new RegExp(/[0-9]/),
											message: "La contraseña debe tener al menos un numero",
										},
										{
											pattern: new RegExp(/[a-z]/),
											message:
												"La contraseña debe tener al menos una minúscula",
										},
										{
											pattern: new RegExp(/[A-Z]/),
											message:
												"La contraseña debe tener al menos una mayúscula",
										},
										{
											pattern: new RegExp(
												/([^a-zA-Z\d])+([a-zA-Z\d])+|([a-zA-Z\d])+([^a-zA-Z\d])+/,
											),
											message:
												"La contraseña debe tener al menos un caracter especial",
										},
										{
											pattern: new RegExp(/^\S+(?: \S+)*$/),
											message:
												"La contraseña no puede comenzar o terminar con un espacio en blanco",
										},
									]}
								>
									<Input.Password />
								</Form.Item>

								<Form.Item
									name="confirmPassword"
									label="Repetir Contraseña"
									dependencies={["password"]}
									hasFeedback
									rules={[
										{
											required: true,
											message: "Repetí la contraseña",
										},
										({ getFieldValue }) => ({
											validator(_, value) {
												if (!value || getFieldValue("password") === value) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error("Las contraseñas no coinciden"),
												);
											},
										}),
									]}
								>
									<Input.Password />
								</Form.Item>
							</>
						)}
						<Row justify="center">
							<Form.Item
								shouldUpdate
								className="w75"
								style={{ marginBottom: "15px" }}
							>
								{({ getFieldsError, isFieldsTouched }) => {
									return (
										<Button
											block
											type="primary"
											htmlType="submit"
											loading={loading}
											disabled={
												!isFieldsTouched(true) ||
												getFieldsError().filter(
													({ errors }: any) => errors.length,
												).length > 0 ||
												(codeSent && codeLength < 6)
											}
										>
											Continuar
										</Button>
									);
								}}
							</Form.Item>
						</Row>
						<Row justify="center">
							<Form.Item className="w75">
								<Button
									ghost
									className="btn-ghost"
									onClick={() => navigate("/")}
									block
								>
									Volver
								</Button>
							</Form.Item>
						</Row>
					</Col>
				</Row>
			</Form>
		</div>
	);
}

export default PasswordChange;
