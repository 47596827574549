import React from "react";

function MarkIcon(){
return <svg width="173" height="117" viewBox="0 0 173 117" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.669502 88.5741C-4.5305 54.9741 21.8362 43.5741 35.6695 42.0741C85.6695 42.0741 66.6695 10.0738 109.669 1.07379C152.669 -7.92621 167.169 42.0741 168.169 60.5738C169.169 79.0735 140.169 108.574 109.669 93.0738C92.6585 84.4289 76.7654 91.8074 65.9888 101.079C58.0052 107.948 49.883 115.616 39.3934 116.556C23.2935 117.999 4.47131 113.14 0.669502 88.5741Z" fill="#FDF2F2"/>
<rect x="56" y="30" width="56" height="56" rx="8" fill="#FF6058"/>
<path d="M108.75 25V25C113.306 25 117 28.6937 117 33.25V80C117 86.0751 112.075 91 106 91H84H75.75M59.25 91V91C54.6937 91 51 87.3063 51 82.75V36C51 29.9249 55.9249 25 62 25H84H92.25" stroke="#202124" stroke-width="1.7" stroke-linecap="round"/>
<path d="M76 67L84.5 58.5L93 50" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M93 67L84.5 58.5L76 50" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="8.5" cy="42.5" r="4.5" fill="#FDF2F2"/>
<circle cx="19.5" cy="35.5" r="2.5" fill="#FDF2F2"/>
<circle cx="159.5" cy="95.5" r="4.5" fill="#FDF2F2"/>
<circle cx="170.5" cy="88.5" r="2.5" fill="#FDF2F2"/>
</svg>

}

export default MarkIcon;
