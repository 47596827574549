import { createRef, useEffect, useState } from "react";
import { Button, Form, Input, Row, Typography } from "antd";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {
	createTransportCompany,
	getLastTermId,
	updateTransportCompany,
} from "../../../services/api.client";
import {
	errorNotification,
	successNotification,
} from "../notification/Notification";
import * as StringUtils from "../../../utils/string.utils";
import { TransportCompany } from "../../../models/TransportCompany/TransportCompany";
import { Document } from "../../../models/Document";
import { ErrorResponse } from "../../../models/Errors/ErrorResponse";
import { registerResult } from "../../../constants/routes";
import {
	errorMessages,
	getMessageErrors,
	isTypeAboutBankAccounts,
} from "../../../models/Errors/Errors";
import {
	CreateTransportCompanyRequest,
	UpdateTransportCompanyRequest,
} from "../../../models/TransportCompany/TransportCompanyRequest";
import { TransportCompanyForm } from "../../../models/TransportCompany/TransportCompanyForm";
import "react-phone-input-2/lib/style.css";
import "./transportCompaniesForm.scss";
import { useDispatch, useSelector } from "react-redux";
import {
	openWarningAccountModal,
	updateData as FUpdateData,
} from "../../../store/reducers/warningAccountModalSlice";
import { RootState } from "../../../store/store";
import UserRegistrationWarningModal from "../UserRegistrationWarningModal/UserRegistrationWarningModal";

interface TransportCompaniesFormProps {
	documentSelected: Document;
	userData: any;
	setError: (message: string) => void;
	goBack: () => void;
}

const TransportCompaniesForm = ({
	documentSelected,
	userData,
	setError,
	goBack,
}: TransportCompaniesFormProps) => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const reCaptchaRef = createRef<ReCAPTCHA>();
	const [termsId, setTermsId] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [userRegistrationModalIsOpen, setUserRegistrationModalIsOpen] =
		useState<boolean>(false);
	const [newUser, setNewUser] = useState<TransportCompany>();
	const [bankAccountError, setBankAccountError] = useState<any>({
		input: null,
		order: null,
		message: null,
	});
	const [recaptcha, setRecaptcha] = useState<string | null>(null);

	const [errorMail, setErrorMail] = useState<string>();
	const [errorCompany, setErrorCompany] = useState<string>();
	const [errorDocument, setErrorDocument] = useState<string>();
	const [errorCbu, setErrorCbu] = useState<string>();
	const [isEmailMatchError, setIsEmailMatchError] = useState<boolean>(false);
	const updateData = useSelector(
		(state: RootState) => state.warningAccountModal.updateData,
	);

	const dispatch = useDispatch();

	useEffect(() => {
		if (updateData) {
			dispatch(FUpdateData(false));
			successNotification("Usuario creado con éxito");
			navigate(
				registerResult.dataRoute(
					window.btoa(
						unescape(encodeURIComponent(JSON.stringify({ bool: true }))),
					),
				),
			);
		}
	}, [updateData]);

	const createAccount = async (recaptcha: string | null) => {
		try {
			const hasUserData = userData !== undefined;
			let response;
			if (hasUserData) {
				const data = {
					...newUser,
					bankAccounts: userData.payManually ? [] : newUser?.bankAccounts,
				};
				response = await updateTransportCompany(
					userData.id,
					UpdateTransportCompanyRequest.create(
						data as TransportCompany,
						recaptcha as string,
					),
				);
			} else {
				response = await createTransportCompany(
					CreateTransportCompanyRequest.create(
						newUser as TransportCompany,
						documentSelected.documentTypeId,
						documentSelected.documentNumber,
						recaptcha as string,
					),
				);
			}

			successNotification("Usuario creado con éxito");
			navigate(
				registerResult.dataRoute(
					window.btoa(
						unescape(
							encodeURIComponent(
								JSON.stringify({ bool: true, data: response }),
							),
						),
					),
				),
			);
		} catch (error: any) {
			const errorResponse: ErrorResponse = error.response?.data;
			const message = getMessageErrors(errorResponse.Type);

			switch (message) {
				case errorMessages.CompanyCuitDoesNotMatchWithBankAccountCuit:
					if (userData !== undefined) {
						dispatch(
							openWarningAccountModal({
								data: {
									id: userData.id,
									values: UpdateTransportCompanyRequest.create(
										newUser as TransportCompany,
										recaptcha as string,
									),
								},
								typeRegister: "partial",
							}),
						);
					} else {
						dispatch(
							openWarningAccountModal({
								data: CreateTransportCompanyRequest.create(
									newUser as TransportCompany,
									documentSelected.documentTypeId,
									documentSelected.documentNumber,
									recaptcha as string,
								),
								typeRegister: "total",
							}),
						);
					}
					break;
				case errorMessages.UserWithRepeatedEmail:
					setErrorMail(message);
					break;
				case errorMessages.CompanyNumberIsRepeated:
					setErrorCompany(message);
					break;
				case errorMessages.DocumentNumberIsRepeated:
					setErrorDocument(message);
					break;
				case errorMessages.CbuFromBankAccountsMustBeDifferent:
					setErrorCbu(message);
					break;
				default:
			}

			if (isTypeAboutBankAccounts(errorResponse.Type)) {
				setBankAccountError({
					input: errorResponse.Type.toLowerCase().includes("cuit")
						? "cuit"
						: "cbu",
					order: errorResponse.ReferenceValue,
					message: message,
				});
			}

			setError(message);
			errorNotification("Hubo un error procesando su solicitud");
		}

		setLoading(false);
	};

	const register = async (recaptcha: string | null) => {
		setLoading(true);
		setError("");
		setErrorMail(undefined);
		setErrorCompany(undefined);
		setErrorDocument(undefined);
		setErrorCbu(undefined);
		setBankAccountError({ input: null, order: null, message: null });

		const payManually = userData.payManually;
		if (!payManually) {
			setRecaptcha(recaptcha);
			setUserRegistrationModalIsOpen(true);
		} else {
			createAccount(recaptcha);
		}
	};

	const onFinish = (user: TransportCompanyForm) => {
		reCaptchaRef.current?.execute();
		setNewUser(TransportCompany.mapToTransportCompany(user, termsId));
	};

	const onReCaptchaChange = (token: string | null) => {
		register(token);

		reCaptchaRef.current?.reset();
	};

	const handlePreventCopyAndPaste = (e: any) => e.preventDefault();

	const handleValidateEmailWithConfirmedEmail = () => {
		const email = form.getFieldValue("email");
		const emailConfirm = form.getFieldValue("confirmEmail");
		setIsEmailMatchError(false);
		if (
			email &&
			emailConfirm &&
			email.toLowerCase() !== emailConfirm.toLowerCase()
		) {
			setIsEmailMatchError(true);
		}
	};

	const canSubmit = (user: any) => {
		const payManually = userData.payManually;
		const hasAccounts =
			(StringUtils.isNullOrWhiteSpace(user.secondaryAccountCbu) &&
				StringUtils.isNullOrWhiteSpace(user.secondaryAccountCuit)) ||
			(!StringUtils.isNullOrWhiteSpace(user.secondaryAccountCbu) &&
				!StringUtils.isNullOrWhiteSpace(user.secondaryAccountCuit));
		const accountIsAdded = payManually ? true : hasAccounts;
		const hasMainAccountCbu = payManually ? true : user.mainAccountCbu;
		const hasMainAccountCuit = payManually ? true : user.mainAccountCuit;

		return (
			!isEmailMatchError &&
			user &&
			user.businessName &&
			user.companyNumber &&
			user.countryCode &&
			user.email &&
			user.confirmEmail &&
			hasMainAccountCbu &&
			hasMainAccountCuit &&
			accountIsAdded &&
			user.number
		);
	};

	useEffect(() => {
		setError("");
		const getLastTermIdFromApi = async () => {
			const termId = await getLastTermId();
			setTermsId(termId);
		};

		getLastTermIdFromApi();
	}, [setError]);

	return (
		<>
			<Form
				name="basic"
				onFinish={onFinish}
				autoComplete="off"
				requiredMark
				className="formContainer"
				form={form}
			>
				<div className="whiteContainer">
					<div className="divTitle">
						<h2
							style={{
								color: "#054368",
								textAlign: "center",
								margin: "0 auto",
							}}
						>
							Registrarse
						</h2>
					</div>

					<div className="rowD">
						<div className="divWidth">
							<Form.Item
								name={["businessName"]}
								label="Razón Social"
								rules={[
									{ required: true, message: "Razón Social es requerido" },
								]}
								initialValue={
									userData?.businessName ? userData?.businessName : ""
								}
							>
								<Input
									disabled={userData?.businessName ? true : false}
									maxLength={300}
									style={{ minWidth: 100 }}
								/>
							</Form.Item>
						</div>
						<div className="divWidth">
							<Form.Item
								label="Número de Empresa"
								name={["companyNumber"]}
								initialValue={userData?.companyNumber?.toString() || ""}
								rules={[
									{ required: true, message: "Número de Empresa es requerido" },
									{
										max: 10,
										message: "El Número de Empresa no debe tener más 9 dígitos",
									},
									{
										pattern: /^(?:\d*)$/,
										message: "El número de empresa es inválido",
									},
								]}
							>
								<Input
									disabled={userData?.companyNumber ? true : false}
									maxLength={9}
									style={{ minWidth: 100 }}
								/>
							</Form.Item>
							{errorCompany && <p className="errorMessage">{errorCompany}</p>}
						</div>
					</div>
					<div className="rowD">
						<div className="divWidth">
							<Form.Item
								label="Código de País"
								name={["countryCode"]}
								initialValue={"54"}
								rules={[
									{ required: true, message: "Código de País es requerido" },
									{
										max: 3,
										message:
											"Código de País no puede contener más de 3 números",
									},
								]}
							>
								<PhoneInput
									inputStyle={{
										height: 32,
										minWidth: 100,
										fontSize: 15,
										width: "100%",
									}}
									placeholder="Cód"
									country="ar"
									preferredCountries={["ar"]}
								/>
							</Form.Item>
						</div>
						<div className="divWidth">
							<Form.Item
								label="Número de Teléfono"
								name={["number"]}
								rules={[
									{
										required: true,
										message: "Número de Teléfono es requerido",
									},
									{
										max: 10,
										message:
											"El número de teléfono no debe tener más 10 dígitos",
									},
									{
										min: 10,
										message:
											"El número de teléfono debe tener al menos 10 dígitos",
									},
									{
										pattern: /^(?:\d*)$/,
										message: "Solo se permite ingresar caracteres numéricos",
									},
								]}
							>
								<Input maxLength={10} style={{ minWidth: 100 }} />
							</Form.Item>
						</div>
					</div>
					<div className="rowD">
						<div className="divWidth">
							<Form.Item
								name={["email"]}
								label="Correo Electrónico"
								rules={[
									{ required: true, message: "Email es requerido" },
									{
										type: "email",
										message:
											"El correo electrónico debe poseer una estructura válida",
									},
								]}
								{...(isEmailMatchError && {
									validateStatus: "error",
									help: "Los correos electrónicos ingresados no coinciden.",
								})}
							>
								<Input
									style={{ minWidth: 100 }}
									onPaste={handlePreventCopyAndPaste}
									onCut={handlePreventCopyAndPaste}
									onCopy={handlePreventCopyAndPaste}
									onDropCapture={handlePreventCopyAndPaste}
									onBlur={handleValidateEmailWithConfirmedEmail}
									onChange={(e) =>
										form.setFieldsValue({ email: e.target.value })
									}
								/>
							</Form.Item>
							{errorMail && <p className="errorMessage">{errorMail}</p>}
						</div>
						<div className="divWidth">
							<Form.Item
								name={["confirmEmail"]}
								label="Confirmación de E-mail"
								rules={[
									{ required: true, message: "Email es requerido" },
									{
										type: "email",
										message:
											"El correo electrónico debe poseer una estructura válida",
									},
								]}
								{...(isEmailMatchError && {
									validateStatus: "error",
									help: "Los correos electrónicos ingresados no coinciden.",
								})}
							>
								<Input
									style={{ minWidth: 100 }}
									onPaste={handlePreventCopyAndPaste}
									onCut={handlePreventCopyAndPaste}
									onCopy={handlePreventCopyAndPaste}
									onDropCapture={handlePreventCopyAndPaste}
									onBlur={handleValidateEmailWithConfirmedEmail}
									onChange={(e) =>
										form.setFieldsValue({ confirmEmail: e.target.value })
									}
								/>
							</Form.Item>
						</div>
					</div>
				</div>

				{!userData.payManually && (
					<>
						<div className="whiteContainer">
							<Row className="rowTitle" justify="start">
								<Typography.Text strong>Cuenta principal</Typography.Text>
							</Row>
							<div className="rowD">
								<div className="divWidth">
									<Form.Item
										{...(bankAccountError.input === "cuit" &&
											bankAccountError.order === "1" && {
												validateStatus: "error",
												help: bankAccountError.message,
											})}
										name={["mainAccountCuit"]}
										label="CUIT"
										rules={[
											{
												required: true,
												type: "string",
												message: "CUIT es requerido",
											},
											{
												pattern: /^(?:\d*)$/,
												message: "El CUIT debe ser numérico",
											},
											{
												pattern: /^[\d]{11}$/,
												message: "El CUIT debe contener 11 dígitos",
											},
										]}
									>
										<Input style={{ minWidth: 100 }} maxLength={11} />
									</Form.Item>
									{errorDocument && (
										<p className="errorMessage">{errorDocument}</p>
									)}
								</div>
								<div className="divWidth">
									<Form.Item
										{...(bankAccountError.input === "cbu" &&
											bankAccountError.order === "1" && {
												validateStatus: "error",
												help: bankAccountError.message,
											})}
										name={["mainAccountCbu"]}
										label="CBU"
										rules={[
											{
												required: true,
												type: "string",
												message: "CBU es requerido",
											},
											{
												pattern: /^(?:\d*)$/,
												message: "El CBU debe ser numérico",
											},
											{
												pattern: /^[\d]{22}$/,
												message: "El CBU debe contener 22 dígitos",
											},
										]}
									>
										<Input style={{ width: "100%" }} maxLength={22} />
									</Form.Item>
								</div>
							</div>
						</div>

						<div className="whiteContainer">
							<Row className="rowTitle" justify="start">
								<Typography.Text strong>Cuenta secundaria</Typography.Text>
							</Row>
							<div className="rowD">
								<div className="divWidth">
									<Form.Item
										{...(bankAccountError.input === "cuit" &&
											bankAccountError.order === "2" && {
												validateStatus: "error",
												help: bankAccountError.message,
											})}
										name={["secondaryAccountCuit"]}
										label="CUIT"
										rules={[
											{ type: "string" },
											{
												pattern: /^(?:\d*)$/,
												message: "El CUIT debe ser numérico",
											},
											{
												pattern: /^[\d]{11}$/,
												message: "El CUIT debe contener 11 dígitos",
											},
										]}
									>
										<Input style={{ minWidth: 100 }} maxLength={11} />
									</Form.Item>
								</div>
								<div className="divWidth">
									<Form.Item
										{...(bankAccountError.input === "cbu" &&
											bankAccountError.order === "2" && {
												validateStatus: "error",
												help: bankAccountError.message,
											})}
										name={["secondaryAccountCbu"]}
										label="CBU"
										rules={[
											{ type: "string" },
											{
												pattern: /^(?:\d*)$/,
												message: "El CBU debe ser numérico",
											},
											{
												pattern: /^[\d]{22}$/,
												message: "El CBU debe contener 22 dígitos",
											},
										]}
									>
										<Input style={{ minWidth: 100 }} maxLength={22} />
									</Form.Item>
									{errorCbu && <p className="errorMessage">{errorCbu}</p>}
								</div>
							</div>
						</div>
					</>
				)}

				<Row justify="center">
					<Form.Item
						style={{ width: 380, textAlign: "center", marginTop: 20 }}
						name={["agreement"]}
						valuePropName="checked"
					>
						<>Al registrarse acepta los términos y condiciones</>
					</Form.Item>
				</Row>
				<Row justify="center">
					<Form.Item shouldUpdate>
						{({ getFieldsValue }) => {
							const isFormValid = canSubmit(getFieldsValue());
							return (
								<Button
									type="primary"
									htmlType="submit"
									disabled={!isFormValid}
									loading={loading}
								>
									Registrarse
								</Button>
							);
						}}
					</Form.Item>
					<Form.Item>
						<ReCAPTCHA
							ref={reCaptchaRef}
							size="invisible"
							sitekey="6Lef85QiAAAAAJ8M6TIr3tBL60jMaMh8JLNaCvZ_"
							onChange={(token) => onReCaptchaChange(token)}
						/>
					</Form.Item>
				</Row>
				<Row justify="center">
					<Form.Item>
						<Button
							ghost
							className="btn-ghost"
							onClick={() => {
								setError("");
								goBack();
							}}
						>
							Volver al paso anterior
						</Button>
					</Form.Item>
				</Row>
			</Form>
			<UserRegistrationWarningModal
				isOpen={userRegistrationModalIsOpen}
				handleClickOk={async () => {
					setUserRegistrationModalIsOpen(false);
					await createAccount(recaptcha);
				}}
			/>
		</>
	);
};

export default TransportCompaniesForm;
