import { Modal, Input, Typography, Form, Button, message } from "antd"
import { COLORS } from "../../../constants/colors"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { closeWarningAccountModal, updateData } from "../../../store/reducers/warningAccountModalSlice"
import { totalRegisterTransportCompany } from "../../../services/transportCompany.services"
import { createRef, useState } from "react"
import { createBankAccount, updateTransportCompany } from "../../../services/api.client"
import ReCAPTCHA from "react-google-recaptcha";

const { Paragraph, Text } = Typography
const { TextArea } = Input

interface WarningAccountForm {
  description: string
}

const WarningAccountModal = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { isOpen, data, typeRegister } = useSelector((state: RootState) => state.warningAccountModal)
  const reCaptchaRef = createRef<ReCAPTCHA>();
  const dispatch = useDispatch()

  const onFinish = async ({ description }: WarningAccountForm) => {
    setLoading(true)
    try {
      if (typeRegister === 'total') {
        const token = await reCaptchaRef.current?.executeAsync()
        await totalRegisterTransportCompany({
          ...data,
          bankAccounts: data.bankAccounts.map((bankAccount: any) => ({ ...bankAccount, relationshipConfirmation: { description } })),
          recaptchaToken: token
        })
      }

      if (typeRegister === 'partial') {
        const token = await reCaptchaRef.current?.executeAsync()
        await updateTransportCompany(data.id, {
          ...data.values,
          bankAccounts: data.values.bankAccounts.map((bankAccount: any) => ({ ...bankAccount, relationshipConfirmation: { description } })),
          recaptchaToken: token
        })
      }
      
      if (typeRegister === 'addBankAccount') {
        await createBankAccount({
          ...data,
          relationshipConfirmation: { description }
        })
      }

      dispatch(updateData(true))
      dispatch(closeWarningAccountModal())
    } catch {
      message.error('Ha ocurrido un error, por favor intente nuevamente')
    } finally {
      setLoading(false)
    }

  }

  return (
    <Modal
      open={isOpen}
      closable={false}
      maskClosable={true}
      footer={false}
    >
      <Form
        name="warningAccountForm"
        onFinish={onFinish}
        layout="vertical"
      >
        <Paragraph>
          <Text strong style={{ color: COLORS.RED }}>¡Advertencia!</Text> La titularidad de la cuenta del CBU/CVU que esta declarando, en la cual se efectuaran los débitos, no se corresponde con la titularidad de la empresa de transporte. Por favor, confirme esta acción bajo su entera responsabilidad.
        </Paragraph>

        <Form.Item
          name="description"
          label="Declare vinculo con el transportista:"
          rules={[
            { required: true, message: "Por favor, ingrese una declaración" },
            { max: 100, message: "La declaración no debe tener más de 100 dígitos" },
            { min: 5, message: "La declaración no debe tener menos de 5 dígitos" },
          ]}
        >
          <TextArea rows={4} maxLength={100} minLength={5}/>
        </Form.Item>

        <Form.Item>
          <ReCAPTCHA
            ref={reCaptchaRef}
            size="invisible"
            sitekey="6Lef85QiAAAAAJ8M6TIr3tBL60jMaMh8JLNaCvZ_"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
            Confirmar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default WarningAccountModal