import { Typography } from 'antd';
import '../../tripCertificates.scss'

const { Text } = Typography;

interface DataSection {
  name: string
  content: string
  amount: string
  id: number
}
interface CentralSectionProps {
  list: DataSection[]
}

const CentralSection = ({ list }: CentralSectionProps) => {
  return (
    <>
      <Text strong className='trip-certificate__paragraph trip-certificate__paragraph--align-center'>
        C.A - AP 1.1- Cláusula Adicional de Accidentes a Pasajeros y/o Personas Transportadas.
      </Text>
      <div className='trip-certificate__central-section'>
        {list.map((item) => {
          return (
            <div className='trip-certificate__section-space-between' key={`${item.id}`}>
              <Text className='trip-certificate__paragraph'>{item.name}</Text>
              <Text className='trip-certificate__paragraph trip-certificate__paragraph--min-width'>{item.content} <strong>{item.amount}</strong></Text>
            </div>
          )
        })}
      </div>
    </>
  )
}
export default CentralSection