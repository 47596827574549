import { useContext } from "react"
import { ClearOutlined, SearchOutlined } from "@ant-design/icons"
import { Button, Form, Input, Select, Space } from "antd"
import useUserFilters from "../../hooks/useUserFilters"
import UsersContext from "../../context/UsersContextProvider"
import UserFiltersSkeleton from "../userFiltersSkeleton/UserFiltersSkeleton"

const UserFilters = () => {
  const { handleChangeUpdateFilters } = useContext(UsersContext)
  const { transports, roles, loading } = useUserFilters()

  const onFinish = (values: any) => {
    handleChangeUpdateFilters(values)
  }

  if (loading) {
    return <UserFiltersSkeleton />
  }

  return (
    <div className="layer">
      <Form
        name="userFiltersForm"
        layout="vertical"
        onFinish={onFinish}
      >
        <div>
          <Space wrap>
            <Form.Item
              name="role"
              label="Rol"
            >
              <Select
                placeholder="Seleccione..."
                options={roles.map(role => ({ value: role.id, label: role.name }))}
                disabled={loading}
                loading={loading}
                style={{ width: '215px' }}
              />
            </Form.Item>

            <Form.Item 
              name="numberCompany"
              label="Número de empresa"
            >
              <Input disabled={loading} />
            </Form.Item>

            <Form.Item
              name="transporter"
              label="Transportista"
            >
              <Select
                placeholder="Seleccione..."
                options={transports.map(transport => ({ value: transport.id, label: transport.name }))}
                disabled={loading}
                loading={loading}
                style={{ width: '215px' }}
              />
            </Form.Item>
          </Space>
        </div>

        <Space wrap>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
              loading={loading}
            >
              Buscar
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="default"
              htmlType="reset"
              icon={<ClearOutlined />}
              loading={loading}
            >
              Limpiar
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  )
}

export default UserFilters