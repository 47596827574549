import { Switch, Table } from "antd"
import useBankAccountStatusTable from "../../hooks/useBankAccountStatusTable"

const BankAccountStatusTable = () => {
  const { loading, handleChangeBankAccountStatus, accounts } = useBankAccountStatusTable()

  return (
    <Table
      className="layer"
      loading={loading}
      columns={[
        { title: 'N° de cuenta', dataIndex: 'cbu' },
        {
          title: 'Suscripción Debin',
          dataIndex: 'debinSubscriptionState',
          render: (debinSubscriptionState) => {
            return debinSubscriptionState.shortDescription
          }
        },
        {
          title: 'Estado',
          dataIndex: 'isActive',
          render: (isActive: boolean) => {
            return isActive ? 'Activa' : 'Inactiva'
          }
        },
        {
          title: 'Activar o Desactivar',
          dataIndex: 'isActive',
          render: (isActive: boolean, record) => {
            return (
              <Switch
                checked={isActive}
                onChange={() => handleChangeBankAccountStatus(record.id, !isActive)}
              />
            )
          }
        }
      ]} 
      dataSource={accounts}
      pagination={false}
    />
  )
}

export default BankAccountStatusTable