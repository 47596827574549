import { COLORS } from "./colors";

export enum CollectionStateIds {
  Pending = 1,
  PaidOut = 2,
  Rejected = 3,
  UnknownDebin = 4,
  Defaulter = 5,
  DefaulterUserNotRegistered = 6,
  PendingDebin = 7,
  DefaulterInactiveDebinSubscription = 8,
  CancelledTrip = 9,
  AnnulledTrip = 10,
  OtherPayments = 11,
  Protection = 12
}

export const COLLECTION_STATE_COLORS_BY_ID: Record<CollectionStateIds, string> = {
  [CollectionStateIds.Pending]: COLORS.BLACK,
  [CollectionStateIds.PaidOut]: COLORS.GREEN,
  [CollectionStateIds.Rejected]: COLORS.ORANGE,
  [CollectionStateIds.UnknownDebin]: COLORS.RED,
  [CollectionStateIds.Defaulter]: COLORS.RED,
  [CollectionStateIds.DefaulterUserNotRegistered]: COLORS.RED,
  [CollectionStateIds.PendingDebin]: COLORS.LIGHT_BLUE,
  [CollectionStateIds.DefaulterInactiveDebinSubscription]: COLORS.RED,
  [CollectionStateIds.CancelledTrip]: COLORS.BLACK,
  [CollectionStateIds.AnnulledTrip]: COLORS.GREY,
  [CollectionStateIds.OtherPayments]: COLORS.BLACK,
  [CollectionStateIds.Protection]: COLORS.GREEN
}