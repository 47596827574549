import { useParams } from "react-router-dom";

import { Layout, Table, Typography } from "antd";
import { Content } from "antd/es/layout/layout";

import { getDebinsStateChanges } from "../../../services/trips.services";

import useData from "../../../hooks/useData";
import SkeletonTable from "../../shared/skeletonTable/SkeletonTable";

import GoBackTitle from "../../shared/goBackTitle/GoBackTitle";

import { stateColor } from "../../../helper";

import './debinStateChanges.scss'

const { Text } = Typography

const DebinDetail = () => {
  const { id, idDebin } = useParams();
  const [data, loading] = useData({
    service: () => getDebinsStateChanges({ tripId: id ?? '', debinId: idDebin ? parseInt(idDebin) : 0 }) 
  })


  return (
    <Layout>
      <Content className="content-debin-state-changes">
        <GoBackTitle title="Detalle de cobranza" goTo={`/payment/${id}`}/>
        {
          loading
            ? <SkeletonTable numberOfColumns={3} numberOfRows={3}/>
            : (
                <Table
                  className="layer"
                  columns={[
                    {
                      title: 'Fecha',
                      dataIndex: 'ocurredAt',
                      key: 'ocurredAt',
                      render: (ocurredAt) => <Text>{ocurredAt}</Text>
                    },
                    {
                      title: 'Estado Origen',
                      dataIndex: 'from',
                      key: 'from.id',
                      render: (from) => from ? <Text style={{ color: stateColor(from.id) }}>{from.description}</Text> : '-'
                    },
                    {
                      title: 'Estado Destino',
                      dataIndex: 'to',
                      key: 'to.id',
                      render: (to) => to ? <Text style={{ color: stateColor(to.id) }}>{to.description}</Text> : '-'
                    }
                  ]}
                  dataSource={data?.map((d, i) => ({ ...d, key: i })) ?? []}
                  pagination={false}
                />
            )
        }
      </Content>
    </Layout>
  );
}

export default DebinDetail;
