import React, { useState } from 'react'
import { TripAnnulment } from '../../../../models/Trips/TripAnnulment'
import { cancelTrip } from '../../../../services/trips.services'
import { message } from 'antd'
import { errorMessages } from '../../../../models/Errors/Errors'
import { ErrorResponse } from '../../../../models/Errors/ErrorResponse'

interface TripAnnulmentContextState {
  annulmentedTrips: TripAnnulment[]
  loading: boolean
  handleSubmitCancelTrip: (file: File) => Promise<void>
}

const initialTripAnnulmentContextState: TripAnnulmentContextState = {
  annulmentedTrips: [],
  loading: false,
  handleSubmitCancelTrip: async (file: File) => {},
}

const TripAnnulmentContext = React.createContext<TripAnnulmentContextState>(initialTripAnnulmentContextState)

interface TripAnnulmentContextProviderProps {
  children: React.ReactNode | React.ReactNode[]
}


export const TripAnnulmentContextProvider = ({ children }: TripAnnulmentContextProviderProps) => {
  const [annulmentedTrips, setAnnulmentedTrips] = useState<TripAnnulment[]>(initialTripAnnulmentContextState.annulmentedTrips)
  const [loading, setLoading] = useState<boolean>(initialTripAnnulmentContextState.loading)

  const handleSubmitCancelTrip = async (file: File) => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('file', file)
      const data = await cancelTrip(formData)
      setAnnulmentedTrips(data)
      message.success('el archivo se ha importado correctamente')
    }catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data
      const erroMessage = errorResponse ? errorMessages[errorResponse.Type] : errorMessages.tryAgain
      message.error(erroMessage)
      setAnnulmentedTrips(initialTripAnnulmentContextState.annulmentedTrips)
    } finally {
      setLoading(false)
    }
  }

  return (
    <TripAnnulmentContext.Provider 
      value={{
        annulmentedTrips: annulmentedTrips,
        loading,
        handleSubmitCancelTrip,
      }}
    >
      {children}
    </TripAnnulmentContext.Provider>
  )
}

export default TripAnnulmentContext
