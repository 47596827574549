import React, { useState } from 'react'
import { annulEndorsements } from '../../../../services/endorsement.services'
import { message } from 'antd'
import { errorMessages } from '../../../../models/Errors/Errors'
import { ErrorResponse } from '../../../../models/Errors/ErrorResponse'
import { Endorsement } from "../../../../models/Endorsement/Endorsement"


interface EndorsementAnnulmentContextState {
  annulmentedEndorsements: Endorsement[]
  loading: boolean
  handleSubmitAnnulEndorsements: (file: File) => Promise<void>
}

const initialEndorsementAnnulmentContextState: EndorsementAnnulmentContextState = {
  annulmentedEndorsements: [],
  loading: false,
  handleSubmitAnnulEndorsements: async (file: File) => {},
}

const EndorsementAnnulmentContext = React.createContext<EndorsementAnnulmentContextState>(initialEndorsementAnnulmentContextState)

interface EndorsementAnnulmentContextProviderProps {
  children: React.ReactNode | React.ReactNode[]
}


export const EndorsementAnnulmentContextProvider = ({ children }: EndorsementAnnulmentContextProviderProps) => {
  const [annulmentedEndorsements, setAnnulmentedEndorsements] = useState<Endorsement[]>(initialEndorsementAnnulmentContextState.annulmentedEndorsements)
  const [loading, setLoading] = useState<boolean>(initialEndorsementAnnulmentContextState.loading)

  const handleSubmitAnnulEndorsements = async (file: File) => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('file', file)
      const data = await annulEndorsements(formData)
      setAnnulmentedEndorsements(data)
      message.success('el archivo se ha importado correctamente')
    }catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data
      const erroMessage = errorResponse ? errorMessages[errorResponse.Type] : errorMessages.tryAgain
      message.error(erroMessage)
      setAnnulmentedEndorsements(initialEndorsementAnnulmentContextState.annulmentedEndorsements)
    } finally {
      setLoading(false)
    }
  }

  return (
    <EndorsementAnnulmentContext.Provider 
      value={{
        annulmentedEndorsements,
        loading,
        handleSubmitAnnulEndorsements,
      }}
    >
      {children}
    </EndorsementAnnulmentContext.Provider>
  )
}

export default EndorsementAnnulmentContext
