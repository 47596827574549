import { useContext, useState } from "react";
import { Layout, Menu, Modal } from "antd";
import {
  BankOutlined,
  CreditCardOutlined,
  DollarOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  LogoutOutlined,
  PieChartOutlined,
  StopOutlined,
  TableOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BASSA_ADMIN,
  BASSA_OPERATOR,
  TRANSPORT_COMPANY,
  INSURANCE_COMPANY,
  ALL_ROLES,
  Role,
} from "../../../constants/roles";
import {
  bankAccounts,
  endorsementAnnulment,
  rates,
  trips,
  payments,
  tripAnnulment,
  managePayments,
  otherPayments,
  users,
  bankAccountStatus,
  protección,
  checkingAccount,
  managePreliquidations,
  endorsementRejection,
  endorsementPayment,
} from "../../../constants/routes";

const { Sider } = Layout;

interface ItemSideBar {
  key: number;
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  roles: Role[];
  permissions?: boolean[];
}

function SideBar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { signOut, role, paymentsManually } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(true);
  const [modal, contextHolder] = Modal.useModal();
  const modalConfig = {
    title: "Cerrar Sesión",
    content: "Esta seguro que desea cerrar la sesión?",
    okText: "Si",
    cancelText: "No",
    onOk: () => signOut(),
  };

  const getItems = () => {
    const isPaymentsManually = paymentsManually ? paymentsManually : false;

    const items: ItemSideBar[] = [
      {
        key: 1,
        icon: <TableOutlined />,
        label: "Listado de Viajes",
        onClick: () => navigate("/"),
        roles: ALL_ROLES,
      },
      {
        key: 2,
        icon: <BankOutlined />,
        label: "Cuentas Bancarias",
        onClick: () => navigate(bankAccounts.path),
        roles: [TRANSPORT_COMPANY, INSURANCE_COMPANY],
        permissions: [!isPaymentsManually],
      },
      {
        key: 3,
        icon: <UserOutlined />,
        label: "Usuarios",
        onClick: () => navigate(users.path),
        roles: [BASSA_ADMIN],
      },
      {
        key: 4,
        icon: <DollarOutlined />,
        label: "Modificar tarifas",
        onClick: () => navigate(rates.path),
        roles: [BASSA_ADMIN],
      },
      {
        key: 5,
        icon: <BankOutlined />,
        label: "Crear pago",
        onClick: () => navigate(payments.path),
        roles: [BASSA_ADMIN, BASSA_OPERATOR],
      },
      {
        key: 6,
        icon: <StopOutlined />,
        label: "Anulación de Viajes",
        onClick: () => navigate(tripAnnulment.path),
        roles: [BASSA_ADMIN, BASSA_OPERATOR],
      },
      {
        key: 7,
        icon: <StopOutlined />,
        label: 'Anulación de endorsos',
        onClick: () => navigate(endorsementAnnulment.path),
        roles: [BASSA_ADMIN]
      },
      {
        key: 8,
        icon: <PieChartOutlined />,
        label: "Otros Pagos",
        onClick: () => navigate(otherPayments.path),
        roles: [BASSA_ADMIN, BASSA_OPERATOR],
      },
      {
        key: 9,
        icon: <CreditCardOutlined />,
        label: "Cuentas bancarias",
        onClick: () => navigate(bankAccountStatus.path),
        roles: [BASSA_ADMIN],
      },
      {
        key: 10,
        icon: <CreditCardOutlined />,
        label: "Protección",
        onClick: () => navigate(protección.path),
        roles: [BASSA_ADMIN],
      },
      {
        key: 11,
        icon: <FileDoneOutlined />,
        label: "Gestionar Pagos",
        onClick: () => navigate(managePayments.path),
        roles: [BASSA_ADMIN, BASSA_OPERATOR],
      },
      {
        key: 12,
        icon: <FileTextOutlined />,
        label: "Gestionar Preliquidaciones",
        onClick: () => navigate(managePreliquidations.path),
        roles: [BASSA_ADMIN, BASSA_OPERATOR],
      },
      {
        key: 13,
        icon: <FileDoneOutlined />,
        label: 'Cuenta corriente',
        onClick: () => navigate(checkingAccount.path),
        roles: [BASSA_ADMIN, TRANSPORT_COMPANY]
      },
      {
        key: 14,
        icon: <FileExcelOutlined />,
        label: 'Rechazo de endosos',
        onClick: () => navigate(endorsementRejection.path),
        roles: [BASSA_ADMIN]
      },
      {
        key: 15,
        icon: <FileDoneOutlined />,
        label: 'Pago de endosos',
        onClick: () => navigate(endorsementPayment.path),
        roles: [BASSA_ADMIN]
      },
      {
        key: 16,
        icon: <LogoutOutlined />,
        label: "Cerrar Sesión",
        onClick: () => modal.confirm(modalConfig),
        roles: ALL_ROLES,
      },
    ];

    return items
      .filter((item) => {
        const roleCheck = item.roles.includes(role);
        const permissionCheck = item.permissions ? item.permissions.some((permission) => permission) : true;
        return roleCheck && permissionCheck;
      })
      .sort((a, b) => a.key - b.key);
  };

  const getCurrentKey = () => (pathname === "1" ? trips.path : pathname === "2" ? bankAccounts.path : "0");

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="40"
      trigger={null}
      collapsible
      collapsed={collapsed}
      onMouseEnter={() => setCollapsed(false)}
      onMouseLeave={() => setCollapsed(true)}
      style={{ height: "100vh", position: "fixed", zIndex: 100 }}
    >
      <Menu theme="dark" mode="inline" selectedKeys={[getCurrentKey()]} items={getItems()} />
      {contextHolder}
    </Sider>
  );
}

export default SideBar;
