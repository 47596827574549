import { useContext, useEffect, useState } from "react"
import { TransactionReason } from "../../../../models/CheckingAccount/TransactionReason"
import { getTransactionReason } from "../../../../services/checkingAccount.services"
import { getEconomicGroups } from "../../../../services/group.services"
import { deleteDuplicatedBusinessNames, getTransportCompanies } from "../../../../services/transportCompany.services"
import { AuthContext } from "../../../context/AuthContext"
import { BASSA_ADMIN } from "../../../../constants/roles"
import { EconomicGroup } from "../../../../models/EconomicGroup"
import { TransportCompanyResponse } from "../../../../models/TransportCompany/TransportCompany"
import { message } from "antd"
import { errorMessages } from "../../../../models/Errors/Errors"

const useCheckingAccountFilters = () => {
  const { role } = useContext(AuthContext)
  const isAdminUser = role === BASSA_ADMIN
  const [transactionReason, setTransactionReason] = useState<TransactionReason[]>([])
  const [economicGroups, setEconomicGroups] = useState<EconomicGroup[]>([])
  const [transportCompanies, setTransportCompanies] = useState<TransportCompanyResponse[]>([])
  const [loading, setLoading] = useState<boolean>(true)


  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const promises = isAdminUser ? [getTransactionReason(), getEconomicGroups(), getTransportCompanies()] : [getTransactionReason()]
      Promise.all(promises).then((response) => {
        setTransactionReason(response[0] as TransactionReason[])
        if (isAdminUser) {
          setEconomicGroups(response[1] as EconomicGroup[])
          setTransportCompanies(deleteDuplicatedBusinessNames(response[2] as TransportCompanyResponse[]))
        }
      }).catch(() => {
        message.error(errorMessages.tryAgain)
      }).finally(() => {
        setLoading(false)
      })
    }
    void getData()
  }, [])

  return {
    transactionReason,
    economicGroups,
    transportCompanies,
    loading
  }
}

export default useCheckingAccountFilters