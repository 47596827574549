import { Skeleton, Space } from "antd"
import './userFiltersSkeletonStyles.scss'

const UserFiltersSkeleton = () => {
  return (
    <div className="layer">
      <div className="container-skeleton">
        <Space wrap>
          <div className="content-input">
            <Skeleton className="label-skeleton" style={{ width: '50px' }} paragraph={false} active={true} />
            <Skeleton className="input-skeleton" paragraph={false} active={true} />
          </div>
          <div className="content-input">
            <Skeleton className="label-skeleton" style={{ width: '150px' }} paragraph={false} active={true} />
            <Skeleton className="input-skeleton" paragraph={false} active={true} />
          </div>
          <div className="content-input">
            <Skeleton className="label-skeleton" style={{ width: '100px' }} paragraph={false} active={true} />
            <Skeleton className="input-skeleton" paragraph={false} active={true} />
          </div>
        </Space>
      </div>

      <Space wrap>
        <Skeleton className="button-skeleton" paragraph={false} active={true} />
        <Skeleton className="button-skeleton" paragraph={false} active={true} />
      </Space>
    </div>
  )
}

export default UserFiltersSkeleton