import { useState, useContext } from "react"

import { Modal, message } from "antd"
import PaymentRejectionForm from "../paymentRejectionForm/PaymentRejectionForm"
import PreliquidationContext from "../../contexts/ManagePaymentsContextProvider"
import { errorMessages } from "../../../../../models/Errors/Errors"
import { rejectionPayment } from "../../../../../services/payment.services"

const PaymentRejectionModal = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [resetForm, setResetForm] = useState<boolean>(false)
  const {
    isPaymentRejectionModalOpen,
    openRejectedPaymentsModal,
    closeRejectedPaymentsModal,
    selectedPayment,
    handleUpdateTable
  } = useContext(PreliquidationContext)

  const onSubmit = async (values: any) => {
    setLoading(true)
    try {
      await rejectionPayment({
        message: values.reasonRejection,
        paymentId: selectedPayment?.id ?? '0'
      })
      handleUpdateTable({})
      closeRejectedPaymentsModal()
      message.success('Pago rechazado exitosamente')
    } catch (e) {
      message.error(errorMessages.tryAgain)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      open={isPaymentRejectionModalOpen}
      onOk={openRejectedPaymentsModal}
      onCancel={closeRejectedPaymentsModal}
      footer={null}
      afterClose={() => setResetForm(!resetForm)}
    >
      <PaymentRejectionForm
        handleSubmit={onSubmit}
        handleCancel={closeRejectedPaymentsModal}
        isDisabled={loading}
        isLoading={loading}
        reset={resetForm}
      />
    </Modal>
  )
}
export default PaymentRejectionModal