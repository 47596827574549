export class BankAccount {
  constructor(
    public documentNumber: string,
    public cbu: string,
    public order: number
  ) {}
}

export interface IBankAccount {
  id: string
  documentNumber: string
  cbu: string
  order: number
  isActive: boolean
  debinSubscriptionState: DebinSubscriptionState
}

export interface DebinSubscriptionState {
  id: number
  shortDescription: string
}
