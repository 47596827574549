import { useState } from "react";
import { Layout } from "antd";
import { errorNotification } from "../../atoms/notification/Notification";
import TripFilters from "../../atoms/TripFilters/TripFilters";
import Title from "antd/es/typography/Title";
import { getObject, hasValue } from "../../../services/storage.service";
import { PaginatedResponse } from "../../../models/Pagination";
import { TripResponse } from "../../../models/Trips/Trip";
import TripsTable from "../../atoms/TripsTable/TripsTable";
import { getTrips } from "../../../services/trips.services";
import "./trips.scss";

const { Content } = Layout;
const pageSize = 10;

function Trips() {
  const cachedFilters = hasValue('filters') ? getObject('filters') : undefined;
  const [trips, setTrips] = useState<PaginatedResponse<TripResponse>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [exportExcelLoading, setExportExcelLoading] = useState(false);
  const [endorsementState, setEndorsementState] = useState<any>(cachedFilters?.endorsementState);
  const [collectionStateId, setCollectionStateId] = useState<any>(cachedFilters?.collectionState);
  const [economicGroup, setEconomicGroup] = useState<any>(cachedFilters?.economicGroup);
  const [insuranceCompany, setInsuranceCompany] = useState<any>(cachedFilters?.insuranceCompany);
  const [departureFrom, setDepartureFrom] = useState<any>(cachedFilters?.departureFrom);
  const [departureTo, setDepartureTo] = useState<any>(cachedFilters?.departureTo);
  const [tripExternalId, setTripExternalId] = useState<any>(cachedFilters?.tripExternalId);
  const [transportCompany, setTransportCompany] = useState<any>(cachedFilters?.transportCompany);
  const [paidAtFrom, setPaidAtFrom] = useState<any>(cachedFilters?.paidAtFrom);
  const [paidAtTo, setPaidAtTo] = useState<any>(cachedFilters?.paidAtTo);
  const [closedAtFrom, setClosedAtFrom] = useState<any>(cachedFilters?.closedAtFrom);
  const [closedAtTo, setClosedAtTo] = useState<any>(cachedFilters?.closedAtTo);
  const [page, setPage] = useState<any>(cachedFilters?.page ?? 1);
  const [endorsmentNumber, setEndorsmentNumber] = useState<string | undefined>(cachedFilters?.endorsmentNumber);

  const [estimatedClosedAtFrom, setEstimatedClosedAtFrom] = useState<any>(cachedFilters?.estimatedClosedAtFrom);
  const [estimatedClosedAtTo, setEstimatedClosedAtTo] = useState<any>(cachedFilters?.estimatedClosedAtTo);

  const getTripsFromApi = async (page: number, pageSize: number) => {
    setLoading(true);

    const transport = transportCompany ? encodeURIComponent(transportCompany) : ''
    const insurance = insuranceCompany ? encodeURIComponent(insuranceCompany) : ''

    try {
      const tripResponse = await getTrips(
        page > 0 ? page : 1, 
        pageSize,
        tripExternalId,
        departureFrom,
        departureTo,
        paidAtFrom,
        paidAtTo,
        closedAtFrom,
        closedAtTo,
        estimatedClosedAtFrom,
        estimatedClosedAtTo,
        endorsementState,
        collectionStateId,
        transport,
        insurance,
        economicGroup,
        endorsmentNumber
      );


      if (tripResponse.pages !== 0 && page > tripResponse.pages) {
        getTripsFromApi(tripResponse.pages, pageSize);
      }
      
      setPage(tripResponse.page);
      setTrips(tripResponse);
    } catch (error) {
      errorNotification("Error al traer datos de viajes");
    }

    setLoading(false);
  };

  return (
    <Layout>
      <Layout>
        <Content className="travelContent">
          <div>
            <Title level={2}>Viajes</Title>
          </div>

          <TripFilters
            getTripsFromApi={(pageNumber?: number) => getTripsFromApi(pageNumber ?? page, pageSize)}
            setDepartureFrom={setDepartureFrom}
            setDepartureTo={setDepartureTo}
            setInsuranceCompany={setInsuranceCompany}
            setEndorsementState={setEndorsementState}
            setEconomicGroup={setEconomicGroup}
            setTransportCompany={setTransportCompany}
            setPaidAtFrom={setPaidAtFrom}
            setPaidAtTo={setPaidAtTo}
            setCollectionState={setCollectionStateId}
            setTripExternalId={setTripExternalId}
            setExportExcelLoading={setExportExcelLoading}
            setClosedAtTo={setClosedAtTo}
            setClosedAtFrom={setClosedAtFrom}
            setEstimatedClosedAtFrom={setEstimatedClosedAtFrom}
            setEstimatedClosedAtTo={setEstimatedClosedAtTo}
            setLoading={setLoading}
            setEndorsmentNumber={setEndorsmentNumber}
            loading={loading}
            exportExcelLoading={exportExcelLoading}
            endorsementState={endorsementState}
            collectionState={collectionStateId}
            economicGroup={economicGroup}
            paidAtFrom={paidAtFrom}
            paidAtTo={paidAtTo}
            closedAtFrom={closedAtFrom}
            closedAtTo={closedAtTo}
            estimatedClosedAtFrom={estimatedClosedAtFrom}
            estimatedClosedAtTo={estimatedClosedAtTo}
            insuranceCompany={insuranceCompany}
            transportCompany={transportCompany}
            departureFrom={departureFrom}
            departureTo={departureTo}
            tripExternalId={tripExternalId}
            page={page}
            endorsmentNumber={endorsmentNumber}
          />

          <TripsTable 
            getTripsFromApi={getTripsFromApi}
            setLoading={setLoading}
            loading={loading}
            trips={trips}
          />

        </Content>
      </Layout>
    </Layout>
  );
}

export default Trips;
