import { useState, useContext } from "react";

import { Modal, message } from "antd";

import ManagePreliquidationContext from "../../contexts/ManagePreliquidationContextProvider";
import { approvePreliquidation } from "../../../../../services/preliquidation.service";

import { errorMessages } from "../../../../../models/Errors/Errors";
import { ErrorResponse } from "../../../../../models/Errors/ErrorResponse";
import PreliquidationApprovalForm from "../PreliquidationApprovalForm/PreliquidationApprovalForm";

const PreliquidationApprovalModal = () => {
  const {
    isPreliquidationApprovalModalOpen,
    openApprovePreliquidationModal,
    closeApprovePreliquidationModal,
    selectedPreliquidation,
    handleManagePreliquidationsUpdateTable,
  } = useContext(ManagePreliquidationContext);

  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      await approvePreliquidation(selectedPreliquidation?.id ?? "");
      message.success("Preliquidación aprobada");
      handleManagePreliquidationsUpdateTable({});
      closeApprovePreliquidationModal();
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      const type = errorResponse.Type;
      const errorMessage = errorMessages[type] ?? errorMessages.tryAgain;
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={isPreliquidationApprovalModalOpen}
      onOk={openApprovePreliquidationModal}
      onCancel={closeApprovePreliquidationModal}
      footer={null}
    >
      <PreliquidationApprovalForm
        handleOnSubmit={handleSubmit}
        handleClickCancel={closeApprovePreliquidationModal}
        isLoading={loading}
        isDisabled={loading}
      />
    </Modal>
  );
};

export default PreliquidationApprovalModal;
