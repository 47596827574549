import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import { login } from '../../../constants/routes';

const SuccessEmailConfirmation = () => {
  return (
    <div className="confirm">
      <Result
        status="success"
        title="Cuenta Activada"
        subTitle="Su cuenta fue activada exitosamente."
        extra={[
          <Link to={login.path}>
            <Button type="primary" key="console">
              Ir al inicio
            </Button>
          </Link>
        ]}
      />
    </div>
  );
}

export default SuccessEmailConfirmation;