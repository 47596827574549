import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { trips } from "../../../constants/routes";

type Props = {
  component: JSX.Element;
};

const PublicRoute = ({ component }: Props) => {
  const { isAuth } = useContext(AuthContext);

  return isAuth ? <Navigate to={trips.path} replace /> : component;
};

export default PublicRoute;