import { Layout } from "antd";
import PreliquidationContext, { PreliquidationContextProvider } from "./contexts/PreliquidationContextProvider";
import "./preliquidations.scss";
import PreliquidationSteps from "./components/PreliquidationSteps/PreliquidationSteps";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";

const Preliquidations = () => {
  const { id } = useParams();
  const { setPreliquidationId } = useContext(PreliquidationContext);

  useEffect(() => {
    if (id) setPreliquidationId(id);
  }, [id]);

  return (
    <PreliquidationContextProvider>
      <Layout>
        <PreliquidationSteps />
      </Layout>
    </PreliquidationContextProvider>
  );
};

export default Preliquidations;
