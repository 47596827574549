import { Button, Col, Row, Table, Tooltip, Typography, message } from "antd";
import React, { Key, useContext, useState } from "react";
import PreliquidationContext from "../../contexts/PreliquidationContextProvider";
import moment from "moment";
import { toARSCurrency } from "../../../../../utils/number.utils";
import { CloseCircleOutlined } from "@ant-design/icons";
import { getCollectionStateColors } from "../../../../../utils/collectionStateColors";
import { dateFormatWithoutTime } from "../../../../../utils/dateFormatter.util";
import { PreliquidationTripItem } from "../../../../../models/Preliquidation/Preliquidation";
import { removeMultipleTrips, removeTrip } from "../../../../../services/preliquidation.service";
import { errorMessages } from "../../../../../models/Errors/Errors";
import useGetStep2Data from "../../hooks/useGetStep2Data";
import SkeletonTable from "../../../../shared/skeletonTable/SkeletonTable";
import "./GeneratePreliquidationTripsTable.scss";
import { ErrorResponse } from "../../../../../models/Errors/ErrorResponse";

const GeneratePreliquidationTripsTable: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [, setDeleting] = useState(false);
  const {
    preliquidationId,
    preliquidationTrips,
    preliquidationDiscounts,
    loadingPreliquidationTrips: loading,
    initialLoading,
    tripModified,
    setTripModified,
  } = useContext(PreliquidationContext);

  const {
    handlePreliquidationTripsUpdateTable,
    handlePreliquidationDetailsUpdateTable,
    preliquidationTripsPagination,
  } = useGetStep2Data();

  const { Text, Title } = Typography;

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys,
    getCheckboxProps: (record: PreliquidationTripItem) => ({
      name: record.externalId,
    }),
  };

  const handleDeletePreliquidationTrip = async (preliquidationId: string, tripId: string) => {
    try {
      setTripModified(true);
      const removeResult = await removeTrip(preliquidationId, tripId);
      if (removeResult) {
        message.success("Viaje eliminado correctamente");
        handlePreliquidationTripsUpdateTable({
          page: preliquidationTripsPagination.page,
          pageSize: preliquidationTripsPagination.pageSize,
        });
        handlePreliquidationDetailsUpdateTable();
      } else {
        message.error("No se pudo eliminar el viaje");
      }
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      const type = errorResponse.Type;
      const errorMessage = errorMessages[type] ?? errorMessages.tryAgain;
      message.error(errorMessage);
    } finally {
      setTripModified(false);
    }
  };

  const deleteSelected = async () => {
    setDeleting(true);

    try {
      const removeResult = await removeMultipleTrips(preliquidationId, selectedRowKeys);
      if (removeResult) {
        handlePreliquidationTripsUpdateTable({
          page: preliquidationTripsPagination.page,
          pageSize: preliquidationTripsPagination.pageSize,
        });
        handlePreliquidationDetailsUpdateTable();
      }
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      const type = errorResponse.Type;
      const errorMessage = errorMessages[type] ?? errorMessages.tryAgain;
      message.error(errorMessage);
    } finally {
      setTripModified(false);
    }

    setTimeout(() => {
      setSelectedRowKeys([]);
      message.success("Viajes eliminados correctamente");
      setDeleting(false);
    }, 1000);
  };

  const hasRowsSelected = selectedRowKeys.length > 0;

  if (initialLoading) {
    return (
      <div className="generate-preliquidations-table layer">
        <SkeletonTable numberOfColumns={9} numberOfRows={10} />
      </div>
    );
  }

  return (
    <div>
      <Title level={4}>Los viajes incluidos en la presente preliquidación son:</Title>
      <Row justify={"end"}>
        <Col>
          <Button type="primary" danger onClick={deleteSelected} disabled={!hasRowsSelected} loading={loading}>
            Eliminar viajes
          </Button>
        </Col>
      </Row>

      <div className="layer">
        <Table<PreliquidationTripItem>
          loading={loading || tripModified}
          key={"id"}
          rowKey={"id"}
          columns={[
            {
              title: "ID",
              dataIndex: "externalId",
              key: "externalId",
              render: (externalId) => externalId,
            },
            {
              title: "Fecha Arribo",
              dataIndex: "arrivalAt",
              key: "arrivalAt",
              render: (arrivalAt) => dateFormatWithoutTime(arrivalAt),
            },
            {
              title: "Fecha Cierre",
              dataIndex: "closeAt",
              key: "closeAt",
              sorter: (a, b) => (moment(a.closeAt, "YYYY/MM/DD").isBefore(moment(b.closeAt, "YYYY/MM/DD")) ? -1 : 1),
              render: (closeAt) => (closeAt ? dateFormatWithoutTime(closeAt) : "-"),
            },
            {
              title: "Tipo de servicio",
              dataIndex: "serviceType",
              key: "serviceType",
              render: (serviceType) => serviceType,
            },
            {
              title: "Compañía",
              dataIndex: "transportCompany",
              key: "transportCompany",
              render: (transportCompany) => transportCompany,
            },
            {
              title: "Estado",
              dataIndex: "collectionState",
              key: "collectionState?.id",
              sorter: (a, b) => a.collectionState?.description.localeCompare(b.collectionState?.description),
              width: "180px",
              render: (collectionState) => {
                return (
                  <Text
                    children={collectionState?.description}
                    style={{ color: getCollectionStateColors(collectionState?.id) }}
                  />
                );
              },
            },
            {
              title: "Pasajeros",
              dataIndex: "passengersCount",
              key: "passengersCount",
              render: (passengersCount) => passengersCount,
            },
            {
              title: "Importe",
              dataIndex: "amount",
              key: "amount",
              render: (amount) => toARSCurrency(amount),
            },
            {
              title: "Acciones",
              dataIndex: "externalId",
              align: "center",
              fixed: "right",
              render: (_, record) => {
                return (
                  <>
                    <Tooltip placement="top" title={"Eliminar viaje"}>
                      <Button
                        type="link"
                        danger={true}
                        icon={<CloseCircleOutlined />}
                        disabled={(preliquidationDiscounts?.length ?? 0) > 0}
                        onClick={() => {
                          handleDeletePreliquidationTrip(preliquidationId, record.id);
                        }}
                      />
                    </Tooltip>
                  </>
                );
              },
            },
          ]}
          dataSource={preliquidationTrips?.items.map((p, i) => ({
            ...p,
            key: `${i}-${p.id}`,
          }))}
          scroll={{ x: true }}
          rowSelection={rowSelection}
          pagination={{
            position: ["bottomCenter"],
            total: preliquidationTrips?.totalItems,
            pageSize: preliquidationTrips?.pageSize,
            locale: { next_page: "Próxima página", prev_page: "Página anterior" },
            current: preliquidationTrips?.page,
            onChange: (page: number, pageSize: number) => handlePreliquidationTripsUpdateTable({ page, pageSize }),
          }}
        />
      </div>
    </div>
  );
};

export default GeneratePreliquidationTripsTable;
