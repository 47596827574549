import { PaymentMethods } from "../models/Payment/PaymentMethods";
import { COLORS } from "./colors";

export enum PaymentIds {
  Pending = 1,
  Rejected = 2,
  Confirmed = 3
}

export const PAYMENT_STATES = {
  [PaymentIds.Pending]: 'Pendiente',
  [PaymentIds.Rejected]: 'Rechazado',
  [PaymentIds.Confirmed]: 'Confirmado',
}

export const PAYMENT_COLORS_BY_ID: Record<PaymentIds, string> = {
    [PaymentIds.Pending]: COLORS.BLACK,
    [PaymentIds.Rejected]: COLORS.RED,
    [PaymentIds.Confirmed]: COLORS.GREEN
}

export enum TypeOfTransportersToBePaid {
  Company = 'Company',
  Group = 'Group'
}

export const PAYMENT_METHODS: PaymentMethods[] = [
  PaymentMethods.Check,
  PaymentMethods.bankDeposit,
  PaymentMethods.ECheq,
  PaymentMethods.BankTransfer
]

export enum TypeOfPayment {
  Preliquidation = '1',
  account = '2'
}