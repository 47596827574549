import React, { useState } from 'react'
import { Protection } from '../../../../models/Trips/Protection'
import { message } from 'antd'
import { updatePaymentStateToProtection } from '../../../../services/trips.services'
import { ErrorResponse } from '../../../../models/Errors/ErrorResponse'
import { errorMessages } from '../../../../models/Errors/Errors'

interface ProtectionContextState {
  protection: Protection[]
  loading: boolean
  handleSubmit: (file: File) => Promise<void>
}

const initialProtectionContextState: ProtectionContextState = {
  protection: [],
  loading: false,
  handleSubmit: async () => {},
}

const ProtectionContext = React.createContext<ProtectionContextState>(initialProtectionContextState)

interface ProtectionContextProviderProps {
  children: React.ReactNode | React.ReactNode[]
}


export const ProtectionContextProvider = ({ children }: ProtectionContextProviderProps) => {
  const [protection, setProtection] = useState<Protection[]>(initialProtectionContextState.protection)
  const [loading, setLoading] = useState<boolean>(initialProtectionContextState.loading)

  const handleSubmit = async (file: File) => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('file', file)
      const data = await updatePaymentStateToProtection(formData)
      setProtection(data)
      message.success('el archivo se ha importado correctamente')
    }catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data
      const erroMessage = errorResponse ? errorMessages[errorResponse.Type] : errorMessages.tryAgain
      message.error(erroMessage)
      setProtection(initialProtectionContextState.protection)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ProtectionContext.Provider 
      value={{
        protection: protection,
        loading,
        handleSubmit
      }}
    >
      {children}
    </ProtectionContext.Provider>
  )
}

export default ProtectionContext
