import { Button, Table, Tooltip, Typography } from "antd";
import React, { useContext } from "react";
import { PreliquidationItem } from "../../../../../models/Preliquidation/Preliquidation";
import { dateFormatWithoutTime } from "../../../../../utils/dateFormatter.util";
import { getCollectionStateColors } from "../../../../../utils/collectionStateColors";
import { toARSCurrency } from "../../../../../utils/number.utils";
import moment from "moment";
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined } from "@ant-design/icons";
import { COLORS } from "../../../../../constants/colors";
import ManagePreliquidationContext from "../../contexts/ManagePreliquidationContextProvider";
import SkeletonTable from "../../../../shared/skeletonTable/SkeletonTable";
import "./ManagePreliquidationsTable.scss";
import { useNavigate } from "react-router-dom";

const ManagePreliquidationsTable: React.FC = () => {
  const { Text } = Typography;

  const navigate = useNavigate();

  const {
    handleSelectPreliquidation,
    openApprovePreliquidationModal,
    openRejectPreliquidationModal,
    loadingPreliquidations: loading,
    preliquidations,
    handleManagePreliquidationsUpdateTable,
    initialLoading,
  } = useContext(ManagePreliquidationContext);

  if (initialLoading) {
    return (
      <div className="manage-preliquidations-table layer">
        <SkeletonTable numberOfColumns={9} numberOfRows={10} />
      </div>
    );
  }

  return (
    <div>
      <div className="layer">
        <Table<PreliquidationItem>
          loading={loading}
          columns={[
            {
              title: "ID",
              dataIndex: "id",
              key: "id",
              render: (id) => id,
            },
            {
              title: "Fecha Cierre",
              dataIndex: "deadline",
              key: "deadline",

              render: (deadline) => (deadline ? dateFormatWithoutTime(deadline) : ""),
            },
            {
              title: "Fecha Preliq.",
              dataIndex: "createdAt",
              key: "createdAt",
              sorter: (a, b) =>
                moment(a.createdAt, "YYYY/MM/DD").isBefore(moment(b.createdAt, "YYYY/MM/DD")) ? -1 : 1,
              render: (createdAt) => dateFormatWithoutTime(createdAt),
            },
            {
              title: "Compañía",
              dataIndex: "transportCompany",
              key: "transportCompany.id",
              render: (transportCompany) => transportCompany?.name,
            },
            {
              title: "Grupo",
              dataIndex: "economicGroup",
              key: "economicGroup.id",
              render: (economicGroup) => economicGroup?.name,
            },
            {
              title: "Estado",
              dataIndex: "preliquidationState",
              key: "preliquidationState.id",
              sorter: (a, b) => a.preliquidationState.description.localeCompare(b.preliquidationState.description),
              width: "180px",
              render: (preliquidationState) => {
                return (
                  <Text
                    children={preliquidationState.description}
                    style={{ color: getCollectionStateColors(preliquidationState.id) }}
                  />
                );
              },
            },
            {
              title: "Total",
              dataIndex: "totalAmount",
              key: "totalAmount",
              render: (totalAmount) => toARSCurrency(totalAmount),
            },
            {
              title: "Saldo pendiente",
              dataIndex: "pendingBalance",
              key: "pendingBalance",
              render: (pendingBalance) => toARSCurrency(pendingBalance),
            },
            {
              title: "Acciones",
              dataIndex: "id",
              align: "center",
              fixed: "right",
              render: (_, record) => {
                return (
                  <>
                    <Tooltip placement="top" title={"Aprobar preliquidación"}>
                      <Button
                        disabled={!record.canConfirm}
                        type="link"
                        icon={
                          <CheckCircleOutlined
                            style={{
                              color: !record.canConfirm ? "" : COLORS.GREEN,
                            }}
                          />
                        }
                        onClick={() => {
                          handleSelectPreliquidation(record);
                          openApprovePreliquidationModal();
                        }}
                      />
                    </Tooltip>
                    <Tooltip placement="top" title={"Rechazar preliquidación"}>
                      <Button
                        disabled={!record.canReject}
                        danger={record.canReject}
                        type="link"
                        icon={<CloseCircleOutlined />}
                        onClick={() => {
                          handleSelectPreliquidation(record);
                          openRejectPreliquidationModal();
                        }}
                      />
                    </Tooltip>
                    <Tooltip placement="top" title={"Editar preliquidación"}>
                      <Button
                        type="link"
                        //disabled={true} //TODO: no está desarrollado el Editar, después cambiar por !record.canEdit
                        disabled={!record.canEdit}
                        icon={<EditOutlined />}
                        onClick={() => {
                          handleSelectPreliquidation(record);
                          navigate(`/preliquidation/${record.id}`);
                        }}
                      />
                    </Tooltip>
                  </>
                );
              },
            },
          ]}
          dataSource={preliquidations?.items.map((p, i) => ({
            ...p,
            key: `${i}-${p.id}`,
          }))}
          scroll={{ x: true }}
          pagination={{
            position: ["bottomCenter"],
            total: preliquidations?.totalItems,
            pageSize: preliquidations?.pageSize,
            locale: { next_page: "Próxima página", prev_page: "Página anterior" },
            current: preliquidations?.page,
            onChange: (page: number, pageSize: number) => handleManagePreliquidationsUpdateTable({ page, pageSize }),
          }}
        />
      </div>
    </div>
  );
};

export default ManagePreliquidationsTable;
