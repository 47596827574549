export interface IPaymentMethods {
  id: number
  description: string
}

export enum PaymentMethods {
  BankTransfer = 'Transferencia bancaria',
  Check = 'Cheque',
  ECheq = 'E-Cheq',  
  bankDeposit = 'Depósito bancario'
}

export type TypePaymentMethods = keyof typeof PaymentMethods
