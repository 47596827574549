import { Skeleton, Row, Col } from 'antd'

import './PaymentFormSkeleton.scss'

const PaymentFormSkeleton = () => {
  return (
    <div className='payment-form-skeleton'>
      <Row className='payment-form-skeleton__margin-bottom'>
        <Skeleton paragraph={false} className='payment-form-skeleton__full-width' active />
      </Row>

      <Row gutter={[16, 16]} className='payment-form-skeleton__margin-bottom'>
        <Col span={12} className='payment-form-skeleton__content-check'>
          <Skeleton.Avatar active />
          <Skeleton paragraph={false} style={{ marginLeft: 5, width: '30%', marginBottom: 5 }} active />
        </Col>
        <Col span={12} className='payment-form-skeleton__content-check'>
          <Skeleton.Avatar active />
          <Skeleton paragraph={false} style={{ marginLeft: 5, width: '30%', marginBottom: 5 }} active />
        </Col>
      </Row>

      <Row gutter={[16, 16]} className='payment-form-skeleton__margin-bottom'>
        <Col span={12}>
          <Skeleton paragraph={false} className='payment-form-skeleton__full-width' active />
        </Col>
        <Col span={12}>
        <Skeleton paragraph={false} className='payment-form-skeleton__full-width' active />
        </Col>
      </Row>

      <Row className='payment-form-skeleton__margin-bottom'>
        <Skeleton paragraph={false} className='payment-form-skeleton__full-width' active />
      </Row>

      <Row gutter={[16, 16]} className='payment-form-skeleton__margin-bottom'>
        <Col span={12}>
          <Skeleton paragraph={false} className='payment-form-skeleton__text' active />
          <Skeleton paragraph={false} className='payment-form-skeleton__full-width' active />
        </Col>
        <Col span={12}>
          <Skeleton paragraph={false} className='payment-form-skeleton__text' active />
          <Skeleton paragraph={false} className='payment-form-skeleton__full-width' active />
        </Col>
      </Row>

      <Row gutter={[16, 16]} className='payment-form-skeleton__margin-bottom'>
        <Col span={12}>
          <Skeleton paragraph={false} className='payment-form-skeleton__text' active />
          <Skeleton paragraph={false} className='payment-form-skeleton__full-width' active />
        </Col>
        <Col span={12}>
          <Skeleton paragraph={false} className='payment-form-skeleton__text' active />
          <Skeleton paragraph={false} className='payment-form-skeleton__full-width' active />
        </Col>
      </Row>

      <Row className='payment-form-skeleton__margin-bottom'>
        <Skeleton paragraph={false} className='payment-form-skeleton__full-width' active />
      </Row>

      <Row className='payment-form-skeleton__margin-bottom'>
        <Skeleton paragraph={false} className='payment-form-skeleton__texarea' active />
      </Row>

      <Row className='payment-form-skeleton__margin-bottom'>
        <Skeleton paragraph={false} className='payment-form-skeleton__button-file' active />
      </Row>

      <Row className='payment-form-skeleton__buttom'>
        <Skeleton.Button active />
      </Row>
    </div>
  )
}

export default PaymentFormSkeleton