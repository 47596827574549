import { useState } from "react";
import { Alert, Button, Col, Form, Input, Row } from "antd";
import { resendEmailConfirmationCode } from "../../../services/api.client";
import { useNavigate } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import { successNotification } from "../../atoms/notification/Notification";
import { isNullOrWhiteSpace } from "../../../utils/string.utils";
import { getResendEmailConfirmCodeMessageErrorByType } from "../../../models/Errors/Errors";
import { ErrorResponse } from "../../../models/Errors/ErrorResponse";
import { confirmEmail } from "../../../constants/routes";
import "./ResendEmailConfirmCode.scss";

const ResendEmailConfirmCode = () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const sendCodeToEmail = async (email: string) => {
		setError(null);
		setLoading(true);

		try {
			await resendEmailConfirmationCode(email);
			successNotification(
				"Recibirá un código de 6 dígitos para finalizar la activación de su cuenta.",
			);
			navigate(confirmEmail.path);
		} catch (error: any) {
			const errorResponse: ErrorResponse = error.response?.data;
			setError(
				getResendEmailConfirmCodeMessageErrorByType(errorResponse?.Type),
			);
		}

		setLoading(false);
	};

	return (
		<div className="resendEmailConfirmCodeModal">
			<div className="divForm">
				<h4 className="title">Reenvío de Código de Activación</h4>
				<p className="parraf">
					Ingrese el correo electrónico que utilizó para registrar su cuenta
				</p>
			</div>

			{error && (
				<Alert
					type="error"
					message={error}
					style={{ padding: 10, margin: 15 }}
					showIcon
				/>
			)}

			<Form
				name="basic"
				form={form}
				initialValues={{ remember: true }}
				onFinish={(value) => sendCodeToEmail(value?.email)}
				autoComplete="off"
				layout="vertical"
				requiredMark={false}
			>
				<Row justify="center">
					<Col xs={{ span: 18 }} md={{ span: 20 }} xl={{ span: 28 }}>
						<Form.Item
							name="email"
							rules={[
								{
									required: true,
									type: "email",
									message:
										"El correo electrónico ingresado posee un formato inválido",
								},
							]}
						>
							<Input
								prefix={<MailOutlined />}
								maxLength={50}
								autoComplete="email"
								placeholder="Correo Electrónico"
								style={{ marginTop: "15px" }}
							/>
						</Form.Item>
						<Row justify="center">
							<Form.Item
								shouldUpdate
								className="w100"
								style={{ marginBottom: "15px" }}
							>
								{({ getFieldsValue }) => {
									const { email } = getFieldsValue();
									return (
										<Button
											type="primary"
											htmlType="submit"
											className="w100"
											style={{ marginTop: 10 }}
											disabled={isNullOrWhiteSpace(email)}
											loading={loading}
										>
											Siguiente
										</Button>
									);
								}}
							</Form.Item>
						</Row>
						<Row justify="center">
							<Form.Item className="w100">
								<Button
									ghost
									className="btn-ghost"
									onClick={() => navigate(-1)}
									block
								>
									Volver
								</Button>
							</Form.Item>
						</Row>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default ResendEmailConfirmCode;
