import { useContext } from "react"

import { Table, Typography } from "antd"

import EndorsementAnnulmentContext from "../../context/EndorsementAnnulmentContextProvider"

import { COLORS } from "../../../../../constants/colors"

const { Paragraph, Text } = Typography

const AnnulmentedendorsementsTable = () => {
  const { annulmentedEndorsements, loading } = useContext(EndorsementAnnulmentContext)

  return (
    <>
      <Paragraph strong>Resultados:</Paragraph>
      <Table
      loading={loading}
      columns={[
        { title: 'Fila', dataIndex: 'row' },
        { title: 'ExternalId', dataIndex: 'externalId' },
        { title: 'ServiceTypeId', dataIndex: 'serviceTypeId' },
        {
          title: 'Resultado',
          dataIndex: 'message',
          render: (message, data) => {
            const colorAndStatus = data.success ? {color: COLORS.GREEN, status: 'El viaje ha sido anulado correctamente.'} : { color: COLORS.RED, status: 'ERROR:'}
            return <Text style={{ color: colorAndStatus.color }} strong>{`${colorAndStatus.status} ${message}`}</Text>
          }
        }
      ]}
      dataSource={annulmentedEndorsements.map((annulmentedEndorsement, index) => {
        return {
          ...annulmentedEndorsement,
          key: `${annulmentedEndorsement.externalId}-${index}`
        }
      })}
    />
    </>
  )
}

export default AnnulmentedendorsementsTable