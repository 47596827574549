import { TokenResponse } from "../models/TokenResponse";

const TOKEN_KEY = "TOKEN_KEY";

export const saveObject = (name: string, value: any) => localStorage.setItem(name, JSON.stringify(value));

export const getObject = (name: string) => safeJSONParse(localStorage.getItem(name));

export const saveString = (name: string, value: any) => localStorage.setItem(name, value);

export const getString = (name: string) => localStorage.getItem(name);

export const hasValue = (name: string) : boolean => !!localStorage.getItem(name);

export const clearAllLocalStorage = () => localStorage.clear();

export const saveTokens = (tokenResponse: TokenResponse) => saveObject(TOKEN_KEY, tokenResponse);
export const getTokens = (): TokenResponse => getObject(TOKEN_KEY);
export const isLoggedIn = () : boolean => hasValue(TOKEN_KEY);

const safeJSONParse = (input: string | null) => {
  try {
    if (input === null) {
      return input;
    }
    return JSON.parse(input);
  } catch (error) {
    return null;
  }
}