import React, { useState } from 'react'
import { payEndorsements } from '../../../../services/endorsement.services'
import { message } from 'antd'
import { errorMessages } from '../../../../models/Errors/Errors'
import { ErrorResponse } from '../../../../models/Errors/ErrorResponse'
import {Endorsement } from "../../../../models/Endorsement/Endorsement"

interface EndorsementPaymentContextState {
  payedEndorsements: Endorsement[]
  loading: boolean
  handleSubmitPayEndorsements: (file: File) => Promise<void>
}

const initialEndorsementPaymentContextState: EndorsementPaymentContextState = {
  payedEndorsements: [],
  loading: false,
  handleSubmitPayEndorsements: async (file: File) => {},
}

const EndorsementPaymentContext = React.createContext<EndorsementPaymentContextState>(initialEndorsementPaymentContextState)

interface EndorsementPaymentContextProviderProps {
  children: React.ReactNode | React.ReactNode[]
}


export const EndorsementPaymentContextProvider = ({ children }: EndorsementPaymentContextProviderProps) => {
  const [payedEndorsements, setPayedEndorsements] = useState<Endorsement[]>(initialEndorsementPaymentContextState.payedEndorsements)
  const [loading, setLoading] = useState<boolean>(initialEndorsementPaymentContextState.loading)

  const handleSubmitPayEndorsements = async (file: File) => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('file', file)
      const data = await payEndorsements(formData)
      setPayedEndorsements(data)
      message.success('El archivo se ha importado correctamente')
    }catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data
      const errorMessage = errorResponse ? errorMessages[errorResponse.Type] : errorMessages.tryAgain
      message.error(errorMessage)
      setPayedEndorsements(initialEndorsementPaymentContextState.payedEndorsements)
    } finally {
      setLoading(false)
    }
  }

  return (
    <EndorsementPaymentContext.Provider 
      value={{
        payedEndorsements,
        loading,
        handleSubmitPayEndorsements,
      }}
    >
      {children}
    </EndorsementPaymentContext.Provider>
  )
}

export default EndorsementPaymentContext
