import { createContext, useState } from "react";
import { notification } from "antd";
import apiClient from "../../services/api.client";
import { TokenResponse } from "../../models/TokenResponse";
import { ErrorResponse } from "../../models/Errors/ErrorResponse";
import { clearAllLocalStorage, getTokens, isLoggedIn, saveTokens } from "../../services/storage.service";
import { getLoginErrorMessageByType } from "../../models/Errors/LoginErrorMessages";
import { Role } from "../../constants/roles";

interface AuthContextInterface {
    isAuth: boolean;
    role: Role;
    username?: string;
    paymentsManually?: boolean
    signIn: (username: string, password: string, callback?: () => void) => void;
    signOut: () => void;
}

interface Children {
    children: JSX.Element[] | JSX.Element;
}

const initialState = {
    isAuth: false,
    role: undefined,
    username: undefined,
    signIn: () => { },
    signOut: () => { }
};

const decodeToken = (token: string) => JSON.parse(window.atob(token.split(".")[1]));
const isRefreshTokenInvalid = (errorType: string) : boolean => errorType === "InvalidRefreshToken" || errorType === "ExpiredRefreshToken";

const AuthContext = createContext<AuthContextInterface>(initialState);

function AuthProvider({ children }: Children) {
    const [isAuth, setIsAuth] = useState(() => isLoggedIn());
    const [role, setRole] = useState<Role>(() => isLoggedIn() ? decodeToken(getTokens().token).roles : undefined)
    const [username, setUsername] = useState(() => isLoggedIn() ? decodeToken(getTokens().token).preferred_username : undefined)
    const [paymentsManually, setPaymentsManually] = useState (()=> isLoggedIn() ? !(decodeToken(getTokens().token).pays_trips_manually === 'False') : undefined)
    let isRefreshing = false;
    let failedQueue: any[] = [];

    const processQueue = (error: null) => {
      failedQueue.forEach(prom => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve();
        }
      });

      isRefreshing = false;
      failedQueue = [];
    };
    
    const setUser = (token: string) => {
        if (token) {
            try {
                const parsedToken = decodeToken(token);
                if (parsedToken) {
                    setRole(parsedToken.roles);
                    setUsername(parsedToken.preferred_username);
                    setPaymentsManually(!(parsedToken.pays_trips_manually === 'False'))
                }
            } catch (error) {
                signOut();
                notification.error({
                    message: 'Hubo un error con su sesión',
                    placement: 'bottomRight',
                    duration: 5
                });
            }
        }
    };

    const signIn = async (username: string, password: string, callback?: () => void) => {
        try {
            const tokenResponse = await apiClient.post<TokenResponse>('/Security/Login', { username, password });
            saveTokens(tokenResponse.data);
            setUser(tokenResponse.data.token);
            setIsAuth(true);
        } catch (error: any) {
            const errorResponse: ErrorResponse = error.response?.data; 
            notification.error({
                message: getLoginErrorMessageByType(errorResponse.Type),
                placement: "bottomRight",
                duration: 5
            });
        } finally {
            if (callback) {
                callback();
            }
        }
    }

    const signOut = () => {
        clearAllLocalStorage();
        setIsAuth(false);
        setRole(undefined);
        isRefreshing = false;
        failedQueue = [];
    };

    apiClient.interceptors.response.use(
        (response) => response,
        async function (error) {
            const originalRequest = error.config;     

            if (error.response?.status === 401 && isLoggedIn()) {
                if (isRefreshTokenInvalid(error?.response?.data?.Type)) {
                    return new Promise((resolve, reject) => signOut());
                }
                
                if (isRefreshing) {
                    return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
                    .then(() => apiClient({
                        ...originalRequest,
                        ...{ headers: originalRequest.headers.toJSON() }
                    }))
                    .catch(err => Promise.reject(err));
                }

                isRefreshing = true;
                const { refreshToken } = getTokens();
                try {
                    const tokenResponse = await apiClient.post<TokenResponse>(`/Security/Refresh`, { 'RefreshToken': refreshToken });
                    saveTokens(tokenResponse.data);
                    setUser(tokenResponse.data.token);
                    processQueue(null);
                    return apiClient({
                      ...originalRequest,
                      ...{ headers: originalRequest.headers.toJSON() }
                    });
                  } catch (error: any) {
                    processQueue(error);
                    throw error;
                  } 
            }
        
            return Promise.reject(error);
        }
    );

    const value: AuthContextInterface = { isAuth, role, signIn, signOut, username, paymentsManually }
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider };