import { PaymentServices, RegisterManualPaymentParams, ManagePaymentsFiltersParams } from "../models/Payment/Payment";
import apiClient from "./api.client";
import { createQueryParams } from "../utils/http.utils";
import { PaymentMethodsDTO } from "../DTO/payments/PaymentMethos.dto";
import { PaginatedPaymentsDTO, PaymentsDetailsDTO } from "../DTO/payments/Payment.dto";
import { dateFormatWithoutTime } from "../utils/dateFormatter.util";
import { PaymentStateDTO } from "../DTO/payments/PaymentState.dto";
import moment from "moment";

export const getPaymentMethods = async (params?: { isManual?: boolean }) => {
  const queryParams = params ? createQueryParams(params) : ''
  const response = await apiClient.get<PaymentMethodsDTO[]>(`/PaymentMethods${queryParams}`)
  const { data } = response
  return data.map(method => {
    return {
      id: method.id,
      description: method.description
    }
  }).sort((a, b) => a.description.localeCompare(b.description))
}

export const registerManualPayment = async (params: RegisterManualPaymentParams) => {
  const formData = new FormData()
  if (params.file) {
    formData.append('file', params.file)
  }
  formData.append('jsonData', JSON.stringify(params.jsonData))

  const response = await apiClient.post(`/Payments`, formData)
  return response.data
}

export const getPayments = async ({ managePaymentsFilters, page, pageSize }: ManagePaymentsFiltersParams) => {
  const { paymentMethod, paymentStatus, paymentId, transport, economicGroup, paymentDate } = managePaymentsFilters ?? {}
  const from: any = paymentDate && paymentDate[0] ? paymentDate[0] : undefined
  const to: any = paymentDate && paymentDate[1] ? paymentDate[1] : undefined

  const params = {
    from: from ? from.format('YYYY-MM-DD') : undefined,
    to: to ? to.format('YYYY-MM-DD') : undefined,
    methodId: paymentMethod ?? undefined,
    stateId: paymentStatus ?? undefined,
    externalId: paymentId ?? undefined,
    transportCompanyId: transport ?? undefined,
    economicGroupId: economicGroup ?? undefined,
    page,
    pageSize
  }

  const queryParams = managePaymentsFilters ? createQueryParams(params as any) : ''
  const response = await apiClient.get<PaginatedPaymentsDTO>(`/Payments${queryParams}`)
  const data = response.data

  return {
    page: data.page,
    pageSize: data.pageSize,
    pages: data.pages,
    total: data.totalItems,
    items: data.items.map(payment => {
      return {
        id: payment.id,
        externalId: payment.externalId ?? '',
        creationDate: dateFormatWithoutTime(payment.createAt.toString()),
        paymentDate: dateFormatWithoutTime(payment.paidAt.toString()),
        paymentMethod: payment.method,
        company: payment.company,
        status: payment.state,
        amount: payment.amount,
        proofId: payment.proofId,
        economicGroup: payment.economicGroup,
      }
    })
  }
}

export const approvePayment = async ({ paymentId, destinyId, preliquidationId }: { paymentId: string, destinyId: number, preliquidationId?: number }) => {
  if (preliquidationId) {
    const response = await apiClient.post(`/Payments/${paymentId}/Confirm/${destinyId}?preliquidationId=${preliquidationId}`);
    return response;
  } else {
    const response = await apiClient.post(`/Payments/${paymentId}/Confirm/${destinyId}`);
    return response;
  }
  
}

export const rejectionPayment = async ({ message, paymentId }: { message?: string, paymentId: string }) => {
  const response = await apiClient.post<PaginatedPaymentsDTO>(`/Payments/${paymentId}/Rejections`, { RejectionReasonDescription : message })
  return response
}

export const updatePayment = async (paymentId: string, params: RegisterManualPaymentParams) => {
  const formData = new FormData()
  if (params.file) {
    formData.append('file', params.file)
  }
  formData.append('jsonData', JSON.stringify(params.jsonData))
  const response = await apiClient.put(`/Payments/${paymentId}`, formData)
  return response
}

export const generateProofPayment = async (proofId: string) => {
  const response = await apiClient.get(`/Payments/Proofs/${proofId}`, {
    responseType: 'arraybuffer',
    headers: {
      'Accept': 'application/pdf, image/png, image/jpeg'
    }
  })

  return {
    data: response.data,
    headers: response.headers
  }
}

export const getPaymentStates = async () => {
  const response = await apiClient.get<PaymentStateDTO[]>(`/Payments/States`)
  return response.data
}

export const getPaymentsById = async (paymentId: string) => {
  const response = await apiClient.get<PaymentsDetailsDTO>(`/Payments/${paymentId}`)
  const { data } = response
  return {
    ...data,
    paidAt: moment(data.paidAt).format('DD/MM/YYYY'),
    createAt: moment(data.createAt).format('DD/MM/YYYY')
  }
}

const paymentServices: PaymentServices = {
  getPaymentMethods,
  registerManualPayment,
  getPayments,
  approvePayment,
  rejectionPayment,
  updatePayment,
  generateProofPayment,
  getPaymentStates,
  getPaymentsById
}

export default paymentServices