import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  landing,
  login,
  bankAccounts,
  payment,
  debinDetail,
  confirmEmail,
  passwordChange,
  rates,
  register,
  registerResult,
  resendEmailConfirmCode,
  successEmailConfirmation,
  termsAndConditions,
  tripDetail,
  payments,
  trips,
  tripAnnulment,
  tripCertificates,
  otherPayments,
  users,
  bankAccountStatus,
  protección,
  tripCertificateNotFound,
  managePayments,
  checkingAccount,
  preliquidations,
  endorsementAnnulment,
  managePreliquidations,
  preliquidation,
  endorsementRejection,
  endorsementPayment,
} from "./constants/routes";
import { PrivateRoute, PublicRoute } from "./components/pages";
import LoggedInUserInfo from "./components/LoggedInUserInfo/LoggedInUserInfo";

import "./App.css";

const App = () => {
  return (
    <>
      <LoggedInUserInfo />
      <BrowserRouter>
        <Routes>
          <Route path={landing.path} element={<PublicRoute component={landing.component} />} />
          <Route path={login.path} element={<PublicRoute component={login.component} />} />
          <Route path={register.path} element={<PublicRoute component={register.component} />} />
          <Route path={registerResult.path} element={<PublicRoute component={registerResult.component} />} />
          <Route
            path={successEmailConfirmation.path}
            element={<PublicRoute component={successEmailConfirmation.component} />}
          />
          <Route path={confirmEmail.path} element={<PublicRoute component={confirmEmail.component} />} />
          <Route
            path={resendEmailConfirmCode.path}
            element={<PublicRoute component={resendEmailConfirmCode.component} />}
          />
          <Route path={termsAndConditions.path} element={<PublicRoute component={termsAndConditions.component} />} />
          <Route path={tripCertificates.path} element={<PublicRoute component={tripCertificates.component} />} />
          <Route path={passwordChange.path} element={<PublicRoute component={passwordChange.component} />} />
          <Route
            path={bankAccounts.path}
            element={<PrivateRoute roles={bankAccounts.roles} component={bankAccounts.component} />}
          />
          <Route path={rates.path} element={<PrivateRoute roles={rates.roles} component={rates.component} />} />
          <Route path={payment.path} element={<PrivateRoute roles={payment.roles} component={payment.component} />} />
          <Route
            path={debinDetail.path}
            element={<PrivateRoute roles={debinDetail.roles} component={debinDetail.component} />}
          />
          <Route path={trips.path} element={<PrivateRoute roles={trips.roles} component={trips.component} />} />
          <Route
            path={tripDetail.path}
            element={<PrivateRoute roles={tripDetail.roles} component={tripDetail.component} />}
          />
          <Route
            path={payments.path}
            element={<PrivateRoute roles={payments.roles} component={payments.component} />}
          />
          <Route
            path={tripAnnulment.path}
            element={<PrivateRoute roles={tripAnnulment.roles} component={tripAnnulment.component} />}
          />
          <Route
            path={otherPayments.path}
            element={<PrivateRoute roles={otherPayments.roles} component={otherPayments.component} />}
          />
          <Route path={users.path} element={<PrivateRoute roles={users.roles} component={users.component} />} />
          <Route
            path={bankAccountStatus.path}
            element={<PrivateRoute roles={bankAccountStatus.roles} component={bankAccountStatus.component} />}
          />
          <Route
            path={protección.path}
            element={<PrivateRoute roles={protección.roles} component={protección.component} />}
          />
          <Route
            path={tripCertificateNotFound.path}
            element={<PublicRoute component={tripCertificateNotFound.component} />}
          />
          <Route
            path={managePayments.path}
            element={<PrivateRoute roles={managePayments.roles} component={managePayments.component} />}
          />
          <Route
            path={checkingAccount.path}
            element={<PrivateRoute roles={checkingAccount.roles} component={checkingAccount.component} />}
          />
          <Route
            path={preliquidations.path}
            element={<PrivateRoute roles={preliquidations.roles} component={preliquidations.component} />}
          />
          <Route
            path={preliquidation.path}
            element={<PrivateRoute roles={preliquidation.roles} component={preliquidation.component} />}
          />
          <Route
            path={endorsementAnnulment.path}
            element={<PrivateRoute roles={endorsementAnnulment.roles} component={endorsementAnnulment.component} />}
          />
		      <Route
            path={endorsementRejection.path}
            element={<PrivateRoute roles={endorsementRejection.roles} component={endorsementRejection.component} />}
          />
          <Route
            path={endorsementPayment.path}
            element={<PrivateRoute roles={endorsementPayment.roles} component={endorsementPayment.component} />}
          />
          <Route
            path={managePreliquidations.path}
            element={<PrivateRoute roles={managePreliquidations.roles} component={managePreliquidations.component} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
