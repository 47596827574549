import { useState } from "react";
import { Alert, Button, Col, Form, Input, Row } from "antd";
import ReactCodeInput from "react-code-input";
import { Link, useNavigate } from "react-router-dom";
import { confirmEmail } from "../../../services/api.client";
import { ErrorResponse } from "../../../models/Errors/ErrorResponse";
import { getConfirmEmailMessageErrorByType } from "../../../models/Errors/Errors";
import {
	login,
	resendEmailConfirmCode,
	successEmailConfirmation,
} from "../../../constants/routes";
import "./ConfirmEmail.scss";

const ConfirmEmail = () => {
	const navigate = useNavigate();
	const [error, setError] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	const onSuccess = async ({ email, code, password }: any) => {
		setLoading(true);

		try {
			await confirmEmail(email, code, password);
			navigate(successEmailConfirmation.path);
		} catch (error: any) {
			const errorResponse: ErrorResponse = error.response?.data;
			setError(getConfirmEmailMessageErrorByType(errorResponse?.Type));
		}

		setLoading(false);
	};

	return (
		<div className="confirm">
			<h3 className="title">Activar Usuario</h3>

			<Form
				name="basic"
				initialValues={{ remember: true }}
				onFinish={onSuccess}
				autoComplete="off"
				layout="vertical"
				requiredMark={false}
			>
				<Row justify="center">
					<Col xs={{ span: 18 }} md={{ span: 12 }} xl={{ span: 8 }}>
						{error && (
							<Alert
								style={{ padding: 12, marginBottom: 10 }}
								message={error}
								type="error"
								showIcon
							/>
						)}
					</Col>
				</Row>
				<Row justify="center">
					<Col xs={{ span: 18 }} md={{ span: 12 }} xl={{ span: 8 }}>
						<Form.Item
							label="Correo Electrónico"
							name="email"
							rules={[
								{
									required: true,
									type: "email",
									message: "El correo electrónico ingresado es incorrecto",
								},
							]}
						>
							<Input
								maxLength={50}
								autoComplete="email"
								placeholder="Ingrese su Correo Electrónico"
							/>
						</Form.Item>

						<Form.Item
							label="Código"
							name="code"
							rules={[
								{
									required: true,
									message: "El código es requerido",
								},
							]}
						>
							<ReactCodeInput
								type="text"
								fields={6}
								name="code"
								inputMode="numeric"
							/>
						</Form.Item>

						<Form.Item
							name="password"
							label="Contraseña"
							hasFeedback
							rules={[
								{
									required: true,
									message: "La contraseña es requerida",
								},
								{
									min: 6,
									message: "La contraseña debe tener al menos 6 caracteres",
								},
								{
									max: 50,
									message: "La contraseña debe tener menos de 50 caracteres",
								},
								{
									pattern: new RegExp(/[0-9]/),
									message: "La contraseña debe tener al menos un numero",
								},
								{
									pattern: new RegExp(/[a-z]/),
									message: "La contraseña debe tener al menos una minúscula",
								},
								{
									pattern: new RegExp(/[A-Z]/),
									message: "La contraseña debe tener al menos una mayúscula",
								},
								{
									pattern: new RegExp(
										/([^a-zA-Z\d])+([a-zA-Z\d])+|([a-zA-Z\d])+([^a-zA-Z\d])+/,
									),
									message:
										"La contraseña debe tener al menos un caracter especial",
								},
								{
									pattern: new RegExp(/^\S+(?: \S+)*$/),
									message:
										"La contraseña no puede comenzar o terminar con un espacio en blanco",
								},
							]}
						>
							<Input.Password />
						</Form.Item>

						<Form.Item
							name="confirmPassword"
							label="Repetir Contraseña"
							dependencies={["password"]}
							hasFeedback
							rules={[
								{
									required: true,
									message: "Repetí la contraseña",
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue("password") === value) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error("Las contraseñas no coinciden"),
										);
									},
								}),
							]}
						>
							<Input.Password />
						</Form.Item>
						<Row
							justify="center"
							gutter={[48, 48]}
							style={{ marginBottom: "15px" }}
						>
							<Link
								to={resendEmailConfirmCode.path}
								children="Reenviar código de activación"
							/>
						</Row>
						<Row justify="center">
							<Form.Item className="w100" style={{ marginBottom: "15px" }}>
								<Button
									type="primary"
									htmlType="submit"
									className="btn-submit"
									block
									loading={loading}
								>
									Activar
								</Button>
							</Form.Item>
						</Row>
						<Row justify="center">
							<Form.Item className="w100">
								<Button
									ghost
									className="btn-ghost"
									onClick={() => navigate(login.path)}
									block
								>
									Volver
								</Button>
							</Form.Item>
						</Row>
					</Col>
				</Row>
			</Form>
		</div>
	);
};

export default ConfirmEmail;
