import { useContext } from "react";

import { Button, Col, DatePicker, Form, Input, Row, Typography } from "antd";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

import PreliquidationContext from "../../contexts/PreliquidationContextProvider";
import { DefaultDateFormat } from "../../../../../models/Date";
import SkeletonFilters from "../skeletonFilters/SkeletonFilters";
import useGetStep2Data from "../../hooks/useGetStep2Data";

const { RangePicker } = DatePicker;

const GeneratePreliquidationFilters = () => {
  const { setSearchingPreliquidationTrips, initialLoading } = useContext(PreliquidationContext);

  const [form] = useForm();

  const { Title } = Typography;

  const { handlePreliquidationTripsUpdateTable } = useGetStep2Data();

  const { preliquidationId } = useContext(PreliquidationContext);

  const onFilter = (values: any) => {
    setSearchingPreliquidationTrips(true);
    handlePreliquidationTripsUpdateTable({
      preliquidationsFilters: values,
      page: 1,
      pageSize: 10,
    });
  };

  const handleReset = () => {
    form.resetFields();
    form.setFieldsValue({ collectionState: null });
  };

  const dateValidator = (_: any, value: any[]) => {
    if (value && value[0] && value[1] && value[0].isAfter(value[1])) {
      return Promise.reject(new Error("La fecha 'Desde' no puede ser mayor que la fecha 'Hasta'"));
    }
    return Promise.resolve();
  };

  if (initialLoading) {
    return <SkeletonFilters />;
  }

  return (
    <>
      <Title level={2}>Preliquidación Nº: {preliquidationId}</Title>
      <div className="layer">
        <Form
          form={form}
          name="generatePreliquidationFiltersForm"
          layout="vertical"
          className="preliquidation-filters__form"
          onFinish={onFilter}
        >
          <Row gutter={[16, 16]} justify={"start"} align={"bottom"}>
            <Col>
              <Form.Item
                label="Id de viaje"
                name="tripId"
                rules={[
                  {
                    validator: (_, value) =>
                      value > 0 || !value
                        ? Promise.resolve()
                        : Promise.reject(new Error("El id de viaje no puede ser un número negativo")),
                  },
                ]}
              >
                <Input placeholder="Id de viaje" type="number" className="preliquidation-filters__field" allowClear />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Importe"
                name="amount"
                rules={[
                  {
                    validator: (_, value) =>
                      value > 0 || !value
                        ? Promise.resolve()
                        : Promise.reject(new Error("El monto debe ser mayor a cero")),
                  },
                ]}
              >
                <Input placeholder="Importe" type="number" className="preliquidation-filters__field" allowClear />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Fecha de cierre"
                name="closedDate"
                dependencies={["closedDate"]}
                rules={[{ validator: dateValidator }]}
              >
                <RangePicker
                  placeholder={["Desde", "Hasta"]}
                  format={DefaultDateFormat}
                  allowEmpty={[true, true]}
                  allowClear
                  className="preliquidation-filters__field--xxl"
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="default"
                  htmlType="button"
                  form={"generatePreliquidationFiltersForm"}
                  icon={<ClearOutlined />}
                  onClick={handleReset}
                >
                  Limpiar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  form={"generatePreliquidationFiltersForm"}
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Buscar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default GeneratePreliquidationFilters;
