import { Typography } from "antd"
import { useNavigate, useParams } from "react-router-dom"

import { TripCertificate } from "../../../models/Trips/Certificate"

import { getTripCertificate } from "../../../services/trips.services"

import { tripCertificateNotFound } from "../../../constants/routes"

import TripCertificateSkeleton from "./components/tripCertificateSkeleton/TripCertificateSkeleton"
import CentralSection from "./components/centralSection/centralSection"
import Footer from "./components/footer/Footer"

import useData from "../../../hooks/useData"

import { customDateFormat } from "../../../utils/dateFormatter.util"

import logoProteccion from "../../../assets/logos/logo-proteccion.png"

import "./tripCertificates.scss"

const { Paragraph, Title, Text } = Typography;

const TripCertificates = () => {
  const navigate = useNavigate() 
  const { tripId, certificateId } = useParams();
  const [data, loading, error] = useData<TripCertificate>({
    service: () => getTripCertificate({tripId: tripId ?? '', certificateId: certificateId ?? '' })
  })

  if (loading) {
    return <TripCertificateSkeleton />
  }

  if (error) {
    navigate(tripCertificateNotFound.path)
  }

  return (
    <div className='trip-certificate'>
      <img 
        src={logoProteccion} 
        className='trip-certificate__logo' 
        alt='log proteccion mutual de seguros de transporte publico de pasajeros'
        width={200}
        height={58}
      />
      <Title level={5} className='trip-certificate__title'>Constancia de cobertura</Title>

      <Paragraph className='trip-certificate__paragraph'>
        Sección Vida Transporte Público de Pasajeros.
      </Paragraph>
      <Paragraph className='trip-certificate__paragraph'>
        Certificamos por la presente que la empresa: <Text strong>{data?.companyName.toUpperCase() ?? ''}</Text>
      </Paragraph>
      <Paragraph className='trip-certificate__paragraph'>
        Tiene contratada en esta mutual la póliza flota en la que se encuentra la unidad con el dominio <Text strong>{data?.domain.toUpperCase() ?? ''}</Text>
      </Paragraph>
      <Paragraph className='trip-certificate__paragraph'>
        La cual realiza el viaje n°: <Text strong>{data?.externalId ?? ''}</Text> 
      </Paragraph>

      <div className='trip-certificate__section-space-between trip-certificate__section-space-between--column'>
        <Paragraph className='trip-certificate__paragraph'>
          Fecha de partida: <Text strong>{data?.departureDate ?? ''} hs</Text>
        </Paragraph>

        <Paragraph className='trip-certificate__paragraph'>
          Fecha de llegada: <Text strong>{data?.arrivalDate ?? ''} hs</Text>
        </Paragraph>
      </div>
            
      <Paragraph className='trip-certificate__paragraph'>
        Origen del viaje: <Text strong>{data?.tripOrigin.toUpperCase() ?? ''}</Text>
      </Paragraph>
      <Paragraph className='trip-certificate__paragraph'>
        Destino del viaje: <Text strong>{data?.tripDestination.toUpperCase() ?? ''}</Text>
      </Paragraph>
      <Paragraph className='trip-certificate__paragraph'>
        Cantidad de pasajeros declarados por la empresa ante el organismo de contralor <Text strong> {data && data?.numberPassengers > 0 ? data?.numberPassengers.toString() : 'Sin pasajeros informados'}</Text>, informados a la fecha y hora de emisión del presente.
      </Paragraph>
      <Paragraph className='trip-certificate__paragraph'>
        Amparando los riesgos que se detallan a continuación:
      </Paragraph>

      <CentralSection 
        list={[
          { name: '1. Muerte', content: 'Hasta la suma de', amount: '$ 7.000.000,00', id: 1 },
          { name: '2. Incapacidad Permanente Total', content: 'Hasta la suma de', amount: '$ 7.000.000,00', id: 2 },
          { name: '3. Incapacidad Permanente Parcial', content: 'Hasta la suma de', amount: '$ 7.000.000,00', id: 3 }
        ]}
      />

      <Paragraph className='trip-certificate__paragraph'>
        Dicha cobertura ampara lo solicitado en la Resolución 4/2023 de la Superintendencia de Seguros de la Nación.
      </Paragraph>
      <Paragraph className='trip-certificate__paragraph'>
        La vigencia de la misma corresponde desde la partida del viaje, hasta la finalización del mismo declarada ante el organismo correspondiente.
      </Paragraph>
      <Paragraph className='trip-certificate__paragraph'>
        Se emite la presente constancia en Buenos Aires <Text strong>{customDateFormat(data?.generatedAt ?? '')}</Text> para ser presentada ante quien corresponda.
      </Paragraph>
      <Paragraph className='trip-certificate__paragraph'>
        <strong>ADVERTENCIA AL ASEGURADO:</strong> La extensión del presente Certificado no implica renuncia a oponer la suspensión o caducidad de la cobertura por falla de pago del premio correspondiente, conforme a las Condiciones de la Póliza.
      </Paragraph>

      <Footer />
    </div>
  )
}

export default TripCertificates