import React, { useContext, useState } from "react";
import { Select, Input, Button, AutoComplete, Form, Typography, message, Spin } from "antd";
import PreliquidationContext from "../../contexts/PreliquidationContextProvider";
import useGetSelectableData from "../../hooks/useGetSelectableData";
import { DiscountTypes, IDiscountTypes } from "../../../../../models/Preliquidation/DiscountTypes";
import { TransportCompanyResponse } from "../../../../../models/TransportCompany/TransportCompany";
import { applyDiscount } from "../../../../../services/preliquidation.service";
import { errorMessages } from "../../../../../models/Errors/Errors";
import { ApplyDiscountParams } from "../../../../../DTO/preliquidation/preliquidation.dto";
import useGetStep2Data from "../../hooks/useGetStep2Data";
import { ErrorResponse } from "../../../../../models/Errors/ErrorResponse";

const { Title } = Typography;

const DiscountForm: React.FC = () => {
  const {
    preliquidationId,
    transportCompany,
    preliquidationDetails,
    economicGroup,
    preliquidationIncludedTransportCompanies,
    discountModified,
    setDiscountModified,
  } = useContext(PreliquidationContext);

  const [discountForm] = Form.useForm();
  const { loading, discountTypes } = useGetSelectableData();
  const { handlePreliquidationDiscountsUpdateTable } = useGetStep2Data();

  const [discountType, setDiscountType] = useState<string>(DiscountTypes.Amount);

  const [discountTransportCompany, setDiscountTransportCompany] = useState<TransportCompanyResponse | null>(null);
  const [calculatedDiscount, setCalculatedDiscount] = useState<number>(0);

  const handleSelectTransportCompany = (value: string) => {
    const selected = preliquidationIncludedTransportCompanies.find((item) => item.name === value) || null;

    setDiscountTransportCompany(selected);
  };

  const handleDicountTypeChange = (
    _value: any,
    selectedOption:
      | {
          value: number;
          label: string;
        }
      | {
          value: number;
          label: string;
        }[]
  ) => {
    if (Array.isArray(selectedOption)) {
      setDiscountType(selectedOption[0]?.label);
    } else {
      setDiscountType(selectedOption?.label);
    }
  };

  interface ApplyDiscountForm {
    discountType: number;
    transporter: string;
    amount: number;
    amountPerPassenger: number;
  }

  const mapDiscountFormToParams = (values: ApplyDiscountForm): ApplyDiscountParams => {
    const { discountType, amount, amountPerPassenger } = values;
    const transportCompanyId = economicGroup ? discountTransportCompany?.id : transportCompany?.id;

    return {
      discountTypeId: discountType,
      transportCompanyId,
      value: amount,
      amountPerPassenger,
    };
  };

  const handleApplyDiscount = async (values: ApplyDiscountForm) => {
    try {
      setDiscountModified(true);
      const applyResult = await applyDiscount(preliquidationId, mapDiscountFormToParams(values));
      if (applyResult) {
        message.success("Descuento aplicado correctamente");
        handlePreliquidationDiscountsUpdateTable();
        discountForm.resetFields();
      } else {
        message.error("No se pudo aplicar el descuento");
      }
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      const type = errorResponse.Type;
      const errorMessage = errorMessages[type] ?? errorMessages.tryAgain;
      message.error(errorMessage);
    } finally {
      setDiscountModified(false);
    }
  };

  const handleCalculateDiscount = (value: string) => {
    let discountToApply = 0;
    if (discountType === DiscountTypes.Passengers) {
      discountToApply = Number(value) * discountForm.getFieldValue("amountPerPassenger") ?? 0;
    } else if (discountType === DiscountTypes.Percentage) {
      if (economicGroup) {
        const transportCompanyTotalAmount = preliquidationDetails?.details.filter(
          (detail) => detail.transportCompany === discountTransportCompany?.name
        )?.[0].amountToPay;
        discountToApply = parseFloat((Number(value) * (transportCompanyTotalAmount ?? 0)).toFixed(2));
      } else {
        discountToApply = parseFloat((Number(value) * (preliquidationDetails?.totalAmount ?? 0)).toFixed(2));
      }
    } else {
      discountToApply = Number(value);
    }
    setCalculatedDiscount(discountToApply);
    discountForm.setFieldsValue({ discount: discountToApply });
  };

  return (
    <>
      <Title level={4}>Descuentos:</Title>
      <div className="layer">
        <Form
          form={discountForm}
          name="discountsForm"
          layout="vertical"
          onFinish={(values) => handleApplyDiscount(values)}
          className="discounts-filters__form"
        >
          <Form.Item
            name="discountType"
            label="Tipo de descuento"
            rules={[{ required: true, message: "Debe completar este campo" }]}
          >
            <Select
              placeholder="Seleccione..."
              loading={loading}
              notFoundContent={loading ? <Spin size="small" /> : null}
              onChange={(value, selectedOption) => handleDicountTypeChange(value, selectedOption)}
              options={discountTypes.map((discountType: IDiscountTypes) => ({
                value: discountType.id,
                label: discountType.description,
              }))}
            />
          </Form.Item>
          {economicGroup ? (
            <Form.Item
              name="transporter"
              label="Transportista"
              rules={[{ required: true, message: "Debe completar este campo" }]}
            >
              <AutoComplete
                placeholder="Seleccione..."
                notFoundContent={loading ? <Spin size="small" /> : null}
                options={preliquidationIncludedTransportCompanies.map((item) => ({
                  value: item.name,
                  label: (
                    <div>
                      {item.name} ({item.id})
                    </div>
                  ),
                }))}
                onSelect={(value) => handleSelectTransportCompany(value)}
                filterOption
                allowClear
                defaultValue={""}
              />
            </Form.Item>
          ) : null}
          {(discountType as DiscountTypes) === DiscountTypes.Passengers ? (
            <Form.Item
              label="Tarifa por pasajero"
              name="amountPerPassenger"
              rules={[
                { required: true, message: "Debe completar este campo" },
                {
                  validator: (_, value) => {
                    return value > 0 ? Promise.resolve() : Promise.reject(new Error("El valor debe ser mayor a cero"));
                  },
                },
              ]}
            >
              <Input placeholder="Tarifa por pasajero" type="number" className="discount-filters__field" allowClear />
            </Form.Item>
          ) : null}

          <Form.Item
            label="Valor"
            name="amount"
            rules={[
              { required: true, message: "Debe completar este campo" },
              {
                validator: (_, value) => {
                  if (discountType === DiscountTypes.Percentage) {
                    return value > 0 && value < 1
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("Si el tipo de descuento es porcentaje, el valor debe estar entre 0 y 0.99")
                        );
                  } else {
                    return value > 0 ? Promise.resolve() : Promise.reject(new Error("El valor debe ser mayor a cero"));
                  }
                },
              },
            ]}
          >
            <Input
              placeholder="Valor"
              type="number"
              step="0.01"
              className="discount-filters__field"
              allowClear
              onBlur={(e) => handleCalculateDiscount(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Descuento" name="discount">
            <Input
              type="number"
              readOnly
              disabled={true}
              className="discount-filters__field"
              allowClear
              value={calculatedDiscount}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={discountModified} loading={discountModified}>
              Aplicar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default DiscountForm;
