import React, { useContext, useEffect, useState } from 'react'
import { CheckingAccountPaginated, GetCheckingAccountFilters } from '../../../../models/CheckingAccount/CheckingAccount'
import { getCheckingAccounts } from '../../../../services/checkingAccount.services'
import { message } from 'antd'
import { errorMessages } from '../../../../models/Errors/Errors'
import { AuthContext } from '../../../context/AuthContext'
import { BASSA_ADMIN } from '../../../../constants/roles'
import { ErrorResponse } from '../../../../models/Errors/ErrorResponse'

interface changeFilterParams {
  to?: string
  from?: string
  reason?: string
  transportCompanyId?: string
  economicGroupId?: string
}
interface CheckingAccountContextState {
  checkingAccount?: CheckingAccountPaginated
  loading: boolean
  initialLoading: boolean
  filters: GetCheckingAccountFilters
  handleChangePanigated: ({ page, size }: { page: number, size: number }) => void
  handleChangeFilters: ({ from, to, reason, transportCompanyId, economicGroupId }: changeFilterParams) => void
}

const initialCheckingAccountContextState: CheckingAccountContextState = {
  loading: false,
  initialLoading: true,
  filters: {
    page: 1,
    size: 10
  },
  handleChangePanigated: ({ page, size }: { page: number, size: number }) => {},
  handleChangeFilters: ({ from, to, reason, transportCompanyId, economicGroupId }: changeFilterParams) => {}
}

const CheckingAccountContext = React.createContext<CheckingAccountContextState>(initialCheckingAccountContextState)

interface CheckingAccountContextProviderProps {
  children: React.ReactNode | React.ReactNode[]
}


export const CheckingAccountContextProvider = ({ children }: CheckingAccountContextProviderProps) => {
  const { role } = useContext(AuthContext)
  const isAdminUser = role === BASSA_ADMIN
  const [checkingAccount, setCheckingAccount] = useState<CheckingAccountPaginated>()
  const [loading, setLoading] = useState<boolean>(initialCheckingAccountContextState.loading)
  const [initialLoading, setInitialLoading] = useState<boolean>(initialCheckingAccountContextState.initialLoading)
  const [filters, setFilters] = useState<GetCheckingAccountFilters>(initialCheckingAccountContextState.filters)

  const getData = async (filterParams: GetCheckingAccountFilters = filters) => {
    try {
      setLoading(true)
      const response = await getCheckingAccounts(filterParams)
      setCheckingAccount(response)
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      message.error(errorMessages[errorResponse?.Type] ?? errorMessages.tryAgain)
    } finally {
      setLoading(false)
    }
  }

  const handleChangePanigated = ({ page, size }: { page: number, size: number }) => {
    getData({ ...filters, page, size })
    setFilters({ ...filters, page, size })
  }

  const handleChangeFilters = ({ from, to, reason, transportCompanyId, economicGroupId }: changeFilterParams) => {
    getData({ ...filters, to, from, transportCompanyId, economicGroupId, reason, page: initialCheckingAccountContextState.filters.page })
    setFilters({ ...filters, page: initialCheckingAccountContextState.filters.page, to, from, reason, economicGroupId, transportCompanyId })
  }

  useEffect(() => {
    !isAdminUser && getData({ page: 1, size: 10 })
    setTimeout(() => {
      setInitialLoading(false)
    }, 1000)
  }, [])

  return (
    <CheckingAccountContext.Provider 
      value={{
        checkingAccount,
        loading,
        initialLoading,
        filters,
        handleChangePanigated,
        handleChangeFilters
      }}
    >
      {children}
    </CheckingAccountContext.Provider>
  )
}

export default CheckingAccountContext
