import { useEffect, useState } from 'react'
import { getTransportCompanies } from '../../../../services/transportCompany.services'
import { getRoles } from '../../../../services/user.services'
import { Role } from '../../../../models/User/Role'
import { TransportCompanyResponse } from '../../../../models/TransportCompany/TransportCompany'
import { errorMessages } from '../../../../models/Errors/Errors'
import { message } from 'antd'

const useUserFilters = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [transports, setTransports] = useState<TransportCompanyResponse[]>([])
  const [roles, setRoles] = useState<Role[]>([])

  const getData = () => {
    Promise.all([
      getTransportCompanies({ isRegistered: true }),
      getRoles()
    ])
      .then((data) => {
        const [transports, roles] = data
        setTransports(transports)
        setRoles([...roles, { id: '', name: 'Todos' }])
      }).catch(() => {
        message.error(errorMessages.tryAgain)
      }).finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    void getData()
  }, [])

  return {
    transports,
    roles,
    loading
  }
}

export default useUserFilters