import { Link, To } from 'react-router-dom'

import { Typography } from 'antd'
import { LeftOutlined } from '@ant-design/icons'

import './goBackTitle.scss'

const { Title } = Typography

interface GoBackTitleProps {
  title: string
  goTo?: To
}

const GoBackTitle: React.FC<GoBackTitleProps> = ({ title, goTo }) => {

  return (
    <div className="go-back-container">
      <Link className="go-back-container__button" to={goTo ?? '-1'} >
        <Title level={2} className="go-back-container__title">
          <LeftOutlined /> {title}
        </Title>
      </Link>
    </div>
  )
}

export default GoBackTitle