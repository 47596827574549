import { Col, Row, Steps } from "antd";
import PreliquidationContext from "../../contexts/PreliquidationContextProvider";
import { Content } from "antd/es/layout/layout";
import SelectTripsToPreliquidateForm from "../SelectTripsToPreliquidateForm/SelectTripsToPreliquidateForm";
import Sider from "antd/es/layout/Sider";
import GeneratePreliquidation from "../GeneratePreliquidation/GeneratePreliquidation";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";

const PreliquidationSteps = () => {
  const { id } = useParams();
  const {
    setPreliquidationId,
    setCurrentStep,
    currentStep,
    setGeneratePreliquidationStatus,
    generatePreliquidationStatus,
  } = useContext(PreliquidationContext);

  useEffect(() => {
    if (id) {
      setPreliquidationId(id);

      setCurrentStep(currentStep + 1);
      setGeneratePreliquidationStatus("process");
    }
  }, [id]);

  return (
    <>
      <Sider breakpoint="lg" width={"100px"}>
        <div
          style={{
            background: "linear-gradient(to bottom, silver, white)",
            height: "100%",
            paddingTop: "200px",
            paddingLeft: "60px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Row>
            <Col>
              <Steps
                direction="vertical"
                size="default"
                current={currentStep}
                items={[
                  { title: "", style: { paddingBlockEnd: 280 } },
                  { title: "", status: generatePreliquidationStatus },
                ]}
              />
            </Col>
          </Row>
        </div>
      </Sider>

      <Content className="preliquidationsContent">
        {currentStep === 0 ? <SelectTripsToPreliquidateForm /> : <GeneratePreliquidation />}
      </Content>
    </>
  );
};

export default PreliquidationSteps;
