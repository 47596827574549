import { Link, useParams } from "react-router-dom"

import { Table, Typography } from "antd"
import SkeletonTable from "../../../../shared/skeletonTable/SkeletonTable"

import { Debin } from "../../../../../models/Trips/Debin"
import { debinDetail } from "../../../../../constants/routes"

import { stateColor } from "../../../../../helper"
import { toARSCurrency } from "../../../../../utils/number.utils"

import "./paymentDetailTable.scss"

const { Text } = Typography

interface PaymentDetailTableProps {
  loading: boolean
  debins: Debin[]
}

const PaymentDetailTable: React.FC<PaymentDetailTableProps> = ({ loading, debins }) => {
  const { id: tripId } = useParams()

  return (
    <div className="layer">
      {
        loading
          ? <SkeletonTable numberOfColumns={5}/>
          : (
              <Table
                dataSource={debins}
                columns={[
                  { 
                    title: 'ID Debin',
                    dataIndex: 'id',
                    key: 'id',
                    render: (debinId) => <Link to={debinDetail.dataRoute(tripId, debinId)}>{debinId}</Link> 
                  },
                  { title: 'Fecha creación', dataIndex: 'creationDate', key: 'creationDate'},
                  {
                    title: 'Monto',
                    dataIndex: 'amount',
                    key: 'amount',
                    render: (amount) => {
                      return <Text>{toARSCurrency(amount)}</Text>
                    }
                  },
                  {
                    title: 'Estado',
                    dataIndex: 'status',
                    key: 'status',
                    render: (status) => <Text strong style={{ color: stateColor(status.id) }}> {status.description} </Text>
                  },
                  { title: 'CBU', dataIndex: 'cbu', key: 'cbu'}
                ]}
                loading={loading}
                pagination={false}
              />
          )
      }
    </div>
  )
}
export default PaymentDetailTable