import { Layout, Typography } from "antd"
import { Content } from "antd/es/layout/layout"

import ManagePaymentsFiltersFilters from "./components/managePaymentsFilters/ManagePaymentsFilters"
import ManagePaymentsTable from "./components/managePaymentsTable/ManagePaymentsTable"
import PaymentApprovalModal from "./components/paymentApprovalModal/PaymentApprovalModal"
import PaymentRejectionModal from "./components/paymentRejectionModal/PaymentRejectionModal"
import UpdatePaymentModal from "./components/updatePaymentModal/UpdatePaymentModal"

import { ManagePaymentsContextProvider } from "./contexts/ManagePaymentsContextProvider"

import './managePayments.scss'

const { Title } = Typography

const ManagePayments = () => {
  return (
    <Layout>
      <Content className="manage-payments-content">
        <Title level={2}>Gestionar pagos</Title>
        <ManagePaymentsContextProvider>
          <ManagePaymentsFiltersFilters />
          <ManagePaymentsTable />
          <PaymentApprovalModal />
          <PaymentRejectionModal />
          <UpdatePaymentModal />
        </ManagePaymentsContextProvider>
      </Content>
    </Layout>
  )
}

export default ManagePayments
