import { Alert, Layout, Typography } from "antd"
import { Content } from "antd/es/layout/layout"

import { EndorsementPaymentContextProvider } from "./context/EndorsementPaymentContextProvider"



import './EndorsementPayment.scss'
import PayEndorsementsForm from "./components/PayEndorsementsFiltersForm/PayEndorsementsForm"
import PayEndorsementsTable from "./components/PayEndorsementsTable/PayEndorsementsTable"

const { Title } = Typography

const EndorsomentPayment = () => {
  return (
    <Layout>
      <Content className="endorsement-payment-content">
        <Title level={2}>Pagar endosos</Title>
        <div className="endorsement-payment-content__content-alert">
          <Alert
            message="Seleccione el archivo xlsx que desea importar para pagar los endosos."
            type="info"
          />
        </div>
        <div className="endorsement-payment-content layer">
          <EndorsementPaymentContextProvider>
            <PayEndorsementsForm />
            <PayEndorsementsTable />
          </EndorsementPaymentContextProvider>
        </div>
      </Content>
    </Layout>
  )
}

export default EndorsomentPayment;
