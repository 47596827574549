import { UserServices } from '../models/User/User'
import { RoleReponseDto } from '../DTO/user/Role.dto'
import apiClient from './api.client'
import { Role } from '../models/User/Role'
import { FiltersAndPaginationParams } from '../models/User/User'
import { UserResponseDTO } from '../DTO/user/User.dto'
import { createQueryParams } from '../utils/http.utils'

export const getRoles = async (): Promise<Role[]> => {
  const response = await apiClient.get<RoleReponseDto[]>('ApplicationRoles')
  const { data } = response
  return data
}

export const getUsers = async (filtersAndPagination: FiltersAndPaginationParams) => {
  const queryString = createQueryParams({
    roleId: filtersAndPagination.filters.role,
    transportCompanyNumber: filtersAndPagination.filters.numberCompany,
    transportCompanyId: filtersAndPagination.filters.transporter,
    page: filtersAndPagination.page,
    pageSize: filtersAndPagination.pageSize
  })
  
  const response = await apiClient.get<UserResponseDTO>(`/ApplicationUsers${queryString !== '?' ? queryString : ''}`)
  const { data } = response
  return {
    page: data.page,
    pageSize: data.pageSize,
    pages: data.pages,
    total: data.totalItems,
    data: data.items
  }
}

export const activateUser = async (userId: string, isActive: boolean) => {
  const response = await apiClient.patch<UserResponseDTO>(`/ApplicationUsers/${userId}`, { IsActive: isActive })
  const { data } = response
  return data || { isActive }
}

export const userServices: UserServices = {
  getRoles,
  getUsers,
  activateUser
}
