import { useContext, useEffect, useState } from "react";
import { getDetails, getDiscounts, getTrips } from "../../../../services/preliquidation.service";
import {
  PreliquidationTripsFilters,
  PreliquidationTripsFiltersParams,
} from "../../../../models/Preliquidation/Preliquidation";
import PreliquidationContext from "../contexts/PreliquidationContextProvider";
import { ErrorResponse } from "../../../../models/Errors/ErrorResponse";
import { errorMessages } from "../../../../models/Errors/Errors";
import { message } from "antd";

const useGetStep2Data = () => {
  const {
    preliquidationId,
    setPreliquidationTrips,
    setPreliquidationDiscounts,
    setPreliquidationDetails,
    setLoadingPreliquidationTrips,
    setLoadingPreliquidationDetails,
    setLoadingPreliquidationDiscounts,
    setInitialLoading,
  } = useContext(PreliquidationContext);

  const [preliquidationTripsFilters, setPreliquidationTripsFilters] = useState<PreliquidationTripsFilters>({
    amount: undefined,
    closedDate: undefined,
    tripId: undefined,
  });

  const [preliquidationTripsPagination, setPreliquidationTripsPagination] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });

  const [preliquidationTripsUpdateTable, setPreliquidationTripsUpdateTable] = useState<boolean>(false);

  const [preliquidationDiscountsUpdateTable, setPreliquidationDiscountsUpdateTable] = useState<boolean>(false);

  const [preliquidationDetailsUpdateTable, setPreliquidationDetailsUpdateTable] = useState<boolean>(false);

  const fetchPreliquidationTripsData = async (preliquidationId: string) => {
    try {
      setLoadingPreliquidationTrips(true);
      const response = await getTrips(
        preliquidationId,
        preliquidationTripsFilters,
        preliquidationTripsPagination.page,
        preliquidationTripsPagination.pageSize
      );
      setPreliquidationTrips(response);
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      const erroMessage = errorResponse ? errorResponse.Message : errorMessages.tryAgain;
      message.error(erroMessage);
    } finally {
      setLoadingPreliquidationTrips(false);
      setInitialLoading(false);
    }
  };

  const fetchPreliquidationDetailsData = async (preliquidationId: string) => {
    try {
      setLoadingPreliquidationDetails(true);
      const response = await getDetails(preliquidationId);
      setPreliquidationDetails(response);
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      const erroMessage = errorResponse ? errorMessages[errorResponse.Type] : errorMessages.tryAgain;
      message.error(erroMessage);
    } finally {
      setLoadingPreliquidationDetails(false);
      setInitialLoading(false);
    }
  };

  const fetchPreliquidationDiscountsData = async (preliquidationId: string) => {
    try {
      const response = await getDiscounts(preliquidationId);
      setPreliquidationDiscounts(response);
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      const erroMessage = errorResponse ? errorMessages[errorResponse.Type] : errorMessages.tryAgain;
      message.error(erroMessage);
    } finally {
      setLoadingPreliquidationDiscounts(false);
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    if (preliquidationId) {
      void fetchPreliquidationDiscountsData(preliquidationId);
    }
  }, [preliquidationId, preliquidationDiscountsUpdateTable]);

  useEffect(() => {
    if (preliquidationId) {
      void fetchPreliquidationDetailsData(preliquidationId);
    }
  }, [preliquidationId, preliquidationDetailsUpdateTable, preliquidationDiscountsUpdateTable]);

  useEffect(() => {
    if (preliquidationId) {
      void fetchPreliquidationTripsData(preliquidationId);
    }
  }, [preliquidationId, preliquidationTripsFilters, preliquidationTripsPagination, preliquidationTripsUpdateTable]);

  const handlePreliquidationTripsUpdateTable = ({
    preliquidationsFilters: preliquidationTripsFilters,
    page,
    pageSize,
  }: PreliquidationTripsFiltersParams) => {
    preliquidationTripsFilters && setPreliquidationTripsFilters(preliquidationTripsFilters);
    if (page && pageSize) {
      setPreliquidationTripsPagination({ page, pageSize });
    }
    setPreliquidationTripsUpdateTable((prev) => !prev);
  };

  const handlePreliquidationDiscountsUpdateTable = () => {
    setPreliquidationDiscountsUpdateTable((prev) => !prev);
  };

  const handlePreliquidationDetailsUpdateTable = () => {
    setPreliquidationDetailsUpdateTable((prev) => !prev);
  };

  return {
    handlePreliquidationTripsUpdateTable,
    handlePreliquidationDiscountsUpdateTable,
    handlePreliquidationDetailsUpdateTable,
    preliquidationTripsPagination,
  };
};

export default useGetStep2Data;
