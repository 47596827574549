import { useContext } from "react"

import { Table, Typography } from "antd"

import OtherPaymentsContext from "../context/OtherPaymentsContextProvider"

import { COLORS } from "../../../../constants/colors"

const { Paragraph, Text } = Typography

const OtherPaymentTable = () => {
  const { otherPayments, loading } = useContext(OtherPaymentsContext)

  return (
    <>
      <Paragraph strong>Resultados:</Paragraph>
      {
        otherPayments.length > 0 &&
        <>
          <Paragraph>Registros procesados: {otherPayments.filter(t => t.success).length}</Paragraph>
          <Paragraph>Registros no procesados: {otherPayments.filter(t => !t.success).length}</Paragraph>
        </>
      }
      <Table
      loading={loading}
      columns={[
        { title: 'Fila', dataIndex: 'row' },
        { title: 'ExternalId', dataIndex: 'externalId' },
        { title: 'ServiceTypeId', dataIndex: 'serviceTypeId' },
        {
          title: 'Resultado',
          dataIndex: 'message',
          render: (message, data) => {
            const colorAndStatus = data.success ? {color: COLORS.GREEN, status: 'Importado.'} : { color: COLORS.RED, status: data.message }
            return <Text style={{ color: colorAndStatus.color }} strong>{`${colorAndStatus.status}`}</Text>
          }
        }
      ]}
      dataSource={otherPayments.filter(t => !t.success).map((otherPayment, index) => {
        return {
          ...otherPayment,
          key: `${otherPayment.externalId}-${index}`
        }
      })}
    />
    </>
  )
}

export default OtherPaymentTable