import { useEffect, useState } from "react";
import { Rule } from "antd/es/form";
import {
  Form,
  InputNumber,
  Radio,
  Row,
  Col,
  Select,
  Typography,
  Input,
  DatePicker,
  Button,
  Upload,
  RadioChangeEvent,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";

import { TypeOfTransportersToBePaid } from "../../../../../constants/payments";

import useGetSelectableData from "../../hooks/useGetSelectableData";

import PaymentFormSkeleton from "../paymentFormSkeleton/PaymentFormSkeleton";

import "./payment-form.scss";

const { Title } = Typography;

interface SubmitPaymentForm {
  TypeOfTransportersToBePaid: string;
  amount: number;
  group: string;
  methodPayment: string;
  payId: string;
  paymentDate: moment.Moment;
  transporter: string;
  description?: string;
  sourceFunds?: number;
}

interface PaymentFormProps {
  hanldeSubmit: (values: any) => Promise<void>;
  amount?: number;
  payId?: string;
  paymentDate?: string;
  paymentMethodId?: number;
  sourceFunds?: string;
  description?: string;
  economicGroupId?: string | null;
  transportCompanyId?: string | null;
  document?: {
    id: string;
    fileName: string;
  };
  loadingData?: boolean;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  hanldeSubmit,
  amount,
  payId,
  paymentDate,
  paymentMethodId,
  sourceFunds,
  description,
  economicGroupId,
  transportCompanyId,
  document,
  loadingData,
}) => {
  const [finishing, setFinishing] = useState<boolean>(false);
  const [typeOfTransportersToBePaid, setTypeOfTransportersToBePaid] =
    useState<TypeOfTransportersToBePaid>(TypeOfTransportersToBePaid.Company);
  const { loading, economicGroups, transportCompanies, paymentMethods } =
    useGetSelectableData();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      amount: amount ?? null,
      payId: payId ?? null,
      paymentDate: paymentDate ? dayjs(paymentDate, "DD/MM/YYYY") : null,
      methodPayment: paymentMethodId ? Number(paymentMethodId) : null,
      sourceFunds: sourceFunds ?? null,
      description: description ?? null,
      transporter: transportCompanyId ?? null,
      group: economicGroupId ?? null,
      typeOfTransportersToBePaid: economicGroupId
        ? TypeOfTransportersToBePaid.Group
        : TypeOfTransportersToBePaid.Company,
    });
    setTypeOfTransportersToBePaid(
      economicGroupId
        ? TypeOfTransportersToBePaid.Group
        : TypeOfTransportersToBePaid.Company
    );
  }, [
    amount,
    payId,
    paymentDate,
    paymentMethodId,
    sourceFunds,
    description,
    economicGroupId,
    transportCompanyId,
    form,
  ]);

  if (loading || loadingData) {
    return <PaymentFormSkeleton />;
  }

  const onFinish = async (values: SubmitPaymentForm) => {
    setFinishing(true);
    const paymentDate = values.paymentDate
      ? values.paymentDate.format("YYYY-MM-DD")
      : "";
    const data = { ...values, paymentDate };
    await hanldeSubmit(data);
    setFinishing(false);
  };

  const hanldeChangeTypeOfTransportersToBePaid = (e: RadioChangeEvent) => {
    const { value } = e.target;
    const key =
      value === TypeOfTransportersToBePaid.Company ? "group" : "transporter";
    form.setFieldValue(key, undefined);
    setTypeOfTransportersToBePaid(value);
  };

  const checkFile = (_: Rule, value: any) => {
    if (value && value.length > 0) {
      const file = value[0].originFileObj;
      const acceptedExtensions = [".png", ".jpg", ".jpeg", ".pdf"];
      const fileExtension = file.name
        .slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2)
        .toLowerCase();

      const extensionIsValid = acceptedExtensions.includes("." + fileExtension);
      return extensionIsValid
        ? Promise.resolve()
        : Promise.reject(
            new Error("Debe adjuntar un archivo de tipo PNG, JPG o PDF")
          );
    }

    if (!value || value.length === 0) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error("Debe adjuntar un archivo de tipo PNG, JPG o PDF")
    );
  };

  return (
    <div className="payment-form">
      <Form
        disabled={finishing}
        onFinish={onFinish}
        layout={"vertical"}
        labelAlign="left"
        form={form}
        noValidate
      >
        <Title level={4}>
          Seleccione si desea imputar el viaje a una empresa o grupo
          transportista
        </Title>
        <Row gutter={[0, 24]}>
          <Form.Item
            name="typeOfTransportersToBePaid"
            rules={[{ required: true, message: "Debe completar este campo" }]}
          >
            <Radio.Group onChange={hanldeChangeTypeOfTransportersToBePaid}>
              <Radio value={TypeOfTransportersToBePaid.Company}>
                Empresa Transportista
              </Radio>
              <Radio value={TypeOfTransportersToBePaid.Group}>
                Grupo Transportista
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="transporter"
              label="Transportista"
              rules={[
                {
                  required:
                    typeOfTransportersToBePaid ===
                    TypeOfTransportersToBePaid.Company,
                  message: "Debe completar este campo",
                },
              ]}
            >
              <Select
                placeholder="Seleccione..."
                disabled={
                  loading ||
                  typeOfTransportersToBePaid !==
                    TypeOfTransportersToBePaid.Company
                }
                loading={loading}
                options={transportCompanies.map((transporter) => ({
                  value: transporter.id,
                  label: transporter.name,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="group"
              label="Grupo económico"
              rules={[
                {
                  required:
                    typeOfTransportersToBePaid ===
                    TypeOfTransportersToBePaid.Group,
                  message: "Debe completar este campo",
                },
              ]}
            >
              <Select
                placeholder="Seleccione..."
                disabled={
                  loading ||
                  typeOfTransportersToBePaid !==
                    TypeOfTransportersToBePaid.Group
                }
                loading={loading}
                options={economicGroups.map((economicGroup) => ({
                  value: economicGroup.id,
                  label: economicGroup.name,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Title level={4}>Ingrese los datos asociados al pago realizado:</Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="payId"
              label="Identificador de pago"
              rules={[
                { required: true, message: "Debe completar este campo" },
                {
                  max: 50,
                  message: "Este campo no puede tener mas de 50 caracteres",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="paymentDate"
              label="Fecha de pago"
              rules={[{ required: true, message: "Debe completar este campo" }]}
            >
              <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="amount"
              label="Monto"
              rules={[
                { required: true, message: "Debe completar este campo" },
                {
                  validator: (_, value) =>
                    value > 0
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error("El monto debe ser mayor a cero")
                        ),
                },
                {
                  pattern: /^\d+(\.\d{1,2})?$/,
                  message:
                    "El monto debe ser un número con un máximo de dos decimales",
                },
              ]}
            >
              <InputNumber
                type="number"
                precision={2}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="methodPayment"
              label="Medio de pago"
              rules={[{ required: true, message: "Debe completar este campo" }]}
            >
              <Select
                placeholder="Seleccione..."
                disabled={loading}
                loading={loading}
                options={paymentMethods.map((paymentMethod) => ({
                  value: paymentMethod.id,
                  label: paymentMethod.description,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              name="sourceFunds"
              label="Origen de los fondos"
              rules={[
                {
                  max: 70,
                  message:
                    "El origen de fondo no puede tener más de 70 caracteres",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Descripción"
              rules={[
                { pattern: /\S/, message: "Debe completar este campo" },
                {
                  max: 500,
                  message:
                    "La description no puede tener más de 500 caracteres",
                },
              ]}
            >
              <Input.TextArea rows={4} maxLength={500} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item
              name="document"
              label="Adjunto"
              rules={[{ validator: checkFile }]}
              extra="Seleccione un archivo"
              valuePropName="fileList"
              getValueFromEvent={(e) => e && e.fileList}
            >
              <Upload
                defaultFileList={
                  document
                    ? [
                        {
                          uid: document.id,
                          name: document.fileName,
                          status: "done",
                        },
                      ]
                    : []
                }
                maxCount={1}
                name="file"
                accept="image/png, image/jpeg, application/pdf"
                customRequest={({ file, onSuccess, onError }: any) => {
                  setTimeout(() => {
                    const acceptExtension = [
                      "image/png",
                      "image/jpeg",
                      "application/pdf",
                    ];
                    if (acceptExtension.includes(file.type)) {
                      onSuccess();
                    } else {
                      onError();
                    }
                  }, 0);
                }}
              >
                <Button type="primary" htmlType="button">
                  Comprobante
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: "right" }}
                loading={finishing}
                disabled={finishing || loading}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PaymentForm;
