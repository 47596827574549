import { useState, useContext } from "react";

import { Modal, message } from "antd";

import ManagePreliquidationContext from "../../contexts/ManagePreliquidationContextProvider";
import { errorMessages } from "../../../../../models/Errors/Errors";
import { rejectPreliquidation } from "../../../../../services/preliquidation.service";
import PreliquidationRejectionForm from "../PreliquidationRejectionForm/PreliquidationRejectionForm";
import { ErrorResponse } from "../../../../../models/Errors/ErrorResponse";

const PreliquidationRejectionModal = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const {
    isPreliquidationRejectionModalOpen,
    openRejectPreliquidationModal,
    closeRejectPreliquidationModal,
    selectedPreliquidation,
    handleManagePreliquidationsUpdateTable,
  } = useContext(ManagePreliquidationContext);

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await rejectPreliquidation(selectedPreliquidation?.id ?? "0", values.rejectionReason);
      handleManagePreliquidationsUpdateTable({});
      closeRejectPreliquidationModal();
      message.success("Preliquidación rechazada exitosamente");
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      const type = errorResponse.Type;
      const errorMessage = errorMessages[type] ?? errorMessages.tryAgain;
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={isPreliquidationRejectionModalOpen}
      onOk={openRejectPreliquidationModal}
      onCancel={closeRejectPreliquidationModal}
      footer={null}
      afterClose={() => setResetForm(!resetForm)}
    >
      <PreliquidationRejectionForm
        handleSubmit={onSubmit}
        handleCancel={closeRejectPreliquidationModal}
        isDisabled={loading}
        isLoading={loading}
        reset={resetForm}
      />
    </Modal>
  );
};
export default PreliquidationRejectionModal;
