import { useEffect, useState } from 'react'

interface UseDataProps<T> {
  service: (params?: any) => Promise<T>
  initialValue?: T
}

type UseDataResult<T> = [T | null, boolean, Error | unknown | null]

const useData = <T extends any>({ service, initialValue }: UseDataProps<T>): UseDataResult<T> => {
  const [data, setData] = useState<T | null>(initialValue ?? null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | unknown | null>(null)

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await service()
        setData(response)
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }

    getData()
  }, [])

  return [data, loading, error]
}

export default useData
