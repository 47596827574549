import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { Trip } from "../../../../models/Trips/Trip"
import { getDebinsByTripId, getTripById } from "../../../../services/trips.services"
import { Debin } from "../../../../models/Trips/Debin"

const usePaymentDetail = () => {
  const { id } = useParams()
  const [trip, setTrip] = useState<Trip>()
  const [loadingTrip, setLoadingTrip] = useState<boolean>(true)
  const [debins, setDebins] = useState<Debin[]>([])
  const [loadingDebins, setLoadingDebins] = useState<boolean>(true)

  const getData = () => {
    void getTripById(id as string)
      .then((data) => setTrip(data))
      .finally(() => setLoadingTrip(false))

    void getDebinsByTripId(id as string)
      .then((data) => setDebins(data))
      .finally(() => setLoadingDebins(false))
  }

  useEffect(() => {
    void getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    loadingTrip,
    trip,
    debins,
    loadingDebins
  }
}

export default usePaymentDetail