import { useEffect, useState } from "react";
import { getStates } from "../../../../services/preliquidation.service";
import { PreliquidationState } from "../../../../models/Preliquidation/PreliquidationState";

const useGetManagePreliquidationsSelectableData = () => {
  const [loadingFilters, setLoadingFilters] = useState<boolean>(true);

  const [preliquidationStates, setPreliquidationStates] = useState<PreliquidationState[]>([]);

  useEffect(() => {
    Promise.all([getStates()])
      .then((response) => {
        const [states] = response;
        setPreliquidationStates(states);
      })
      .finally(() => setLoadingFilters(false));
  }, []);

  return {
    loadingFilters,
    preliquidationStates,
  };
};

export default useGetManagePreliquidationsSelectableData;
