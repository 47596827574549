import { useState, useContext } from "react"

import { Modal, message } from "antd"

import PaymentApprovalForm from "../paymentApprovalForm/PaymentApprovalForm"

import PreliquidationContext from "../../contexts/ManagePaymentsContextProvider"
import { approvePayment } from "../../../../../services/payment.services"

import { errorMessages } from "../../../../../models/Errors/Errors"
import { ErrorResponse } from "../../../../../models/Errors/ErrorResponse"

const PaymentApprovalModal = () => {
  const {
    isPaymentApprovalModalOpen,
    openApprovedPaymentsModal,
    closeApprovedPaymentsModal,
    handleUpdateTable,
    selectedPayment
  } = useContext(PreliquidationContext)
  const [laoding, setLoading] = useState<boolean>(false)
  const [resetForm, setResetForm] = useState<boolean>(false)

  const handleSubmit = async (values: any) => {
    setLoading(true)
    try {
      await approvePayment({ paymentId: selectedPayment?.id ?? '', destinyId: values.paymentType, preliquidationId: values.preliquidationId })
      message.success('Pago aprobado')
      handleUpdateTable({})
      closeApprovedPaymentsModal()
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      message.error(errorMessages[errorResponse?.Type] ?? errorMessages.tryAgain)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      open={isPaymentApprovalModalOpen}
      onOk={openApprovedPaymentsModal}
      onCancel={closeApprovedPaymentsModal}
      footer={null}
      afterClose={() => setResetForm(!resetForm)} 
    >
      <PaymentApprovalForm
        handleOnSubmit={handleSubmit}
        handleClickCancel={closeApprovedPaymentsModal}
        isLoading={laoding}
        isDisabled={laoding}
        reset={resetForm}
        transportCompanyId={selectedPayment?.company?.id}
        economicGroupId={selectedPayment?.economicGroup.id}
      />
    </Modal>
  )
}

export default PaymentApprovalModal