export const toARSCurrency = (number: number | any) : string | null => {
  if (number === undefined || typeof number !== "number") {
    return null;
  }
  
  return number.toLocaleString('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 2
  });
};
