import { AutoComplete, Button, DatePicker, Form, Input, Modal, Select, Space } from 'antd';
import { BASSA_ADMIN, BASSA_OPERATOR, TRANSPORT_COMPANY } from '../../../constants/roles';
import { PrivateComponent } from '../../pages';
import ExcelIcon from '../../../assets/ExcelIcon';
import Title from "antd/es/typography/Title";
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { ApiDateFormat, exportExcel, getEndorsementStates, getInsuranceCompanyNames } from '../../../services/api.client';
import { getEconomicGroups } from '../../../services/group.services';
import { getTransportCompanyBusinessNames } from '../../../services/transportCompany.services';
import { EconomicGroup } from '../../../models/EconomicGroup';
import { EndorsementStateResponse } from '../../../models/EndorsementState';
import { saveObject } from '../../../services/storage.service';
import dayjs from 'dayjs';
import { deleteDuplicates } from '../../../utils/array.utils';
import { errorNotification } from '../notification/Notification';
import ExcelErrorIcon from '../../../assets/ExcelErrorIcon';
import { DefaultDateFormat } from '../../../models/Date';
import { TripTableFilters } from '../../../models/Trips/TripTableFilters';
import { CollectionState } from '../../../models/CollectionState/CollectionState';
import { getCollectionStates } from '../../../services/collectionState.services';
import './TripFilters.scss';

const { RangePicker } = DatePicker;

const milisecondsInOneDay = 1000 * 60 * 60 * 24;
const EXPORT_EXCEL_PAGE = 1;
const EXPORT_EXCEL_PAGE_SIZE = 10;

interface TripFiltersProps {
  getTripsFromApi: (pageNumber?: number) => void;
  setDepartureFrom: (value: any) => void;
  setDepartureTo: (value: any) => void;
  setInsuranceCompany: (value: any) => void;
  setEndorsementState: (value: any) => void;
  setEconomicGroup: (value: any) => void;
  setTransportCompany: (value: any) => void;
  setPaidAtFrom: (value: any) => void;
  setPaidAtTo: (value: any) => void;
  setCollectionState: (value: any) => void;
  setTripExternalId: (value: any) => void;
  setClosedAtFrom: (value: any) => void;
  setClosedAtTo: (value: any) => void;
  setEstimatedClosedAtFrom: (value: any) => void;
  setEstimatedClosedAtTo: (value: any) => void;
  setExportExcelLoading: (value: any) => void;
  setLoading: (value: any) => void;
  setEndorsmentNumber: (value: string | undefined) => void;
  loading: boolean;
  exportExcelLoading: boolean;
  endorsementState: any;
  collectionState: any;
  economicGroup: any;
  paidAtFrom: any;
  paidAtTo: any;
  closedAtTo: any;
  closedAtFrom: any;
  estimatedClosedAtFrom: any
  estimatedClosedAtTo: any
  insuranceCompany: any;
  transportCompany: any;
  departureFrom: any;
  departureTo: any;
  tripExternalId: any;
  page: any;
  endorsmentNumber: string | undefined;
}

const TripFilters = ({
  getTripsFromApi,
  setDepartureFrom,
  setDepartureTo,
  setInsuranceCompany,
  setEndorsementState,
  setEconomicGroup,
  setTransportCompany,
  setPaidAtFrom,
  setPaidAtTo,
  setCollectionState,
  setTripExternalId,
  setClosedAtFrom,
  setClosedAtTo,
  setEstimatedClosedAtFrom,
  setEstimatedClosedAtTo,
  setExportExcelLoading,
  setLoading,
  setEndorsmentNumber,
  loading,
  exportExcelLoading,
  endorsementState,
  collectionState,
  economicGroup,
  paidAtFrom,
  paidAtTo,
  closedAtTo,
  closedAtFrom,
  estimatedClosedAtFrom,
  estimatedClosedAtTo,
  insuranceCompany,
  transportCompany,
  departureFrom,
  departureTo,
  tripExternalId,
  page,
  endorsmentNumber
}: TripFiltersProps) => {
  const [form] = Form.useForm();
  const [endorsementStates, setEndorsementValues] = useState<EndorsementStateResponse[]>([]);
  const [collectionStates, setCollectionStates] = useState<CollectionState[]>([])
  const [economicGroups, setEconomicGroupValues] = useState<EconomicGroup[]>([]);
  const [insuranceCompanyNames, setInsuranceCompaniesName] = useState<string[]>([]);
  const [transportCompanyNames, setTransportCompaniesName] = useState<string[]>([]);
  const [modalExcelVisible, setModalExcelVisible] = useState<boolean>(false);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const getEndorsementStatesFromApi = async () => setEndorsementValues(await getEndorsementStates());

    const getCollectionStatesFromApi = async () => setCollectionStates(await getCollectionStates());
    const getEconomicGroupsFromApi = async () => setEconomicGroupValues(await getEconomicGroups());
    const getInsuranceCompanyNamesFromApi = async () => setInsuranceCompaniesName(deleteDuplicates(await getInsuranceCompanyNames()));
    const getTransportCompanyNamesFromApi = async () => setTransportCompaniesName(deleteDuplicates(await getTransportCompanyBusinessNames()));

    getEndorsementStatesFromApi();
    getCollectionStatesFromApi()
    getEconomicGroupsFromApi();
    getInsuranceCompanyNamesFromApi();
    getTransportCompanyNamesFromApi();
    getTripsFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    saveObject("filters", new TripTableFilters(
      endorsementState,
      collectionState,
      economicGroup,
      paidAtFrom,
      paidAtTo,
      closedAtTo,
      closedAtFrom,
      estimatedClosedAtFrom,
      estimatedClosedAtTo,
      insuranceCompany,
      transportCompany,
      departureFrom,
      departureTo,
      tripExternalId,
      page,
      endorsmentNumber
    ));
  }, [
    endorsementState, collectionState, economicGroup, paidAtFrom, paidAtTo, closedAtTo, closedAtFrom, estimatedClosedAtFrom, 
    estimatedClosedAtTo, insuranceCompany, transportCompany, departureFrom, departureTo, tripExternalId, page, endorsmentNumber
  ]);

  const getTripsIfEnter = (event: React.KeyboardEvent<HTMLElement>) => event.key === 'Enter' && !isSelectOpen && (getTripsFromApi(1), setIsSelectOpen(false));

  const clearFilters = () => {
    form.resetFields();
    setDepartureFrom(undefined);
    setDepartureTo(undefined);
    setCollectionState(undefined);
    setEndorsementState(undefined);
    setInsuranceCompany(undefined);
    setTripExternalId(undefined);
    setEconomicGroup(undefined);
    setTransportCompany(undefined);
    setPaidAtFrom(undefined);
    setPaidAtTo(undefined);
    setClosedAtFrom(undefined);
    setClosedAtTo(undefined);
    setEstimatedClosedAtFrom(undefined)
    setEstimatedClosedAtTo(undefined)
    setEndorsmentNumber(undefined)
  };

  const AreDateRangesForExcelExportValid = (): boolean => 
    isDateRangeWithIn31Days(departureFrom, departureTo) || 
    isDateRangeWithIn31Days(paidAtFrom, paidAtTo) || 
    isDateRangeWithIn31Days(closedAtFrom, closedAtTo) || 
    isDateRangeWithIn31Days(estimatedClosedAtFrom, estimatedClosedAtTo);

  const isDateRangeWithIn31Days = (from: Date, to: Date): boolean => {
    if (!to || !from) {
      return false;
    }

    const differenceInMs = new Date(to).getTime() - new Date(from).getTime();
    const differenceInDays = Math.round(differenceInMs / milisecondsInOneDay);
    return differenceInDays >= 0 && differenceInDays <= 31;
  };

  const exportExcelFromApi = async () => {
    if (AreDateRangesForExcelExportValid()) {
      setExportExcelLoading(true);

      try {
        await exportExcel(
          EXPORT_EXCEL_PAGE, 
          EXPORT_EXCEL_PAGE_SIZE,
          tripExternalId,
          departureFrom,
          departureTo,
          paidAtFrom,
          paidAtTo,
          closedAtFrom,
          closedAtTo,
          estimatedClosedAtFrom,
          estimatedClosedAtTo,
          endorsementState,
          collectionState,
          transportCompany,
          insuranceCompany,
          economicGroup
        );
      } catch (error) {
        errorNotification("Error al exportar datos de viajes");
      }

      setExportExcelLoading(false);
    } else {
      setModalExcelVisible(true);
    }
  };

  return (
    <div className="tripTableFilters">
      <div>
        <Title level={4}>Filtros de viaje</Title>
      </div>

      <Form form={form} layout="vertical">
        <Space wrap>
          <PrivateComponent roles={[BASSA_OPERATOR, BASSA_ADMIN, TRANSPORT_COMPANY]}>
            <Form.Item label="Id de viaje" name="tripExternalId">
              <Input
                placeholder="Id de viaje"
                type='number'
                defaultValue={tripExternalId}
                min={1}
                className='tripFilter'
                onChange={value => setTripExternalId(value?.target?.value)}
                onKeyUp={getTripsIfEnter}
                allowClear
              />
            </Form.Item>
          </PrivateComponent>
          <PrivateComponent roles={[BASSA_OPERATOR, BASSA_ADMIN, TRANSPORT_COMPANY]}>
            <Form.Item label="Fecha de cobro" name="paidAtFromTo">
              <RangePicker
                placeholder={["Desde", "Hasta"]}
                format={DefaultDateFormat}
                defaultValue={[
                  paidAtFrom && dayjs(paidAtFrom, ApiDateFormat),
                  paidAtTo && dayjs(paidAtTo, ApiDateFormat)
                ]}
                onChange={fromTo => {
                  setPaidAtFrom(fromTo?.[0]?.format(ApiDateFormat));
                  setPaidAtTo(fromTo?.[1]?.format(ApiDateFormat));
                }}    
                className='tripFilter'  
                allowEmpty={[true, true]}
                allowClear
              />
            </Form.Item>
          </PrivateComponent>
          <Form.Item label="Fecha de salida" name="departureFromTo">
            <RangePicker
              placeholder={["Desde", "Hasta"]}
              format={DefaultDateFormat}
              defaultValue={[
                departureFrom && dayjs(departureFrom, ApiDateFormat),
                departureTo && dayjs(departureTo, ApiDateFormat)
              ]}
              onChange={fromTo => {
                setDepartureFrom(fromTo?.[0]?.format(ApiDateFormat));
                setDepartureTo(fromTo?.[1]?.format(ApiDateFormat));
              }}    
              className='tripFilter'
              allowEmpty={[true, true]}
              allowClear
            />
          </Form.Item>
          <Form.Item label="Estado cobranza" name="collectionState">
            <Select
              placeholder="Estado cobranza"
              defaultValue={collectionState}
              options={collectionStates?.map(colleciontState => ({ label: colleciontState.description, value: colleciontState.id }))}
              onChange={option => setCollectionState(option)}
              onKeyUp={getTripsIfEnter}
              onMouseDown={e => { e.preventDefault(); e.stopPropagation(); }}
              onDropdownVisibleChange={(open) => setIsSelectOpen(open)}
              className='tripFilter'
              loading={loading}
              allowClear
            />
          </Form.Item>
          <PrivateComponent roles={[BASSA_OPERATOR, BASSA_ADMIN]}>
            <Form.Item label="Número de endoso" name="endorsmentNumber">
              <Input
                placeholder="Número de endoso"
                type='text'
                defaultValue={endorsmentNumber}
                className='tripFilter'
                onChange={value => setEndorsmentNumber(value?.target?.value)}
                onKeyUp={getTripsIfEnter}
                allowClear
              />
            </Form.Item>
          </PrivateComponent>
          <PrivateComponent roles={[BASSA_OPERATOR, BASSA_ADMIN]}>
            <Form.Item label="Estado endoso" name="endorsementState">
              <Select
                placeholder="Estado endoso"
                defaultValue={endorsementState}
                options={endorsementStates?.map(e => ({ label: e.description, value: e.id }))}
                onChange={option => setEndorsementState(option)}
                onKeyUp={getTripsIfEnter}
                onMouseDown={e => { e.preventDefault(); e.stopPropagation(); }}
                onDropdownVisibleChange={(open) => setIsSelectOpen(open)}
                className='tripFilter'
                loading={loading}
                allowClear
              />
            </Form.Item>
          </PrivateComponent>
          <PrivateComponent roles={[BASSA_OPERATOR, BASSA_ADMIN]}>
            <Form.Item label="Aseguradora" name="insuranceCompanyName">
              <AutoComplete
                placeholder="Aseguradora"
                defaultValue={insuranceCompany}
                options={insuranceCompanyNames?.map(name => ({ label: name, value: name }))}
                onChange={option => setInsuranceCompany(option)}
                onKeyUp={getTripsIfEnter}
                onDropdownVisibleChange={(open) => setIsSelectOpen(open)}
                className='tripFilter'
                filterOption
                allowClear
              />
            </Form.Item>
          </PrivateComponent>
          <PrivateComponent roles={[BASSA_OPERATOR, BASSA_ADMIN]}>
            <Form.Item label="Grupo económico" name="economicGroup">
              <Select
                placeholder="Grupo económico"
                defaultValue={economicGroup}
                options={economicGroups?.map(e => ({ label: e.name, value: e.id }))}
                className='tripFilter'
                onChange={option => setEconomicGroup(option)}
                onKeyUp={getTripsIfEnter}
                onMouseDown={e => { e.preventDefault(); e.stopPropagation(); }}
                onDropdownVisibleChange={(open) => setIsSelectOpen(open)}
                loading={loading}
                allowClear
              />
            </Form.Item>
          </PrivateComponent>
          <PrivateComponent roles={[BASSA_OPERATOR, BASSA_ADMIN]}>
            <Form.Item label="Compañia transportista" name="transportCompanyName">
              <AutoComplete
                placeholder="Compañia transportista"
                defaultValue={transportCompany}
                options={transportCompanyNames.map(name => ({ label: name, value: name }))}
                className='tripFilter'
                onChange={option => setTransportCompany(option)}
                onKeyUp={getTripsIfEnter}
                onDropdownVisibleChange={(open) => setIsSelectOpen(open)}
                filterOption
                allowClear
              />
            </Form.Item>
          </PrivateComponent>
          <PrivateComponent roles={[BASSA_OPERATOR, BASSA_ADMIN, TRANSPORT_COMPANY]}>
            <Form.Item label="Fecha cierre" name="closedAtFromTo">
              <RangePicker
                placeholder={["Desde", "Hasta"]}
                format={DefaultDateFormat}
                defaultValue={[
                  closedAtFrom && dayjs(closedAtFrom, ApiDateFormat),
                  closedAtTo && dayjs(closedAtTo, ApiDateFormat)
                ]}
                onChange={fromTo => {
                  setClosedAtFrom(fromTo?.[0]?.format(ApiDateFormat));
                  setClosedAtTo(fromTo?.[1]?.format(ApiDateFormat));
                }}    
                className='tripFilter'  
                allowEmpty={[true, true]}
                allowClear
              />
            </Form.Item>
          </PrivateComponent>
          <PrivateComponent roles={[BASSA_OPERATOR, BASSA_ADMIN, TRANSPORT_COMPANY]}>
            <Form.Item label="Cierre Estimado" name="estimatedAtFromTo">
              <RangePicker
                placeholder={["Desde", "Hasta"]}
                format={DefaultDateFormat}
                defaultValue={[
                  estimatedClosedAtFrom && dayjs(estimatedClosedAtFrom, ApiDateFormat),
                  estimatedClosedAtTo && dayjs(estimatedClosedAtTo, ApiDateFormat)
                ]}
                onChange={fromTo => {
                  setEstimatedClosedAtFrom(fromTo?.[0]?.format(ApiDateFormat));
                  setEstimatedClosedAtTo(fromTo?.[1]?.format(ApiDateFormat));
                }}
                className='tripFilter'  
                allowEmpty={[true, true]}
                allowClear
              />
            </Form.Item>
          </PrivateComponent>
        </Space>
      </Form>

      <div className="buttonContainer">
        <div>
          <Button
            type="primary"
            style={{ width: 100 }}
            onClick={() => getTripsFromApi(1)}
            loading={loading}
            disabled={exportExcelLoading}
            icon={<SearchOutlined />}
            className="startButton"
          >
            Buscar
          </Button>
          <Button
            type="default"
            style={{ width: 100 }}
            onClick={() => clearFilters()}
            loading={loading}
            disabled={exportExcelLoading}
            className="startButton"
            icon={<ClearOutlined />}
          >
            Limpiar
          </Button>
        </div>
        <Button
          className="excelButtonTravels"
          onClick={exportExcelFromApi}
          loading={exportExcelLoading}
          disabled={loading}
          icon={<ExcelIcon />}
        >
          Exportar
        </Button>
      </div>

      <Modal
        open={modalExcelVisible}
        closable={false}
        maskClosable={true}
        onCancel={() => setModalExcelVisible(false)}
        footer={[
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "45%" }}>
              <Button
                key="back"
                onClick={() => setModalExcelVisible(false)}
                block
              >
                Cancelar
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className="divDeleteModal">
          <ExcelErrorIcon />
          <p className="textDeleteModal">
            Debe ingresar un rango de fecha de viaje (desde - hasta) o un rango de fecha de cobro (desde - hasta) o fecha de cierre o fecha de cierre estimado menor a 31 días
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default TripFilters;
