import { Skeleton, Table } from 'antd';
import { generateRandomString } from '../../../utils/generateRandomString.utils';

interface SkeletonTableProps {
  numberOfColumns?: number
  numberOfRows?: number
}

const SkeletonTable: React.FC<SkeletonTableProps> = ({ numberOfColumns = 5, numberOfRows = 5 }) => {
  return (
    <Table
      dataSource={Array(numberOfRows).fill({}).map((d, i) => ({ ...d, key: i}))}
      pagination={false}
      loading={false}
      showHeader
      columns={
        Array(numberOfColumns).fill({
          title: <Skeleton paragraph={false} active={true} style={{ width: 100 }}/>,
          dataIndex: generateRandomString(10),
          key: generateRandomString(10),
          render: () => <Skeleton paragraph={false} active={true} style={{ width: 100 }}/>
        })
      }
    />
  );
};

export default SkeletonTable
