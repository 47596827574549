import React, { useContext, useEffect } from "react";
import SkeletonFilters from "../skeletonFilters/SkeletonFilters";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { ClearOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { DefaultDateFormat } from "../../../../../models/Date";
import { useForm } from "antd/es/form/Form";

import ManagePreliquidationContext from "../../contexts/ManagePreliquidationContextProvider";
import useGetManagePreliquidationsSelectableData from "../../hooks/useGetManagePreliquidationsSelectableData";
import { useNavigate } from "react-router-dom";

const ManagePreliquidationsFilters: React.FC = () => {
  const [form] = useForm();

  const navigate = useNavigate();

  const { RangePicker } = DatePicker;

  const {
    loadingPreliquidations: loading,
    setLoadingPreliquidations: setLoading,
    handleManagePreliquidationsUpdateTable,
  } = useContext(ManagePreliquidationContext);

  const { preliquidationStates } = useGetManagePreliquidationsSelectableData();

  const onFilter = (values: any) => {
    handleManagePreliquidationsUpdateTable({
      preliquidationsFilters: values,
      page: 1,
      pageSize: 10,
    });
  };

  const handleReset = () => {
    form.resetFields();
    form.setFieldsValue({ collectionState: null });
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const dateValidator = (_: any, value: any[]) => {
    if (value && value[0] && value[1] && value[0].isAfter(value[1])) {
      return Promise.reject(new Error("La fecha 'Desde' no puede ser mayor que la fecha 'Hasta'"));
    }
    return Promise.resolve();
  };

  if (loading) {
    return <SkeletonFilters />;
  }

  return (
    <>
      <Button type="primary" onClick={() => navigate("/preliquidations")} icon={<PlusOutlined />} size="large">
        Agregar preliquidación
      </Button>
      <div className="manage-preliquidations-filters layer">
        <Form
          form={form}
          name="managePreliquidationFiltersForm"
          layout="vertical"
          className="preliquidation-filters__form"
          onFinish={onFilter}
        >
          <Row gutter={[16, 16]} justify={"start"} align={"bottom"}>
            <Col span={3}>
              <Form.Item
                label="Id de Preliquidación"
                name="preliquidationId"
                rules={[
                  {
                    validator: (_, value) =>
                      value > 0 || !value
                        ? Promise.resolve()
                        : Promise.reject(new Error("El id de preliquidación no puede ser un número negativo")),
                  },
                ]}
              >
                <Input
                  placeholder="Id de preliquidación"
                  type="number"
                  className="preliquidation-filters__field"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Fecha Preliquidación" name="createdAt">
                <DatePicker style={{ width: "100%" }} format={DefaultDateFormat} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Estado" name="preliquidationStatus" initialValue={null}>
                <Select
                  placeholder="Seleccione..."
                  options={[{ description: "Todos", id: null }, ...preliquidationStates]?.map((status) => ({
                    label: status.description,
                    value: status.id,
                  }))}
                  loading={loading}
                  disabled={loading}
                  className="preliquidation-filters__field"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Fecha de cierre"
                name="closedDate"
                dependencies={["closedDate"]}
                rules={[{ validator: dateValidator }]}
              >
                <RangePicker
                  placeholder={["Desde", "Hasta"]}
                  format={DefaultDateFormat}
                  allowEmpty={[true, true]}
                  allowClear
                  className="preliquidation-filters__field--xxl"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col>
              <Form.Item>
                <Button
                  type="default"
                  htmlType="button"
                  form={"managePreliquidationFiltersForm"}
                  icon={<ClearOutlined />}
                  onClick={handleReset}
                >
                  Limpiar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  form={"managePreliquidationFiltersForm"}
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Buscar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default ManagePreliquidationsFilters;
