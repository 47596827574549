import React, { useCallback, useEffect, useState } from 'react'

import { message } from 'antd'

import {
  PaginatedPayments,
  ManagePaymentsFilters,
  ManagePaymentsFiltersParams,
  Payment
} from '../../../../models/Payment/Payment'

import { PaymentIds } from '../../../../constants/payments'

import { getPayments } from '../../../../services/payment.services'

interface ManagePaymentsContextState {
  loading: boolean
  initialLoading: boolean
  handleUpdateTable: ({ managePaymentsFilters, page, pageSize }: ManagePaymentsFiltersParams) => void
  payments: PaginatedPayments | null
  isPaymentApprovalModalOpen: boolean
  isPaymentRejectionModalOpen: boolean
  isUpdatePaymentModalOpen: boolean
  openApprovedPaymentsModal: () => void
  closeApprovedPaymentsModal: () => void
  openRejectedPaymentsModal: () => void
  closeRejectedPaymentsModal: () => void
  openUpdatePaymentModal: () => void
  closeUpdatePaymentModal: () => void
  selectedPayment?: Payment
  handleSelectPayment: (payment: Payment) => void
}

const initialManagePaymentsContextState: ManagePaymentsContextState = {
  loading: true,
  handleUpdateTable: () => {},
  payments: null,
  initialLoading: true,
  isPaymentApprovalModalOpen: false,
  isPaymentRejectionModalOpen: false,
  isUpdatePaymentModalOpen: false,
  openApprovedPaymentsModal: () => {},
  closeApprovedPaymentsModal: () => {},
  openRejectedPaymentsModal: () => {},
  closeRejectedPaymentsModal: () => {},
  openUpdatePaymentModal: () => {},
  closeUpdatePaymentModal: () => {},
  handleSelectPayment: (payment: Payment) => {}
}

const ManagePaymentsContext = React.createContext<ManagePaymentsContextState>(initialManagePaymentsContextState)

interface ManagePaymentsContextProviderProps {
  children: React.ReactNode | React.ReactNode[]
}


export const ManagePaymentsContextProvider = ({ children }: ManagePaymentsContextProviderProps) => {
  const [filters, setFilters] = useState<ManagePaymentsFilters>({ paymentStatus: PaymentIds.Pending })
  const [pagination, setPagination] = useState<{ page: number, pageSize: number }>({ page: 1, pageSize: 10 })
  const [payments, setPayments] = useState<PaginatedPayments | null>(initialManagePaymentsContextState.payments)
  const [initialLoading, setInitialLoading] = useState<boolean>(initialManagePaymentsContextState.initialLoading)
  const [loading, setLoading] = useState<boolean>(initialManagePaymentsContextState.loading)
  const [selectedPayment, setSelectedPayments] = useState<Payment>()
  const [updateTable, setUpdateTable] = useState<boolean>(false)

  const [isPaymentApprovalModalOpen, setIsPaymentApprovalModalOpen] = useState<boolean>(initialManagePaymentsContextState.isPaymentApprovalModalOpen)
  const [isPaymentRejectionModalOpen, setIsPaymentRejectionModalOpen] = useState<boolean>(initialManagePaymentsContextState.isPaymentRejectionModalOpen)
  const [isUpdatePaymentModalOpen, setIsUpdatePaymentModalOpen] = useState<boolean>(initialManagePaymentsContextState.isUpdatePaymentModalOpen)

  const handleUpdateTable = ({ managePaymentsFilters, page, pageSize }: ManagePaymentsFiltersParams) => {
    managePaymentsFilters && setFilters(managePaymentsFilters)
    if (page && pageSize) {
      setPagination({ page, pageSize })
    }
    setUpdateTable(prev => !prev)
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await getPayments({
        managePaymentsFilters: filters,
        page: pagination.page,
        pageSize: pagination.pageSize
      })
      setPayments(response)
    } catch (e) {
      message.error('error')
    } finally {
      setLoading(false)
      setInitialLoading(false)
    }
  }

  useEffect(() => {
    void fetchData()
  }, [filters, pagination.page, pagination.pageSize, updateTable])

  const openApprovedPaymentsModal = useCallback(() => setIsPaymentApprovalModalOpen(true), [])
  const closeApprovedPaymentsModal = useCallback(() => setIsPaymentApprovalModalOpen(false), [])
  const openRejectedPaymentsModal = useCallback(() => setIsPaymentRejectionModalOpen(true), [])
  const closeRejectedPaymentsModal = useCallback(() => setIsPaymentRejectionModalOpen(false), [])
  const openUpdatePaymentModal = useCallback(() => setIsUpdatePaymentModalOpen(true), [])
  const closeUpdatePaymentModal = useCallback(() => setIsUpdatePaymentModalOpen(false), [])

  const handleSelectPayment = (payment: Payment) => setSelectedPayments(payment)
  
  return (
    <ManagePaymentsContext.Provider 
      value={{
        loading,
        initialLoading,
        handleUpdateTable,
        payments,
        isPaymentApprovalModalOpen,
        isPaymentRejectionModalOpen,
        isUpdatePaymentModalOpen,
        openApprovedPaymentsModal,
        closeApprovedPaymentsModal,
        openRejectedPaymentsModal,
        closeRejectedPaymentsModal,
        openUpdatePaymentModal,
        closeUpdatePaymentModal,
        selectedPayment,
        handleSelectPayment
      }}
    >
      {children}
    </ManagePaymentsContext.Provider>
  )
}

export default ManagePaymentsContext
