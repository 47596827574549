import { useEffect, useState } from "react";
import { LeftOutlined, MenuOutlined, PlusOutlined } from "@ant-design/icons";
import {
	Alert,
	Button,
	Card,
	Checkbox,
	Col,
	Form,
	Input,
	Layout,
	Modal,
	Row,
	Skeleton,
	Typography,
} from "antd";
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import {
	updateTransportCompanyBankAccountsOrder,
	createBankAccount,
	getTransportCompanyBankAccounts,
	updateBankAccountActiveness,
} from "../../../services/api.client";
import {
	errorNotification,
	successNotification,
} from "../../atoms/notification/Notification";
import { ErrorResponse } from "../../../models/Errors/ErrorResponse";
import { getMessageErrors } from "../../../models/Errors/Errors";
import "./bankAccount.scss";
import { useNavigate } from "react-router-dom";
import WarningAccountModal from "../../shared/warningAccountModal/WarningAccountModal";
import { useDispatch, useSelector } from "react-redux";
import {
	openWarningAccountModal,
	updateData as FUpdateData,
} from "../../../store/reducers/warningAccountModalSlice";
import { RootState } from "../../../store/store";

const { Content } = Layout;
const { Title } = Typography;

const BankAccounts = () => {
	const [bankAccounts, setBankAccounts] = useState<[] | any>([]);
	const [loading, setLoading] = useState(true);
	const [processing, setProcessing] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const updateData = useSelector(
		(state: RootState) => state.warningAccountModal.updateData,
	);
	const navigate = useNavigate();

	useEffect(() => {
		if (updateData) {
			dispatch(FUpdateData(false));
			getBankAccountsFromApi();
			successNotification("Cuenta bancaria agregada.");
		}
	}, [updateData]);

	const onSortEnd = async ({ oldIndex, newIndex }: any) => {
		if (oldIndex !== newIndex) {
			setProcessing(true);
			const _currentArray = [...bankAccounts];
			const newArray = arrayMoveImmutable(bankAccounts, oldIndex, newIndex);
			newArray.forEach(
				(bankAccount: any, index: number) => (bankAccount.order = index + 1),
			);
			setBankAccounts(newArray);

			try {
				await updateTransportCompanyBankAccountsOrder(newArray);
				getBankAccountsFromApi();
				successNotification("Orden cambiado");
			} catch (error: any) {
				const errorResponse: ErrorResponse = error.response?.data;
				errorNotification(getMessageErrors(errorResponse.Type));
				setBankAccounts(_currentArray);
				getBankAccountsFromApi();
			}

			setProcessing(false);
		}
	};

	const changeActive = async (value: any, id: string) => {
		setProcessing(true);

		try {
			await updateBankAccountActiveness(id, value.checked);
			successNotification(
				`Cuenta bancaria ${value.checked ? "activada" : "desactivada"}!`,
			);
			getBankAccountsFromApi();
		} catch (error: any) {
			const errorResponse: ErrorResponse = error.response?.data;
			errorNotification(getMessageErrors(errorResponse.Type));
		}

		setProcessing(false);
	};

	const addBankAccount = async () => {
		const values = await form.validateFields();

		if (!values.errorFields) {
			setShowModal(false);
			setProcessing(true);

			try {
				await createBankAccount(values);
				getBankAccountsFromApi();
				successNotification("Cuenta bancaria agregada.");
			} catch (error: any) {
				const errorResponse: ErrorResponse = error.response?.data;
				if (
					errorResponse.Type === "CompanyCuitDoesNotMatchWithBankAccountCuit"
				) {
					dispatch(
						openWarningAccountModal({
							data: values,
							typeRegister: "addBankAccount",
						}),
					);
					return;
				}
				errorNotification(getMessageErrors(errorResponse.Type));
			}

			form.resetFields();
			setProcessing(false);
		}
	};

	const getBankAccountsFromApi = async () => {
		try {
			setBankAccounts(await getTransportCompanyBankAccounts());
		} catch (error) {
			errorNotification(
				"No se ha podido realizar su solicitud, por favor inténtelo de nuevo más tarde",
			);
		}

		setLoading(false);
		setProcessing(false);
	};

	const SortableItem = SortableElement<any>(({ bankAccount }: any) => {
		return (
			<Card className="bankAccount" style={{ opacity: processing ? 0.5 : 1 }}>
				<div className="card">
					<div style={{ width: "10%" }}>
						<MenuOutlined />
					</div>
					<div className="dataCard">
						<Typography>CBU: {bankAccount.cbu}</Typography>
						<Typography>CUIT: {bankAccount.documentNumber}</Typography>
					</div>
					<div style={{ width: "10%" }}>
						<Checkbox
							checked={bankAccount.isActive}
							onChange={({ target }) => changeActive(target, bankAccount.id)}
						/>
					</div>
				</div>
			</Card>
		);
	});

	const SortableList = SortableContainer<any>(({ bankAccounts }: any) => (
		<Col>
			{bankAccounts.map((bankAccount: any, index: any) => (
				<SortableItem
					index={index}
					bankAccount={bankAccount}
					key={bankAccount.id}
					disabled={processing}
				/>
			))}
		</Col>
	));

	useEffect(() => {
		setLoading(true);
		getBankAccountsFromApi();
	}, []);

	return (
		<>
			<Layout>
				<Content className="bankAccountsContent">
					<div style={{ marginBottom: 30 }}>
						<Button ghost onClick={() => navigate(-1)}>
							<Title level={2}>
								<LeftOutlined /> Cuentas bancarias
							</Title>
						</Button>
					</div>

					<div className="bankAccountList">
						<Col>
							<Alert
								description={
									<p>
										A continuación podrá modificar el orden en el cual se
										intentará realizar el cobro correspondiente al Seguro de
										Responsabilidad Civil.
										<p /> Para modificarlo, deberá arrastrar y soltar la cuenta
										en el orden deseado.
									</p>
								}
								showIcon
							/>
						</Col>

						<Button
							type="primary"
							onClick={() => setShowModal(true)}
							icon={<PlusOutlined />}
							style={{ margin: "20px 0px 20px 0px" }}
							size="large"
						>
							Agregar Cuenta
						</Button>

						<Row>
							<Col span={24}>
								<Skeleton loading={loading}>
									<SortableList
										bankAccounts={bankAccounts}
										onSortEnd={onSortEnd}
									/>
								</Skeleton>
							</Col>
						</Row>
					</div>

					<Modal
						title="Agregar cuenta"
						style={{ height: "50%" }}
						footer={null}
						open={showModal}
						onCancel={() => {
							setShowModal(false);
							form.resetFields();
						}}
					>
						<Form name="basic" form={form}>
							<Form.Item
								labelCol={{ style: { width: 50 } }}
								label="CBU"
								name="cbu"
								rules={[
									{
										required: true,
										type: "string",
										message: "Es necesario un CBU",
									},
									{
										pattern: /^(?:\d*)$/,
										message: "El CBU debe ser un número",
									},
									{
										pattern: /^[\d]{22}$/,
										message: "El CBU debe tener 22 dígitos",
									},
								]}
							>
								<Input style={{ width: "60%" }} maxLength={22} />
							</Form.Item>
							<Form.Item
								labelCol={{ style: { width: 50 } }}
								label="CUIT"
								name="documentNumber"
								rules={[
									{
										required: true,
										message: "Es necesario un CUIT",
									},
									{
										pattern: /^(?:\d*)$/,
										message: "El CUIT debe ser numérico",
									},
									{
										pattern: /^[\d]{11}$/,
										message: "El CUIT debe contener 11 dígitos",
									},
								]}
							>
								<Input style={{ width: "60%" }} maxLength={11} />
							</Form.Item>
							<Form.Item style={{ margin: 0 }}>
								<Button
									type="primary"
									onClick={addBankAccount}
									style={{ marginTop: "10px" }}
								>
									Agregar
								</Button>
							</Form.Item>
						</Form>
					</Modal>
				</Content>
			</Layout>
			<WarningAccountModal />
		</>
	);
};

export default BankAccounts;
