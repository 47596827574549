import { Skeleton } from 'antd'

import './tripCertificateSkeleton.scss'

const TripCertificateSkeleton = () => {
  return (
    <div className="trip-certificate-skeleton">
      <Skeleton.Avatar active={true} shape='square' className='trip-certificate-skeleton__logo'/>
      <Skeleton active={true} paragraph={false} className='trip-certificate-skeleton__title'/>
      <Skeleton active={true} className="trip-certificate-skeleton__paragraph"/>
      
      <div className='trip-certificate__section-space-between'>
        <Skeleton active={true} paragraph={false} className='trip-certificate-skeleton__text' />
        <Skeleton active={true} paragraph={false} className='trip-certificate-skeleton__text' />
      </div>
      <Skeleton active={true} paragraph={false} className='trip-certificate-skeleton__text' />
      <Skeleton active={true} paragraph={false} className='trip-certificate-skeleton__text' />

      <Skeleton active={true} className="trip-certificate-skeleton__paragraph"/>

      <Skeleton active={true} paragraph={false} className='trip-certificate-skeleton__title--large'/>
      <div className='trip-certificate__section-space-between'>
        <Skeleton active={true} paragraph={false} className='trip-certificate-skeleton__text' />
        <Skeleton active={true} paragraph={false} className='trip-certificate-skeleton__text' />
      </div>
      <div className='trip-certificate__section-space-between'>
        <Skeleton active={true} paragraph={false} className='trip-certificate-skeleton__text' />
        <Skeleton active={true} paragraph={false} className='trip-certificate-skeleton__text' />
      </div>
      <div className='trip-certificate__section-space-between'>
        <Skeleton active={true} paragraph={false} className='trip-certificate-skeleton__text' />
        <Skeleton active={true} paragraph={false} className='trip-certificate-skeleton__text' />
      </div>

      <Skeleton active={true} className="trip-certificate-skeleton__paragraph"/>
      <Skeleton active={true} className="trip-certificate-skeleton__paragraph"/>

      <div className='trip-certificate-skeleton__footer'>
        <Skeleton.Avatar active={true} shape='square' className="trip-certificate-skeleton__signature"/>
        <Skeleton.Avatar active={true} shape='square' className="trip-certificate-skeleton__signature"/>
      </div>
    </div>
  )
}

export default TripCertificateSkeleton