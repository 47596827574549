import { useEffect } from "react";

import { Button, Col, Form, Input, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";

interface PreliquidationRejectionFormProps {
  handleSubmit: (values: any) => Promise<void>;
  handleCancel?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  reset?: boolean;
}

const PreliquidationRejectionForm: React.FC<PreliquidationRejectionFormProps> = ({
  handleSubmit,
  handleCancel,
  isDisabled,
  isLoading,
  reset,
}) => {
  const [form] = useForm();

  const onFinish = async (values: any) => {
    await handleSubmit({ ...values, rejectionReason: values.rejectionReason.trim() });
  };

  useEffect(() => {
    reset !== undefined && form.resetFields();
  }, [reset]);

  return (
    <Form layout="vertical" onFinish={onFinish} disabled={isDisabled} form={form}>
      <Form.Item
        label={"Motivo del rechazo:"}
        name={"rejectionReason"}
        rules={[
          { required: true, message: "Debe completar este campo" },
          { pattern: /\S/, message: "Debe completar este campo" },
          { max: 500, message: "El motivo del rechazo no puede tener más de 500 caracteres" },
        ]}
      >
        <Input.TextArea rows={4} maxLength={500} />
      </Form.Item>

      <Row gutter={[16, 16]}>
        {handleCancel ? (
          <Col>
            <Form.Item>
              <Button type="default" onClick={handleCancel}>
                Cancelar
              </Button>
            </Form.Item>
          </Col>
        ) : null}
        <Col>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isDisabled} loading={isLoading}>
              Guardar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PreliquidationRejectionForm;
