import moment from 'moment';
import { DefaultDateFormat, DefaultTimeFormat } from '../models/Date';

export const customDateFormat = (date: string): string => {
  const customFormat = 'DD [del mes de] MMMM [de] YYYY [siendo las] HH:mm [hs]'
  const formattedDate = moment(date, `${DefaultDateFormat} ${DefaultTimeFormat}`).format(customFormat)
  return formattedDate
}

export const dateFormat = (date: string): string => {
  const formattedDate = moment(date).format(`${DefaultDateFormat} ${DefaultTimeFormat}`)
  return formattedDate
}

export const dateFormatWithoutTime = (date: string): string => {
  const formattedDate = moment(date).format(DefaultDateFormat)
  return formattedDate
}

export const parseDate = (dateString: string): number => {
  const [day, month, year] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day).getTime();
};