import { createSlice } from '@reduxjs/toolkit'

export interface ModalState {
  isOpen: boolean
  data: any
  typeRegister: 'partial' | 'total' | 'addBankAccount'
  updateData: boolean
}

const initialState: ModalState = {
  isOpen: false,
  data: {},
  typeRegister: 'partial',
  updateData: false
}

export const warningAccountModal = createSlice({
  name: 'warningAccountModal',
  initialState,
  reducers: {
    openWarningAccountModal: (state, payload) => {
      const { data, typeRegister } = payload.payload
      state.data = data
      state.typeRegister = typeRegister
      state.isOpen = true
    },
    closeWarningAccountModal: (state) => {
      state.isOpen = false
    },
    updateData: (state, payload) => {
      state.updateData = payload.payload
    }
  }
});

export const { openWarningAccountModal, closeWarningAccountModal, updateData } = warningAccountModal.actions

export default warningAccountModal.reducer
