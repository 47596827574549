import React, { useState } from 'react'

interface BankAccountStatusFilters {
  transporter?: string
}

interface BankAccountStatusContextState {
  filters: BankAccountStatusFilters
  handleChangeFilters: (filters: BankAccountStatusFilters) => void
}

const initialBankAccountStatusContextState: BankAccountStatusContextState = {
  filters: { transporter: undefined },
  handleChangeFilters: () => {}
}

const BankAccountStatusContext = React.createContext<BankAccountStatusContextState>(initialBankAccountStatusContextState)

interface BankAccountStatusContextProviderProps {
  children: React.ReactNode | React.ReactNode[]
}


export const BankAccountStatusContextProvider = ({ children }: BankAccountStatusContextProviderProps) => {
  const [filters, setFilters] = useState<BankAccountStatusFilters>(initialBankAccountStatusContextState.filters)

  const handleChangeFilters = (values: BankAccountStatusFilters) => {
    setFilters({
      ...filters,
      ...values
    })
  }

  return (
    <BankAccountStatusContext.Provider 
      value={{
        filters,
        handleChangeFilters
      }}
    >
      {children}
    </BankAccountStatusContext.Provider>
  )
}

export default BankAccountStatusContext
