import { useContext } from "react"
import { SearchOutlined } from "@ant-design/icons"
import { Button, Form, Select, Space, Typography } from "antd"

import BankAccountStatusContext from "../../context/bankAccountStatusContextProvider"
import useBankAccountStatusFilters from "../../hooks/useBankAccountStatusFilters"
import BankAccountStatusFiltersSkeleton from "../bankAccountStatusFiltersSkeleton/BankAccountStatusFiltersSkeleton"

interface FormValues {
  transporter: string
}

const { Paragraph } = Typography

const BankAccountStatutsFilters = () => {
  const { handleChangeFilters } = useContext(BankAccountStatusContext)
  const { transporters, loading } = useBankAccountStatusFilters()

  const onFinish = (values: FormValues) => {
    handleChangeFilters({ 
      transporter: values.transporter
    })
  }

  if (loading) {
    return (
      <BankAccountStatusFiltersSkeleton />
    )
  }

  return (
    <div className="layer">
      <Form
        name="bankAccountFiltersForm"
        onFinish={onFinish}
      >
        <Paragraph>Seleccione la empresa transportista</Paragraph>
        <Space wrap>
          <Form.Item
            name="transporter"
            label="Transportista"
            colon={false}
          >
            <Select
              placeholder="Seleccione..."
              style={{ width: '215px' }}
              loading={loading}
              disabled={loading}
              options={transporters.map(transporter => ({ label: transporter.name, value: transporter.id }))}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
              disabled={loading}
            >
              Buscar
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  )
}

export default BankAccountStatutsFilters