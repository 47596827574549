import {
  ConfirmEmail,
  Login,
  PasswordChange,
  Register,
  TermsAndConditions,
  Trips,
  TripDetail,
  BankAccounts,
  RegisterResult,
  Rates,
  Payments,
  TripAnnulment,
  TripCertificates,
  TripCertificateNotFound,
  PaymentDetail,
  Users,
  BankAccountStatus,
  ManagePayments,
  CheckingAccount,
  EndorsementAnnulment
} from "../components/pages";
import { ALL_ROLES, BASSA_ADMIN, Role, TRANSPORT_COMPANY, BASSA_OPERATOR } from "./roles";
import DebinDetail from "../components/pages/debinDetail/DebinDetail";
import Landing from "../components/pages/landing/Landing";
import ResendEmailConfirmCode from "../components/pages/resendEmailConfirmCode/ResendEmailConfirmCode";
import SuccessEmailConfirmation from "../components/atoms/SuccessEmailConfirmation/SuccessEmailConfirmation";
import OtherPayments from "../components/pages/otherPayments/OtherPayments";
import Protection from "../components/pages/protection/Protection";
import Preliquidations from "../components/pages/preliquidations/Preliquidations";
import ManagePreliquidations from "../components/pages/preliquidations/ManagePreliquidations";
import EndorsementRejection from "../components/pages/endorsementRejection/EndorsementRejection";
import EndorsementPayment from "../components/pages/endorsementPayment/EndorsementPayment";

class Route {
  constructor(
    public path: string,
    public component: JSX.Element,
    public roles: Role[] = [],
    public dataRoute: Function = () => path
  ) {}
}

export const landing = new Route("/", <Landing />);
export const login = new Route("/login", <Login />);
export const register = new Route("/register", <Register />);
export const registerResult = new Route(
  "/registerResult/:obj",
  <RegisterResult />,
  [],
  (obj: any) => `/registerResult/${obj}`
);
export const successEmailConfirmation = new Route("/successEmailConfirmation", <SuccessEmailConfirmation />);
export const confirmEmail = new Route("/confirmEmail", <ConfirmEmail />);
export const resendEmailConfirmCode = new Route("/resendEmailConfirmCode", <ResendEmailConfirmCode />);
export const termsAndConditions = new Route(
  "/termsAndConditions",
  <TermsAndConditions />,
  [],
  (id: string) => `/termsAndConditions/${id}`
);
export const passwordChange = new Route("/passwordChange", <PasswordChange />);
export const bankAccounts = new Route("/bankAccounts", <BankAccounts />, [TRANSPORT_COMPANY]);
export const rates = new Route("/rates", <Rates />, [BASSA_ADMIN]);
export const payment = new Route("/payment/:id", <PaymentDetail />, ALL_ROLES, (id: string) => `/payment/${id}`);
export const debinDetail = new Route(
  "/debinDetail/:id/:idDebin",
  <DebinDetail />,
  ALL_ROLES,
  (id: string, debinId: string) => `/debinDetail/${id}/${debinId}`
);
export const trips = new Route("/trips", <Trips />, ALL_ROLES);
export const tripDetail = new Route("/trips/:id", <TripDetail />, ALL_ROLES, (id: string) => `/trips/${id}`);
export const payments = new Route("/payments", <Payments />, [BASSA_ADMIN, BASSA_OPERATOR]);
export const tripAnnulment = new Route("/trip/annulments", <TripAnnulment />, [BASSA_ADMIN]);
export const tripCertificates = new Route(
  "/Trip/:tripId/Certificate/:certificateId",
  <TripCertificates />,
  ALL_ROLES,
  (tripId: string, certificateId: string) => `/trips/${tripId}/certificates/${certificateId}`
);
export const tripCertificateNotFound = new Route("/trips/certificates/notFound", <TripCertificateNotFound />);
export const otherPayments = new Route("/otherPayments", <OtherPayments />, [BASSA_ADMIN]);
export const users = new Route("/users", <Users />, [BASSA_ADMIN]);
export const bankAccountStatus = new Route("/bankAccount", <BankAccountStatus />, [BASSA_ADMIN]);
export const protección = new Route("/protección", <Protection />, [BASSA_ADMIN]);
export const managePayments = new Route("/managePayments", <ManagePayments />, [BASSA_ADMIN, BASSA_OPERATOR]);
export const checkingAccount = new Route("/checkingAccount", <CheckingAccount />, [BASSA_ADMIN, TRANSPORT_COMPANY]);
export const preliquidations = new Route("/preliquidations", <Preliquidations />, [BASSA_ADMIN, BASSA_OPERATOR]);
export const preliquidation = new Route("/preliquidation/:id", <Preliquidations />,  [BASSA_ADMIN, BASSA_OPERATOR], (id: string) => `/preliquidation/${id}`);
export const managePreliquidations = new Route("/managePreliquidations", <ManagePreliquidations />, [
  BASSA_ADMIN,
  BASSA_OPERATOR,
]);
export const endorsementAnnulment = new Route("/annulEndorsement", <EndorsementAnnulment />, [BASSA_ADMIN]);
export const endorsementRejection = new Route("/rejectEndorsement", <EndorsementRejection />, [BASSA_ADMIN]);
export const endorsementPayment = new Route("/endorsementPayment", <EndorsementPayment />, [BASSA_ADMIN]);
