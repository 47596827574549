import { Typography } from "antd"
import './tripCertificateNotFound.scss'

const { Title, Text } = Typography

const TripCertificateNotFound = () => {
  return (
    <div className="trip-certificate-not-found">
      <Title level={1}>404</Title>
      <div className="trip-certificate-not-found__text">
        <Text strong>Hubo un error inesperado o no se pudo generar la constancia de viaje Web.</Text>
        <Text strong>Reintente más tarde.</Text>
      </div>
    </div>
  )
}

export default TripCertificateNotFound