import { useContext, useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { AuthContext } from "../../context/AuthContext";
import { confirmEmail, passwordChange, register } from "../../../constants/routes";
import "./Login.scss";

function Login() {
  const { signIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  
  const onFinish = ({ username, password }: { username: string; password: string }) => {
    setLoading(true);
    signIn(username, password, () => setLoading(false));
  }

  const navigate = useNavigate();

  return (
    <>  
      <div className="login">
        <h4 className="title"> Te damos la Bienvenida</h4>
        <Form name="nest-messages" onFinish={onFinish}>
          <Row justify="center">
            <Col md={{ span: 12 }} xl={{ span: 15 }}>
              <Form.Item
                name="username"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Debe ingresar un correo electrónico",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Correo Electrónico"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Debe ingresar una contraseña" },
                ]}
              >
                <Input.Password
                  // prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Contraseña"
                />
              </Form.Item>
              <Row justify="center">
                <Form.Item className="w100">
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={loading}
                  >
                    Iniciar Sesión
                  </Button>
                </Form.Item>
              </Row>
              <Row justify="center">
                <Form.Item className="w100">
                  <Button
                    type="default"
                    onClick={() => navigate(register.path)}
                    block
                  >
                    <Link to={register.path}>Registrarse</Link>
                  </Button>
                </Form.Item>
              </Row>
              <Row justify="center">
                <Form.Item>
                  <p className="center-text">
                    Si has olvidado tu contraseña o tu usuario se encuentra
                    bloqueado, <Link to={passwordChange.path}>presiona aquí</Link>
                  </p>
                </Form.Item>
              </Row>
              <Row justify="center">
                <Form.Item>
                  <p className="center-text">
                    Si todavía no activaste tu usuario,{" "}
                    <Link to={confirmEmail.path}>hace click aquí</Link>
                  </p>
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default Login;
