import apiClient from "./api.client"

import { Endorsement, EndorsementServices } from "../models/Endorsement/Endorsement"
import { EndorsementDTO } from "../DTO/endorsement/Endorsements.dto"

export const annulEndorsements = async (file: FormData): Promise<Endorsement[]> => {
  const { data } = await apiClient.post<EndorsementDTO[]>(`/Trips/Processes/EndorsementAnnulments`, file, { timeout: 1800000 })
  return data;
}

export const rejectEndorsements = async (file: FormData): Promise<Endorsement[]> => {
  const { data } = await apiClient.post<EndorsementDTO[]>(`/Trips/Processes/EndorsementRejections`, file, { timeout: 1800000 })
  return data.map((RejectedEndorsementDTO: any) => ({
    externalId: RejectedEndorsementDTO.externalId,
    serviceTypeId: RejectedEndorsementDTO.serviceTypeId,
    message: RejectedEndorsementDTO.message,
    success: RejectedEndorsementDTO.success,
    row: RejectedEndorsementDTO.row
  }))
}

export const payEndorsements = async (file: FormData): Promise<Endorsement[]> => {
  const { data } = await apiClient.post<EndorsementDTO[]>(`/Trips/Processes/EndorsementPayments`, file, { timeout: 1800000 })
  return data;
}

const endorsementServices: EndorsementServices = {
  annulEndorsements,
  rejectEndorsements,
  payEndorsements
}

export default endorsementServices