import { useEffect, useState } from "react"

import { EconomicGroup } from "../../../../models/EconomicGroup"
import { IPaymentMethods } from "../../../../models/Payment/PaymentMethods"

import { getEconomicGroups } from "../../../../services/group.services"
import { getTransportCompanies } from "../../../../services/transportCompany.services"
import { getPaymentMethods } from "../../../../services/payment.services"
import { TransportCompanyResponse } from "../../../../models/TransportCompany/TransportCompany"

const useGetSelectableData = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [economicGroups, setEconomicGroups] = useState<EconomicGroup[]>([])
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethods[]>([])
  const [transportCompanies, setTransportCompanies] = useState<TransportCompanyResponse[]>([])

  useEffect(() => {
    Promise.all([
      getEconomicGroups(),
      getTransportCompanies(),
      getPaymentMethods({ isManual: true })
    ])
      .then((response) => {
        const [economicGroups, transportCompanies, paymentMethods] = response
        setEconomicGroups(economicGroups)
        setTransportCompanies(transportCompanies)
        setPaymentMethods(paymentMethods)
      })
      .finally(() => setLoading(false))
  }, [])

  return {
    loading,
    economicGroups,
    paymentMethods,
    transportCompanies
  }
}

export default useGetSelectableData