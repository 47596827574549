import { useContext } from "react";

import { Button, DatePicker, Form, Input, Select, Space } from "antd";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

import SkeletonFilters from "../skeletonFilters/SkeletonFilters";

import { DefaultDateFormat } from "../../../../../models/Date";

import useDataManagePaymentsFilters from "../../hooks/useDataManagePaymentsFilters";

import ManagePaymentsContext from "../../contexts/ManagePaymentsContextProvider";

import "./managePaymentsFilters.scss";

const { RangePicker } = DatePicker;

const ManagePaymentsFilters = () => {
	const [form] = useForm();
	const { handleUpdateTable } = useContext(ManagePaymentsContext);
	const {
		economicGroups,
		transportCompanies,
		paymentMethods,
		paymentStates,
		loading,
	} = useDataManagePaymentsFilters();

	const onFinish = (values: any) => {
		handleUpdateTable({ managePaymentsFilters: values, page: 1, pageSize: 10 });
	};

	const handleReset = () => {
		form.resetFields();
		form.setFieldsValue({ paymentStates: null });
	};

	if (loading) {
		return <SkeletonFilters />;
	}

	return (
		<div className="manage-payments-filters layer">
			<Form
				form={form}
				name="managePaymentsFiltersForm"
				layout="vertical"
				className="manage-payments-filters__form"
				onFinish={onFinish}
			>
				<Space wrap style={{ maxWidth: "80%" }}>
					<Form.Item label="Identificador de pago" name="paymentId">
						<Input
							placeholder="Identificador de pago"
							className="manage-payments-filters__field"
							allowClear
						/>
					</Form.Item>
					<Form.Item label="Transportista" name="transport">
						<Select
							placeholder="Seleccione..."
							options={transportCompanies?.map((transporter) => ({
								value: transporter.id,
								label: transporter.name,
							}))}
							loading={loading}
							disabled={loading}
							className="manage-payments-filters__field"
						/>
					</Form.Item>
					<Form.Item label="Grupo economico" name="economicGroup">
						<Select
							placeholder="Seleccione..."
							options={economicGroups?.map((economicGroup) => ({
								label: economicGroup.name,
								value: economicGroup.id,
							}))}
							loading={loading}
							disabled={loading}
							className="manage-payments-filters__field"
						/>
					</Form.Item>
					<Form.Item label="Fecha de pago" name="paymentDate">
						<RangePicker
							placeholder={["Desde", "Hasta"]}
							format={DefaultDateFormat}
							allowEmpty={[true, true]}
							allowClear
							className="manage-payments-filters__field--xxl"
						/>
					</Form.Item>
					<Form.Item label="Medio de pago" name="paymentMethod">
						<Select
							placeholder="Seleccione..."
							options={paymentMethods?.map((paymentMethod) => ({
								label: paymentMethod.description,
								value: paymentMethod.id,
							}))}
							loading={loading}
							disabled={loading}
							className="manage-payments-filters__field"
						/>
					</Form.Item>
					<Form.Item label="Estado" name="paymentStatus" initialValue={1}>
						<Select
							placeholder="Seleccione..."
							options={[
								{ description: "Todos", id: null },
								...paymentStates,
							]?.map((status) => ({
								label: status.description,
								value: status.id,
							}))}
							loading={loading}
							disabled={loading}
							className="manage-payments-filters__field"
						/>
					</Form.Item>
				</Space>
				<br />
				<Space wrap>
					<Form.Item>
						<Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
							Buscar
						</Button>
					</Form.Item>
					<Form.Item>
						<Button
							type="default"
							htmlType="button"
							icon={<ClearOutlined />}
							onClick={handleReset}
						>
							Limpiar
						</Button>
					</Form.Item>
				</Space>
			</Form>
		</div>
	);
};

export default ManagePaymentsFilters;
