import { Skeleton, Space } from "antd"

import './skeletonFilters.scss'

const SkeletonFilters = () => {
  return (
    <div className="skeleton-filters layer">
      <Space wrap className="skeleton-filters__content">
        <div className="skeleton-filters__field">
          <Skeleton paragraph={false} active={true} className="skeleton-filters__label"/>
          <Skeleton paragraph={false} active={true} className="skeleton-filters__input"/>
        </div>
        <div className="skeleton-filters__field">
          <Skeleton paragraph={false} active={true} className="skeleton-filters__label"/>
          <Skeleton paragraph={false} active={true} className="skeleton-filters__input"/>
        </div>
        <div className="skeleton-filters__field">
          <Skeleton paragraph={false} active={true} className="skeleton-filters__label"/>
          <Skeleton paragraph={false} active={true} className="skeleton-filters__input"/>
        </div>
        <div className="skeleton-filters__field">
          <Skeleton paragraph={false} active={true} className="skeleton-filters__label"/>
          <Skeleton paragraph={false} active={true} className="skeleton-filters__input skeleton-filters__input--range-picker"/>
        </div>
        <div className="skeleton-filters__field">
          <Skeleton paragraph={false} active={true} className="skeleton-filters__label"/>
          <Skeleton paragraph={false} active={true} className="skeleton-filters__input"/>
        </div>
        <div className="skeleton-filters__field">
          <Skeleton paragraph={false} active={true} className="skeleton-filters__label"/>
          <Skeleton paragraph={false} active={true} className="skeleton-filters__input"/>
        </div>
      </Space>
      <br />
      <Space>
        <Skeleton.Button active />
        <Skeleton.Button active />
      </Space>
    </div>
  )
}
export default SkeletonFilters