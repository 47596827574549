/* eslint-disable no-template-curly-in-string */
import { useEffect, useState } from "react";
import { Alert, Col, Row, Spin, Steps } from "antd";
import { TransportCompaniesForm, DocumentForm } from "../../atoms";
import apiClient from "../../../services/api.client";
import { LoadingOutlined } from "@ant-design/icons";
import { Document } from "../../../models/Document";
import WarningAccountModal from "../../shared/warningAccountModal/WarningAccountModal";

function Register() {
  const [error, setError] = useState<string>('')
  const [documentConfirmed, setDocumentConfirmed] = useState(false)
  const [documentSelected, setDocumentSelected] = useState<Document>()
  const [userData, setUserData] = useState({})
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    //Obtengo los datos de la empresa transportista, si existe y no esta registrada
    if (documentSelected && documentSelected.documentNumber.length > 0) {
      setLoading(true)
      apiClient.get(`/TransportCompanies?documentNumber=${documentSelected?.documentNumber}`).then((response) => {
        setUserData(response.data[0])
        setLoading(false)
      }).catch((error) => {
        console.log('error: ', error)
      })
    }
  }, [documentSelected])

  return (
    <>
      <Col>
        <Row justify={"center"}>
          <Col md={{ span: 12 }} xs={{ span: 8 }} style={{ marginTop: 12, marginBottom: 24 }}>
            <Steps current={documentConfirmed ? 1 : 0} items={[{ title: 'Paso 1' }, { title: 'Paso 2' }]} />
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col md={{ span: 11 }} xs={{ span: 23 }} style={{ marginBottom: 32 }}>
            {
              error &&
              <Alert
                style={{ padding: 12 }}
                message={error}
                type="error"
                showIcon
              />
            }
          </Col>
        </Row>
        <Row justify={"center"} >
          {
            loading 
              ? <Spin indicator={<LoadingOutlined style={{ fontSize: 64}}/>}/>
              : documentConfirmed 
                ? <TransportCompaniesForm 
                    userData={userData}
                    documentSelected={documentSelected as Document}
                    setError={setError}
                    goBack={() => setDocumentConfirmed(false)}
                  /> 
                : <DocumentForm
                    setDocumentConfirmed={setDocumentConfirmed}
                    setDocumentSelected={setDocumentSelected}
                    setError={setError}
                  />
          }
        </Row>
      </Col>
      <WarningAccountModal />
    </>
  )
}

export default Register