import { Table as ADTable } from 'antd'

interface TableProps {
  trips: [];
  columns: any; 
  loading: any; 
  pagination: any; 
  scroll: {}
}

function Table({trips, columns, loading, pagination, scroll}: TableProps) {
  return (
    <div style={{border:'1.5px solid #EBEBEB', borderRadius:10, width:'100%'}}>
        <ADTable dataSource={trips ? trips : []} columns={columns} loading={loading} pagination={pagination} rowKey={(value:any)=> value?.id} scroll={scroll} tableLayout='fixed'/>
    </div>
  )
}

export default Table