import { UserOutlined } from "@ant-design/icons";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import { Typography } from "antd";
import './LoggedInUserInfo.scss';

const { Text } = Typography;

const LoggedInUserInfo = () => {
  const { username, isAuth } = useContext(AuthContext);

  return !isAuth ? <></> : (
    <Text className="loggedInUserInfoText">
      <UserOutlined /> {username}
    </Text>
    )
}

export default LoggedInUserInfo