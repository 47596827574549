import {
  Alert,
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import useGetSelectableData from "../../hooks/useGetSelectableData";
import { TypeOfTransportersToBePaid } from "../../../../../constants/payments";
import PreliquidationContext from "../../contexts/PreliquidationContextProvider";
import { DefaultDateFormat } from "../../../../../models/Date";
import { generatePreliquidationDraft } from "../../../../../services/preliquidation.service";
import { GeneratePreliquidationDraftParams } from "../../../../../DTO/preliquidation/preliquidation.dto";
import { errorMessages } from "../../../../../models/Errors/Errors";
import { useNavigate } from "react-router-dom";
import { ErrorResponse } from "../../../../../models/Errors/ErrorResponse";
import dayjs from "dayjs";

const { Title } = Typography;

const SelectTripsToPreliquidateForm: React.FC = () => {
  const {
    currentStep,
    setCurrentStep,
    setGeneratePreliquidationStatus,
    setPreliquidationId,
    transportCompany,
    setTransportCompany,
    economicGroup,
    setEconomicGroup,
    selectedPreliquidationItem,
  } = useContext(PreliquidationContext);

  const navigate = useNavigate();

  const { loading, economicGroups, transportCompanies, economicGroupTransportCompanies } = useGetSelectableData();

  const [finishingStep1, setFinishingStep1] = useState<boolean>(false);

  const [typeOfTransportersToBePaid, setTypeOfTransportersToBePaid] = useState<TypeOfTransportersToBePaid>(
    TypeOfTransportersToBePaid.Company
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedPreliquidationItem?.id) {
      form.setFieldsValue({
        transporter: selectedPreliquidationItem?.transportCompany ?? null,
        group: selectedPreliquidationItem?.economicGroup ?? null,
        excludedTransporter: selectedPreliquidationItem?.excludedTransportCompaniesIds ?? null,
        closeDate: selectedPreliquidationItem?.deadline
          ? dayjs(selectedPreliquidationItem?.deadline, "DD/MM/YYYY")
          : null,
        typeOfTransportersToBePaid: economicGroup
          ? TypeOfTransportersToBePaid.Group
          : TypeOfTransportersToBePaid.Company,
      });
      setTypeOfTransportersToBePaid(
        economicGroup ? TypeOfTransportersToBePaid.Group : TypeOfTransportersToBePaid.Company
      );
    }
  }, [selectedPreliquidationItem?.id]);

  interface PreliquidationForm {
    typeOfTransportersToBePaid: string;
    group: string;
    excludedTransporter: string[];
    closeDate: moment.Moment;
    transporter: string;
  }

  const mapPreliquidationFormToParams = (values: PreliquidationForm): GeneratePreliquidationDraftParams => {
    const { typeOfTransportersToBePaid, excludedTransporter, closeDate } = values;
    const transportCompanyId =
      typeOfTransportersToBePaid === TypeOfTransportersToBePaid.Company ? transportCompany?.id : undefined;
    const economicGroupId = typeOfTransportersToBePaid === TypeOfTransportersToBePaid.Group ? economicGroup : undefined;

    return {
      transportCompanyId: transportCompanyId,
      economicGroupId: economicGroupId,
      excludedTransportCompaniesIds: excludedTransporter,
      deadline: closeDate.toDate(),
    };
  };

  const onFinishStep1 = async (values: PreliquidationForm) => {
    setFinishingStep1(true);

    try {
      const generatePreliquidationResult = await generatePreliquidationDraft(mapPreliquidationFormToParams(values));

      if (generatePreliquidationResult && generatePreliquidationResult?.id) {
        setPreliquidationId(generatePreliquidationResult?.id);
        setCurrentStep(currentStep + 1);
        setGeneratePreliquidationStatus("process");
      } else {
        setGeneratePreliquidationStatus("error");
        message.error("Ocurrió un error al generar la preliquidación");
      }
    } catch (e: any) {
      setGeneratePreliquidationStatus("error");
      const errorResponse: ErrorResponse = e.response?.data;
      const type = errorResponse.Type;
      const errorMessage = errorMessages[type] ?? errorMessages.tryAgain;
      message.error(errorMessage);
    }

    setFinishingStep1(false);
  };

  const handleChangeTypeOfTransportersToBePaid = (e: RadioChangeEvent) => {
    const { value } = e.target;
    const key = value === TypeOfTransportersToBePaid.Company ? "group" : "transporter";
    form.setFieldValue(key, undefined);
    form.setFieldValue("excludedTransporter", []);
    setTypeOfTransportersToBePaid(value);
  };

  const handleSelectTransportCompany = (value: string) => {
    const selected = transportCompanies.find((item) => item.name === value) || null;
    setTransportCompany(selected);
  };

  const handleChangeEconomicGroup = (value: string) => {
    setEconomicGroup(value);
    form.setFieldValue("excludedTransporter", []);
  };

  return (
    <div className="layer">
      <Form
        disabled={finishingStep1}
        onFinish={onFinishStep1}
        layout={"vertical"}
        labelAlign="left"
        form={form}
        name="selectTripsToPreliquidateForm"
      >
        <Space wrap style={{ maxWidth: "95%" }}>
          <Row gutter={[24, 12]}>
            <Col span={24}>
              <Title level={2}>Registrar preliquidación</Title>
            </Col>
            <Col span={24}>
              <Alert message="Seleccione si desea imputar el viaje a una empresa o grupo transportista" type="info" />
            </Col>
            <Col span={24}>
              <Form.Item
                name="typeOfTransportersToBePaid"
                rules={[{ required: true, message: "Debe seleccionar una opción" }]}
                initialValue={TypeOfTransportersToBePaid.Company}
              >
                <Radio.Group
                  onChange={handleChangeTypeOfTransportersToBePaid}
                  value={TypeOfTransportersToBePaid.Company}
                >
                  <Radio
                    value={TypeOfTransportersToBePaid.Company}
                    checked={typeOfTransportersToBePaid === TypeOfTransportersToBePaid.Company}
                  >
                    Empresa Transportista
                  </Radio>
                  <Radio
                    value={TypeOfTransportersToBePaid.Group}
                    checked={typeOfTransportersToBePaid === TypeOfTransportersToBePaid.Group}
                  >
                    Grupo Económico
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={{ span: 12 }} xs={{ span: 24 }}>
              <Form.Item
                name="transporter"
                label="Transportista"
                rules={[
                  {
                    required: typeOfTransportersToBePaid === TypeOfTransportersToBePaid.Company,
                    message: "Debe completar este campo",
                  },
                ]}
              >
                <AutoComplete
                  placeholder="Seleccione..."
                  options={transportCompanies.map((item) => ({
                    value: item.name,
                    label: (
                      <div>
                        {item.name} ({item.number})
                      </div>
                    ),
                  }))}
                  notFoundContent={loading ? <Spin size="small" /> : null}
                  disabled={typeOfTransportersToBePaid !== TypeOfTransportersToBePaid.Company}
                  onSelect={(value) => handleSelectTransportCompany(value)}
                  filterOption
                  allowClear
                  defaultValue={""}
                />
              </Form.Item>
            </Col>

            <Col md={{ span: 12 }} xs={{ span: 24 }}>
              <Form.Item
                name="group"
                label="Grupo económico"
                rules={[
                  {
                    required: typeOfTransportersToBePaid === TypeOfTransportersToBePaid.Group,
                    message: "Debe completar este campo",
                  },
                ]}
              >
                <Select
                  placeholder="Seleccione..."
                  disabled={typeOfTransportersToBePaid !== TypeOfTransportersToBePaid.Group}
                  onChange={(value) => handleChangeEconomicGroup(value)}
                  loading={loading}
                  notFoundContent={loading ? <Spin size="small" /> : null}
                  options={economicGroups.map((economicGroup) => ({
                    value: economicGroup.id,
                    label: economicGroup.name,
                  }))}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Title level={4}>Seleccione las empresas que desea excluir del grupo seleccionado:</Title>
            </Col>

            <Col md={{ span: 12 }} xs={{ span: 24 }}>
              <Form.Item name="excludedTransporter" label="Transportista">
                <Select
                  placeholder="Seleccione..."
                  mode="multiple"
                  disabled={typeOfTransportersToBePaid === TypeOfTransportersToBePaid.Company}
                  loading={loading}
                  notFoundContent={loading ? <Spin size="small" /> : null}
                  options={economicGroupTransportCompanies.map((transportCompany) => ({
                    value: transportCompany.id,
                    label: transportCompany.name,
                  }))}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Title level={4}>
                Seleccione la fecha de cierre hasta la cual desea incluir viajes en la preliquidación
              </Title>
            </Col>

            <Col span={11}>
              <Form.Item
                name="closeDate"
                label="Fecha de cierre"
                rules={[{ required: true, message: "Debe completar este campo" }]}
              >
                <DatePicker style={{ width: "100%" }} format={DefaultDateFormat} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <div style={{ float: "right" }}>
                <Row gutter={[16, 16]}>
                  <Col>
                    <Button
                      type="default"
                      form={"selectTripsToPreliquidateForm"}
                      onClick={() => navigate("/managePreliquidations")}
                    >
                      Cancelar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      form={"selectTripsToPreliquidateForm"}
                      htmlType="submit"
                      loading={finishingStep1}
                      disabled={finishingStep1 || loading}
                    >
                      Siguiente
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Space>
      </Form>
    </div>
  );
};

export default SelectTripsToPreliquidateForm;
