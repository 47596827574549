import { Alert, Layout, Typography } from "antd"
import { Content } from "antd/es/layout/layout"
import OtherPaymentsForm from "./components/OtherPaymentsForm"
import OtherPaymentsTable from "./components/OtherPaymentsTable"

import './otherPayments.scss'
import { OtherPaymentsContextProvider } from "./context/OtherPaymentsContextProvider"

const { Title } = Typography

const OtherPayments = () => {
  return (
    <Layout>
      <Content className="other-payments-content">
        <Title level={2}>Otros Pagos</Title>
        <div className="other-payments-content__content-alert">
            <Alert
              message="Seleccione el archivo xlsx que desea importar para registrar Otros Pagos."
              type="info"
            />
          </div>
        <div className="layer">
          <OtherPaymentsContextProvider>
            <OtherPaymentsForm />
            <OtherPaymentsTable />
          </OtherPaymentsContextProvider>
        </div>
      </Content>
    </Layout>
  )
}

export default OtherPayments
