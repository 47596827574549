import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TransportCompanyResponse } from "../../../../models/TransportCompany/TransportCompany";
import { getPreliquidationIncludedTransportCompanies } from "../../../../services/transportCompany.services";
import { PreliquidationDetailsDTO, PreliquidationTripsDTO } from "../../../../DTO/preliquidation/preliquidation.dto";
import {
  IdNameResponse,
  PreliquidationDiscount,
  PreliquidationItem,
} from "../../../../models/Preliquidation/Preliquidation";
import { getPreliquidationById } from "../../../../services/preliquidation.service";
import { PreliquidationStateIds } from "../../../../constants/preliquidations";

interface PreliquidationContextState {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  generatePreliquidationStatus: "wait" | "process" | "finish" | "error" | undefined;
  setGeneratePreliquidationStatus: Dispatch<SetStateAction<"wait" | "process" | "finish" | "error" | undefined>>;
  preliquidationId: string;
  setPreliquidationId: Dispatch<SetStateAction<string>>;
  preliquidationIncludedTransportCompanies: TransportCompanyResponse[];
  setPreliquidationIncludedTransportCompanies: Dispatch<SetStateAction<TransportCompanyResponse[]>>;
  preliquidationTrips: PreliquidationTripsDTO | undefined;
  setPreliquidationTrips: Dispatch<SetStateAction<PreliquidationTripsDTO | undefined>>;
  preliquidationDiscounts: PreliquidationDiscount[] | undefined;
  setPreliquidationDiscounts: Dispatch<SetStateAction<PreliquidationDiscount[] | undefined>>;
  transportCompany?: IdNameResponse | null;
  setTransportCompany: Dispatch<SetStateAction<IdNameResponse | null>>;
  preliquidationDetails?: PreliquidationDetailsDTO | undefined;
  setPreliquidationDetails: Dispatch<SetStateAction<PreliquidationDetailsDTO | undefined>>;
  economicGroup?: string;
  setEconomicGroup: Dispatch<SetStateAction<string>>;
  selectedPreliquidationItem?: PreliquidationItem;
  loadingPreliquidationTrips: boolean;
  setLoadingPreliquidationTrips: Dispatch<SetStateAction<boolean>>;
  searchingPreliquidationTrips: boolean;
  setSearchingPreliquidationTrips: Dispatch<SetStateAction<boolean>>;
  loadingPreliquidationDiscounts: boolean;
  setLoadingPreliquidationDiscounts: Dispatch<SetStateAction<boolean>>;
  loadingPreliquidationDetails: boolean;
  setLoadingPreliquidationDetails: Dispatch<SetStateAction<boolean>>;
  discountModified: boolean;
  setDiscountModified: Dispatch<SetStateAction<boolean>>;
  tripModified: boolean;
  setTripModified: Dispatch<SetStateAction<boolean>>;
  initialLoading: boolean;
  setInitialLoading: Dispatch<SetStateAction<boolean>>;
  operationMode: "create" | "edit";
  setOperationMode: Dispatch<SetStateAction<"create" | "edit">>;
}

const initialPreliquidationContextState: PreliquidationContextState = {
  currentStep: 0,
  setCurrentStep: () => {},
  generatePreliquidationStatus: "wait",
  setGeneratePreliquidationStatus: () => {},
  preliquidationId: "",
  setPreliquidationId: () => {},
  preliquidationIncludedTransportCompanies: [],
  setPreliquidationIncludedTransportCompanies: () => {},
  preliquidationTrips: undefined,
  setPreliquidationTrips: () => {},
  preliquidationDiscounts: undefined,
  setPreliquidationDiscounts: () => {},
  transportCompany: null,
  setTransportCompany: () => {},
  preliquidationDetails: undefined,
  setPreliquidationDetails: () => {},
  economicGroup: "",
  setEconomicGroup: () => {},
  loadingPreliquidationTrips: false,
  setLoadingPreliquidationTrips: () => {},
  searchingPreliquidationTrips: false,
  setSearchingPreliquidationTrips: () => {},
  loadingPreliquidationDiscounts: false,
  setLoadingPreliquidationDiscounts: () => {},
  loadingPreliquidationDetails: false,
  setLoadingPreliquidationDetails: () => {},
  discountModified: false,
  setDiscountModified: () => {},
  tripModified: false,
  setTripModified: () => {},
  initialLoading: true,
  setInitialLoading: () => {},
  operationMode: "create",
  setOperationMode: () => {},
};

const PreliquidationContext = React.createContext<PreliquidationContextState>(initialPreliquidationContextState);

interface PreliquidationContextProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

export const PreliquidationContextProvider = ({ children }: PreliquidationContextProviderProps) => {
  const [generatePreliquidationStatus, setGeneratePreliquidationStatus] = useState<
    "wait" | "process" | "finish" | "error" | undefined
  >("wait");

  const [currentStep, setCurrentStep] = useState<number>(0);

  const [selectedPreliquidationItem, setSelectedPreliquidationItem] = useState<PreliquidationItem>();

  const [preliquidationId, setPreliquidationId] = React.useState<string>("");

  const [transportCompany, setTransportCompany] = useState<IdNameResponse | null>(null);

  const [preliquidationTrips, setPreliquidationTrips] = useState<PreliquidationTripsDTO>();

  const [loadingPreliquidationTrips, setLoadingPreliquidationTrips] = useState<boolean>(false);

  const [loadingPreliquidationDiscounts, setLoadingPreliquidationDiscounts] = useState<boolean>(false);

  const [loadingPreliquidationDetails, setLoadingPreliquidationDetails] = useState<boolean>(false);

  const [searchingPreliquidationTrips, setSearchingPreliquidationTrips] = useState(false);

  const [preliquidationDiscounts, setPreliquidationDiscounts] = useState<PreliquidationDiscount[]>();

  const [preliquidationDetails, setPreliquidationDetails] = useState<PreliquidationDetailsDTO>();

  const [initialLoading, setInitialLoading] = useState<boolean>(initialPreliquidationContextState.initialLoading);

  const [discountModified, setDiscountModified] = useState<boolean>(false);

  const [tripModified, setTripModified] = useState<boolean>(false);

  const [preliquidationIncludedTransportCompanies, setPreliquidationIncludedTransportCompanies] = useState<
    TransportCompanyResponse[]
  >([]);

  const [operationMode, setOperationMode] = useState<"create" | "edit">(
    initialPreliquidationContextState.operationMode
  );

  const [economicGroup, setEconomicGroup] = useState<string>("");

  useEffect(() => {
    if (preliquidationId)
      Promise.resolve(getPreliquidationIncludedTransportCompanies(preliquidationId))
        .then((response) => setPreliquidationIncludedTransportCompanies(response))
        .catch((error) => {
          console.error(error);
        });
  }, [preliquidationId]);

  useEffect(() => {
    if (currentStep === 1 && preliquidationId)
      Promise.resolve(getPreliquidationById(preliquidationId)).then((response) => {
        setSelectedPreliquidationItem(response);
        setOperationMode(response?.preliquidationState?.id === PreliquidationStateIds.Pending ? "edit" : "create");
        setTransportCompany(response?.transportCompany ?? null);
        setEconomicGroup(response?.economicGroup?.id ?? "");
      });
  }, [preliquidationId, currentStep]);

  return (
    <PreliquidationContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        generatePreliquidationStatus,
        setGeneratePreliquidationStatus,
        preliquidationId,
        setPreliquidationId,
        preliquidationIncludedTransportCompanies,
        setPreliquidationIncludedTransportCompanies,
        preliquidationTrips,
        setPreliquidationTrips,
        preliquidationDiscounts,
        setPreliquidationDiscounts,
        transportCompany,
        setTransportCompany,
        preliquidationDetails,
        setPreliquidationDetails,
        economicGroup,
        setEconomicGroup,
        selectedPreliquidationItem,
        loadingPreliquidationTrips,
        setLoadingPreliquidationTrips,
        searchingPreliquidationTrips,
        setSearchingPreliquidationTrips,
        loadingPreliquidationDiscounts,
        setLoadingPreliquidationDiscounts,
        loadingPreliquidationDetails,
        setLoadingPreliquidationDetails,
        discountModified,
        setDiscountModified,
        tripModified,
        setTripModified,
        initialLoading,
        setInitialLoading,
        operationMode,
        setOperationMode,
      }}
    >
      {children}
    </PreliquidationContext.Provider>
  );
};

export default PreliquidationContext;
