export interface IDiscountTypes {
  id: number;
  description: string;
}

export enum DiscountTypes {
  Percentage = "Porcentaje",
  Amount = "Importe",
  Passengers = "Pasajeros",
}

export type TypeDiscountTypes = keyof typeof DiscountTypes;
