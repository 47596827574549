import { Button, Collapse, Space, Typography } from "antd";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DownOutlined, DownloadOutlined, UpOutlined } from "@ant-design/icons";
import { StrongLink } from "../../atoms/StrongLink";
import { bankAccounts, confirmEmail, login, passwordChange, register, trips } from "../../../constants/routes";
import "./landing.scss";


const Landing = () => {
  const { Panel } = Collapse;
  const { Link } = Typography;
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  let text = isExpanded ? (
    <p>
      Mediante resolución 684/2022 el Ministerio de Transporte estableció la
      ampliación del seguro de responsabilidad civil de personas transportadas,
      para cubrir específicamente los riesgos de muerte e incapacidad de
      pasajeros por accidentes en los servicios de transporte automotor
      interurbanos de pasajeros de larga distancia de jurisdicción nacional
      regulados por el decreto nº 958/92 y sus modificatorios.{<br />}
      {<br />}Con fecha 3/1/2023 la SUPERINTENDENCIA DE SEGUROS DE LA NACION
      emitió la Resolución 4/2023 regulando la nueva cobertura.{<br />} {<br />}{" "}
      La nueva normativa establece la obligatoriedad por parte de las empresas
      prestadoras de los servicios de LARGA DISTANCIA de contratar esta nueva
      cobertura de seguros de carácter obligatorio y a través de las mismas
      aseguradoras que hoy le dan cobertura de Responsabilidad Civil.{<br />}{" "}
      Para tal fin, las empresas deberán incluir en la emisión de sus
      respectivos boletos un nuevo renglón especificando que se incluye el
      mencionado seguro. Dicho renglón deberá especificar lo siguiente:{<br />}{" "}
      {<br />} Según Resolución 684/2022 del Ministerio de Transporte. Monto
      Seguro: $xxx (1){<br />}
      <p style={{ fontSize: 12 }}>
        (1) Dicho costo será informado por las aseguradoras periódicamente.
      </p>
      {<br />}Dicho costo será informado por las aseguradoras periódicamente. La
      obligación de la cobertura es exigible desde el cierre del Documento
      Universal de Transporte (DUT) y/o Lista de Pasajeros. La información para
      la emisión de las coberturas será suministrada por el Organismo de control de transporte en forma directa a la aseguradora. Con el
      cierre definitivo del DUT o Lista de Pasajeros correspondiente, se
      procederá a la emisión de la cobertura.
    </p>
  ) : (
    <p>
      Mediante resolución 684/2022 el Ministerio de Transporte estableció la
      ampliación del seguro de responsabilidad civil de personas transportadas,
      para cubrir específicamente los riesgos de muerte e incapacidad de
      pasajeros por accidentes en los servicios de transporte automotor
      interurbanos de pasajeros de larga distancia de jurisdicción nacional
      regulados por el decreto nº 958/92 y sus modificatorios.{<br />}
      {<br />}Con fecha 3/1/2023 la SUPERINTENDENCIA DE SEGUROS DE LA NACION
      emitió la Resolución 4/2023 regulando la nueva cobertura.{<br />} {<br />}{" "}
      La nueva normativa establece la obligatoriedad por parte de las
      empresas...
    </p>
  );
  const navigation = useNavigate();
  
  const makeFAQs = (title: string, text: string | JSX.Element) => {
    return (
      <Collapse
        expandIconPosition="end"
        style={{ marginTop: 20 }}
        expandIcon={({ isActive }) => isActive ? <UpOutlined /> : <DownOutlined />}
      >
        <Panel header={title} key="1">
          <p>{text}</p>
        </Panel>
      </Collapse>
    );
  };

  return (
    <>
      <a href="/Instructivo Seguro de Vida Pasajeros.pdf" download="Instructivo Seguro de Vida Pasajeros.pdf" target='_blank'>
        <Button className="downloadInstructiveButton" icon={<DownloadOutlined />}>
          Descargar instructivo
        </Button>
      </a>
      <div className="containerLanding">
        <FloatingWhatsApp
          phoneNumber="+541161639295"
          accountName=""
          statusMessage="En linea"
          chatMessage="Gracias por comunicarte, por favor indicanos tu razón social y motivo de consulta. Serás atendido a la brevedad."
          messageDelay={2}
          darkMode={true}
          notification={true}
          notificationSound={true}
          allowEsc={true}
          allowClickAway={true}
          placeholder="Mensaje..."
          chatboxHeight={340}
        />
        <div className="containerHeader">
          <div className="contentHeader">
            <p className="parrafWhiteLanding">
              Una plataforma destinada específicamente a la administración y gestión de cobranza de la cobertura prevista en las resoluciones 684/2022 del Ministerio de Transporte y 4/2023 de la Superintendencia de Seguros de la Nación.
            </p>
            <Space>
              <Button className="loginSignInbutton loginButton" onClick={() => navigation(login.path)} block>
                Iniciar Sesión
              </Button>
              <Button className="loginSignInbutton" type="default" onClick={() => navigation(register.path)} block>
                Registrarse
              </Button>
            </Space>
          </div>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <div className="containerParrafLanding">
          {text}
          {
            <button onClick={toggleExpand} className="expandButton">
              {isExpanded ? "Ver menos" : "Ver más"}
            </button>
          }

            <div className="containerDataLanding">
              <div className="divLinks">
                <p className="titleDataLanding">Consultas</p>
                <p className="titleLink">vida@promut.com.ar</p>
                <p className="titleLink">011-61639295</p>
                <p className="titleLink">011-58335591</p>
              </div>
              <div className="divLinks">
                <p className="titleDataLanding">Comercial</p>
                <p className="titleLink">comercial@promut.com.ar</p>
                <p className="titleLink">011-4129-7351/7433/7448</p>
              </div>
            </div>
            <Collapse
              expandIconPosition="end"
              style={{ marginTop: 20 }}
              expandIcon={({ isActive }) => isActive ? <UpOutlined /> : <DownOutlined />}
            >
              <Panel
                header="Te compartimos links que podrían interesarte"
                key="1"
              >
                <div className="containDivsLinks">
                  <div className="divLinks">
                    <p className="titleLink">
                      Resolución 684/2022 del Ministerio de Transporte
                    </p>
                    <Link
                      href="https://www.argentina.gob.ar/normativa/nacional/resoluci%C3%B3n-684-2022-372904"
                      target="_blank"
                    >
                      https://www.argentina.gob.ar/normativa/nacional/resoluci%C3%B3n-684-2022-372904
                    </Link>
                    
                  </div>
                  <div className="divLinks">
                    <p className="titleLink">Resolución 4/2023 de la SSN</p>
                    <Link
                      href="https://www.argentina.gob.ar/normativa/nacional/resoluci%C3%B3n-4-2023-377814"
                      target="_blank"
                    >
                      https://www.argentina.gob.ar/normativa/nacional/resoluci%C3%B3n-4-2023-377814
                    </Link>

                    
                  </div>
                </div>
              </Panel>
            </Collapse>
            <p className="subTitleLanding">Preguntas frecuentes</p>
            {makeFAQs(
              "¿Qué cubre el nuevo seguro?",
              "El nuevo seguro cubre específicamente los riesgos de muerte e incapacidad a cada pasajero que utilice servicios regulares  de  larga  distancia  de  jurisdicción  nacional  regulados  por  el  decreto  Nº  958/92  y  sus  modificatorios.  La cobertura actual es de hasta $7.000.000 por pasajero con un costo actual de $ 250."
            )}
            {makeFAQs(
              "¿Cómo se contrata el nuevo seguro?",
              "El nuevo seguro es de carácter obligatorio y automático. Los servicios alcanzados por la resolución 684/2022 regulados por el organismo de control de transporte tienen cobertura automática. El seguro se facturará una vez cerrada la LISTA DE PASAJEROS y/o DUT."
            )}
            {makeFAQs(
              "¿El  importe  del  seguro  estará  incluido  dentro  del  importe  del  boleto?  ¿O  este  será  un  monto  adicional independiente que se sumará al boleto conformando el importe total de la transacción?",
              "La resolución indica que este seguro se cobra en un renglón aparte en el boleto. En el caso de servicios de turismo, donde no se emite un boleto, se le debe cobrar este adicional a cada pasajero."
            )}
            {makeFAQs(
              "Para los boletos que se cobren con formas de pago distintas a efectivo, y les corresponda un recargo ¿Se debe contemplar el valor del seguro en el cobro de dicho recargo o esto solo debe impactar en precio del boleto?",
              "Si existe una financiación o una forma de pago que contempla intereses, se debe incluir el valor del seguro en el cobro de dicho recargo."
            )}
            {makeFAQs(
              "¿Los impuestos como IVA, RG3450, impuesto país, entre otros, se deben calcular sobre este valor de seguro o el mismo está exento de estos impuestos?",
              "Este seguro se cobra por cuenta y orden de las mutuales de seguros, es decir, la empresa no es sujeto obligado de ningún impuesto sobre estos montos."
            )}
            {makeFAQs(
              "¿En  caso  de  existir  algún  tipo  de  descuento  o  promoción  sobre  el  boleto  a  comprar,  el  monto  del  seguro debe verse afectado por dichos descuentos o promociones?",
              "No, el monto del seguro no se ve afectado por descuentos o promociones. Se debe cobrar el valor vigente del seguro, discriminado en renglón aparte conforme indica la resolución."
            )}
            {makeFAQs(
              "¿El nuevo seguro es obligatorio solo para los boletos que tienen butacas asignadas o para los boletos de seguros de menores también debe cobrarse?",
              "El nuevo seguro es obligatorio para todo pasajero que utilice el servicio, incluyendo menores."
            )}
            {makeFAQs(
              "En  los  boletos  emitidos  a  personas  con  certificado  de  discapacidad  o  credencial  del  INCUCAI,  que actualmente son sin cargo ¿El monto del seguro debe cobrarse como un adicional?",
              "Conforme la normativa vigente para los boletos emitidos a personas con certificado de discapacidad o credencial del INCUCAI, será el Ministerio de Transporte quien se haga cargo del costo de este seguro, debiendo de igual forma discriminarse  en  renglón  aparte  en  el  correspondiente  boleto,  sirviendo  de  constancia  al  pasajero  de  la  cobertura contratada."
            )}
            {makeFAQs(
              "¿Qué  sucede  en  caso  de  devolución  de  un  boleto?  ¿Corresponde  el  reintegro  del  seguro?  ¿En  caso  que exista retención por la devolución, se debe aplicar a dicho monto?",
              "En caso de devolución de un boleto corresponde el reintegro de la totalidad del seguro cobrado. El seguro se factura una vez cerrada la lista de pasajeros y/o DUT, siendo este evento posterior a cualquier devolución."
            )}
            {makeFAQs(
              "¿Para el caso de boletos abiertos, debe contemplarse dicho monto al momento de la venta o al momento de la confirmación?",
              "El valor del seguro a abonar es el determinado para el período correspondiente a la fecha de inicio del viaje, el que será cobrado con posterioridad al cierre de las LISTAS DE PASAJEROS / DUT."
            )}
            {makeFAQs(
              "¿Qué debe suceder en caso que entre el periodo de compra y el periodo de confirmación del boleto abierto del monto del seguro cambie en aumento o en decremento, se debe cobrar o devolver dicha diferencia?",
              "En  casos  de  variaciones  en  el  costo  del  seguro  entre  la  compra  y  la  confirmación  del  boleto  abierto,  el  pasajero deberá abonar la diferencia correspondiente, debiendo emitirse el boleto con el valor del seguro vigente al momento de iniciar el viaje."
            )}
            {makeFAQs(
              "En una operación del tipo canje ¿Se debe tener en cuenta el monto del seguro? ¿Si el mismo cambió respecto de  valor  en  la  compra  del  boleto  a  canjear  debe  considerarse  el  cobro  o  devolución  de  los  montos correspondientes?",
              "Todo pasajero que viaje tiene cobertura y debe pagar su seguro, esto sin importar que el viaje en cuestión sea fruto de un canje o de alguna otra promoción."
            )}
            {makeFAQs(
              "¿Cómo puedo registrarme?",
              <>Para realizar el alta de tu usuario ingresa en <StrongLink to={register.path} text="Registrarse" />. Verificá y completá todos los datos requeridos. Tené en cuenta que el correo electrónico con el que registres tu razón social será el único que tenga acceso a la plataforma para la administración y gestión de la nueva cobertura.</>
            )}
            {makeFAQs(
              "¿Cómo activo mi usuario?",
              <>Para <StrongLink to={confirmEmail.path} text="activar tu usuario" /> vas a necesitar el código de activación de 6 dígitos que enviamos al correo electrónico que utilizaste para registrarte. Confirmá tu correo electrónico, ingresa el código y elegí tu contraseña ¡Tu registro se habrá completado exitosamente!</>
            )}
            {makeFAQs(
              "¿Cómo recupero mi contraseña?",
              <>Para <StrongLink to={passwordChange.path} text="recuperar tu contraseña" /> vas a necesitar ingresar el correo electrónico con el que te encontrás registrado y utilizar el código de 6 dígitos que te enviaremos. Escribí una nueva y presiona continuar ¡Tu contraseña habrá sido cambiada!</>
            )}
            {makeFAQs(
              "¿Cómo pago los seguros contratados?",
              <>"La única forma de pago habilitada es debin en <StrongLink to={bankAccounts.path} text="cuentas bancarias" /> y es indispensable que te encuentres registrado en nuestro sitio. Cumplidas 48 horas desde el cierre del Documento Universal de Transporte (DUT) y/o Lista de Pasajeros, los seguros contratados serán debitados de las <StrongLink to={bankAccounts.path} text="cuentas" /> consignadas en la registración.</>
            )}
            {makeFAQs(
              "¿Cómo modifico mis cuentas bancarias?",
              <>Logueate y accedé desde tu usuario al menú <StrongLink to={bankAccounts.path} text="cuentas bancarias" /> para modificar o agregar las necesarias para realizar el pago de tus seguros.</>
            )}
            {makeFAQs(
              "¿Qué pasa si los seguros contratados se encontraran impagos?",
              "La aseguradora debe informar sobre la efectiva cobertura de cada DUT y LISTA DE PASAJEROS al organismo de control, en los términos de la resolución 544/98, siendo pasible de las sanciones previstas en caso de incumplimiento."
            )}
            {makeFAQs(
              "¿Cómo visualizo los seguros pagados?",
              <>Logueate y accedé desde tu usuario al menú <StrongLink to={trips.path} text="lista de viajes" />. Utiliza los distintos filtros para visualizar los DUT/LISTAS DE PASAJEROS de los pagos que estás buscando.</>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Landing;
