import { Button, Modal, Typography } from "antd"

const { Paragraph, Title } = Typography

interface UserRegistrationWarningModalProps {
  isOpen: boolean
  handleClickOk?: () => Promise<void> | void
}

const UserRegistrationWarningModal = ({ isOpen = false, handleClickOk }: UserRegistrationWarningModalProps) => {

  const handleClick = () => {
    handleClickOk && handleClickOk()
  }

  return (
    <Modal
      open={isOpen}
      closable={false}
      footer={null}
      maskClosable={true}
    >
      <Title level={4}>Aceptación de suscripción</Title>
      <Paragraph>
        Para completar con éxito su registro, por favor confirme la suscripción desde su plataforma de banca en línea o billetera virtual.
      </Paragraph>
      <Button type="primary" onClick={handleClick}>
        Continuar
      </Button>
    </Modal>
  )
}
export default UserRegistrationWarningModal