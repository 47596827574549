import React from "react";

function CheckIcon(){
return <svg width="173" height="117" viewBox="0 0 173 117" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.669502 88.5742C-4.5305 54.9742 21.8362 43.5742 35.6695 42.0742C85.6695 42.0742 66.6695 10.0738 109.669 1.07382C152.669 -7.92618 167.169 42.0742 168.169 60.5738C169.169 79.0735 140.169 108.574 109.669 93.0738C92.6585 84.4289 76.7654 91.8075 65.9888 101.079C58.0052 107.948 49.883 115.616 39.3934 116.556C23.2935 117.999 4.47131 113.14 0.669502 88.5742Z" fill="#EFFEFF"/>
<rect x="56" y="30" width="56" height="56" rx="8" fill="#00A6AE"/>
<path d="M108.75 25V25C113.306 25 117 28.6936 117 33.25V80C117 86.0751 112.075 91 106 91H84H75.75M59.25 91V91C54.6937 91 51 87.3063 51 82.75V36C51 29.9249 55.9249 25 62 25H84H92.25" stroke="#202124" stroke-width="1.7" stroke-linecap="round"/>
<path d="M72 56.5L79.5 64L96.5 47" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="8.5" cy="42.5" r="4.5" fill="#EFFEFF"/>
<circle cx="19.5" cy="35.5" r="2.5" fill="#EFFEFF"/>
<circle cx="159.5" cy="95.5" r="4.5" fill="#EFFEFF"/>
<circle cx="170.5" cy="88.5" r="2.5" fill="#EFFEFF"/>
</svg>
}

export default CheckIcon;
