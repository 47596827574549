import { Link  } from "react-router-dom";

interface StrongLinkProps { 
  to: string;
  text: string;
}

export const StrongLink = ({ to, text }: StrongLinkProps) => (
  <Link to={to}> 
    <strong>{text}</strong>
  </Link>
);
