import React, { useCallback, useEffect, useState } from "react";
import {
  PreliquidationFiltersParams,
  PreliquidationItem,
  PreliquidationsFiltersForm,
} from "../../../../models/Preliquidation/Preliquidation";
import { PreliquidationDTO } from "../../../../DTO/preliquidation/preliquidation.dto";
import { getPreliquidations } from "../../../../services/preliquidation.service";
import { ErrorResponse } from "../../../../models/Errors/ErrorResponse";
import { errorMessages } from "../../../../models/Errors/Errors";
import { message } from "antd";

interface ManagePreliquidationContextState {
  selectedPreliquidation?: PreliquidationItem;
  handleSelectPreliquidation: (preliquidation: PreliquidationItem) => void;
  isPreliquidationApprovalModalOpen: boolean;
  isPreliquidationRejectionModalOpen: boolean;
  isPreliquidationUpdateModalOpen: boolean;
  openApprovePreliquidationModal: () => void;
  closeApprovePreliquidationModal: () => void;
  openRejectPreliquidationModal: () => void;
  closeRejectPreliquidationModal: () => void;
  openUpdatePreliquidationModal: () => void;
  closeUpdatePreliquidationModal: () => void;
  loadingPreliquidations: boolean;
  setLoadingPreliquidations: (loading: boolean) => void;
  preliquidations: PreliquidationDTO | undefined;
  handleManagePreliquidationsUpdateTable: (params: PreliquidationFiltersParams) => void;
  initialLoading: boolean;
}

const initialManagePreliquidationContextState: ManagePreliquidationContextState = {
  selectedPreliquidation: undefined,
  handleSelectPreliquidation: () => {},
  isPreliquidationApprovalModalOpen: false,
  isPreliquidationRejectionModalOpen: false,
  isPreliquidationUpdateModalOpen: false,
  openApprovePreliquidationModal: () => {},
  closeApprovePreliquidationModal: () => {},
  openRejectPreliquidationModal: () => {},
  closeRejectPreliquidationModal: () => {},
  openUpdatePreliquidationModal: () => {},
  closeUpdatePreliquidationModal: () => {},
  loadingPreliquidations: false,
  preliquidations: undefined,
  handleManagePreliquidationsUpdateTable: () => {},
  initialLoading: true,
  setLoadingPreliquidations: () => {},
};

const ManagePreliquidationContext = React.createContext<ManagePreliquidationContextState>(
  initialManagePreliquidationContextState
);

interface ManagePreliquidationContextProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

export const ManagePreliquidationContextProvider = ({ children }: ManagePreliquidationContextProviderProps) => {
  const [selectedPreliquidation, setSelectedPreliquidation] = useState<PreliquidationItem>();

  const [isPreliquidationApprovalModalOpen, setIsPreliquidationApprovalModalOpen] = useState<boolean>(
    initialManagePreliquidationContextState.isPreliquidationApprovalModalOpen
  );
  const [isPreliquidationRejectionModalOpen, setIsPreliquidationRejectionModalOpen] = useState<boolean>(
    initialManagePreliquidationContextState.isPreliquidationRejectionModalOpen
  );
  const [isPreliquidationUpdateModalOpen, setIsPreliquidationUpdateModalOpen] = useState<boolean>(
    initialManagePreliquidationContextState.isPreliquidationUpdateModalOpen
  );

  const [managePreliquidationsUpdateTable, setManagePreliquidationsUpdateTable] = useState<boolean>(false);

  const [preliquidationPagination, setPreliquidationPagination] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 1, pageSize: 10 });

  const [preliquidations, setPreliquidations] = useState<PreliquidationDTO>();

  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const [loadingPreliquidations, setLoadingPreliquidations] = useState<boolean>(false);

  const [managePreliquidationFilters, setManagePreliquidationFilters] = useState<PreliquidationsFiltersForm>({
    preliquidationId: undefined,
    createdAt: undefined,
    preliquidationStatus: undefined,
    closedDate: undefined,
  });

  const handleSelectPreliquidation = (preliquidation: PreliquidationItem) => setSelectedPreliquidation(preliquidation);
  const openApprovePreliquidationModal = useCallback(() => setIsPreliquidationApprovalModalOpen(true), []);
  const closeApprovePreliquidationModal = useCallback(() => setIsPreliquidationApprovalModalOpen(false), []);
  const openRejectPreliquidationModal = useCallback(() => setIsPreliquidationRejectionModalOpen(true), []);
  const closeRejectPreliquidationModal = useCallback(() => setIsPreliquidationRejectionModalOpen(false), []);
  const openUpdatePreliquidationModal = useCallback(() => setIsPreliquidationUpdateModalOpen(true), []);
  const closeUpdatePreliquidationModal = useCallback(() => setIsPreliquidationUpdateModalOpen(false), []);

  const fetchPreliquidationsData = async () => {
    try {
      setLoadingPreliquidations(true);
      const response = await getPreliquidations(
        managePreliquidationFilters,
        preliquidationPagination.page,
        preliquidationPagination.pageSize
      );
      setPreliquidations(response);
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      const erroMessage = errorResponse ? errorMessages[errorResponse.Type] : errorMessages.tryAgain;
      message.error(erroMessage);
    } finally {
      setLoadingPreliquidations(false);
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    void fetchPreliquidationsData();
  }, [
    managePreliquidationFilters,
    preliquidationPagination.page,
    preliquidationPagination.pageSize,
    managePreliquidationsUpdateTable,
  ]);

  const handleManagePreliquidationsUpdateTable = ({
    preliquidationsFilters,
    page,
    pageSize,
  }: PreliquidationFiltersParams) => {
    preliquidationsFilters && setManagePreliquidationFilters(preliquidationsFilters);
    if (page && pageSize) {
      setPreliquidationPagination({ page, pageSize });
    }
    setManagePreliquidationsUpdateTable((prev) => !prev);
  };

  return (
    <ManagePreliquidationContext.Provider
      value={{
        selectedPreliquidation,
        handleSelectPreliquidation,
        isPreliquidationApprovalModalOpen,
        isPreliquidationRejectionModalOpen,
        isPreliquidationUpdateModalOpen,
        openApprovePreliquidationModal,
        closeApprovePreliquidationModal,
        openRejectPreliquidationModal,
        closeRejectPreliquidationModal,
        openUpdatePreliquidationModal,
        closeUpdatePreliquidationModal,
        loadingPreliquidations,
        preliquidations,
        handleManagePreliquidationsUpdateTable,
        initialLoading,
        setLoadingPreliquidations,
      }}
    >
      {children}
    </ManagePreliquidationContext.Provider>
  );
};

export default ManagePreliquidationContext;
