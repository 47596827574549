import { Button, Col, Row, message } from "antd";

import React, { useContext } from "react";
import PreliquidationContext from "../../contexts/PreliquidationContextProvider";
import GeneratePreliquidationFilters from "../GeneratePreliquidationFilters/GeneratePreliquidationFilters";
import GeneratePreliquidationTripsTable from "../GeneratePreliquidationTripsTable/GeneratePreliquidationTripsTable";
import DiscountForm from "../DiscountForm/DiscountForm";
import DiscountTable from "../DiscountTable/DiscountTable";
import PreliquidationDetailsTable from "../PreliquidationDetailsTable/PreliquidationDetailsTable";
import { savePreliquidationPending } from "../../../../../services/preliquidation.service";
import { errorMessages } from "../../../../../models/Errors/Errors";
import { useNavigate } from "react-router-dom";
import { ErrorResponse } from "../../../../../models/Errors/ErrorResponse";

const GeneratePreliquidation: React.FC = () => {
  const { currentStep, setCurrentStep, setGeneratePreliquidationStatus, preliquidationId, operationMode } =
    useContext(PreliquidationContext);

  const navigate = useNavigate();

  const onFinishStep2 = async () => {
    //TODO: actualizar respuesta cuando se definan contratos
    try {
      if (operationMode === "create") {
        await savePreliquidationPending(preliquidationId);
      }
      message.success("Preliquidación actualizada exitosamente");

      setCurrentStep(currentStep + 1);
      setGeneratePreliquidationStatus("process");
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data;
      const type = errorResponse.Type;
      const errorMessage = errorMessages[type] ?? errorMessages.tryAgain;
      message.error(errorMessage);
    }
    setCurrentStep(currentStep + 1);
    setGeneratePreliquidationStatus("finish");
    navigate("/managePreliquidations");
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <GeneratePreliquidationFilters />
      <GeneratePreliquidationTripsTable />
      <Row gutter={[24, 12]}>
        <Col xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 6 }}>
          <DiscountForm />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 18 }}>
          <DiscountTable />
        </Col>
      </Row>

      <PreliquidationDetailsTable />

      <Row gutter={[16, 16]} justify={"end"}>
        <Col span={24}>
          <div style={{ float: "right" }}>
            <Row gutter={[16, 16]}>
              <Col>
                <Button
                  type="default"
                  form={"selectTripsToPreliquidateForm"}
                  onClick={() => navigate("/managePreliquidations")}
                >
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button type="primary" onClick={onFinishStep2} form={"generatePreliquidationForm"}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GeneratePreliquidation;
