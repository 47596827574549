import { message } from "antd"
import { useEffect, useState } from "react"
import { errorMessages } from "../../../../models/Errors/Errors"
import { getTransportCompanies } from "../../../../services/transportCompany.services"
import { TransportCompanyResponse } from "../../../../models/TransportCompany/TransportCompany"

const useBankAccountStatusFilters = () => {
  const [transporters, setTransporters] = useState<TransportCompanyResponse[]>([])
  const [loading, setLoading] = useState(true)

  const getTransporters = async () => {
    try {
      const response = await getTransportCompanies({ isRegistered: true })
      setTransporters(response)
    } catch {
      message.error(errorMessages.tryAgain)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTransporters()
  }, [])

  return {
    transporters,
    loading
  }
}

export default useBankAccountStatusFilters