import { Alert, Layout, Typography } from "antd"
import { Content } from "antd/es/layout/layout"

import './EndorsementRejection.scss'
import RejectEndorsementsForm from "./components/RejectEndorsementsFiltersForm/RejectEndorsementsForm"
import RejectEndorsementsTable from "./components/RejectEndorsementsTable/RejectEndorsementsTable"
import { EndorsementRejectionContextProvider } from "./context/EndorsementRejectionContextProvider"

const { Title } = Typography

const EndorsementRejection = () => {
  return (
    <Layout>
      <Content className="endorsement-rejection-content">
        <Title level={2}>Rechazar endosos</Title>
        <div className="endorsement-rejection-content__content-alert">
          <Alert
            message="Seleccione el archivo xlsx que desea importar para rechazar los endosos."
            type="info"
          />
        </div>
        <div className="endorsement-rejection-content layer">
         <EndorsementRejectionContextProvider>
            <RejectEndorsementsForm />
            <RejectEndorsementsTable />
         </EndorsementRejectionContextProvider>
        </div>
      </Content>
    </Layout>
  )
}

export default EndorsementRejection
