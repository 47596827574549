import { isNullOrWhiteSpace } from "../../utils/string.utils";
import { BankAccount, IBankAccount } from "../BankAccount";
import { Phone } from "../Phone";
import { TransportCompanyBusinessName } from "./TransportCompanyBusinessName";
import { TransportCompanyForm } from "./TransportCompanyForm";

export class TransportCompany {
  constructor(
    public agreement: boolean = true,
    public businessName: string,
    public companyNumber: string,
    public email: string,
    public confirmEmail: string,
    public acceptedTermId: string,
    public phone: Phone,
    public bankAccounts: BankAccount[]
  ) {}

  static mapToTransportCompany(form: TransportCompanyForm, acceptedTermId: string): TransportCompany {
    const phone = new Phone(form.countryCode, form.number);
    const bankAccounts: BankAccount[] = [new BankAccount(form.mainAccountCuit, form.mainAccountCbu, 1)];

    if (!isNullOrWhiteSpace(form.secondaryAccountCbu) || !isNullOrWhiteSpace(form.secondaryAccountCuit)) {
      bankAccounts.push(new BankAccount(form.secondaryAccountCuit, form.secondaryAccountCbu, 2));
    }

    return new TransportCompany(
      form.agreement,
      form.businessName,
      form.companyNumber,
      form.email,
      form.confirmEmail,
      acceptedTermId,
      phone,
      bankAccounts
    );
  }
}

export interface TransportCompaniesParams {
  documentNumber?: number;
  isRegistered?: boolean;
  PayManually?: boolean;
  economicGroupId?: string;
}

// sustituir la clase por esta interfaz y crear los servicios
// necesarios que tenga la clase TransportCompany y cambiar el nombre de esta interface por TransportCompany
export interface TransportCompanyResponse {
  id: string;
  name: string;
  number: number;
}

export interface TransportCompanyServices {
  getTransportCompanyBusinessNames: () => Promise<TransportCompanyBusinessName[]>;
  getTransportCompanies: (params?: TransportCompaniesParams) => Promise<TransportCompanyResponse[]>;
  getBankAccountsByTransportCompanyId: (id: string) => Promise<IBankAccount[]>;
  changeBankAccountStatusByTransportCompanyId: (
    transportCompanyId: string,
    bankAccountId: string,
    isActive: boolean
  ) => Promise<void>;
  totalRegisterTransportCompany: (params: any) => Promise<any>;
  getPreliquidationIncludedTransportCompanies: (preliquidationId: string) => Promise<TransportCompanyResponse[]>;
  deleteDuplicatedBusinessNames: (array: TransportCompanyResponse[]) => TransportCompanyResponse[];
}
