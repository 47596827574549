import { useEffect, useState } from "react";
import {
	DeleteOutlined,
	EditOutlined,
	LeftOutlined,
	PlusOutlined,
	PlusSquareOutlined,
} from "@ant-design/icons";
import { Button, Layout, Modal, Space, Table } from "antd";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../../assets/DeleteIcon";
import { deleteRate, getRates } from "../../../services/api.client";
import {
	errorNotification,
	successNotification,
} from "../../atoms/notification/Notification";
import moment from "moment";
import { RateResponse, RateTableItem } from "../../../models/Rate";
import { PaginatedResponse } from "../../../models/Pagination";
import Title from "antd/es/typography/Title";
import { Content } from "antd/es/layout/layout";
import { ErrorResponse } from "../../../models/Errors/ErrorResponse";
import { getMessageErrors } from "../../../models/Errors/Errors";
import AddRateModal from "./AddRateModal";
import AddRateBatchModal from "./AddRateBatchModal";
import EditRateModal from "./EditRateModal";
import { DefaultDateFormat } from "../../../models/Date";
import { trips } from "../../../constants/routes";
import { toARSCurrency } from "../../../utils/number.utils";
import RatesFilters from "../../atoms/RatesFilters/RatesFilters";
import "./Rates.scss";

const pageSize = 20;

const Rates = () => {
	const navigate = useNavigate();
	const [rates, setRates] = useState<PaginatedResponse<RateResponse>>();
	const [rate, setRate] = useState<RateTableItem>();
	const [loading, setLoading] = useState<boolean>(false);
	const [isDeleteRateModalOpen, setDeleteRateModalOpen] =
		useState<boolean>(false);
	const [addRateModalOpen, setAddRateModalOpen] = useState<boolean>(false);
	const [editRateModalOpen, setEditRateModalOpen] = useState<boolean>(false);
	const [addRateBatchModalOpen, setAddRateBatchModalOpen] =
		useState<boolean>(false);

	const [modality, setModality] = useState<string>();
	const [serviceTypeId, setServiceTypeId] = useState<string>();
	const [fromTo, setFromTo] = useState<
		[string | undefined, string | undefined]
	>([undefined, undefined]);
	const [isNotDatedUntil, setIsNotDatedUntil] = useState<boolean>(true);

	useEffect(() => {
		getRatesFromApi(1, pageSize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getRatesFromApi = async (page: number, pageSize: number) => {
		setLoading(true);
		const toIsDefined = isNotDatedUntil ? false : undefined;
		setRates(
			await getRates(
				page,
				pageSize,
				fromTo[0],
				fromTo[1],
				modality,
				serviceTypeId,
				toIsDefined,
			),
		);
		setLoading(false);
	};

	const deleteRateFromApi = async (id: string) => {
		setLoading(true);

		try {
			await deleteRate(id);
			setDeleteRateModalOpen(false);
			successNotification("Tarifa eliminada con éxito.");
			getRatesFromApi(1, pageSize);
		} catch (error: any) {
			const errorResponse: ErrorResponse = error.response?.data;
			errorNotification(getMessageErrors(errorResponse?.Type));
		}

		setDeleteRateModalOpen(false);
		setLoading(false);
	};

	const openDeleteRateModal = (rate: any) => {
		setDeleteRateModalOpen(true);
		setRate(rate);
	};

	const openEditRateModal = (rate: any) => {
		setEditRateModalOpen(true);
		setRate(rate);
	};

	const getActionButtons = (rate: RateTableItem) => (
		<Space size={0} wrap>
			<Button type="link" onClick={() => openEditRateModal(rate)}>
				<EditOutlined /> Editar
			</Button>
			<Button danger type="text" onClick={() => openDeleteRateModal(rate)}>
				<DeleteOutlined /> Borrar
			</Button>
		</Space>
	);

	return (
		<Layout>
			<Content className="ratesContent">
				<div style={{ marginBottom: 30 }}>
					<Button ghost onClick={() => navigate(trips.path)}>
						<Title level={2}>
							<LeftOutlined /> Tarifas
						</Title>
					</Button>
				</div>

				<Space wrap>
					<Button
						type="primary"
						onClick={() => setAddRateModalOpen(true)}
						icon={<PlusOutlined />}
						size="large"
					>
						Agregar tarifa
					</Button>
					<Button
						type="default"
						onClick={() => setAddRateBatchModalOpen(true)}
						icon={<PlusSquareOutlined />}
						size="large"
					>
						Agregar tarifas en bloque
					</Button>
				</Space>

				<RatesFilters
					getRatesFromApi={() => getRatesFromApi(1, pageSize)}
					setLoading={setLoading}
					setModality={setModality}
					setServiceTypeId={setServiceTypeId}
					setFromTo={setFromTo}
					setIsNotDatedUntil={(value) => setIsNotDatedUntil(value)}
					loading={loading}
					modality={modality}
					serviceTypeId={serviceTypeId}
					fromTo={fromTo}
					isNotDatedUntil={isNotDatedUntil}
				/>

				<div className="ratesTable">
					<Table
						loading={loading}
						scroll={{ x: true }}
						size="small"
						pagination={{
							position: ["bottomCenter"],
							total: rates?.totalItems,
							pageSize: rates?.pageSize,
							locale: {
								next_page: "Próxima página",
								prev_page: "Página anterior",
							},
							onChange: (page: number, pageSize: number) =>
								getRatesFromApi(page, pageSize),
						}}
						columns={[
							{
								title: "Modalidad",
								dataIndex: "modalityDescription",
								key: "modalityDescription",
							},
							{
								title: "Tipo servicio",
								dataIndex: "serviceType",
								key: "serviceType",
							},
							{ title: "Fecha desde", dataIndex: "fromDate", key: "fromDate" },
							{ title: "Fecha hasta", dataIndex: "toDate", key: "toDate" },
							{
								title: "Tarifa pasajero",
								dataIndex: "amountPerPassengerFormated",
								key: "amountPerPassengerFormated",
								align: "right",
							},
							{
								title: "Fee",
								dataIndex: "fee",
								key: "fee",
								align: "right",
								render: (_, rate) => `${rate.fee * 100}%`,
							},
							{
								title: "Acciones",
								key: "actions",
								fixed: "right",
								width: 220,
								render: (_, rate) => getActionButtons(rate),
							},
						]}
						dataSource={rates?.items?.map(
							(rate: RateResponse, index): RateTableItem => ({
								id: rate.id,
								key: index,
								fromDate: moment(rate.from).format(DefaultDateFormat),
								toDate: rate.to
									? moment(rate.to).format(DefaultDateFormat)
									: "-",
								amountPerPassengerFormated:
									toARSCurrency(rate.amountPerPassenger) ?? "-",
								modalityDescription: rate.modality.description,
								from: rate.from,
								to: rate.to,
								modality: rate.modality,
								amountPerPassenger: rate.amountPerPassenger,
								serviceType: rate.modality.serviceType.description,
								fee: rate.fee,
							}),
						)}
					/>
				</div>

				<AddRateModal
					getRatesFromApi={() => getRatesFromApi(1, pageSize)}
					setAddRateModalOpen={setAddRateModalOpen}
					addRateModalOpen={addRateModalOpen}
				/>

				<EditRateModal
					getRatesFromApi={() => getRatesFromApi(1, pageSize)}
					setEditRateModalOpen={setEditRateModalOpen}
					editRateModalOpen={editRateModalOpen}
					rate={rate}
				/>

				<AddRateBatchModal
					getRatesFromApi={() => getRatesFromApi(1, pageSize)}
					setAddRateBatchModalOpen={setAddRateBatchModalOpen}
					addRateBatchModalOpen={addRateBatchModalOpen}
				/>

				<Modal
					open={isDeleteRateModalOpen}
					closable={false}
					maskClosable={true}
					onCancel={() => setDeleteRateModalOpen(false)}
					onOk={() => rate && deleteRateFromApi(rate.id)}
					okText="Continuar"
					cancelText="Cancelar"
				>
					<Space>
						<DeleteIcon />
						<Space direction="vertical">
							<p>
								Está a punto de eliminar la tarifa para{" "}
								<strong>
									{rate?.modalityDescription} ({rate?.serviceType}){" "}
								</strong>
								del{" "}
								<strong>
									{rate?.fromDate} al {rate?.toDate}
								</strong>
								. Esto puede afectar el proceso de cierre de viajes donde se
								calculan montos de endoso.
							</p>
							<p>¿Está seguro de que desea continuar?</p>
						</Space>
					</Space>
				</Modal>
			</Content>
		</Layout>
	);
};

export default Rates;
