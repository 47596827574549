interface QueryParams {
  [key: string]: string | number | undefined | boolean;
};

export const createQueryParams = (params: QueryParams): string => {
  const queryParams: string[] = [];

  const addQueryParam = (key: string, value: string | number | undefined | boolean) => {
    if (value !== null && value !== undefined && value !== '') {
      queryParams.push(`${key}=${value}`);
    }
  };

  for (const key in params) {
    addQueryParam(key, params[key]);
  }

  return `?${queryParams.join('&')}`;
};

