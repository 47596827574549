import { Layout, Typography, message } from "antd"
import { Content } from "antd/es/layout/layout"

import PaymentForm from "./components/paymentForm/PaymentForm"

import { registerManualPayment } from "../../../services/payment.services"
import { errorMessages } from "../../../models/Errors/Errors"

import "./payments.scss"
import { ErrorResponse } from "../../../models/Errors/ErrorResponse"

const { Title } = Typography

const Payments = () => {

  const handleSubmit = async (values: any) => {
    try {
      const document = values.document
      const file = document ? document[0] : {}
      const data = {
        jsonData: {
          amount: values.amount,
          description: values.description || '',
          economicGroupId: values.group ?? null,
          externalId: values.payId,
          fundsSource: values.sourceFunds || null,
          methodId: values.methodPayment,
          paidAt: values.paymentDate,
          transportCompanyId: values.transporter ?? null
        },
        file: file.originFileObj
      }
      await registerManualPayment(data)
      message.success('Pago registrado correctamente')
    } catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data
      const type = errorResponse.Type
      const errorMessage = errorMessages[type] ?? errorMessages.tryAgain
      message.error(errorMessage)
    }

  }

  return (
    <Layout>
      <Layout>
        <Content className="payment-content">
          <Title level={2}>Registro de pagos</Title>
          <PaymentForm hanldeSubmit={handleSubmit} />
        </Content>
      </Layout>
    </Layout>
  )
}

export default Payments