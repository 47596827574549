import tripsServices from "../../../../services/trips.services"
import { errorNotification } from "../../notification/Notification"

interface UseTripsDocumentsProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const useTripsDocuments = ({ setLoading }: UseTripsDocumentsProps) => {
  const downloadDocument = (data: any, tripId: string) => {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${tripId}.pdf`);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  }

  const downloadTripCertificate = async (tripId: string) => {
    setLoading(true)
    try {
      const data = await tripsServices.downloadCertificate(tripId)
      downloadDocument(data, tripId)
    } catch {
      errorNotification("Error al descargar el certificado.");
    } finally {
      setLoading(false)
    }
  }

  const downloadTripReceipt = async (tripId: string) => {
    setLoading(true);
    try {
      const data = await tripsServices.downloadReceipt(tripId)
      downloadDocument(data, tripId)
    } catch (error) {
      errorNotification("Error al descargar el recibo.");
    } finally {
      setLoading(false);
    }
  }

  return {
    downloadTripCertificate,
    downloadTripReceipt
  }
}

export default useTripsDocuments
