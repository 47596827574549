import React, { useState } from 'react'
import { rejectEndorsements } from '../../../../services/endorsement.services'
import { message } from 'antd'
import { errorMessages } from '../../../../models/Errors/Errors'
import { ErrorResponse } from '../../../../models/Errors/ErrorResponse'
import { RejectedEndorsement } from "../../../../models/Endorsement/RejectedEndorsement"

interface EndorsementRejectionContextState {
  rejectedEndorsements: RejectedEndorsement[]
  loading: boolean
  handleSubmitRejectEndorsements: (file: File) => Promise<void>
}

const initialEndorsementRejectionContextState: EndorsementRejectionContextState = {
  rejectedEndorsements: [],
  loading: false,
  handleSubmitRejectEndorsements: async (file: File) => {},
}

const EndorsementRejectionContext = React.createContext<EndorsementRejectionContextState>(initialEndorsementRejectionContextState)

interface EndorsementRejectionContextProviderProps {
  children: React.ReactNode | React.ReactNode[]
}


export const EndorsementRejectionContextProvider = ({ children }: EndorsementRejectionContextProviderProps) => {
  const [rejectedEndorsements, setRejectedEndorsements] = useState<RejectedEndorsement[]>(initialEndorsementRejectionContextState.rejectedEndorsements)
  const [loading, setLoading] = useState<boolean>(initialEndorsementRejectionContextState.loading)

  const handleSubmitRejectEndorsements = async (file: File) => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('file', file)
      const data = await rejectEndorsements(formData)
      setRejectedEndorsements(data)
      message.success('El archivo se ha importado correctamente')
    }catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data
      const errorMessage = errorResponse ? errorMessages[errorResponse.Type] : errorMessages.tryAgain
      message.error(errorMessage)
      setRejectedEndorsements(initialEndorsementRejectionContextState.rejectedEndorsements)
    } finally {
      setLoading(false)
    }
  }

  return (
    <EndorsementRejectionContext.Provider 
      value={{
        rejectedEndorsements,
        loading,
        handleSubmitRejectEndorsements,
      }}
    >
      {children}
    </EndorsementRejectionContext.Provider>
  )
}

export default EndorsementRejectionContext
