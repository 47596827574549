import { useEffect } from "react";
import { DatePicker, Form, Input, InputNumber, Modal, Select } from "antd";
import { ApiDateFormat, updateRate } from "../../../services/api.client";
import { errorNotification, successNotification } from "../../atoms/notification/Notification";
import { getMessageErrors } from "../../../models/Errors/Errors";
import { ErrorResponse } from "../../../models/Errors/ErrorResponse";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/es_ES";
import { RateTableItem } from "../../../models/Rate";
import "moment/locale/zh-cn";
import { DefaultDateFormat } from "../../../models/Date";

const { RangePicker } = DatePicker;

interface EditRateModalProps {
  getRatesFromApi: () => void;
  setEditRateModalOpen: (value: any) => void;
  editRateModalOpen: boolean;
  rate: RateTableItem | undefined;
}

const EditRateModal = ({ getRatesFromApi, setEditRateModalOpen, editRateModalOpen, rate }: EditRateModalProps) => {
  const [editRateForm] = Form.useForm();

  useEffect(() => {
    editRateForm.resetFields();
    editRateForm.setFieldsValue({
      id: rate?.id,
      modalityId: rate?.modality.id,
      fromTo: [dayjs(rate?.from), rate?.to && dayjs(rate?.to)],
      amountPerPassenger: rate?.amountPerPassenger,
      fee: rate?.fee
    });

  }, [rate]);


  const editRate = () => {
    editRateForm
      .validateFields()
      .then(async rate => {

        try {
          await updateRate(rate.id, rate.fromTo[1].format(ApiDateFormat));
          successNotification("Tarifa modificada con éxito");
          getRatesFromApi();
          setEditRateModalOpen(false);
          editRateForm.resetFields();
        } catch (error: any) {
          const errorResponse: ErrorResponse = error.response?.data;
          errorNotification(getMessageErrors(errorResponse?.Type));
        }
      });
  }

  return (
    <Modal
      title="Editar tarifa"
      okText="Editar"
      cancelText="Cancelar"
      open={editRateModalOpen}
      onCancel={() => setEditRateModalOpen(false)}
      onOk={() => editRate()}
    >
      <Form
        form={editRateForm}
        layout="vertical"
        name="editRateForm"
      >
        <Form.Item name="id" hidden>
          <Input value={rate?.id} />
        </Form.Item>

        <Form.Item
          name="modalityId"
          label="Modalidad"
          rules={[{ required: true, message: 'Seleccione la modalidad' }]}
        >
          <Select
            placeholder="Seleccionar modalidad"
            value={rate?.modality?.id}
            onMouseDown={e => { e.preventDefault(); e.stopPropagation(); }}
            options={[rate?.modality]?.map((modality: any) => ({
              value: modality?.id,
              label: `${modality?.description} (${modality?.serviceType?.description})`,
            }))}
            style={{ width: "100%" }}
            disabled
          />
        </Form.Item>

        <Form.Item
          name="fromTo"
          label="Fecha"
          rules={[
            {
              validator: (_, value) => {
                if (!value || value[1] == null) {
                  return Promise.reject('Seleccione la fecha');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <RangePicker
            locale={locale}
            format={DefaultDateFormat}
            disabled={[true, false]}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          name="amountPerPassenger"
          label="Precio por pasajero"
          rules={[{ required: true, message: 'Seleccione el precio por pasajero' }]}
        >
          <InputNumber
            type="number"
            placeholder="Ingresar precio"
            value={rate?.amountPerPassenger}
            min={0}
            style={{ width: '100%' }}
            disabled
          />
        </Form.Item>

        <Form.Item
          name="fee"
          label="Fee"
          rules={[{ required: true, message: 'Seleccione el fee' }]}
        >
          <InputNumber
            type="number"
            placeholder="Ingresar porcentaje"
            value={rate?.fee}
            step="0.01"
            min={0}
            precision={2}
            style={{ width: '100%' }}
            disabled
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditRateModal;
