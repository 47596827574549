import React, { useState } from 'react'
import { OtherPayment } from '../../../../models/Trips/OtherPayment'
import { processOtherPayments } from '../../../../services/trips.services'
import { message } from 'antd'
import { errorMessages } from '../../../../models/Errors/Errors'
import { ErrorResponse } from '../../../../models/Errors/ErrorResponse'

interface OtherPaymentsContextState {
  otherPayments: OtherPayment[]
  loading: boolean
  handleSubmitProcessOtherPayments: (file: File) => Promise<void>
}

const initialOtherPaymentsContextState: OtherPaymentsContextState = {
  otherPayments: [],
  loading: false,
  handleSubmitProcessOtherPayments: async (file: File) => {},
}

const OtherPaymentsContext = React.createContext<OtherPaymentsContextState>(initialOtherPaymentsContextState)

interface OtherPaymentsContextProviderProps {
  children: React.ReactNode | React.ReactNode[]
}


export const OtherPaymentsContextProvider = ({ children }: OtherPaymentsContextProviderProps) => {
  const [otherPayments, setOtherPayments] = useState<OtherPayment[]>(initialOtherPaymentsContextState.otherPayments)
  const [loading, setLoading] = useState<boolean>(initialOtherPaymentsContextState.loading)

  const handleSubmitProcessOtherPayments = async (file: File) => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('file', file)
      const data = await processOtherPayments(formData)
      setOtherPayments(data)
      message.success('El archivo se ha importado correctamente')
    }catch (e: any) {
      const errorResponse: ErrorResponse = e.response?.data
      const erroMessage = errorResponse ? errorMessages[errorResponse.Type] : errorMessages.tryAgain
      message.error(erroMessage)
      setOtherPayments(initialOtherPaymentsContextState.otherPayments)
    } finally {
      setLoading(false)
    }
  }

  return (
    <OtherPaymentsContext.Provider 
      value={{
        otherPayments: otherPayments,
        loading,
        handleSubmitProcessOtherPayments,
      }}
    >
      {children}
    </OtherPaymentsContext.Provider>
  )
}

export default OtherPaymentsContext;
