import React, { useState } from 'react'

export interface UserFilters {
  transporter?: string
  role?: string
  numberCompany?: string
}

interface UsersContextState {
  filters: UserFilters
  handleChangeUpdateFilters: (filters: UserFilters) => void
}

const initialUsersContextState: UsersContextState = {
  filters: {},
  handleChangeUpdateFilters: () => {}
}

const UsersContext = React.createContext<UsersContextState>(initialUsersContextState)

interface UsersContextProviderProps {
  children: React.ReactNode | React.ReactNode[]
}


export const UsersContextProvider = ({ children }: UsersContextProviderProps) => {
  const [filters, setFilters] = useState<UserFilters>(initialUsersContextState.filters)

  const handleChangeUpdateFilters = (filters: UserFilters) => {
    setFilters(filters)
  }

  return (
    <UsersContext.Provider 
      value={{
        filters,
        handleChangeUpdateFilters
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}

export default UsersContext
